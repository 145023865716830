import React from 'react';
import { toastr } from 'react-redux-toastr';
import ExportingIcon from 'react-feather/dist/icons/refresh-cw';

export const showRunningToastr = ({ title, body }) => {
  const toastrType = 'warning';
  const toastrOptions = {
    icon: (<div style={{ marginTop: '1vh' }}><ExportingIcon size={30} /></div>),
    status: toastrType,
    timeOut: 30000,
  };

  toastr.warning(title, body, toastrOptions);
}
import api from '../../services/api';
import { Creators } from '../ducks/instrument';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getInstruments({ filter }) {
  try {
    let response;

    yield put(Creators.instrumentRequest());
    response = yield call(api.post, 'instrument', filter);

    yield put(Creators.instrumentSuccess(filter.chip, response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.instrumentError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar instrumentos'
    }));
  }
}
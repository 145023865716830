import api from '../../services/api';
import { Creators } from '../ducks/user_register';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* addUsers({ request }) {
  try {

    yield put(Creators.userRegisterRequest());
    const response = yield call(api.post, 'user-register', request);

    yield put(Creators.userRegisterSuccess(response.data));

    if (response.data.error_cases.count_users_already_exists > 0) {
      let users = ''

      response.data.error_cases.users_already_exists.forEach((row) => {
        users = users + row + '\n'
      })
      
      yield put(toastrActions.add({
        options: {
          timeOut: 0,
          showCloseButton: true,
          closeOnToastrClick: false,
        },
        type: 'error',
        title: 'Email já está cadastrado',
        message: 'Entre em contato com a Oncase para fazer o recadastramento das seguintes contas: ' + users
      }));
      return
    }

    yield put(toastrActions.add({
      type: 'success',
      title: 'Cadastrando usuários',
      message: 'Isso pode levar alguns minutos.'
    }));

  } catch (err) {
    yield captureSentryException(err, request);
    yield put(Creators.userRegisterError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao cadastrar os usuários.'
    }));
  }
}

export function* getUsers({ request }) {
  try {

    yield put(Creators.getUsersRequest());
    const response = yield call(api.get, 'user-register', request);

    yield put(Creators.getUsersSuccess(response.data));

  } catch (err) {
    yield captureSentryException(err, request);
    yield put(Creators.getUsersError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar contas de usuários.'
    }));
  }
}

export function* reSendEmail({ user }) {
  try {

    yield put(Creators.reSendEmailRequest());
    yield call(api.put, 'user-register', user);

    yield put(toastrActions.add({
      type: 'success',
      title: 'Senha reenviada',
      message: 'Isso pode levar alguns minutos.'
    }));

    const response = yield call(api.get, 'user-register');
    yield put(Creators.getUsersSuccess(response.data));

  } catch (err) {
    yield captureSentryException(err, user);
    yield put(Creators.reSendEmailError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao reenviar o email para redefinição de senha.'
    }));
  }
}

export function* deleteUsers({ users }) {
  try {

    yield put(Creators.deleteUsersRequest());
    yield call(api.put, 'user-register', users);

    yield put(toastrActions.add({
      type: 'success',
      title: 'Usuário deletado',
      message: 'Isso pode levar alguns minutos.'
    }));

    const response = yield call(api.get, 'user-register');
    yield put(Creators.getUsersSuccess(response.data));

  } catch (err) {
    yield captureSentryException(err, users);
    yield put(Creators.deleteUsersError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao deletar o usuário.'
    }));
  }
}

export function* editPermission({ users }) {
  try {

    yield put(Creators.editPermissionRequest());
    yield call(api.put, 'user-register', users);

    yield put(toastrActions.add({
      type: 'success',
      title: 'Nível de permissão editado',
      message: 'Isso pode levar alguns minutos.'
    }));

    const response = yield call(api.get, 'user-register');
    yield put(Creators.getUsersSuccess(response.data));

  } catch (err) {
    yield captureSentryException(err, users);
    yield put(Creators.editPermissionError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao editar nível permissão do usuário.'
    }));
  }
}
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DASHBOARD, ROOT, SEARCH } from '../../constants/routes';
import { Creators as TenantCreators } from '../../store/ducks/tenant';
import { ADMIN } from '../../constants';

class RouteRedirector extends Component {

  componentDidMount() {
    this.props.setTenant();
  }

  render() {
    const { tenant, role } = this.props.tenant;

    if (!tenant) return null;

    return (
      <Redirect exact from={ROOT} to={role === ADMIN ? DASHBOARD : SEARCH} />
    )
  }
}

const mapStateToProps = ({ tenant }) => ({ tenant });

export default compose(
  connect(mapStateToProps, { ...TenantCreators })
)(RouteRedirector);
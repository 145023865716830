import React from 'react';
import { Terms } from './styles';

const TermsOfUse = () => (
  <Terms>
        <h3 align="CENTER"><b><u>Minerador</u></b></h3>
        <h4 align="CENTER"><b>Termos e <b>Condições de Uso</b></b></h4>


        <h5>1. <b><u>INTRODUÇÃO</u></b>:</h5>


        Bem vindo/a ao <b>Minerador</b>! Nós lhe convidamos a visitar a nossa plataforma, informando a seguir os <b>Termos e <b>Condições de Uso</b></b> (o “<b>Termos de Uso</b>”) que regem a sua utilização. Este termo e suas condições aplicam-se à você, “<b>Usuário</b>”, enquanto fizer uso da Plataforma <b>Minerador</b>, de titularidade da <b>Oncase</b> Tecnologia da Informação S.A. (a “<b>Oncase</b>”), pessoa jurídica de direito privado, constituída sob a forma de sociedade anônima, inscrita no CNPJ/ME sob o n° 09.037.118/0001-69, com sede na Rua do Bom Jesus, nº 163, 2º andar, no bairro do Recife, Recife - PE, CEP.: 50.030-170 .
        <p>Consideraremos o devido cadastro do <b>Usuário</b>, seja por você, real utilizador da Plataforma <b>Minerador</b>, ou mesmo pelo <b>Usuário Administrador</b>, pessoa devidamente autorizada pelo titular dos dados pessoais a proceder ao cadastro do <b>Usuário</b> na Plataforma, como sendo a manifestação de aceite, integral e sem reservas, desses <b>Termos de Uso</b>.</p>


        <h5>2. <b><u>INFORMAÇÃO</u></b>:</h5>


        Envidaremos nossos melhores esforços no sentido de manter as informações e materiais contidos nesta plataforma tão precisos, atualizados e completos quanto possível for. Necessário atentar, todavia, que as atualizações, correções e remoções de informações cadastrais são de inteira responsabilidade do <b>Usuário</b> e/ou <b>Usuário Administrador</b>. Neste particular, portanto, a <b>Oncase</b>, enquanto proprietária da plataforma <b>Minerador</b>, não assume qualquer responsabilidade pela utilização, aplicação ou processamento que os <b>Usuário</b>s possam realizar através do uso da plataforma, sobretudo, em relação às informações cadastrais, inclusive que sejam dissonantes da natureza e da função.
        <p>O titular do cadastro, quem seja, o <b>Usuário</b>, que teve suas informações inseridas na Plataforma, seja por si ou pelo <b>Usuário Administrador</b>, receberá um e-mail de confirmação do cadastramento, para que, a partir de então, possa definir uma senha e confirmar o respectivo cadastro no <b>Minerador</b>, sendo de sua exclusiva responsabilidade a manutenção do sigilo dessa informação. Tanto o endereço eletrônico informado pelo <b>Usuário</b> no momento do cadastro, quanto a senha por ele criada serão utilizados para a identificação do mesmo e permissão de acesso à plataforma.</p>
        <p>Procuramos assegurar que as informações fornecidas pelo <b>Minerador</b> sejam corretas, completas e atuais. Contudo, o conteúdo é de exclusiva e inteira responsabilidade dos <b>Usuário</b>s, já que são estes os responsáveis pelas informações disponíveis na plataforma, não havendo qualquer tipo de fiscalização ou controle de veracidade e/ou legalidade dessas informações.</p>
        <p>As informações contidas nesta plataforma poderão ser atualizadas, retiradas e/ou modificadas de acordo com a oportunidade e conveniência dos seus respectivos <b>Usuários</b>.</p>


        <h5>3. <b><u>PRIVACIDADE DOS <b>Usuário</b>S</u></b>:</h5>


        Possuímos uma política expressa sobre privacidade dos <b>Usuário</b>s, que pode ser consultada a qualquer momento dados de cadastro.
        <p>Nesse sentido, a <b>Oncase</b>, enquanto titular do <b>Minerador</b>, pode, eventualmente, ter acesso a dados pessoais dos <b>Usuário</b>s cadastrados. Portanto, é fundamental esclarecer, por meio do presente <b>Termos de Uso</b>, com clareza e transparência, a importância que é dada à privacidade, à confidencialidade e à proteção dos dados pessoais coletados.</p>
        <p>Embora os dados dos <b>Usuário</b>s, bem como as informações por eles prestadas, fiquem armazenadas na base de dados do <b>Minerador</b>, estes dados só serão usados exclusivamente para a finalidade pesquisa e exportação, bem como para o melhoramento da plataforma. É garantido ao <b>Usuário</b>, pois, a confidencialidade, privacidade e proteção de seus dados.</p>
        <p>Em razão do cadastramento prévio feito através da plataforma e necessários à finalidade pesquisa e exportação, o <b>Minerador</b> poderá coleta informações pessoais capazes de identificar o <b>Usuário</b>, tal qual apontado pela <b>Política de Privacidade</b>, que constitui parte integrante dos <b>Termos e <b>Condições de Uso</b></b> da plataforma e deverá ser lido atentamente por todos os <b>Usuário</b>s, antes da aceitação e utilização do presente.</p>


        <h5>4. <b><u>UTILIZAÇÃO DA REDE E DE SEUS LINKS</u></b>:</h5>


        Em nenhuma hipótese, o <b>Minerador</b> será responsável pelo uso do sistema ou pelo acesso a links nele indicados, bem como por atos praticados pelo <b>Usuário</b>. Não nos responsabilizamos nem pelo conteúdo, nem pelas políticas/práticas de privacidade dos sites que, eventualmente, apontam para a nossa plataforma, bem como daqueles para os quais, eventualmente, apontamos.


        <h5>5. <b><u>DIREITOS AUTORIAS E PROPRIEDADE INTELECTUAL</u></b>:</h5>


        Os vídeos, posts, textos, fotografias, imagens, posts, logomarcas, som e demais dados presentes na plataforma se encontram protegidos por direitos autorais ou outros direitos de propriedade intelectual, cuja titularidade/propriedade é da pessoa jurídica desenvolvedora do <b>Minerador</b>, quem seja, <b>Oncase</b>.
        <p>Ao fazer uso do <b>Minerador</b>, o <b>Usuário</b> declara que irá respeitar os direitos de propriedade intelectual da <b>Oncase</b>, enquanto proprietária do <b>Minerador</b>, bem como todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma disponíveis na plataforma. A simples utilização da plataforma, porém, não confere ao <b>Usuário</b> qualquer direito de uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, dentre outras que nele estejam, ou estiveram, disponíveis.</p>
        <p>Todas as marcas comerciais eventualmente incluídas nesta plataforma são de propriedade da <b>Oncase</b>, empresa titular do <b>Minerador</b>, ou lhes foram outorgadas sob licença ou autorização por seus titulares para sua utilização.</p>
        <p>A reprodução dos conteúdos descritos anteriormente está proibida, salvo prévia autorização por escrito da <b>Oncase</b>, ou se destinada ao uso exclusivamente pessoal. Em hipótese alguma, entretanto, o <b>Usuário</b> adquirirá quaisquer direitos sobre os mesmos.</p>
        <p>É vedada a utilização do <b>Minerador</b> para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido no presente documento. Restam igualmente proibidas a reprodução, distribuição e divulgação, total ou parcial, dos vídeos, textos, figuras, gráficos, acessos que compõem a plataforma, sem prévia e expressa autorização da <b>Oncase</b>, proprietária do <b>Minerador</b>.</p>
        <p>O <b>Usuário</b> assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, vídeos, fotografias, textos, gráficos, marcas, obras, enfim, todo e qualquer direito de propriedade intelectual da plataforma, ressalvadas as hipóteses de mau uso evidente pelo <b>Usuário</b>.</p>



        <h5>6. <b><u>CADASTRO DO <b>Usuário</b>, SENHA E SEGURANÇA</u></b>:</h5>


        Para que os <b>Usuário</b>s possam utilizar a plataforma <b>Minerador</b>, faz-se necessário o seu prévio cadastro, seja por si ou pelo <b>Usuário Administrador</b>, e aceite dos <b>Termos e <b>Condições de Uso</b></b>.
        <p>O <b>Usuário</b>, em geral, nestes <b>Termos e <b>Condições de Uso</b></b>, concorda em:</p>
        <p>a. fornecer informações verdadeiras, exatas, atuais e completas sobre si ou da pessoa que esteja cadastrando, caso o cadastro no <b>Minerador</b> seja realizado pelo <b>Usuário Administrador</b>;</p>
        <p>b. conservar e atualizar imediatamente tais informações de registro para mantê-las verdadeiras, exatas, atuais e completas.</p>
        <p>O <b>Usuário</b> concorda em notificar imediatamente o <b>Minerador</b> sobre qualquer uso não-autorizado de sua Plataforma e, por conseguinte, de sua identificação como <b>Usuário</b>, ou qualquer quebra de segurança de seu conhecimento.</p>
        <p>Faz parte de nossa política respeitar a privacidade de nossos <b>Usuário</b>s. Portanto, o <b>Minerador</b> não editará informações privativas de seus <b>Usuário</b>s, salvo quando estritamente necessário para atendimento da ocorrência por eles comunicada, ficando desde já autorizada o monitoramento e a divulgação da localização e dados dos <b>Usuário</b>s, exclusivamente, somente se dará mediante ordem judicial ou por força de lei.</p>
        <p>O <b>Usuário</b> autoriza expressamente a comunicação do <b>Minerador</b> consigo através de todos os canais de comunicação disponíveis, incluindo correio eletrônico (e-mail), Celular, SMS, entre outros, ficando ressaltado que a principal via de informação para o <b>Usuário</b> é a própria plataforma do <b>Minerador</b>.</p>



        <h5>7. <b><u>RESPONSABILIDADE</u></b>:</h5>


        É de inteira responsabilidade do <b>Usuário</b> e/ou do <b>Usuário Administrador</b>:


        <p>a. providenciar o seu próprio acesso à internet e pagar todas as taxas de serviço eventualmente cobradas por terceiros com relação a tal acesso;</p>


        <p>b. providenciar seu próprio acesso a um endereço para envio de mensagens eletrônicas via internet e pagar todas as taxas de serviço eventualmente cobradas por terceiros com relação a tal acesso;</p>


        <p>c. providenciar todo o equipamento necessário para efetuar sua conexão à internet, incluindo, mas não se limitando, a um telefone, tablet e congêneres;</p>


        <p>d. efetuar seu próprio cadastro no <b>Minerador</b>, responsabilizando-se pela correção e veracidade dos dados informados, assim como pela guarda de sua senha de acesso;</p>


        <p>e. manter o ambiente de seu computador, telefone, tablet e congêneres seguro, com uso de ferramentas disponíveis como antivírus e firewall, entre outras, atualizadas, de modo a contribuir na prevenção de riscos eletrônicos do lado do <b>Minerador</b>.</p>


        <h5>8. <b><u>DA PROTEÇÃO DE DADOS PESSOAIS</u></b>:</h5>


        <p>O <b>Minerador</b> dispõe de uma política específica para regular a coleta, guarda e utilização de dados pessoais dos <b>Usuário</b>s, em atendimento à Lei n° 13.709/2018 - Lei Geral de Proteção de Dados (a “<b>LGPD</b>”) que, a princípio, visa assegurar aos <b>Usuário</b>s a atuação com transparência em relação aos dados eventualmente coletados pela Plataforma no momento de seus cadastros, além de fornecer os motivos e a forma pela qual o <b>Minerador</b> realiza e utiliza a coleta, bem como os direitos que lhes são inerentes, enquanto titular dos dados pessoais coletados.</p>
        <p>Nesse sentido, mesmo que em documento específico, conforme acima exposto, o <b>Minerador</b> informa aos <b>Usuário</b>s que o tratamento dos pessoais dos <b>Usuário</b>s será realizado nos termos do inciso I do Artigo 7º e 11º da <b>LGPD</b> e dentro dos limites necessários ao atingimento das finalidades previstas no presente <b>Termos de Uso</b>.</p>
        <p>Ademais, o <b>Minerador</b> garante que os sistemas que servirão de base para armazenamento dos dados pessoais coletados dos <b>Usuário</b>s seguem um conjunto de premissas, políticas e especificações técnicas e seguras, sempre mantendo o compromisso de confidencialidade, integridade e inviolabilidade dos dados, a fim de evitar eventuais desvios e falhas.</p>

        <h5>9. <b><u>MODIFICAÇÃO DESTES TERMOS E CONDIÇÕES</u></b>:</h5>


        Reservamo-nos o direito de modificar unilateralmente, a qualquer momento, o presente <b>Termos e <b>Condições de Uso</b></b>, objetivando uma melhoria contínua. Ao navegar pelo <b>Scora Minerador</b>, você aceita guiar-se pelos <b>Termos e <b>Condições de Uso</b></b> que se encontram vigentes na data, devendo, portanto, verificar os mesmos previamente cada vez que utilizar o <b>Minerador</b>.


        <h5>10. <b><u>EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</u></b>:</h5>


        Em decorrência de questões operacionais, de terceirização de serviços, o <b>Minerador</b> e os serviços prestados estão sujeitos a eventuais problemas de interrupção, falha técnica, ou indisponibilidade temporária. Quando possível, o <b>Minerador</b> advertirá previamente as interrupções do funcionamento do sistema e dos serviços aos seus <b>Usuário</b>s.
        <p>O <b>Minerador</b> se exime, com toda a extensão permitida pelo ordenamento jurídico, de qualquer responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer da falta de disponibilidade ou de continuidade do funcionamento da plataforma e dos serviços, à defraudação da utilidade que os <b>Usuário</b>s possam ter atribuído à plataforma e aos serviços, à falibilidade da plataforma e dos serviços e, em particular, ainda que não de modo exclusivo, às falhas de acesso à plataforma pelas vias disponíveis.</p>


        <h5>11. <b><u>DISPOSIÇÕES GERAIS</u></b>:</h5>


        A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente <b>Termos de Uso</b> não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
        <p>O presente instrumento constitui o acordo integral entre as partes, prevalecendo sobre qualquer outro entendimento firmado anteriormente.</p>


        <h5>12. <b><u>LEI APLICÁVEL</u></b>:</h5>


        Os presentes Termo e <b>Condições de Uso</b> é regido única e exclusivamente pelas leis da República Federativa do Brasil e qualquer discussão judicial que surja tendo por base sua interpretação ou aplicação deverá ser julgado por tribunais brasileiros, estando, desde logo, eleito o foro da cidade de Recife, Estado de Pernambuco, por mais privilegiado que outro seja ou possa vir a ser.


        <h5>13. <b><u>DÚVIDAS</u></b>:</h5>


        Caso tenha qualquer dúvida em relação ao presente documento, favor entrar em contato através do e-mail Minerador@Oncase.com.br.

  </Terms >
)

export default TermsOfUse;
import styled, { css } from 'styled-components';
import { baseColor } from '../App/theme';
import { TYPE_LINK } from '../../utils/schema';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 2vw;
  padding-top: 1.5vh;
`;

export const Container = styled.div`
  padding-left: 2vw;
  padding-top: 1vh;
  align-items: center;
  min-height: 70vh;
  max-width: 45vh;
`;

export const FootActions = styled.div`
  position: absolute;
  bottom: 1vw;
  right: 2vw;
`;

export const Text = styled.div`
  padding-right: 2vw;
  font-size: 14px;
  padding-top: 2vh;
`;

export const TextActions = styled.span`
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  padding-top: 8px;
  padding-left: 2vw;

  ${props => props.type === TYPE_LINK && css`
    color: ${baseColor};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;

export const InfoText = styled.div`
  padding-right: 2vw;
  font-size: 14px;
  color: #676767;
  padding-top: 1vh;
`;

export const HeaderContainer = styled.div`
  padding-right: 2vw;
  padding-top: 1vh;
  min-height: 2vh;
  font-size: 16px;
`;

export const StyledHeader = styled.div`
  display: flex;
  font-weight: bold; 
  font-size: 1.2rem; 
  padding-left: 1rem; 
  padding-right: 1rem;
  padding-top: 1rem;
`;

export const StyledDialogContent = styled.div`
  height: 55vh; 
  padding-right: 0;
  padding-left: 0;
`;

export default () => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem'
  }
})

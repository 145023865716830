import React, { useState, forwardRef, useCallback } from 'react';
import classnames from 'classnames';
import { useStyles } from './styles';

import { SnackbarContent } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DownloadIcon from 'react-feather/dist/icons/download';
import SuccessIcon from 'react-feather/dist/icons/check-circle';
import ErrorIcon from 'react-feather/dist/icons/alert-circle';

const StackNotification = forwardRef((props, ref) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="subtitle2" className={classes.typography}>{props.message}</Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Exibir mais"
              className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon style={{ fill: "white" }} />
            </IconButton>
            <IconButton className={classes.expand} onClick={() => props.closeSnackbar(props.id)}>
              <CloseIcon style={{ fill: "white" }} />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {props.data.map((item, idx) => (
            <Paper key={idx} className={classes.collapse}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {item.status === 'RUNNING' ? <div><DownloadIcon className={classes.downloadIcon} /></div> : null}
                {item.status === 'FINISHED' ? <div><SuccessIcon className={classes.successIcon} /></div> : null}
                {item.status === 'ERROR' ? <div><ErrorIcon className={classes.errorIcon} /></div> : null}
                <div title={item.title} style={{ paddingLeft: '.5vw' }}>
                  <Typography className={classes.notificationText} gutterBottom>
                    {item.title}
                  </Typography>
                </div>
              </div>
            </Paper>
          ))}
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default StackNotification;
import api from '../../services/api';
import { Creators } from '../ducks/rfb_filter';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getRfbFilters({ field, filter }) {
  try {
    let response;
    yield put(Creators.rfbFilterRequest(field));

    response = yield call(api.post, `rfb-filter/${field}`, filter);

    yield put(Creators.rfbFilterSuccess(response.data, field));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.rfbFilterError({ err }, field));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: `Falha ao carregar o filtro de ${field}`
    }));
  }
}
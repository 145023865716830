import {
  DASHBOARD_CHATBOT_INDICATORS,
  DASHBOARD_PERSONA_INDICATORS,
  DASHBOARD_PROSPECT_INDICATORS,
} from "../constants";

export const TYPE_LINK = "TYPE_LINK";
export const TYPE_ICON = "TYPE_ICON";
export const TYPE_TIMESTAMP = "TYPE_TIMESTAMP";
export const TYPE_COLOR = "TYPE_COLOR";
export const TYPE_PERSON = "TYPE_PERSON";
export const TYPE_CGC_CPF = "TYPE_CGC_CPF";
export const TYPE_CEP = "TYPE_CEP";
export const TYPE_CURRENCY = "TYPE_CURRENCY";
export const TYPE_PHONE = "TYPE_PHONE";
export const TYPE_MIXED = "TYPE_MIXED";
export const TYPE_BUTTON = "TYPE_BUTTON";
export const TYPE_SUB_MENU = "TYPE_SUB_MENU";

export const getCustomerSchemaPF = () => ({
  data: [
    { field: "nome", label: "Nome" },
    { field: "classificacaopessoa", label: "Classificação Pessoa" },
    { field: "cnpj", label: "CNPJ", type: TYPE_CGC_CPF },
    { field: "cgccpf", label: "CPF", type: TYPE_CGC_CPF },
    { field: "rg", label: "Identidade" },
    { field: "orgaoemissor", label: "Órgão Emissor" },
    {
      field: "datanascimento",
      label: "Data de Nascimento",
      type: TYPE_TIMESTAMP,
    },
    { field: "estadocivil", label: "Estado Civil" },
    { field: "escolaridade", label: "Escolaridade" },
    { field: "idade", label: "Idade" },
    { field: "sexo", label: "Sexo" },
    { field: "nomemae", label: "Nome da Mãe" },
    { field: "logradouro", label: "Logradouro" },
    { field: "numerologradouro", label: "Número Logradouro" },
    { field: "complemento", label: "Complemento" },
    { field: "bairro", label: "Bairro" },
    { field: "cep", label: "CEP", type: TYPE_CEP },
    { field: "cidade", label: "Município" },
    { field: "uf", label: "UF" },
    { field: "totalinvestido", label: "Total Investido", type: TYPE_CURRENCY },
    { field: "dataatu", label: "Data de Atualização", type: TYPE_TIMESTAMP },
    { field: "statusaceitelgpd", label: "Status LGPD" },
    { field: "situacaovinculo", label: "Situação Vínculo" },
  ],
});

export const getCustomerSchemaPJ = () => ({
  data: [
    { field: "nome", label: "Razão Social" },
    { field: "nomefantasia", label: "Nome Fantasia" },
    { field: "cgccpf", label: "CNPJ", type: TYPE_CGC_CPF },
    { field: "logradouro", label: "Logradouro" },
    { field: "numerologradouro", label: "Número Logradouro" },
    { field: "complemento", label: "Complemento" },
    { field: "bairro", label: "Bairro" },
    { field: "cidade", label: "Município" },
    { field: "cep", label: "CEP", type: TYPE_CEP },
    { field: "uf", label: "UF" },
    { field: "setor", label: "Setor" },
    { field: "macrossegmento", label: "Macrossegmento" },
    { field: "porte", label: "Porte" },
    { field: "constituicaojuridica", label: "Constituição jurídica" },
    { field: "cnae", label: "CNAE" },
    { field: "cnaedescricao", label: "CNAE Descrição" },
    { field: "numfiliais", label: "Número de Filiais" },
    { field: "areaatuacao", label: "Área de atuação" },
    { field: "numfuncionarios", label: "Número de Funcionários" },
    { field: "capitalsocial", label: "Capital Social", type: TYPE_CURRENCY },
    { field: "dataabertura", label: "Data de Abertura", type: TYPE_TIMESTAMP },
    { field: "anosatividade", label: "Anos de Atividade" },
    {
      field: "datafechamento",
      label: "Data de Fechamento",
      type: TYPE_TIMESTAMP,
    },
    { field: "totalinvestido", label: "Total Investido", type: TYPE_CURRENCY },
    { field: "dataatu", label: "Data de Atualização", type: TYPE_TIMESTAMP },
  ],
});

export const getPieChartSchema = () => [
  { label: "Físico/Jurídico", value: "tipoparceiro" },
  { label: "Porte", value: "porte" },
  { label: "Setor", value: "setor" },
  { label: "Sexo", value: "sexo" },
];

export const getTreemapConfigSchema = (type) => {
  if (type === "product") {
    return [
      { label: "Instrumento", value: "instrumento" },
      { label: "Município", value: "cidade_parceiro" },
      { label: "Produto", value: "produto" },
      { label: "Unidade Organizacional", value: "unidadeorganizacional" },
    ];
  }

  if (type === "market") {
    return [
      { label: "Tipo Parceiro", value: "tipoparceiro" },
      { label: "Porte", value: "porte" },
      { label: "Setor", value: "setor" },
      { label: "Instrumento", value: "instrumento" },
      { label: "Sexo", value: "sexo" },
    ];
  }
};

export const getNameByReduxEvent = (key) => {
  const events = {
    INIT_PRODUCT: "Iniciar tela de produto",
    PRODUCT_DETAIL_REQUEST: "Requisitar detalhamento de municípios",
    PRODUCT_DETAIL_EXPAND: "Expandir detalhe de produtos",
    PRODUCT_DETAIL_DOWNLOAD: "Baixar lista de produtos",
    REC_CUSTOMER_REQUEST: "Visualizar recomendação de cliente",
    REC_CUSTOMER_DOWNLOAD: "Baixar produtos recomendados",
    REC_PRODUCT_REQUEST: "Gerar recomendação de produtos",
    REC_PRODUCT_DOWNLOAD: "Baixar recomendação de clientes",
    CITY_REQUEST: "Requisitar filtro por município",
    TIMELINE_REQUEST: "Visualizar jornada do cliente",
    YEAR_SELECT: "Filtrar por ano",
    CUSTOMER_INIT: "Inicar tela de jornada do cliente",
    INIT_SEARCH: "Iniciar tela de busca de cliente",
    TREE_MAP_PRODUCT_INIT: "Iniciar treemap de produtos",
    PRODUCT_DETAIL_INIT: "Iniciar tabela de detalhe de produtos",
    TREE_MAP_MARKET_INIT: "Iniciar treemap de mercado",
    CUSTOMER_DETAIL_INIT: "Iniciar tabela de detalhe de mercado",
    MAP_REQUEST: "Requisitar mapa de análise de produto",
    initLoggedApp: "Iniciar sessão logado",
    CUSTOMER_SELECT: "Selecionar cliente",
    PIE_CHART_REQUEST: "Requisitar detalhe de produto (Gráfico de Pizza)",
    REC_MARKET_REQUEST: "Requisitar análise",
    REC_MARKET_SET_CITY: "Filtrar recomendação de mercado por município",
    PRODUCT_REQUEST: "Consultar análise histórica de produto",
    PRODUCT_DETAIL_SORT_BY: "Ordenar tabela de detalhe de produtos",
    INSTRUMENT_REQUEST: "Consultar instrumentos",
    signOut: "Logout do minerador",
    TREE_MAP_PRODUCT_REQUEST: "Requisitar treemap de análise de produto",
    BAR_CHART_REQUEST: "Requisitar detalhe de produto (Gráfico de Barra)",
    TREE_MAP_MARKET_REQUEST: "Requisitar treemap de mercado",
    CUSTOMER_DETAIL_REQUEST: "Requisitar detalhe de clientes",
    CUSTOMER_DETAIL_INIT_TABLE: "Requisitar detalhamento de municípios",
    REC_CUSTOMER_PERSONA_SET_COMPARE: "Comparar personas no gráfico de radar",
    REC_CUSTOMER_SORT_BY:
      "Ordenar tabela de recomendação de produtos (Tela de cliente)",
    REC_MARKET_CLICK_ONLINE: "Visualizar recomendações online de mercado",
    REC_CUSTOMER_TOP_RECOMMENDATIONS:
      "Armazenar recomendações de produto ao cliente",
    DASHBOARD_REQUEST: "Requisitar acesso ao dashboard",
    RFB_FILTER_SELECT: "Selecionar filtro da RFB",
    RFB_FILTER_REQUEST: "Requisitar leads por filtro",
    SET_DIALOG_TYPE: "Configura tipo de caixa de diálogo",
    PORT_REQUEST: "Requisitar portes",
    ORGANIZATION_UNIT_REQUEST: "Requisitar unidades organizacionais",
    SECTOR_REQUEST: "Requisitar setores",
    RFB_FILTER_INIT: "Iniciar filtro da RFB",
    USERS_GROUPS_REQUEST: "Requisitar grupos de usuários",
    EDIT_PERMISSION: "Acesso para edição da permissão de usuário",
    EDIT_PERMISSION_REQUEST: "Requisitar edição de permissão de usuário",
    INIT_LEADS: "Iniciar tela de leads",
    TENANT_CONFIG_REQUEST: "Requisitar configuração de tenants",
    RFB_ENTERPRISE_REQUEST: "Requisitar empresas da RFB",
    CNAE_REQUEST: "Requisitar CNAEs",
    REC_CUSTOMER_PRODUCT_TOP_RECOMMENDATIONS:
      "Requisitar gráfico de produtos mais recomendados por cliente",
    REC_CUSTOMER_PRODUCT_REQUEST:
      "Requisitar recomendação de produtos por cliente",
    USER_REGISTER_REQUEST: "Requisitar acesso ao gerenciamento de usuários",
    RFB_ENTERPRISE_DOWNLOAD_XLSX: "Download de planilha XLSX de leads",
    RFB_ENTERPRISE_DOWNLOAD_CSV: "Download de planilha CSV de leads",
    REC_PRODUCT_EXPORT_CRM: "Exportação para o CRM da recomendação de produto",
    CRM_REQUEST: "Requisição ao CRM",
    POST_CRM: "Postagem ao CRM",
    REC_PRODUCT_DOWNLOAD_XLSX:
      "Download de planilha XLSX da recomendação de produto",
    DELETE_USERS_REQUEST: "Requisição para deletar usuários",
    DELETE_USERS: "Acesso à deleção de usuário",
    RE_SEND_EMAIL_REQUEST:
      "Requisição para reenviar email de acesso ao Minerador",
    RE_SEND_EMAIL: "Acesso ao fluxo de envio de reenvio de email",
    REC_CUSTOMER_EVENT_REQUEST:
      "Requisição de recomendações de eventos para os clientes",
    REC_CUSTOMER_EVENT_TOP_RECOMMENDATIONS:
      "Requisição das maiores recomendações de eventos para clientes",
    OPENED_EVENTS_REQUEST: "Requisição aos eventos abertos para produto",
  };

  return events[key] || key;
};

export const mixpanelExcludedEvents = () => {
  const events = [
    "SET_CHIP",
    "@ReduxToastr/toastr/REMOVE",
    "@ReduxToastr/toastr/ADD",
    "CITY_REQUEST",
    "YEAR_REQUEST",
    "PERSONA_INFO_REQUEST",
    "REC_CUSTOMER_PERSONA_REQUEST",
    "CUSTOMER_REQUEST",
    "INIT_CUSTOMER",
    "MAP_INIT",
    "SET_TITLE",
    "SET_TENANT",
    "REC_CUSTOMER_PERSONA_INIT_COMPARE",
    "PRODUCT_DETAIL_INIT_TABLE",
    "SET_FILTER",
    "SET_DIALOG",
    "SET_TREE_MAP_PRODUCT_CONFIG",
    "SET_TREE_MAP_MARKET_CONFIG",
    "MIXPANEL_REQUEST",
    "INDICATOR_REQUEST",
  ];

  return events;
};

export const getMixpanelTrackProperties = (action, element) => {
  let mixpanelProps = {
    parceiroid: null,
    eventoid: null,
    produtoid: null,
  };

  if (
    action.type === "CUSTOMER_SELECT" &&
    action.selected &&
    action.selected.value
  ) {
    mixpanelProps = {
      parceiroid: action.selected.value,
    };
  }

  if (action.type === "REC_CUSTOMER_TOP_RECOMMENDATIONS" && element) {
    mixpanelProps = {
      parceiroid: element.parceiroid,
      eventoid: element.eventoid,
      produtoid: element.produtoid,
    };
  }

  return mixpanelProps;
};

export const getMetabaseDashIdByDashName = (dashName, tenant = null) => {
  const dashboardIds = {
    [DASHBOARD_PERSONA_INDICATORS]: 27,
    [DASHBOARD_CHATBOT_INDICATORS]: 33,
    [DASHBOARD_PROSPECT_INDICATORS]: 49,
  };

  if(dashName === DASHBOARD_CHATBOT_INDICATORS && tenant === 'sebraees'){
    return 62
  }

  return dashboardIds[dashName] || null;
};

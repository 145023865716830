import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
  getChatbotRequest: ["request"],
  getChatbotSuccess: ["data"],
  getChatbotError: ["err"],
  getChatbot: ["filter"],
  getChatbotInit: [],
  getChatbotFilter: ["filter"],
  getChatbotSortBy: ["data", "sort"],
  getChatbotExpand: ["expandedRow"],
  getChatbotInitTable: [],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  error: false,
  loading: false,
  request: {},
  count: 0,
  sort: { name: { value: "date", order: "desc" } },
  expandedRow: null,
});

/* Reducers Table */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) =>
  state.merge({ data, sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = (state) =>
  state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.GET_CHATBOT_REQUEST]: request,
  [Types.GET_CHATBOT_SUCCESS]: success,
  [Types.GET_CHATBOT_ERROR]: error,
  [Types.GET_CHATBOT_INIT]: init,
  [Types.GET_CHATBOT_SORT_BY]: sortBy,
  [Types.GET_CHATBOT_EXPAND]: expand,
  [Types.GET_CHATBOT_INIT_TABLE]: initTable,
});

import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  SelectContainer,
  HeaderText,
  TextContainer,
  HeaderTextBold,
  TextBold,
  Text,
  HeaderTextContainer,
  TabsContainer,
  TabItem,
  Separator,
  CardContainer,
  CardValue,
  CardTitle,
  Card,
} from "./styles";
import Header from "../Header";
import { Aside } from "../../styles/Aside";
import CustomerSelect from "../CustomerSelect";
import { Creators as CustomerCreators } from "../../store/ducks/customer";
import {
  TYPE_TIMESTAMP,
  getCustomerSchemaPF,
  getCustomerSchemaPJ,
  TYPE_LINK,
  TYPE_CGC_CPF,
  TYPE_PHONE,
} from "../../utils/schema";
import { fieldParser } from "../../utils";
import { CircularProgress } from "@material-ui/core";
import { baseColor } from "../App/theme";
import {
  COMPANY,
  PARTNER,
  PESSOA_JURIDICA,
  RECSYS,
  DESVINCULADO,
} from "../../constants/index";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Error";
import CheckCircle from "@material-ui/icons/CheckCircle";

class CustomerInfo extends Component {
  renderHeaderField = (label, value) => (
    <HeaderTextContainer title={value}>
      <HeaderText>{label} </HeaderText>
      <HeaderTextBold>{value}</HeaderTextBold>
    </HeaderTextContainer>
  );

  renderHeaderLabel = (label) => (
    <HeaderTextContainer title={label}>
      <HeaderTextBold>{label}</HeaderTextBold>
    </HeaderTextContainer>
  );

  renderField = (item, data, idx) => {
    const fieldParsed =
      data[item.field] || data[item.field] === 0
        ? fieldParser(data[item.field], item.type)
        : "";

    if (!fieldParsed) {
      return null;
    }

    return (
      <TextContainer title={fieldParsed} key={idx}>
        <TextBold>{item.label}: </TextBold>
        {fieldParsed === DESVINCULADO ? (
          <Text style={{ color: "red" }}>{fieldParsed}</Text>
        ) : (
          <Text>{fieldParsed}</Text>
        )}
      </TextContainer>
    );
  };

  onChangeCustomer = (selected) => {
    if (selected) {
      this.props.getCustomer(selected.value);
      this.props.onChange({
        customerId: selected.value,
        customerPartner: null,
      });
      this.props.onChangeTab({ activeTab: COMPANY });
    }
  };

  renderLastPurchases = (customer) => {
    return customer.last_purchases.map((item, idx) => (
      <TextContainer title={item.produto} key={idx}>
        <TextBold>Produto: </TextBold>
        <Text>{item.produto}</Text>
        <br />
        <TextBold>Data: </TextBold>
        <Text>
          {item.data
            ? fieldParser(item.data, TYPE_TIMESTAMP)
            : "Não disponível"}
        </Text>
        <br />
        <TextBold>Instrumento: </TextBold>
        <Text>
          {item.instrumento
            ? item.instrumento
            : "Não disponível"}
        </Text>
        <br />
      </TextContainer>
    ));
  };

  renderDap = (customer) => {
    return (
      <TextContainer>
        {Object.keys(customer.dap).map((key) => (
          <div>
            <TextBold>{key}: </TextBold>
            {key === "CPF Emissor" ? (
              <Text>
                {customer.dap[key]
                  ? fieldParser(customer.dap[key], TYPE_CGC_CPF)
                  : "Não disponível"}
              </Text>
            ) : key === "CNPJ Emissor" ? (
              <Text>
                {customer.dap[key]
                  ? fieldParser(customer.dap[key], TYPE_CGC_CPF)
                  : "Não disponível"}
              </Text>
            ) : (
              <Text>
                {customer.dap[key] ? customer.dap[key] : "Não disponível"}
              </Text>
            )}
            <br />
          </div>
        ))}
      </TextContainer>
    );
  };

  renderLastContact = () => {
    const { timeline } = this.props;
    const item = timeline.data[0];

    return (
      <TextContainer title={item.produto}>
        <TextBold>Tipo: </TextBold>
        <Text>{item.nomerealizacao}</Text>
        <br />
        <TextBold>Data: </TextBold>
        <Text>
          {item.datahorarealizacao
            ? fieldParser(item.datahorarealizacao, TYPE_TIMESTAMP)
            : "Não disponível"}
        </Text>
      </TextContainer>
    );
  };

  renderContacts = (customer) => {
    let hasData = false;

    for (var contato of customer.contacts) {
      if (
        contato.contatoprincipal === true &&
        (contato.recebesms === true || contato.recebeligacao === true)
      ) {
        hasData = true;
        break;
      }
    }

    return !hasData ? (
      <TextContainer>
        <TextBold>
          Nenhum telefone ou email está habilitado para contato
        </TextBold>
      </TextContainer>
    ) : (
      Object.keys(customer.contacts).map((i) =>
        customer.contacts[i].contatoprincipal &&
          customer.contacts[i].recebesms &&
          customer.contacts[i].recebeligacao &&
          customer.contacts[i].tipocontato !== "E-MAIL" ? (
          <>
            <TextContainer>
              <TextBold>Contato SMS:</TextBold>
              <Text>
                {" "}
                {fieldParser(customer.contacts[i].contato, TYPE_PHONE)}
              </Text>
              <br />
            </TextContainer>
            <TextContainer>
              <TextBold>Contato Ligação:</TextBold>
              <Text>
                {" "}
                {fieldParser(customer.contacts[i].contato, TYPE_PHONE)}
              </Text>
              <br />
            </TextContainer>
          </>
        ) : customer.contacts[i].contatoprincipal &&
          customer.contacts[i].recebeligacao &&
          !customer.contacts[i].recebesms ? (
          customer.contacts[i].tipocontato === "E-MAIL" ? (
            <TextContainer>
              <TextBold>Contato Email:</TextBold>
              <Text> {customer.contacts[i].contato}</Text>
              <br />
            </TextContainer>
          ) : (
            <TextContainer>
              <TextBold>Contato Ligação:</TextBold>
              <Text>
                {" "}
                {fieldParser(customer.contacts[i].contato, TYPE_PHONE)}
              </Text>
              <br />
            </TextContainer>
          )
        ) : customer.contacts[i].contatoprincipal &&
          !customer.contacts[i].recebeligacao &&
          customer.contacts[i].recebesms ? (
          <TextContainer>
            <TextBold>Contato SMS:</TextBold>
            <Text>
              {" "}
              {fieldParser(customer.contacts[i].contato, TYPE_PHONE)}
            </Text>
            <br />
          </TextContainer>
        ) : customer.contacts[i].contatoprincipal &&
          customer.contacts[i].recebesms &&
          customer.contacts[i].recebeligacao &&
          customer.contacts[i].tipocontato === "E-MAIL" ? (
          <TextContainer>
            <TextBold>Contato Email:</TextBold>
            <Text> {customer.contacts[i].contato}</Text>
            <br />
          </TextContainer>
        ) : null
      )
    );
  };

  handleTabChange = (value, e) => {
    const { customer } = this.props;

    this.props.onChangeTab({
      activeTab: value,
      customerId: customer.data.parceiroid,
    });
  };

  handleClickPartner = (customer, e) =>
    this.props.onChange({
      customerId: customer.parceiroid,
      customerPartner: customer,
    });

  renderFields = () => {
    const { customer, activeTab } = this.props;
    const pjSchema = getCustomerSchemaPJ();
    const pfSchema = getCustomerSchemaPF();
    const isPJ = customer.data.tipoparceiro === PESSOA_JURIDICA ? true : false;
    const schema = activeTab === COMPANY && isPJ ? pjSchema : pfSchema;
    const isShowCompanyPartner = (activeTab === PARTNER)

    return (
      <>
        <TabsContainer>
          <TabItem
            active={activeTab === COMPANY ? true : false}
            onClick={this.handleTabChange.bind(this, COMPANY)}
          >
            Parceiro
          </TabItem>
          <TabItem
            active={activeTab === PARTNER ? true : false}
            onClick={this.handleTabChange.bind(this, PARTNER)}
          >
            Vínculos
          </TabItem>
        </TabsContainer>
        {this.renderCards()}
        {isShowCompanyPartner
          ? Object.keys(customer.data.partners).map((role) =>
            customer.data.partners[role].map((customerData, idx) => (
              <div key={idx}>
                {this.renderHeaderLabel(role)}
                {schema.data.map((item, idx) =>
                  this.renderField(item, customerData, idx)
                )}
                <TextContainer style={{ paddingTop: ".2rem" }}>
                  <Text
                    onClick={this.handleClickPartner.bind(this, customerData)}
                    type={TYPE_LINK}
                  >
                    Ver histórico
                  </Text>
                </TextContainer>
                {customer.data.partners[role].length > idx + 1 ? (
                  <Separator />
                ) : null}
              </div>
            ))
          )
          : null}

        {!isShowCompanyPartner
          ? schema.data.map((item, idx) =>
            this.renderField(item, customer.data, idx)
          )
          : null}
      </>
    );
  };

  setCardValue = (value) => {
    return value ? (
      <div style={{ display: "flex", color: "#30E398", alignItems: "center" }}>
        <CheckCircle style={{ paddingRight: "4px" }} />
        Consentiu
      </div>
    ) : value === false ? (
      <div style={{ display: "flex", color: "#CB443C", alignItems: "center" }}>
        <CancelIcon style={{ paddingRight: "4px" }} />
        Não Consentiu
      </div>
    ) : (
      <div style={{ display: "flex", color: "#275A8B", alignItems: "center" }}>
        <WarningIcon style={{ paddingRight: "4px" }} />
        Em aberto
      </div>
    );
  };

  renderCards = () => {
    const { customer } = this.props;

    return (
      <>
        <CardContainer>
          <Card style={{ width: "95%" }}>
            <CardTitle>Data do último atendimento</CardTitle>
            <CardValue>
              {customer.data.last_consumption
                ? fieldParser(customer.data.last_consumption, TYPE_TIMESTAMP)
                : "Não disponível"}
            </CardValue>
          </Card>
        </CardContainer>
      </>
    );
  };

  render() {
    const { customer, title, icon, chip, timeline, activeTab } = this.props;
    const customerData =
      activeTab === COMPANY ? customer.data : customer.data.partners;
    const hasData =
      customerData && Object.keys(customerData).length ? true : false;
    const showData = hasData && !customer.loading;

    return (
      <Aside>
        <Header icon={icon} title={title} />

        <SelectContainer>
          <CustomerSelect
            placeholder="Buscar outro CPF/CNPJ/DAP"
            onChange={this.onChangeCustomer.bind(this)}
          />
        </SelectContainer>

        {customer.loading ? (
          <div
            style={{
              color: baseColor,
              display: "flex",
              justifyContent: "center",
              marginTop: "20rem",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : null}

        {showData && this.renderFields()}

        {showData && customerData.contacts ? (
          <>
            {this.renderHeaderLabel("Contatos")}
            {this.renderContacts(customerData)}
          </>
        ) : null}

        {showData && customerData.coddap ? (
          <>
            <Separator />
            {this.renderHeaderLabel("DAP")}
            {this.renderDap(customerData)}
          </>
        ) : null}

        {showData &&
          customerData.last_purchases &&
          customerData.last_purchases.length ? (
          <>
            <Separator />
            {this.renderHeaderLabel("Últimas Compras")}
            {this.renderLastPurchases(customerData)}
          </>
        ) : null}

        {showData &&
          timeline.data &&
          timeline.data.length &&
          chip.customer === RECSYS ? (
          <>
            <Separator />
            {this.renderHeaderLabel("Último Contato")}
            {this.renderLastContact()}
          </>
        ) : null}
      </Aside>
    );
  }
}

const mapStateToProps = ({ customer, chip, timeline }) => ({
  customer,
  chip,
  timeline,
});

export default compose(connect(mapStateToProps, { ...CustomerCreators }))(
  CustomerInfo
);

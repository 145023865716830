import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { I18n } from '@aws-amplify/core';
import i18nData from './i18n/pt.json';
import ReduxToastr from 'react-redux-toastr';
import 'react-vertical-timeline-component/style.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'moment/locale/pt.js';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

require('dotenv').config();

const AMPLIFY_CFG = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolWebClientId: process.env.REACT_APP_APPCLIENTID,
  },
}

Amplify.configure(AMPLIFY_CFG);
I18n.setLanguage('pt');
moment.locale('pt')
I18n.putVocabularies({ pt: i18nData });

const Toastr = () => (
  <ReduxToastr
    timeOut={4000}
    newestOnTop={false}
    preventDuplicates
    closeOnToastrClick={true}
    position="top-right"
    getState={(state) => state.toastr} // This is the default
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    progressBar />
)



ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Toastr />
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </Router>
  </Provider>, document.getElementById('root'));
export { store };
import api from '../../services/api';
import { Creators } from '../ducks/pie_chart';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getPieChart({ filter }) {
  try {
    let response;

    yield put(Creators.pieChartRequest());
    response = yield call(api.post, 'pie-chart', filter);

    yield put(Creators.pieChartSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.pieChartError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar dados do gráfico de pizza'
    }));
  }
}
import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Creators as EventCreators } from "../../store/ducks/event";
import { Creators as DateEventCreators } from "../../store/ducks/date_event";
import { Creators as RfbPartnerActions } from "../../store/ducks/rfb_partner";
import { Creators as DialogCreators } from "../../store/ducks/dialog";
import { Creators as UserRegisterDialogCreators } from "../../store/ducks/user_register_dialog";
import { Creators as ChatbotDetailsCreators } from "../../store/ducks/chatbot_details";
import { Creators as TargetAudiencePerProductCreators } from "../../store/ducks/target_audience_per_product";
import { Creators as NotifyEtlCreators } from "../../store/ducks/notify_etl";
import {
  SEE_CUSTOMER,
  SEE_EVENT,
  SEE_REPRESENTATIVES,
  SEE_AVAILABLE_DATES,
  EDIT_TARGET_AUDIENCE,
  CLEAN_TARGET_AUDIENCE,
  RESEND_EMAIL,
  DELETE_ACCOUNT,
  EDIT_PERMISSION,
  VIEW_CHATBOT_DETAILS,
  RECEIVE_NOTIFICATION_ETL,
} from "../../constants";
import { baseColor } from "../App/theme";

class SubMenu extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  onTableClick = (item, value) => {
    if (value && value === SEE_CUSTOMER) {
      this.props.history.push(item.link);
    }

    if (value && value === SEE_EVENT) {
      this.props.setDialog("event");
      this.props.getOpenedEvents({
        product_id: item.produto,
        client_city: item.cidade_parceiro,
        is_download: false,
      });
    }

    if (value && value === SEE_REPRESENTATIVES) {
      this.props.setDialog("leads");
      this.props.getRfbPartners(item.cnpj);
    }

    if (value && value === SEE_AVAILABLE_DATES) {
      this.props.setDialog("date_event");
      this.props.getDateEvents({
        event_id: item.eventoid,
        city: item.cidade,
      });
    }

    if (value && value === EDIT_TARGET_AUDIENCE) {
      this.props.getTargetAudiencePerProduct({productid: item.produtoid});
      this.props.setDialog("target_audience");
      this.props.getData(item);
    }

    if (value && value === CLEAN_TARGET_AUDIENCE) {
      this.props.setDialog("clean_target_audience");
      this.props.getData(item);
    }

    if (value && value === RESEND_EMAIL) {
      this.props.setDialogType("user_register", RESEND_EMAIL);
      this.props.getDataRow(item);
    }

    if (value && value === DELETE_ACCOUNT) {
      this.props.setDialogType("user_register", DELETE_ACCOUNT);
      this.props.getDataRow(item);
    }

    if (value && value === EDIT_PERMISSION) {
      this.props.setDialogType("user_register", EDIT_PERMISSION);
      this.props.getDataRow(item);
    }

    if (value && value === RECEIVE_NOTIFICATION_ETL) {
      this.props.setDialogType("user_register", RECEIVE_NOTIFICATION_ETL);
      this.props.getNotifyEtl({"tenant": this.props.tenant.tenant, "email": item.email});
      this.props.getDataRow(item);
    }

    if (value && value === VIEW_CHATBOT_DETAILS) {
      this.props.setDialog("chatbot");
      this.props.getChatbotDetails(
        item.attendance_id,
        this.props.tenant.tenant
      );
      this.props.getData(item);
    }

    this.handleClose();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getSubItems = (column, item) => {
    const options = [];

    if (column && column.static_values) {
      const values = column.static_values;
      let disabled = false;
      values.forEach((value) => {
        if (value === SEE_CUSTOMER && item.link) {
          options.push({
            value,
          });
        }
        if (value === SEE_EVENT && item.produto) {
          options.push({
            value,
          });
        }
        if (value === SEE_REPRESENTATIVES && item.link_representantes) {
          options.push({
            value,
          });
        }
        if (value === SEE_AVAILABLE_DATES && item.evento) {
          options.push({
            value,
          });
        }
        if (value === EDIT_TARGET_AUDIENCE && item.produto) {
          options.push({
            value,
          });
        }
        if (value === CLEAN_TARGET_AUDIENCE && item.produto) {
          if (item.transversal === "Sim") {
            disabled = true;
          }
          options.push({
            value,
            disabled,
          });
        }
        if (value === RESEND_EMAIL) {
          options.push({
            value,
          });
        }
        if (value === DELETE_ACCOUNT) {
          options.push({
            value,
          });
        }
        if (value === EDIT_PERMISSION) {
          options.push({
            value,
          });
        }
        if (value === VIEW_CHATBOT_DETAILS) {
          options.push({
            value,
          });
        }
        if (value === RECEIVE_NOTIFICATION_ETL && item.permission === 'admin') {
          options.push({
            value,
          });
        }
      });
    }
    return options;
  };

  render = () => {
    const { anchorEl } = this.state;
    const { column, item } = this.props;
    const options = this.getSubItems(column, item);

    return (
      <div>
        <IconButton onClick={this.handleClick}>
          <MoreVertIcon style={{ color: baseColor }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map((option) => (
            <MenuItem
              disabled={option.disabled}
              key={option.value}
              onClick={() => this.onTableClick(item, option.value)}
            >
              {option.value}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };
}

const mapStateToProps = ({
  tenant,
}) => ({
  tenant,
});

export default compose(
  connect(mapStateToProps, {
    ...DialogCreators,
    ...EventCreators,
    ...RfbPartnerActions,
    ...DateEventCreators,
    ...UserRegisterDialogCreators,
    ...ChatbotDetailsCreators,
    ...NotifyEtlCreators,
    ...TargetAudiencePerProductCreators,
  })
)(SubMenu);

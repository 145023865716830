import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Creators as CustomerCreators } from '../../store/ducks/customer';
import { selectStyle } from '../App/theme';
import AsyncSelect from 'react-select/async';
import debounce from "debounce-promise";
import api from '../../services/api';
import { cpfCnpjMask } from '../../utils';
import { PESSOA_FISICA, PESSOA_JURIDICA } from '../../constants';

class CustomerSelect extends Component {

  constructor(props) {
    super(props);

    const SUGGEST_WAIT = 700; // milliseconds
    const loadOptions = term => this.getSuggestData({ term: term.trim().toUpperCase() });
    this.debouncedLoadOptions = debounce(loadOptions, SUGGEST_WAIT, {
      leading: true
    });
  }

  componentDidMount() {
    this.sendTextChange = debounce(this.sendTextChange, 500);
  }

  transformSuggestData = (data) => {
    let newData = [];

    data.forEach((item) => {
      let label = item.label;
      let type = item.tipoparceiro.trim().toUpperCase();

      if (type === PESSOA_FISICA && item.cgccpf) {
        label = `${label} - CPF: ${cpfCnpjMask(item.cgccpf)}`;
      }

      if (type === PESSOA_JURIDICA && item.cgccpf) {
        label = `${label} - CNPJ: ${cpfCnpjMask(item.cgccpf)}`;
      }

      newData.push({
        label,
        short_label: item.label,
        cgccpf: item.cgccpf,
        value: item.value
      });
    });

    return newData;
  }

  getSuggestData = value => {
    if (value && value.length <= 2) {
      return new Promise(resolve => {
        resolve([]);
      });
    }

    return new Promise(resolve => {
      api
        .post('/search', value)
        .then(res => resolve(this.transformSuggestData(res.data)));
    });
  }

  handleChange = (selected) => {
    const { onChange, customerSelect } = this.props;

    if (onChange) onChange(selected);
    customerSelect(selected);

    if (selected && selected.value) {
      window.history.pushState({}, null, `/customer/${selected.value}`);
    }
  }

  render() {
    const { placeholder, customer } = this.props;

    return (
      <AsyncSelect
        isClearable
        cacheOptions
        value={customer.selected}
        noOptionsMessage={() => 'Sem dados'}
        loadingMessage={() => 'Carregando...'}
        onChange={(e) => this.handleChange(e)}
        placeholder={placeholder}
        loadOptions={value => this.debouncedLoadOptions(value)}
        styles={selectStyle} />
    )
  }
}

const mapStateToProps = ({ customer }) => ({ customer });

export default compose(
  connect(mapStateToProps, { ...CustomerCreators })
)(CustomerSelect);
import styled from 'styled-components';
import { baseColor } from '../App/theme';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const Card3 = styled.div`
  min-height: 35vh;
  min-width: 33%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const Card4 = styled.div`
  min-height: 25vh;
  min-width: 24.2%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const Card2 = styled.div`
  min-height: 35vh;
  min-width: 49.5%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const Card1 = styled.div`
  min-height: 35vh;
  width: 100%;
  border-radius: 5px;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

export const PersonaTableContainer = styled.div`
  margin: 2vh;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 60%;
  min-height: 40%
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const HeaderText = styled.div`
  font-weight: 600;
  font-size: 13px;
  opacity: .5;
  line-height: 23px;
`;

export const CardGroupText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: .5rem 1rem .5rem 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin-top: 3vh;
`;

export const CenterContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin: 10% auto;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const TableColumn = styled.div`
  font-size: 12px;
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  height: 31vh;
  max-height: 31vh;
`;

export const Separator = styled.div`
  margin: 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  border-top: 1px dashed #464646;
`;

export const NumberIndicator = styled.div`
 font-size: 3.5vw;
 border-radius: 50%;
 color: ${baseColor};
 font-weight: bold;
 margin: 10% auto;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 90px;
 height: 90px;
`;

export const NumberIndicator4 = styled.div`
 font-size: 3.5vw;
 color: ${baseColor};
 font-weight: bold;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 17vh;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 1rem;
`;

export const MicroText = styled.span`
  font-size: 10px;
  font-weight: normal;
`;

export const StyledButton = styled(Button)`
  text-transform: none !important;
  font-size: 12px !important;
  color: #6B6D6F !important;
  border-color: #6B6D6F !important;
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: .5rem;
`;

export const StyledSelect = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    width: '4vw'
  },
}))(Select);

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    marginRight: '30px',
    marginTop: '-30px',
    boxShadow: theme.shadows[5]
  },
}))(Tooltip);
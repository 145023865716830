import styled from 'styled-components';

export const Text = styled.div`
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
  padding-right: 2vw;
  min-height: 5vh;
  font-size: 14px;
`;

export const StyledHeader = styled.div`
  display: flex;
  font-weight: bold; 
  font-size: 1.2rem; 
  padding-left: 1rem; 
  padding-right: 1rem;
  padding-top: 1rem;
`;

export const StyledDialogContent = styled.div`
  height: 55vh; 
  padding-right: 0;
  padding-left: 0;
`;

export default () => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem'
  }
})

import styled from 'styled-components';

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C9CED3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 1vw;  
  padding: .7vw;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const CardTitle = styled.div`
  font-size: 14px;
`;

export const CardValue = styled.div`
  font-size: 16px;
  padding-top: .5vh;
  font-weight: bold;
`;
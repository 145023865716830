import React, { Component } from 'react';
import { exportFile } from '../../utils';
import { TYPE_COLOR } from '../../utils/schema';
import { Dialog, Button, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { CSV, XLS } from "../../constants";
import { primary } from "../App/theme";

class DownloadFile extends Component {

  state = {
    exportType: CSV
  };

  export = () => {
    let csvHeader = [];
    let csvRows = [];
    let headerByKey = {};
    const { filename, header, rows } = this.props;

    if (this.props.onClick) {
      this.props.onClick();
      return;
    }

    header.forEach(item => {
      if (item.type === TYPE_COLOR || !item.label.trim().length || item.label.indexOf('#') !== -1 || item.label === 'Ações') {
        return;
      }

      headerByKey[item.value] = item;
      csvHeader.push(item.label);
    });

    rows.forEach(row => {
      let csvRow = {};

      Object.keys(headerByKey).forEach(headerColumn => {
        csvRow[headerColumn] = row[headerColumn];
      });

      csvRows.push(csvRow);
    });

    exportFile(csvHeader, csvRows, filename, this.state.exportType)
  }

  handleDownloadFile = () => {
    this.props.handleDownload();
  }

  handleSelectChange = (event) => {
    this.setState({ exportType: event.target.value });
    this.props.onSelectExportType(event.target.value);
  };

  render() {
    const { isDialogOpened, handleCloseDialog, isDownload } = this.props;
    if (!this.props.rows || !this.props.rows.length || this.props.loading) {
      return null;
    }

    return (
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={isDialogOpened}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <b>Exportar</b>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FormControl variant="outlined" style={{ minWidth: "15rem" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de exportação
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.exportType}
              onChange={this.handleSelectChange}
              label="Tipo de exportação"
            >
              <MenuItem value={XLS}>Excel(XLSX)</MenuItem>
              <MenuItem value={CSV}>CSV</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "2.5rem",
            }}
          >
            <div>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  minWidth: 130,
                  color: primary,
                  fontSize: "0.8rem",
                }}
                onClick={isDownload ? this.handleDownloadFile : this.export.bind(this)}
              >
                Exportar
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  minWidth: 130,
                  color: primary,
                  fontSize: "0.8rem",
                }}
                onClick={handleCloseDialog}
              >
                Fechar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default DownloadFile;

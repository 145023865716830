import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { RECSYS, SIMILARITY } from '../../constants';

export const { Types, Creators } = createActions({
  themeInit: [],
  themeRequest: [],
  themeSuccess: ['context', 'data'],
  themeError: ['err'],
  getThemes: ['filter']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  [RECSYS]: [],
  [SIMILARITY]: [],
  loading: false,
  error: false
});

/* Reducers Product */

export const init = state => state.merge({ data: [] });

export const request = state => state.merge({ loading: true });

export const success = (state, { context, data }) => state.merge({ [context]: data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.THEME_INIT]: init,
  [Types.THEME_REQUEST]: request,
  [Types.THEME_SUCCESS]: success,
  [Types.THEME_ERROR]: error
});
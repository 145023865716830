import Plotly from 'plotly.js-dist';
import React, { Component } from 'react';
import { CenterContainer } from './styles';
import createPlotlyComponent from 'react-plotly.js/factory';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { baseColor } from '../App/theme';

const Plot = createPlotlyComponent(Plotly);

class TreeMap extends Component {

  renderLoading = () => (
    <CenterContainer color={baseColor}>
      <CircularProgress color="primary" />
    </CenterContainer>
  )

  renderError = () => (
    <CenterContainer>Ocorreu um erro. Tente novamente.</CenterContainer>
  )

  renderEmptyState = () => (
    <CenterContainer>Sem dados para serem exibidos.</CenterContainer>
  )

  render() {
    const { height, context } = this.props;

    const treemap = this.props[`treemap_${context}`];
    let marker = { colors: ["#DC8282", "#78AEE1", "#E1BC5D", "#61C9C3"] };

    if (treemap.error && !treemap.loading) return this.renderError();
    if (treemap.loading) return this.renderLoading();

    if (!Object.keys(treemap.data).length) return this.renderEmptyState();

    return (
      <Plot
        useResizeHandler={true}
        style={{ width: "100%", height: height ? height : '90%' }}
        data={[{
          ids: treemap.data.ids,
          labels: treemap.data.labels,
          parents: treemap.data.parents,
          values: treemap.data.values,
          marker,
          branchvalues: "total",
          type: "treemap",
          textposition: 'middle center'
        }]}
        config={{
          displaylogo: false,
          displayModeBar: false,
          responsive: true,
          editable: false
        }}
        layout={{
          showlegend: false,
          margin: {
            l: 0,
            r: 0,
            b: 0,
            t: 0,
            pad: 0
          },
          autosize: true,
          font: {
            family: 'Mada',
            size: 18
          },
        }}
      />
    );
  }
}

const mapStateToProps = ({ treemap, treemap_product, treemap_market }) => {
  return ({ treemap, treemap_market, treemap_product });
}

export default compose(
  connect(mapStateToProps, null)
)(TreeMap);

export const ROOT = '/';
export const REDIRECT = '/redirect';
export const SEARCH = '/search';
export const CUSTOMER = '/customer/:term?';
export const PRODUCT = '/product';
export const PRODUCT_TERM = '/product/:productid?/:product?/:city_customer?';
export const MARKET = '/market';
export const DASHBOARD = '/dashboard';
export const LEADS = '/leads';
export const PROSPECTS = '/prospects';
export const SETTINGS = '/settings';
export const CHAT = '/chat';
export const HELPCENTER = 'https://oncase.zendesk.com/hc/pt-br';
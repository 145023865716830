import api from '../../services/api';
import { Creators } from '../ducks/map';
import { call, put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { getGeoJson } from '../../utils';
import { captureSentryException } from '../../utils';

export function* getMap({ filter, endpoint }) {
  try {
    let response;
    let getJson = null;
    const featuresById  = yield select(state => state.map.featuresById)

    yield put(Creators.mapRequest());

    response = yield call(api.post, endpoint, filter);

    getJson = getGeoJson({ data: response.data, featuresById });
    yield put(Creators.mapSuccess(response.data, getJson));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.mapError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar os dados do mapa'
    }));
  }
}
import api from "../../services/api";
import { Creators } from "../ducks/chatbot_details";
import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import { captureSentryException } from '../../utils';

export function* getChatbotDetails({ attendance_id, tenant }) {
  try {
    let response;

    yield put(Creators.chatbotDetailsRequest());
    response = yield call(api.post, "chatbot-details", {
      attendance_id,
      tenant,
    });
    yield put(Creators.chatbotDetailsSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, attendance_id);
    yield put(Creators.chatbotDetailsError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: "Falha ao detalhar atendimento.",
      })
    );
  }
}

import api from "../../services/api";
import { Creators } from "../ducks/rec_product_indicators";
import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import { captureSentryException } from '../../utils';

export function* getRecProductIndicators({ filter }) {
  let newFilter = { ...filter };
  let errorMsg = "Falha ao listar indicadores da recomendação de produtos";

  try {
    yield put(Creators.recProductIndicatorsRequest());
    const response = yield call(api.post, "rec-product-indicators", newFilter);

    yield put(Creators.recProductIndicatorsSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, newFilter);
    yield put(Creators.recProductIndicatorsError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: errorMsg,
      })
    );
  }
}

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  recCustomerProductInit: [],
  recCustomerProductRequest: [],
  recCustomerProductSuccess: ['data'],
  recCustomerProductError: ['err'],
  getRecCustomerProduct: ['data'],
  recCustomerProductSortBy: ['data', 'sort'],
  recCustomerProductExpand: ['expandedRow'],
  recCustomerProductInitTable: [],
  recCustomerProductTopRecommendations: ['selected']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  defaultData: [],
  sort: { produto_rank_recomendacao: { value: 'produto_rank_recomendacao', order: 'asc' } },
  error: false,
  loading: false,
  expandedRow: null,
  selected: []
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, defaultData: data, error: false, loading: false, sort: INITIAL_STATE.sort });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) => state.merge({ data, sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

export const select = (state, { selected }) => state.merge({ selected });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.REC_CUSTOMER_PRODUCT_INIT]: init,
  [Types.REC_CUSTOMER_PRODUCT_REQUEST]: request,
  [Types.REC_CUSTOMER_PRODUCT_SUCCESS]: success,
  [Types.REC_CUSTOMER_PRODUCT_ERROR]: error,
  [Types.REC_CUSTOMER_PRODUCT_SORT_BY]: sortBy,
  [Types.REC_CUSTOMER_PRODUCT_EXPAND]: expand,
  [Types.REC_CUSTOMER_PRODUCT_INIT_TABLE]: initTable,
  [Types.REC_CUSTOMER_PRODUCT_TOP_RECOMMENDATIONS]: select
});
import styled from "styled-components";
import { baseColor } from "../App/theme";

export const Container = styled.div`
  padding-left: 2vw;
  padding-top: 1vh;
  padding-right: 2vw;
  align-items: center;
  min-height: 75vh;
  width: 70vw;
`;

export const InfoText = styled.div`
  padding-right: 2vw;
  font-size: 14px;
  padding-top: 1vh;
  padding-bottom: 20px;
  display: flex;
  * {
    padding-right: 5px;
  }
`;

export const HeaderContainer = styled.div`
  padding-right: 2vw;
  padding-top: 1vh;
  min-height: 2vh;
  font-size: 16px;
  padding-bottom: 1.6vh;
  font-weight: 600;
`;

export const CenterContainer = styled.div`
  display: flex;
  color: #b9b9b9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin: 10% auto;
`;

export const CardGroup = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 2rem;
`;

export const Card4 = styled.div`
  min-height: 5vh;
  min-width: 22.2%;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const Card3 = styled.div`
  min-height: 5vh;
  max-width: 30%;
  background: #f4f8fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex: 1 1 0px;
`;

export const HeaderText = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
`;

export const NumberIndicator4 = styled.div`
  font-size: 1.8vw;
  color: ${baseColor};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
`;

export const MicroText = styled.span`
  display: flex;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: #676767;
  padding: 1rem 1rem 1rem 1rem;
`;

export const CardHeader = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  margin-top: 0.5vh;
`;

export const CardDescription = styled.div`
  padding: 0 1rem 0 1rem;
  font-weight: bold;
  margin-top: 1vh;
`;

export const CardText = styled.div`
  font-size: 16px;
  margin-left: 3px;
  color: #333333;
`;

export const QuestionsCardText = styled.div`
  padding: 0 1rem 0 1rem;
  font-size: 16px;
`;

export const InfoContainer = styled.div`
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1400px) {
    margin-top: 0.5vh;
  }
`;

export default () => ({
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "2rem",
  },
});

import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Mixpanel } from "../../utils/mixpanel";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { getMetabaseDashIdByDashName } from "../../utils/schema";
import { DASHBOARD_PROSPECT_INDICATORS } from "../../constants";
import Metabase from "../Metabase";


class Prospects extends Component {
  componentDidMount() {
    this.props.setTitle("Prospects");

    Mixpanel.track("INIT_PROSPECTS");
  }


  render() {
    const dashboardId = getMetabaseDashIdByDashName(
      DASHBOARD_PROSPECT_INDICATORS
    );

    return (
      <Metabase
        dashboardId={dashboardId}
        isByTenant={false}
        iframeHeight={'99%'}
      />
    );
  }
}

const mapStateToProps = ({ title }) => ({ title });

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators
  })
)(Prospects);
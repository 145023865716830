import api from '../../services/api';
import { Creators } from '../ducks/crm';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { showRunningToastr } from '../../utils/toastr';
import { captureSentryException } from '../../utils';

export function* postCrm(params) {
  try {

    yield put(Creators.crmRequest());
    const response = yield call(api.post, 'crm', params.data);

    yield put(Creators.crmSuccess(response.data));

    if (params.data && params.data.crm_context === 'rec_product') {

      if (response.data.code === 'EXISTING_JOB_RUNNING') {
        yield put(toastrActions.add({
          type: 'warning',
          title: 'Atualização do CRM',
          message: 'Já existe uma integração em andamento. Tente novamente mais tarde.'
        }));
        return;
      }

      showRunningToastr({ title: "Exportando", body: "Isso pode levar alguns minutos." });
    }

  } catch (err) {
    yield captureSentryException(err, params.data);
    yield put(Creators.crmError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao enviar dados de recomendação para o CRM'
    }));
  }
}
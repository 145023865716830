
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { Creators as CityCreators } from '../../store/ducks/city';
import { Creators as ProductDetailCreators } from '../../store/ducks/product_detail';
import { Creators as CustomerDetailCreators } from '../../store/ducks/customer_detail';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as MapCreators } from '../../store/ducks/map';
import { Creators as TreemapMarketCreators } from '../../store/ducks/treemap_market';
import { Creators as RegionalUnitCreators } from '../../store/ducks/regional_unit';
import { Creators as SectorCreators } from "../../store/ducks/sector";
import { Creators as PortCreators } from "../../store/ducks/port";
import { Creators as InstrumentCreators } from "../../store/ducks/instrument";
import { Creators as YearCreators } from "../../store/ducks/year";
import { Creators as ProductCreators } from "../../store/ducks/product";
import { Creators as ThemeCreators } from "../../store/ducks/theme";
import {
  Container, ProductContainer, HeaderText,
  Content, TreeMapHeader, HeaderTableContainer,
  SettingsIconContainer, TreeMapDimensions,
  HeaderTreeMapContainer
} from './styles';
import Table from '../Table';
import TreeMap from '../TreeMap';
import ChipGroup from '../ChipGroup';
import SettingsIcon from 'react-feather/dist/icons/settings';
import TreeMapDialog from '../TreeMapDialog';
import DownloadFile from "../DownloadFile";
import { getFeatures } from '../../utils/features';
import AnalysisFilter from '../AnalysisFilter';
import RecMarket from '../RecMarket';
import { Mixpanel } from '../../utils/mixpanel';
import { RECSYS, HISTORIC, CUSTOMER_DETAIL_DOWNLOAD, MARKET_CHIP, ITEMS_PER_PAGE, NIVEL1, MARKET, CSV } from '../../constants';
import { getFilterRules } from '../../utils';
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";

const CONTEXT = MARKET;

class Market extends Component {

  state = {
    page: 1,
    isDownload: true,
    isOpenedDialog: false,
    exportType: CSV
  }

  componentDidMount() {
    this.props.setTitle('Mercado');
    this.props.treeMapMarketInit();
    this.props.customerDetailInit();

    Mixpanel.track('INIT_PRODUCT');
  }

  handleOpenDialog = () => { this.setState({ isOpenedDialog: !this.state.isOpenedDialog, isDownload: true })};

  handleDownload = () => this.submit({ is_download: true });

  handlePageChange = (page) => this.setState({ page }, () => this.submit({}));

  handleExportType = (exportType) => {this.setState({exportType: exportType});}
  
  handleTableSortBy = (data, sort) => {
    this.submit({ sort });
    this.props.customerDetailSortBy(sort);
  };

  submit = ({ sort, is_download }) => {

    this.props.getCustomersDetail({
      ...this.props.filter.filter,
      page: this.state.page,
      sort: sort || this.props.customer_detail.sort,
      items_per_page: ITEMS_PER_PAGE,
      is_search: true,
      is_download: is_download || false,
      file_format: this.state.exportType
    });
  }

  renderHistoric = () => {
    return (
      <div style={{ width: '97.6%' }}>
        <ProductContainer style={{ width: '100%', height: '43vh', maxHeight: '43vh', marginBottom: 0 }}>
          {this.renderDetailedCities()}
        </ProductContainer>
        <ProductContainer style={{ width: '100%', height: '43vh', maxHeight: '43vh' }}>
          {this.renderTreemap({ height: '85%' })}
        </ProductContainer>
      </div>
    )
  }

  renderDetailedCities(){
    const { data, defaultData, sort, expandedRow, loading, error } = this.props.customer_detail;
    const { role } = this.props.tenant;

    return (
      <div>
        <HeaderTableContainer>
          <HeaderText>Detalhamento de Municípios</HeaderText>
          {role === NIVEL1 || data.items === undefined ? null :
              <div>
                <DownloadFile
                  label='Baixar lista'
                  mixpanelTrackEvent={CUSTOMER_DETAIL_DOWNLOAD}
                  filename='detalhamento_de_municipios'
                  loading={loading}
                  header={data.header}
                  rows={data.items}
                  isDialogOpened={this.state.isOpenedDialog}
                  isDownload={this.state.isDownload}
                  handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                  handleDownload={this.handleDownload}
                  onSelectExportType={this.handleExportType}
                />
                <IconButton
                  mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                  onClick={() => this.handleOpenDialog()}
                  label={'Exportar'}
                  icon={<ExportIcon />}
                />
              </div>}
        </HeaderTableContainer>
        <Table
          sort={sort}
          error={error}
          data={data}
          loading={loading}
          hasDetail={false}
          defaultData={defaultData}
          expandedRow={expandedRow}
          history={this.props.history}
          sortBy={this.handleTableSortBy}
          expand={this.props.customerDetailExpand}
          pageCount={Math.ceil(data.count / ITEMS_PER_PAGE)}
          onPageChange={this.handlePageChange}
          page={this.state.page}
        />
      </div>
    );
  }

  renderTreemap = ({ height }) => {
    const { setDialog } = this.props;
    const { filter } = this.props.filter;
    const isClickEnabled = Object.keys(filter).length;
    const { parent, child, data, loading } = this.props.treemap_market;

    return (
      <Fragment>
        <HeaderTreeMapContainer>
          <TreeMapHeader>Tree Map</TreeMapHeader>

          <SettingsIconContainer>
            {parent && child && Object.keys(data).length ?
              <TreeMapDimensions>
                <b>Grupos</b>: {parent.label}, {child.label} x <b>Cores:</b> {parent.label}
              </TreeMapDimensions>
              : null}
            {isClickEnabled ?
              <SettingsIcon size={18} onClick={!loading ? setDialog.bind(this, 'treemap') : null} />
              : null}
          </SettingsIconContainer>
        </HeaderTreeMapContainer>
        <TreeMap height={height} context={CONTEXT} />
      </Fragment>
    );
  }

  onChangeChip = ({ activeChip }) => {
    const filter = { context: CONTEXT, chip:activeChip }
    const filterRules = getFilterRules(this.props, CONTEXT, activeChip)

    if (!filterRules || !filterRules.length) {
      return;
    }

    filterRules.forEach((fun) => {
      fun(filter);
    });
  }

  render() {
    const { chip } = this.props;
    const { tenant, role } = this.props.tenant;
    const chipOptions = getFeatures(tenant, MARKET_CHIP) || [];

    return (
      <>
        <Container>
          <AnalysisFilter context={CONTEXT} title='Mercado' />

          <Content>
            <ChipGroup
              onChange={(activeChip) => this.onChangeChip({ activeChip })}
              data={chipOptions}
              title=''
              context={CONTEXT} />

            {chip.market === HISTORIC ? this.renderHistoric() : null}
            {chip.market === RECSYS ? <RecMarket history={this.props.history} role={role} /> : null}
          </Content>
        </Container>
        <TreeMapDialog context={CONTEXT} />
      </>
    )
  }
}

const mapStateToProps = ({
  title, dialog,
  treemap_market, chip,
  filter, treemap_product,
  customer_detail, tenant, instrument,
  product, year, city,
  sector, port, regional_unit, page }) => {
  return ({
    title, dialog, treemap_market,
    chip, filter, treemap_product,
    customer_detail, tenant, instrument,
    product, year, city,
    sector, port, regional_unit, page
  });
}

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...ProductDetailCreators,
    ...DialogCreators,
    ...CustomerDetailCreators,
    ...MapCreators,
    ...TreemapMarketCreators,
    ...CityCreators,
    ...RegionalUnitCreators,
    ...SectorCreators,
    ...PortCreators,
    ...InstrumentCreators,
    ...YearCreators,
    ...ProductCreators,
    ...ThemeCreators,
  })
)(Market);

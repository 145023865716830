import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Container,
  HeaderContainer,
  TableContainer,
  Header
} from './styles';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { Creators as CustomerCreators } from '../../store/ducks/customer';
import { Creators as RecCustomerEventCreators } from '../../store/ducks/rec_customer_event';
import Table from '../Table';
import DownloadFile from "../DownloadFile";
import { REC_CUSTOMER_DOWNLOAD, NIVEL1 } from '../../constants';
import { TYPE_ICON } from '../../utils/schema';
import DateEventDialog from '../DateEventDialog';
import { Creators as DateEventCreators } from '../../store/ducks/date_event';
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";
import { CSV } from "../../constants";

class RecCustomerEvent extends Component {

  state = {
    isOpenedDialog: false,
    exportType: CSV
  };

  handleOpenDialog = () => {
    this.setState({ isOpenedDialog: !this.state.isOpenedDialog })
  };

  handleExportType = (exportType) => {this.setState({exportType: exportType});}

  onTableClick = (column, item) => {
    if (column.type === TYPE_ICON) {
      this.props.setDialog('date_event');
      this.props.getDateEvents({
        event_id: item.evento
      });
    }
  }

  render() {
    const { data, defaultData, sort, expandedRow, loading, error } = this.props.rec_customer_event;
    const { role } = this.props;

    return (
      <>
        <Container>
          <TableContainer>
            {!loading ?
              <HeaderContainer style={{ display: 'flex', justifyContent: 'space-between', padding: 0, marginBottom: 0 }}>
                <Header>Detalhe de Recomendação de Eventos</Header>
                {role === NIVEL1 ? null :
                  <div>
                    <DownloadFile
                      label='Baixar lista'
                      mixpanelTrackEvent={REC_CUSTOMER_DOWNLOAD}
                      filename='recomendacao_de_eventos_por_cliente'
                      header={data.header}
                      rows={data.items}
                      isDialogOpened={this.state.isOpenedDialog}
                      handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                      onSelectExportType={this.handleExportType}
                    />
                    <IconButton
                      mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                      onClick={() => this.handleOpenDialog()}
                      label={'Exportar'}
                      icon={<ExportIcon />}
                    />
                  </div>}
              </HeaderContainer>
              : null}

            <Table
              data={data}
              sort={sort}
              error={error}
              loading={loading}
              onClick={(column, item) => this.onTableClick(column, item)}
              defaultData={defaultData}
              expandedRow={expandedRow}
              sortBy={this.props.recCustomerEventSortBy}
            />

          </TableContainer>
        </Container>
        <DateEventDialog />
      </>
    )
  }
}

const mapStateToProps = ({ title, customer, rec_customer_event }) =>
  ({ title, customer, rec_customer_event });

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...CustomerCreators,
    ...RecCustomerEventCreators,
    ...DateEventCreators
  })
)(RecCustomerEvent);

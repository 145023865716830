import api from '../../services/api';
import { Creators } from '../ducks/users_groups';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getUsersGroups({ filter }) {
  try {
    let response; 

    yield put(Creators.usersGroupsRequest());
    response = yield call(api.post, 'users-groups', filter);

    yield put(Creators.usersGroupsSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.usersGroupsError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar grupos de usuários'
    }));
  }
} 
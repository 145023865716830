import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled, { css } from 'styled-components';
import { baseColor } from '../App/theme';
import { TYPE_LINK } from '../../utils/schema';
import IconButton from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    paddingLeft: '75px',
    marginRight: '0px',
  },
}))(FormControlLabel);

export const StyledButton = withStyles((theme) => ({
  root: {
    fontSize: '16px !important',
    width: '240px',
    height: '40px',
    textTransform: 'none'
  },
}))(IconButton);

export const StyledTextField = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    width: '12vw',
    backgroundColor: 'white',
  },
}))(TextField);

export const StyledSelect = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    width: '10vw',
  },
}))(Select);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: '0px',
    padding: '16px',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      fontSize: '12px'
    },
  },
}))(TableRow);

export const StyledTableContainer = withStyles((theme) => ({
  root: {
    display: 'flex',
    border: 'none',
  },
}))(TableRow);

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    marginRight: '30px',
    marginTop: '-30px',
    boxShadow: theme.shadows[5]
  },
}))(Tooltip);

export const Text = styled.span`
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  padding-top: 8px;

  ${props => props.type === TYPE_LINK && css`
    color: ${baseColor};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;

export const AgreemensContainer = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  margin-left: 1rem;
  margin-right: 6rem;
  margin-top: .5rem;
  overflow: hidden;
  word-break: break-word;
  text-align: right;
  padding-top: '.2rem';
`;

export const UserRegisterContainer = styled.div`
  display: flex;
  width: 98%;
  height: 88.4vh;
  overflow-y: auto;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flexDirection: 'row';
  margin: 10px
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flexDirection: 'row';
  margin: 10px;
`;

export const HeaderTableContainer = styled.div`
  display: flex;
  align-items: top;
  width: 50%;
  flex-direction: column;
`;

export const DashedDivider = styled.hr`
  border:none;
  border-right:1px dashed #676767;
  color:#fff;
  background-color:#fff;
  height:80%;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-top: 55px;
`;

export const HeaderText = styled.div`
  margin-top: 3px;
  margin-left: 5px;
`;

export const FormContainer = styled.div`
  flex-direction: column;
  height: 45vh;
  overflow: auto;
  margin-top: 45px;
`;

export const ImportCSVContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const FormHeader = styled.div`
  font-size: 1.5vh;
  margin-left: 25px;
  margin-top: 10px;
}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  text-transform: none;
  color: rgba(0,0,0,0.5)
`;

export const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
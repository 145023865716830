import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  yearInit: [],
  yearRequest: [],
  getYears: ['filter'],
  yearSelect: ['selected'],
  yearSuccess: ['data'],
  yearError: ['err']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  selected: {},
  loading: false,
  error: false
});

/* Reducers */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false, selected: data });

export const error = state => state.merge({ loading: false, error: true });

export const select = (state, { selected }) => state.merge({ selected });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.YEAR_INIT]: init,
  [Types.YEAR_SELECT]: select,
  [Types.YEAR_REQUEST]: request,
  [Types.YEAR_SUCCESS]: success,
  [Types.YEAR_ERROR]: error
});


import Table from '../Table';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Creators as FilterCreators } from '../../store/ducks/filter';
import { Creators as TargetAudienceCreators } from '../../store/ducks/target_audience';
import {
  Container, ProductContainer, DetailContainer,
  Header, HeaderContainer
} from './styles';
import { ITEMS_PER_PAGE } from '../../constants';

class TargetAudience extends Component {

  state = {
    page: 1
  }

  componentDidMount() {
    this.setState({ page: 1 });
  }

  submit = ({ sort, is_download }) => {
    const { filter } = this.props.filter;

    this.props.getTargetAudience({
      ...filter,
      page: this.state.page,
      sort: sort || this.props.target_audience.sort,
      items_per_page: ITEMS_PER_PAGE,
      is_download: is_download || false
    });
  }

  handlePageChange = (page) => this.setState({ page }, () => this.submit({}));

  handleTableSortBy = (data, sort) => {
    this.submit({ sort });
    this.props.getTargetAudienceSortBy(sort);
  };

  handleDownload = () => this.submit({ is_download: true });

  renderProductContainer = () => {
    const { data, sort, error, loading } = this.props.target_audience;
    const isLoading = loading;

    return (
      <>
      <DetailContainer>
        <ProductContainer>
          <HeaderContainer>
            <Header>{isLoading ? null : <b>{data.count} Produtos encontrados</b>}</Header>
          </HeaderContainer>
          <Table
            data={!isLoading ? data : null}
            sort={sort}
            error={error}
            loading={isLoading}
            pageCount={Math.ceil(data.count / ITEMS_PER_PAGE)}
            sortBy={this.handleTableSortBy}
            onPageChange={this.handlePageChange}
            history={this.props.history}
            page={this.state.page}
          />
        </ProductContainer>
      </DetailContainer>
      </>
    )
  }

  render() {
    return (
      <Container>
        {this.renderProductContainer()}
      </Container>
    )
  }
}

const mapStateToProps = ({ filter, target_audience }) => ({ filter, target_audience });

export default compose(
  connect(mapStateToProps, {
    ...TargetAudienceCreators,
    ...FilterCreators,
  })
)(TargetAudience);

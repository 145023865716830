import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    usersGroupsRequest: [],
    getUsersGroups: ['filter'],
    usersGroupsSuccess: ['data'],
    usersGroupsError: ['err']
  });
  
  /* Initial State */
  
  export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false
  });
  
  /* Reducers */
  
  export const request = state => state.merge({ loading: true });
  
  export const success = (state, { data }) => state.merge({ data: data, error: false, loading: false });
  
  export const error = state => state.merge({ loading: false, error: true });
  
  /* Reducers to types */
  
  export default createReducer(INITIAL_STATE, {
    [Types.USERS_GROUPS_REQUEST]: request,
    [Types.USERS_GROUPS_SUCCESS]: success,
    [Types.USERS_GROUPS_ERROR]: error
  });
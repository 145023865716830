import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  chatbotCustomerInit: [],
  chatbotCustomerSelect: ["selected"],
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  selected: null,
});

/* Reducers */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const select = (state, { selected }) => state.merge({ selected });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CHATBOT_CUSTOMER_INIT]: init,
  [Types.CHATBOT_CUSTOMER_SELECT]: select,
});

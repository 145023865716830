import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/rec_product';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { createRunningNotification } from '../../utils/notification';
import { captureSentryException } from '../../utils';

export function* getRecProduct({ filter }) {
  let newFilter = { ...filter };
  let errorMsg = 'Falha ao listar recomendação de produtos';

  try {
    if (filter && filter.is_download) {
      errorMsg = 'Falha ao enviar por e-mail';
      newFilter = {
        ...newFilter,
        firebase_notification_key: yield createRunningNotification({ title: filter.product_label })
      };
    }

    yield put(Creators.recProductRequest());
    const response = yield call(api.post, 'rec-product', newFilter);

    if (filter && filter.is_download) {
      yield put(Creators.recProductSuccess(store.getState().rec_product.data));
    } else {
      yield put(Creators.recProductSuccess(response.data));
    }

  } catch (err) {
    yield captureSentryException(err, newFilter);
    yield put(Creators.recProductError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: errorMsg
    }));
  }
}
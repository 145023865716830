import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { selectStyle } from '../App/theme';
import styles from './styles';
import {
  Container, Text, SelectContainer, InfoText
} from './styles';
import { withStyles, Dialog } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as TargetAudienceCreators } from '../../store/ducks/target_audience';
import Select from 'react-select';
import { TARGET_AUDIENCE, ITEMS_PER_PAGE } from '../../constants';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { getUserData, getFilterValues } from '../../utils/index';

const FILTER_SECTOR = 'sector';
const FILTER_PUB_ALVO = 'pub_alvo';
const FILTER_MACROSSEGMENT = 'macrossegment';

class TargetAudienceDialog extends Component {

  INITIAL_STATE = {
    [TARGET_AUDIENCE]: {
      product: null,
      pub_alvo: null,
      sector: null,
      macrossegment: null,
    },
    isShowAlert: false,
    isShowToolTipSetor: false,
  }

  state = this.INITIAL_STATE

  user = null

  onClose = () => {
    this.setState(this.INITIAL_STATE);
    this.props.setDialog('target_audience');
  }

  submit = () => {
    const filter = this.getFilter();

    this.setState(this.INITIAL_STATE);
    this.props.setDialog('target_audience');

    this.props.updateTargetAudience({ filter });
  }

  handleChange = (item, name) => {
    const newData = { ...this.state[TARGET_AUDIENCE], [name]: item };

    this.setState({ [TARGET_AUDIENCE]: newData })
  };

  async getUser() {
    this.user = await getUserData();
  }

  componentDidMount() {
    this.getUser()
  }

  getFilter = () => {
    const { dialog } = this.props;
    const { sector, macrossegment, pub_alvo } = this.state[TARGET_AUDIENCE];
    const { target_audience_per_product } = this.props;

    const filter = {
      productid: dialog.product.produtoid,
      product: dialog.product.produto,
      sector: sector ? getFilterValues(sector) : getFilterValues(target_audience_per_product.data.sector),
      macrossegment: macrossegment ? getFilterValues(macrossegment) : getFilterValues(target_audience_per_product.data.macrossegment),
      pub_alvo: pub_alvo ? getFilterValues(pub_alvo) : getFilterValues(target_audience_per_product.data.pub_alvo),
      chip: TARGET_AUDIENCE,
      user: this.user && this.user.email ? this.user.email : null,
      page: 1,
      items_per_page: ITEMS_PER_PAGE
    };

    return filter;
  }

  handleShowAlert = () => {
    this.setState({ isShowAlert: !this.state.isShowAlert });
  };

  handleClose = () => {
    this.setState({ isShowAlert: !this.state.isShowAlert });
  };

  handleCloseToolTip = (e, name) => {
    this.setState({ [name]: false })
  };

  handleOpenToolTip = (e, name) => {
    this.setState({ [name]: true })
  };

  renderSelect = ({ name, data, placeholder, isLoading, isMulti, isDisabled, configured_values }) => (
    <SelectContainer>
      <Select
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading ? true : isDisabled ? true : null}
        noOptionsMessage={() => 'Sem dados'}
        onChange={(e) => this.handleChange(e, name)}
        placeholder={isLoading ? 'Carregando...' : placeholder}
        styles={selectStyle}
        options={data}
        value={isLoading ? null : (
          this.state[TARGET_AUDIENCE][name] ? this.state[TARGET_AUDIENCE][name] : (
            configured_values.length ? configured_values : null
          )
        )}
      />
    </SelectContainer>
  )

  render() {
    const { dialog } = this.props;
    const { sector, pub_alvo, macrossegment, target_audience_per_product } = this.props;
    const isLoading = target_audience_per_product.loading || sector.loading || pub_alvo.loading || macrossegment.loading;

    if (!dialog.target_audience) {
      return null;
    }

    return (
      <>
        <Dialog
          onClose={this.onClose.bind(this)}
          open={dialog.target_audience}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll='paper'
        >
          <DialogTitle id="scroll-dialog-title">
            Editar público-alvo<br/>
            <b>Produto: {dialog.product.produto}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Container>
                <InfoText>Ao selecionar um público alvo, setor ou macrossegmento, o produto será recomendado somente a clientes com essas características</InfoText>
                <Text style={{color:"black"}}>Público Alvo</Text>
                {this.renderSelect({
                  name: FILTER_PUB_ALVO,
                  data: pub_alvo[TARGET_AUDIENCE].asMutable(),
                  placeholder: 'Todos os públicos',
                  isMulti: true,
                  isLoading: isLoading,
                  configured_values: target_audience_per_product.data.pub_alvo
                })}

                <Text style={{color:"black"}}>Setor</Text>
                {this.renderSelect({
                  name: FILTER_SECTOR,
                  data: sector[TARGET_AUDIENCE].asMutable(),
                  placeholder: 'Escolha um ou mais setores',
                  isMulti: true,
                  configured_values: target_audience_per_product.data.sector,
                  isLoading: isLoading
                })}

                <Text style={{color:"black"}}>Macrossegmento</Text>
                {this.renderSelect({
                  name: FILTER_MACROSSEGMENT,
                  data: macrossegment[TARGET_AUDIENCE].asMutable(),
                  placeholder: 'Escolha um ou mais macrossegmentos',
                  isMulti: true,
                  configured_values: target_audience_per_product.data.macrossegment,
                  isLoading: isLoading
                })}
              </Container>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{
            bottom: "1vw",
            right: "1vw",
          }}>
            <Button onClick={this.onClose.bind(this)} style={{ color: '#676767' }}>
              Cancelar
            </Button>
            <Button
              onClick={() => this.handleShowAlert()} color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.isShowAlert}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{ textAlign: "-webkit-center" }}
            id="alert-dialog-title"><b>{"Aviso"}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "black" }}
              id="alert-dialog-description">
              As alterações nas características de público-alvo serão processadas sempre no dia seguinte, quando o modelo de recomendação atualizar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} style={{ color: '#676767' }}>
              Cancelar
            </Button>
            <Button
              onClick={() => this.submit()} color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = ({
  target_audience,
  dialog,
  sector,
  pub_alvo,
  target_audience_per_product,
  macrossegment,
}) => ({
  target_audience,
  dialog,
  sector,
  pub_alvo,
  target_audience_per_product,
  macrossegment,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...TargetAudienceCreators,
  })
)(TargetAudienceDialog);

import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/customer_detail';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { downloadFromURL } from '../../utils';
import { captureSentryException } from '../../utils';

export function* getCustomersDetail({ filter }) {
  try {

    yield put(Creators.customerDetailRequest());
    const response = yield call(api.post, 'customer-detail', filter);

    if (filter && filter.is_download && response.data.url) {
      downloadFromURL(response.data.url);
      yield put(Creators.customerDetailSuccess(store.getState().customer_detail.data));
    } else {
      yield put(Creators.customerDetailSuccess(response.data));
    }

  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.customerDetailError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar detalhe de clientes'
    }));
  }
}
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  recMarketInit: [],
  recMarketRequest: [],
  recMarketSuccess: ['data'],
  recMarketError: ['err'],
  getRecMarket: ['filter'],
  recMarketSortBy: ['data', 'sort'],
  recMarketExpand: ['expandedRow'],
  recMarketInitTable: [],
  recMarketSetCity: ['city'],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  city: null,
  sort: { global_rank: { value: 'global_rank', order: 'asc' } },
  defaultData: [],
  error: false,
  loading: false,
  expandedRow: null
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, defaultData: data, error: false, loading: false, sort: INITIAL_STATE.sort });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) => state.merge({ data, sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

export const setCity = (state, { city }) => state.merge({ city });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.REC_MARKET_INIT]: init,
  [Types.REC_MARKET_REQUEST]: request,
  [Types.REC_MARKET_SUCCESS]: success,
  [Types.REC_MARKET_ERROR]: error,
  [Types.REC_MARKET_SORT_BY]: sortBy,
  [Types.REC_MARKET_EXPAND]: expand,
  [Types.REC_MARKET_INIT_TABLE]: initTable,
  [Types.REC_MARKET_SET_CITY]: setCity
});
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledSelect = withStyles((theme) => ({
    root: {
      fontSize: '14px',
      width: '10vw',
    },
  }))(Select);


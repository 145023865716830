import Plotly from 'plotly.js-dist';
import React, { Component } from 'react';
import { CenterContainer } from './styles';
import createPlotlyComponent from 'react-plotly.js/factory';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { baseColor } from '../App/theme';

const Plot = createPlotlyComponent(Plotly);

class PieChart extends Component {

  renderLoading = () => (
    <CenterContainer color={baseColor}>
      <CircularProgress color="primary" />
    </CenterContainer>
  )

  renderError = () => (
    <CenterContainer>Ocorreu um erro</CenterContainer>
  )

  renderEmptyState = () => (
    <CenterContainer>Sem Dados</CenterContainer>
  )

  render() {
    const { error, loading, data } = this.props.duck;
    const customLayout = this.props.layout || {};

    if (error && !loading) return this.renderError();
    if (loading) return this.renderLoading();

    if (!Object.keys(data).length) return this.renderEmptyState();

    return (
      <Plot
        useResizeHandler={true}
        style={{ width: '100%', height: '25vh' }}
        data={[{
          values: data.values,
          labels: data.labels,
          type: 'pie'
        }]}
        config={{
          displaylogo: false,
          displayModeBar: false,
          responsive: true,
          editable: false
        }}
        layout={{
          showlegend: false,
          margin: {
            l: 0,
            r: 0,
            b: 15,
            t: 15,
            pad: 0
          },
          plot_bgcolor: customLayout.plot_bgcolor || '#EEE',
          paper_bgcolor: customLayout.paper_bgcolor || '#EEE',
          autosize: true,
          font: {
            family: 'Mada'
          },
        }}
      />
    );
  }
}

export default compose(
  connect(null, null)
)(PieChart);
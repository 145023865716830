import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { HISTORIC, RECSYS, SIMILARITY, TARGET_AUDIENCE } from '../../constants';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    sectorRequest: [],
    getSectors: ['filter'],
    sectorSuccess: ['context', 'data'],
    sectorError: ['err']
  });
  
  /* Initial State */
  
  export const INITIAL_STATE = Immutable({
    [HISTORIC]: [],
    [RECSYS]: [],
    [SIMILARITY]: [],
    [TARGET_AUDIENCE]: [],
    loading: false,
    error: false
  });
  
  /* Reducers */
  
  export const request = state => state.merge({ loading: true });
  
  export const success = (state, { context, data }) => state.merge({ [context]: data, error: false, loading: false });
  
  export const error = state => state.merge({ loading: false, error: true });
  
  /* Reducers to types */
  
  export default createReducer(INITIAL_STATE, {
    [Types.SECTOR_REQUEST]: request,
    [Types.SECTOR_SUCCESS]: success,
    [Types.SECTOR_ERROR]: error
  });
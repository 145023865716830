import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setDialog: ['field'],
  getData: ['product']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  treemap: false,
  rec_market: false,
  product: []
});

/* Reducers */

export const set = (state, { field }) => state.merge({ [field]: !state[field] });

export const get = (state, { product }) => state.merge({ product });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_DIALOG]: set,
  [Types.GET_DATA]: get
});

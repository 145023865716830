import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const ProductContainer = styled.div`
  margin: 2vh;
  width: 100%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1vw;
`;

export const MapContainer = styled.div`
  @media screen and (min-height: 600px) {
    height: 84.1vh;
  }

  @media screen and (min-height: 900px) {
    height: 86.35vh;
  }
`;

export const Header = styled.div`
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  line-height: 23px;
  font-weight: 600;
`;

export const ThemeText = styled.span`
  background-color: #0F86FC;
  color: #FFF;
  padding: .2vw;
`;

export const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Text = styled.div`
  margin: 1rem;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 5vh;
  height: 6vh;
  text-transform: none;
  font-size: 16px;
`;
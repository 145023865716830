import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { baseColor } from '../App/theme';
import HomeIcon from 'react-feather/dist/icons/home';
import BriefcaseIcon from 'react-feather/dist/icons/briefcase';
import {
  ContactContainer, DetailContainer, ContactText,
  CenterContainer, HeaderContainer, Header,
  DetailText
} from './styles';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import ReadMoreReact from 'read-more-react';
import { PESSOA_FISICA, PESSOA_JURIDICA } from '../../constants';

class CustomerTimeline extends Component {

  renderBigText = (value) => (
    <ReadMoreReact text={value}
      min={80}
      ideal={100}
      max={200}
      readMoreText="Ver mais" />
  )

  renderEvent = ({ item, idx, showPartnerInfo, showCompanyInfo }) => {
    const { timeline } = this.props;
    const isFirst = idx === 0;
    const isLast = idx + 1 === timeline.data.length;
    const iconColor = isLast ? 'rgb(16, 204, 82)' : baseColor;
    const icon = isLast ? <HomeIcon /> : <BriefcaseIcon />;

    return (
      <VerticalTimelineElement
        key={idx}
        className="vertical-timeline-element--work"
        date={item.datahorarealizacao ? moment(item.datahorarealizacao).format('DD [de] MMM [de] YYYY') : null}
        iconStyle={{ background: iconColor, color: '#FFF' }}
        icon={icon}
      >

        {
          isLast ?
            <ContactContainer>
              < ContactText action="first" > Entrada no {process.env.REACT_APP_APPNAME}</ContactText >
            </ContactContainer > : null}

        {
          isFirst ?
            <ContactContainer >
              <ContactText action="last">Último Contato</ContactText>
            </ContactContainer> : null
        }


        <h4 style={{ color: '#41A2FF', fontWeight: 'normal', fontSize: '1.2rem' }} className="vertical-timeline-element-title">{item.nomerealizacao}</h4>
        <DetailContainer>
          {showPartnerInfo  && item.nome_pf ?
            <DetailText style={{ textTransform: 'capitalize' }}><b>Representante da empresa: </b> {item.nome_pf} </DetailText>
            : null}
          {showCompanyInfo  && item.nome_pj ?
            <DetailText style={{ textTransform: 'capitalize' }}><b>Empresa representada: </b> {item.nome_pj} </DetailText>
            : null}
          {item.instrumento ? <DetailText><b>Instrumento: </b> {item.instrumento}</DetailText> : null}
          {item.macromomento ? <DetailText><b>Macromomento: </b> {item.macromomento}</DetailText> : null}
          {item.descrealizacao ? <DetailText><b>Descrição: </b>{this.renderBigText(item.descrealizacao)}</DetailText> : null}
        </DetailContainer>
      </VerticalTimelineElement>
    )
  }

  render() {
    const { timeline, customer, customerPartner } = this.props;
    const isLoading = timeline.loading || customer.loading;
    const showPartnerInfo = customer.data && customer.data.tipoparceiro === PESSOA_JURIDICA
      && !customerPartner ? true
      : false;
    const showCompanyInfo = customer.data && customer.data.tipoparceiro === PESSOA_FISICA ? true : false;

    return (
      <div style={{ marginTop: '1vh' }}>
        {customerPartner && customerPartner.nome && !isLoading ?
          <HeaderContainer>
            <Header><b>Cliente: </b>{customerPartner.nome}</Header>
          </HeaderContainer>
          : null}

        {isLoading
          ?
          <CenterContainer>
            <CircularProgress color="primary" />
          </CenterContainer>
          : null}

        {!timeline.data.length && !isLoading && !timeline.error ?
          <CenterContainer>
            Sem dados para serem exibidos.
          </CenterContainer>
          : null}

        {timeline.data.length && !isLoading ?
          <VerticalTimeline>
            {timeline.data.map((item, idx) => (
              this.renderEvent({ item, idx, showPartnerInfo, showCompanyInfo })
            ))}
          </VerticalTimeline>
          : null}


        {timeline.error && !isLoading ?
          <CenterContainer>
            Falha ao buscar jornada. Tente novamente.
            </CenterContainer>
          : null}
      </div>
    )
  }
}

const mapStateToProps = ({ customer, timeline }) => ({ customer, timeline });

export default compose(
  connect(mapStateToProps, null)
)(CustomerTimeline);
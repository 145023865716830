import api from '../../services/api';
import store from '../../store';
import { Creators } from '../ducks/dashboard';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { getFeatures } from '../../utils/features';
import { createRunningNotification } from '../../utils/notification';
import { captureSentryException } from '../../utils';

const isLoadComponent = ({ filter }) => {
  const { tenant } = store.getState().tenant;

  if (!filter.feature_flag) {
    return true;
  }

  return getFeatures(tenant, filter.feature_flag) || false;
}

export function* getDashboard({ requests }) {
  for (let filter of requests) {
    const dashboardType = filter.redux_type || filter.type;

    if (filter.is_reload_indicator === false) {
      continue;
    }

    if (!isLoadComponent({ filter })) {
      continue;
    }

    yield put(Creators.dashboardRequest(dashboardType));
  }

  for (let filter of requests) {
    try {
      let response;
      let newFilter = { ...filter };
      const dashboardType = filter.redux_type || filter.type;

      if (filter && filter.is_download) {
        newFilter = {
          ...newFilter,
          firebase_notification_key: yield createRunningNotification({ title: 'Produtos mais recomendados' })
        };
      }

      if (filter.is_reload_indicator === false) {
        continue;
      }

      if (!isLoadComponent({ filter })) {
        continue;
      }

      if (filter && filter.is_download) {
        response = yield call(api.post, 'dashboard', newFilter);
      } else {
        response = yield call(api.post, 'dashboard', filter);
      }

      if (filter && filter.is_download) {
        yield put(Creators.dashboardSuccess(store.getState().dashboard.data, dashboardType));
      } else {
        yield put(Creators.dashboardSuccess(response.data, dashboardType));
      }

    } catch (err) {
      yield captureSentryException(err, filter);
      yield put(Creators.dashboardError({ err }));
      yield put(toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: `Falha ao exibir indicadores`
      }));
    }
  }
}
import styled from 'styled-components';

export const Text = styled.div`
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
`;

export default () => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem'
  }
})

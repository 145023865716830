import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/date_event';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { downloadFromURL } from '../../utils';
import { captureSentryException } from '../../utils';


export function* getDateEvents({ data }) {
  try {
    let response; 

    yield put(Creators.dateEventsRequest());
    response = yield call(api.post, 'date_event', { data });

    if (data && data.is_download && response.data.url) {
      downloadFromURL(response.data.url);
      yield put(Creators.dateEventsSuccess(store.getState().event.data));
    } else {
      yield put(Creators.dateEventsSuccess(response.data));
    }

  } catch (err) {
    yield captureSentryException(err, data);
    yield put(Creators.dateEventsError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar as datas dos eventos'
    }));
  }
} 
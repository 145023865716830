import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;

  ${props => props.color && css`
    color: ${props.color};
  `}

  ${props => props.size && css`
    font-size: ${props.size};
  `}

  ${props => props.isHover && css`
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}

  span {
    margin-left: .5rem;
  }

`;
import store from "../../store";
import api from "../../services/api";
import { Creators } from "../ducks/chatbot";
import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import { downloadFromURL } from "../../utils";
import { captureSentryException } from '../../utils';

export function* getChatbot({ filter }) {
  try {
    yield put(Creators.getChatbotRequest());
    const response = yield call(api.post, "chatbot", filter);

    if (filter && filter.is_download && response.data.url) {
      downloadFromURL(response.data.url);
      yield put(Creators.getChatbotSuccess(store.getState().chatbot.data));
    } else {
      yield put(Creators.getChatbotSuccess(response.data));
    }
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.getChatbotError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: "Falha ao listar atendimentos.",
      })
    );
  }
}

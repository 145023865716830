import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as TenantConfigCreators } from "../../store/ducks/tenant_config";
import { Creators as RfbEnterpriseActions } from "../../store/ducks/rfb_enterprise";
import { Creators as RfbPartnerActions } from "../../store/ducks/rfb_partner";
import {
  Container,
  Content,
  TableContainer,
  HeaderTableContainer,
  HeaderText,
  CardContainer,
} from "./styles";
import { Mixpanel } from "../../utils/mixpanel";
import LeadsFilter from "../LeadsFilter";
import IconButton from "../IconButton";
import CrmIcon from "react-feather/dist/icons/external-link";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  Button,
  FormControlLabel,
  Radio,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormLabel,
} from "@material-ui/core";
import CustomCard from "../Card";
import Table from "../Table";
import { Creators as DialogCreators } from "../../store/ducks/dialog";
import LeadsDialog from "../LeadsDialog";
import {
  CSV,
  EMAIL,
  ITEMS_PER_PAGE,
  NIVEL1,
  TELEFONE,
  TODOS,
  XLSX,
} from "../../constants";
import { getFilter } from "../../utils/rfb_filter";
import { primary } from "../App/theme";
import { getDateYYMMDDDD } from "../../utils";

class Leads extends Component {
  state = {
    page: 1,
    isShowExport: false,
    exportType: CSV,
    exportContext: TODOS,
  };

  componentDidMount() {
    this.props.setTitle("Leads");

    Mixpanel.track("INIT_LEADS");
  }

  getHeaderText = () => {
    let defaultText = "1 Empresa Encontrada";
    const { data, loading } = this.props.rfb_enterprise;

    if (loading || !data.count) return "";

    if (data.count === 1) {
      return defaultText;
    }

    if (data.count > 1) {
      defaultText = `${data.count} Empresas Encontradas`;
    }

    return defaultText;
  };

  renderIndicators = () => {
    const { data, loading } = this.props.rfb_enterprise;

    if (loading || !data.indicators || !Object.keys(data.indicators).length)
      return null;

    return (
      <CardContainer>
        {Object.keys(data.indicators).map((key) => (
          <CustomCard
            key={key}
            label={key}
            value={data.indicators[key]}
          ></CustomCard>
        ))}
      </CardContainer>
    );
  };

  handlePageChange = (page) => this.setState({ page }, () => this.submit({}));

  handleExport = () => {
    this.setState({ isShowExport: !this.state.isShowExport });
  };

  handleTableSortBy = (data, sort) => {
    this.submit({ sort });
    this.props.rfbEnterpriseSortBy(sort);
  };

  handleDownload = ({ download_extension, export_context }) => {
    this.submit({ is_download: true, download_extension, export_context });
    this.setState({ isShowExport: !this.state.isShowExport });
  };

  submit = ({ sort, is_download, download_extension, export_context }) => {
    const { date_from_last_attendance, date_to_last_attendance } =
      this.props.rfb_filter.selecteds;
    const date_from = date_from_last_attendance
      ? getDateYYMMDDDD(date_from_last_attendance)
      : null;
    const date_to = date_to_last_attendance
      ? getDateYYMMDDDD(date_to_last_attendance)
      : null;
    let date_last_attendance;

    const filter = getFilter(this.props.rfb_filter.selecteds);

    if (filter.client && !filter.client.sebrae && filter.client.leads) {
      date_last_attendance = { date_from: null, date_to: null };
    } else {
      date_last_attendance =
        date_from && date_to
          ? { date_from: date_from, date_to: date_to }
          : { date_from: null, date_to: null };
    }

    this.props.getRfbEnterprises({
      ...filter,
      page: this.state.page,
      sort: sort || this.props.rfb_enterprise.sort,
      items_per_page: ITEMS_PER_PAGE,
      is_search: true,
      is_download: is_download || false,
      download_extension: download_extension || CSV,
      export_context: export_context,
      date_last_attendance: date_last_attendance,
    });
  };

  handleRadioChange = (event) => {
    this.setState({ exportContext: event.target.value });
  };

  handleSelectChange = (event) => {
    this.setState({ exportType: event.target.value });
  };

  getRegionalUnitsLabel() {
    if (!this.props.rfb_filter.selecteds.regional_unit) return null;

    let labelString = "";

    let regionalUnits = this.props.rfb_filter.selecteds.regional_unit.map(
      (item) => item.label
    );

    labelString = regionalUnits.join(", ");

    return (
      <>
        <b>Unidades Regionais:</b> {labelString}
      </>
    );
  }

  getPortsLabel() {
    if (!this.props.rfb_filter.selecteds.port) return null;

    let labelString = "";

    let ports = this.props.rfb_filter.selecteds.port.map((item) => item.label);

    labelString = ports.join(", ");

    return (
      <>
        <b>Portes:</b> {labelString}
      </>
    );
  }

  getCitiesLabel() {
    if (!this.props.rfb_filter.selecteds.city) return null;

    let labelString = "";

    let cities = this.props.rfb_filter.selecteds.city.map((item) => item.label);

    labelString = cities.join(", ");

    return (
      <>
        <b>Município:</b> {labelString}
      </>
    );
  }

  renderLeadsContainer() {
    const { data, sort, loading, error } = this.props.rfb_enterprise;
    const { role } = this.props.tenant;

    return (
      <>
        <Container>
          <LeadsFilter title="Leads" />
          <Content>
            <TableContainer>
              {!loading && data.items && data.items.length ? (
                <HeaderTableContainer>
                  <HeaderText>{this.getHeaderText()}</HeaderText>
                  {role === NIVEL1 ? null : (
                    <div style={{ display: "flex" }}>
                      <div>
                        <IconButton
                          onClick={loading ? null : () => this.handleExport()}
                          label={loading ? null : "Exportar"}
                          icon={loading ? null : <CrmIcon />}
                        />
                      </div>
                    </div>
                  )}
                </HeaderTableContainer>
              ) : null}

              {this.renderIndicators()}

              <Table
                data={data}
                sort={sort}
                error={error}
                loading={loading}
                pageCount={Math.ceil(data.count / ITEMS_PER_PAGE)}
                sortBy={this.handleTableSortBy}
                onPageChange={this.handlePageChange}
                history={this.props.history}
                page={this.state.page}
              />
            </TableContainer>
          </Content>
        </Container>
        <LeadsDialog />
        <Dialog
          maxWidth={"sm"}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.isShowExport}
          onClose={this.handleExport}
        >
          <DialogTitle>
            <b>Exportar</b>
          </DialogTitle>
          <DialogContent>
            {this.getRegionalUnitsLabel()}
            <br />
            {this.getCitiesLabel()}
            <br />
            {this.getPortsLabel()}
          </DialogContent>
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" style={{ minWidth: "15rem" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de exportação
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.exportType}
                onChange={this.handleSelectChange}
                label="Tipo de exportação"
              >
                <MenuItem value={XLSX}>Excel(XLSX)</MenuItem>
                <MenuItem value={CSV}>CSV</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              style={{
                padding: "1rem",
                display: "inline",
                textAlign: "center",
              }}
              value={this.state.exportContext}
              onChange={this.handleRadioChange}
            >
              <FormLabel
                component="legend"
                style={{
                  paddingRight: "8rem",
                  paddingBottom: "0.5rem",
                  color: "black",
                }}
              >
                <b>Contatos exportados</b>
              </FormLabel>
              <FormControlLabel
                value={EMAIL}
                control={<Radio color="primary" />}
                label="Email"
              />
              <FormControlLabel
                value={TELEFONE}
                control={<Radio color="primary" />}
                label="Telefone"
              />
              <FormControlLabel
                value={TODOS}
                control={<Radio color="primary" />}
                label="Todos"
              />
            </RadioGroup>
          </FormControl>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "1rem",
              }}
            >
              <div>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    minWidth: 130,
                    color: primary,
                    fontSize: "0.8rem",
                  }}
                  onClick={
                    loading
                      ? null
                      : this.handleDownload.bind(this, {
                          download_extension: this.state.exportType,
                          export_context: this.state.exportContext,
                        })
                  }
                >
                  Exportar
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  render() {
    return <Container>{this.renderLeadsContainer()}</Container>;
  }
}

const mapStateToProps = ({
  title,
  rfb_enterprise,
  rfb_filter,
  tenant_config,
  tenant,
}) => ({ title, rfb_enterprise, rfb_filter, tenant_config, tenant });

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...RfbEnterpriseActions,
    ...DialogCreators,
    ...RfbPartnerActions,
    ...TenantConfigCreators,
  })
)(Leads);

import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
  recSimilarityIndicatorsInit: [],
  recSimilarityIndicatorsRequest: [],
  recSimilarityIndicatorsSuccess: ["data"],
  recSimilarityIndicatorsError: ["err"],
  getRecSimilarityIndicators: ["filter"],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  error: false,
  loading: false,
});

/* Reducers Table */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.REC_SIMILARITY_INDICATORS_INIT]: init,
  [Types.REC_SIMILARITY_INDICATORS_REQUEST]: request,
  [Types.REC_SIMILARITY_INDICATORS_SUCCESS]: success,
  [Types.REC_SIMILARITY_INDICATORS_ERROR]: error,
});

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import styles from './styles';
import {
  StyledHeader,
  StyledDialogContent,
  HeaderContainer
} from './styles';
import { withStyles, Dialog } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import Table from '../Table';
import { Creators as DateEventCreators } from '../../store/ducks/date_event';

class DateEventDialog extends Component {

  onClose = () => {
    this.props.setDialog('date_event');
  }

  render = () => {
    const { dialog } = this.props;
    const { data, defaultData, loading, error } = this.props.date_event;

    if (!dialog.date_event) {
      return null;
    }

    return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.onClose.bind(this)}
        open={dialog.date_event}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContent>
          {!loading ? 
            <HeaderContainer style={{ display: 'flex', justifyContent: 'space-between', padding: 0, marginBottom: 0 }}>
                <StyledHeader>
                  Lista de Datas disponíveis
                </StyledHeader>
            </HeaderContainer>
          : null}

          <Table
            data={data}
            error={error}
            loading={loading}
            defaultData={defaultData}
          />
        </StyledDialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ date_event, dialog }) => ({ date_event, dialog });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...DateEventCreators
  })
)(DateEventDialog);

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { RECSYS, HISTORIC, DASHBOARD_GENERAL_INDICATORS, SETTINGS } from '../../constants';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setChip: ['name', 'value']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  product: HISTORIC,
  market: HISTORIC,
  customer: RECSYS,
  dashboard: DASHBOARD_GENERAL_INDICATORS,
  settings: SETTINGS
});

/* Reducers */

export const set = (state, { name, value }) => state.merge({ [name]: value });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_CHIP]: set
});

import styled from 'styled-components';
import { defaultBackground } from '../App/theme';

export const Container = styled.div`
  display: flex;
  padding-left: 1vw;
  padding-top: 1vh;
  align-items: center;
  background-color: ${defaultBackground};

  span {
    font-size: 12px;
    line-height: 16px;
  }
`;
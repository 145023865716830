import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/event';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { downloadFromURL } from '../../utils';
import { captureSentryException } from '../../utils';

export function* getOpenedEvents({ data }) {
  try {
    let response; 

    yield put(Creators.openedEventsRequest());
    response = yield call(api.post, 'event', { data });

    if (data && data.is_download && response.data.url) {
      downloadFromURL(response.data.url);
      yield put(Creators.openedEventsSuccess(store.getState().event.data));
    } else {
      yield put(Creators.openedEventsSuccess(response.data));
    }

  } catch (err) {
    yield captureSentryException(err, data);
    yield put(Creators.openedEventsError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar eventos abertos'
    }));
  }
} 
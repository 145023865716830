import styled, { keyframes } from 'styled-components';
import { baseColor } from '../App/theme';
import { fadeIn } from 'react-animations';

export const Container = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;

export const RecCustomerTrailContainer = styled.div`
  padding: 1rem;
  width: 47.5%;
  height: 58vh;
  margin-top: .5rem;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const Avatar = styled.img`
  vertical-align: top;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const PersonaContainer = styled.div`
  padding: 1rem;
  margin-left: 1rem;
  width: 49.5%;
  height: 58vh;
  margin-top: .5rem;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const TableContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  margin-top: 0;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const PersonaHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-bottom: 1rem;
  font-size: 14px;
`;

export const PersonaChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PersonaLegendContainer = styled.div`
  display: flex;
  padding: .5rem;
  padding-bottom: 0;
  background-color: #F8F8F8;
  height: 34vh;
  overflow-y: auto;
  min-height: 28vh;
  min-height: 34vh;
  flex-direction: column;
  font-size: 12px;
`;

export const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const LegendTitle = styled.div`
  font-weight: 600;
  padding-bottom: 1vh;
`;

export const Legend = styled.div`
  /* padding-top: .3vh; */
`;

export const PersonaNameContainer = styled.div`
  display: flex;
  padding-top: 2vh;
  padding-bottom: 2vh;
  justify-content: space-between;
`;

export const PersonaName = styled.span`
  background-color: #6DB7FF;
  color: #FFF;
  padding: .2vw;
`;

export const DetailContainer = styled.div`
  margin: 1rem;
  width: 80%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const HeaderTextContainer = styled.div`
  margin: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: .5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderText = styled.span`
  color: ${baseColor};
`;

export const HeaderTextBold = styled.span`
  color: ${baseColor};
  font-weight: bold;
`;

export const Text = styled.span`
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const Separator = styled.div`
  margin: 1rem;
  border-top: 1px dashed #000;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
  padding-right: 2vw;
  min-height: 5vh;
  font-size: 14px;
`;

export const Header = styled.div`
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
`;

export const ChartContainer = styled.div`
  display: flex;
  padding: 1vw;
  padding-top: 0;
  justify-content: space-between;
`;
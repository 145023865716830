import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { StyledSelect, SelectContainer } from './styles';
import { Creators as UserRegisterDialogCreators } from '../../store/ducks/user_register_dialog';
import { Creators as UserRegisterCreators } from '../../store/ducks/user_register';
import { Creators as UsersGroups } from '../../store/ducks/users_groups';
import { Creators as NotifyEtlGroups } from '../../store/ducks/notify_etl';
import {
  Dialog, DialogActions, Button, FormControl, InputLabel,
  DialogTitle, DialogContent, MenuItem, FormControlLabel,
  CircularProgress, Checkbox
} from '@material-ui/core';
import LockIcon from 'react-feather/dist/icons/lock';
import DeleteIcon from 'react-feather/dist/icons/trash-2';
import EditIcon from 'react-feather/dist/icons/edit';
import {
  RESEND_EMAIL, RESEND_EMAIL_MESSAGE,
  DELETE_ACCOUNT_MESSAGE, DELETE_ACCOUNT,
  EDIT_PERMISSION, EDIT_PERMISSION_MESSAGE,
  RECEIVE_NOTIFICATION_ETL, RECEIVE_NOTIFICATION_ETL_MESSAGE,
  CHOICE_RECEIVE_NOTIFICATION_ETL_MESSAGE
} from '../../constants/index';


class UserRegisterDialog extends Component {

  state = {
    permissionLevel: null
  };

  onClose = () => {
    this.setState({ permissionLevel: null, is_notify: null });
    this.props.setDialogType('user_register');
  }

  handleChange = (e) => {
    this.setState({ permissionLevel: e.target.value });
  }

  handleEditPermission(e, email) {
    const permission = [{
      email: email,
      permission: this.state.permissionLevel,
      action: 'edit_permission'
    }]

    this.props.editPermission(permission);
    this.onClose();
  }

  handleReSendEmail(e, email) {
    const reSendEmail = [{
      email: email,
      action: 'resend'
    }]

    this.props.reSendEmail(reSendEmail);
    this.onClose();
  }

  handleDeleteAccounts(e, email) {
    const deleteAccounts = [{
      email: email,
      action: 'delete'
    }]

    this.props.deleteUsers(deleteAccounts);
    this.onClose();
  }

  handleNotifyEtl(e, email) {
    const { notify_etl } = this.props;

    const notify = {
      email: email,
      is_notify: !notify_etl.data.is_notify,
      tenant: this.props.tenant
    }

    this.props.putNotifyEtl(notify);
  }

  renderLoading = () => (
    <CircularProgress color="primary" size={24} />
  )

  render = () => {
    const { user_register_dialog, notify_etl } = this.props;
    const { action, item } = this.props.user_register_dialog;
    const { loading, data } = this.props.users_groups;

    if (!user_register_dialog.user_register) {
      return null;
    }

    return (
      <Dialog
        onClose={this.onClose.bind(this)}
        open={user_register_dialog.user_register}
        scroll={'paper'}
      >
        <DialogTitle style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: '15px'
        }}>
          {action === RESEND_EMAIL ?
            <LockIcon style={{
              color: '41A2FF',
              width: '43.2px',
              height: '48px'
            }} /> : action === DELETE_ACCOUNT ?
              <DeleteIcon style={{
                color: '41A2FF',
                width: '43.2px',
                height: '48px'
              }} /> : action === EDIT_PERMISSION ?
                <EditIcon style={{
                  color: '41A2FF',
                  width: '43.2px',
                  height: '48px'
                }} /> : null
          }
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {action === RESEND_EMAIL ?
            RESEND_EMAIL_MESSAGE + item.email :
            action === DELETE_ACCOUNT ?
              DELETE_ACCOUNT_MESSAGE + item.email :
              action === EDIT_PERMISSION ?
                EDIT_PERMISSION_MESSAGE + item.email :
                action === RECEIVE_NOTIFICATION_ETL ?
                  RECEIVE_NOTIFICATION_ETL_MESSAGE + item.email : null}
          {action === EDIT_PERMISSION ?
            <SelectContainer
              style={{ padding: '1.5vw' }}
            >
              <FormControl variant="outlined">
                <InputLabel>{loading ? "Carregando..." : "Nível de permissão"}</InputLabel>
                <StyledSelect
                  name="permission"
                  onChange={(e) => this.handleChange(e, 'permissionLevel')}
                  value={this.state.permissionLevel !== null ? this.state.permissionLevel : item.permission}
                  label={loading ? "Carregando" : "Nível de permissão"}
                  disabled={loading}
                  style={loading ? { textAlign: 'left', backgroundColor: 'lightgray' } : { textAlign: 'left', backgroundColor: 'white' }}
                >
                  {data && data.map((group) => {
                    return (
                      <MenuItem value={group.groupname}>{group.desc}</MenuItem>
                    )
                  })}
                </StyledSelect>
              </FormControl>
            </SelectContainer> : null}
          {action === RECEIVE_NOTIFICATION_ETL ?
            <SelectContainer
              style={{ padding: '1.5vw' }}
            >{!notify_etl.loading ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notify_etl.data.is_notify}
                    onChange={(e) => this.handleNotifyEtl(e, item.email)}
                    color="primary"
                  />
                }
                label={CHOICE_RECEIVE_NOTIFICATION_ETL_MESSAGE}
              /> : this.renderLoading()}
            </SelectContainer> : null}
        </DialogContent>
        <DialogActions style={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'relative',
          paddingBottom: '30px'
        }}>
          <Button
            onClick={this.onClose.bind(this)}
            color="primary">
            {action === RECEIVE_NOTIFICATION_ETL ? "Ok" : "Cancelar"}
          </Button>
          {action === RESEND_EMAIL ?
            <Button
              onClick={(e) => this.handleReSendEmail(e, item.email)}
              color="primary">
              Reenviar senha
            </Button> : action === DELETE_ACCOUNT ?
              <Button
                onClick={(e) => this.handleDeleteAccounts(e, item.email)}
                color="primary">
                Deletar
              </Button> : action === EDIT_PERMISSION ?
                <Button
                  onClick={(e) => this.handleEditPermission(e, item.email)}
                  color="primary">
                  Salvar
                </Button> : null}
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ user_register_dialog, user_register, users_groups, notify_etl }) => ({ user_register_dialog, user_register, users_groups, notify_etl });

export default compose(
  connect(mapStateToProps, {
    ...UserRegisterDialogCreators,
    ...UserRegisterCreators,
    ...UsersGroups,
    ...NotifyEtlGroups
  })
)(UserRegisterDialog);

import api from '../../services/api';
import { Creators } from '../ducks/target_audience';
import { call, put, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getTargetAudience({ filter }) {
  try {

    yield put(Creators.targetAudienceRequest());
    const response = yield call(api.post, 'target-audience', filter);

    yield put(Creators.targetAudienceSuccess(response.data));

  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.targetAudienceError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar público-alvo.'
    }));
  }
}

export function* updateTargetAudience({ filter }) {
  try {

    yield put(Creators.targetAudienceRequest());
    yield call(api.put, 'target-audience', filter);

    yield put(Creators.targetAudienceSuccess({}));

    yield put(toastrActions.add({
      type: 'success',
      title: 'Público-alvo atualizado',
      message: 'As informações serão processadas no modelo no dia seguinte.'
    }));

    const updateGetFilter = yield select((state) => state.filter.filter);

    yield put(Creators.getTargetAudience(updateGetFilter));

  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.targetAudienceError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao atualizar dados de público-alvo.'
    }));
  }
}

import _ from 'lodash';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { baseColor, selectStyle } from '../App/theme';
import React, { Component, Fragment } from 'react';
import ArrowUpIcon from 'react-feather/dist/icons/chevron-up';
import ArrowDownIcon from 'react-feather/dist/icons/chevron-down';
import DeleteIcon from 'react-feather/dist/icons/trash-2';
import LockIcon from 'react-feather/dist/icons/lock';
import FlagIcon from 'react-feather/dist/icons/flag';
import UserIcon from 'react-feather/dist/icons/user';
import UsersIcon from 'react-feather/dist/icons/users';
import AlertIcon from 'react-feather/dist/icons/alert-circle';
import CheckIcon from 'react-feather/dist/icons/check-circle';
import ListIcon from 'react-feather/dist/icons/list';
import {
  StyledTable, HeaderColumn, RowDetail, ItemColumn,
  Separator, CenterContainer, RowDetailHeaderContainer
} from './styles';
import { fieldParser } from '../../utils';
import { CircularProgress } from '@material-ui/core';
import Select from 'react-select';
import { Creators as PieChartCreators } from '../../store/ducks/pie_chart';
import { Creators as BarChartCreators } from '../../store/ducks/bar_chart';
import { Creators as RecCustomerProductCreators } from '../../store/ducks/rec_customer_product';
import { getPieChartSchema, TYPE_LINK, TYPE_COLOR, TYPE_ICON, 
  TYPE_MIXED, TYPE_BUTTON, TYPE_SUB_MENU } from '../../utils/schema';
import Pagination from '@material-ui/lab/Pagination';
import { CADASTRADO_COM_SUCESSO, CONSENTIU, EMPTY_DATA, EM_ABERTO, NAO_CONSENTIU } from '../../constants';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as UserRegisterDialogCreators } from '../../store/ducks/user_register_dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';

import PieChart from '../PieChart';
import BarChart from '../BarChart';
import SubMenu from '../SubMenu';

class Table extends Component {

  state = {
    pieChartSelected: { label: 'Físico/Jurídico', value: 'tipoparceiro' }
  }

  getCharts = (item, charts) => {
    const { filter } = this.props.filter;
    const { pieChartSelected } = this.state;

    if (charts && charts.includes('pie')) {
      this.props.getPieChart({
        ...filter,
        productid: [item.produtoid],
        product: [item.produto],
        year: { min: item.ano, max: item.ano },
        field: pieChartSelected.value
      });
    }

    if (charts && charts.includes('bar')) {
      this.props.getBarChart({
        ...filter,
        productid: [item.produtoid],
        product: [item.produto]
      });
    }
  }

  handleRowClick(rowId, item) {
    const { expandedRow, hasDetail } = this.props;

    if (!hasDetail) {
      return;
    }

    if (expandedRow !== rowId) {
      this.getCharts(item, ['pie', 'bar']);
    }

    this.props.expand(expandedRow !== rowId ? rowId : null);
  }

  handleChange = (option, name, item) => {
    this.setState({ [name]: option }, () => this.getCharts(item, ['pie']));
  };

  renderItemDetail = (item) => {
    const pieChartOptions = getPieChartSchema();
    const { pieChartSelected } = this.state;
    const { pie_chart, bar_chart } = this.props;

    return (
      <>
        <RowDetailHeaderContainer>
          <div style={{ width: '50%' }}>
            <Select
              isDisabled={pie_chart.loading}
              value={pieChartSelected}
              onChange={(e) => this.handleChange(e, 'pieChartSelected', item)}
              placeholder={'Selecione'}
              styles={selectStyle}
              options={pieChartOptions} />
          </div>

          <div style={{ textAlign: 'center', width: '50%', fontWeight: 'bold' }}>
            Performance de Participantes
            </div>
        </RowDetailHeaderContainer>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <PieChart duck={pie_chart} />
          </div>
          <div style={{ width: '100%' }}>
            <BarChart duck={bar_chart} />
          </div>
        </div>
      </>
    );
  }

  getIcon = (icon) => {
    const props = {
      color: baseColor,
      size: 16
    }

    if (icon === 'flag') return <FlagIcon {...props} />;
    if (icon === 'user') return <UserIcon {...props} />;
    if (icon === 'users') return <UsersIcon {...props} />;
    if (icon === 'lock') return <LockIcon {...props} />;
    if (icon === 'delete') return <DeleteIcon {...props} />;
    if (icon === 'list') return <ListIcon {...props} />;
  }

  getMixedContent = (item) => {

    if (item.tipoparceiro === 'J')
      return <div style={{ textAlignLast: 'center' }}>N/A</div>
    if (item.status_lgpd === CONSENTIU)
      return <div style={{ display: 'flex', color: '#30E398', paddingLeft: '1rem', alignItems: 'center' }}>
        <CheckCircle
          style={{ paddingRight: '4px' }}
        />
        <e>{CONSENTIU}</e>
      </div>
    if (item.status_lgpd === NAO_CONSENTIU)
      return <div style={{ display: 'flex', color: '#CB443C', paddingLeft: '1rem', alignItems: 'center' }}>
        <CancelIcon
          style={{ paddingRight: '4px' }}
        />
        <e>{NAO_CONSENTIU}</e>
      </div>
    if (item.status_lgpd === EM_ABERTO)
      return <div style={{ display: 'flex', color: '#275A8B', paddingLeft: '1rem', alignItems: 'center' }}>
        <WarningIcon
          style={{ paddingRight: '4px' }}
        />
        <e>{EM_ABERTO}</e>
      </div>
    if (item.UserStatus === CADASTRADO_COM_SUCESSO)
      return <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckIcon style={{ color: '#30E398' }} />
        <e style={{ marginLeft: '5px' }}>{CADASTRADO_COM_SUCESSO}</e>
      </div>
    else
      return <div style={{ display: 'flex', alignItems: 'center' }}>
        <AlertIcon style={{ color: '#C9352C' }} />
        <e style={{ marginLeft: '5px' }}>{item.UserStatus}</e>
      </div>
  }

  getSubMenu = (column, item) => {
    return <SubMenu 
              column={column}
              item={item}
              history={this.props.history}
          />
  }

  getValueTd = (item, headerByKey, key) => {
    const column = headerByKey[key];

    if (column && (column.type === TYPE_LINK || column.type === TYPE_BUTTON) && column.value_static) {
      return column.value_static;
    }

    if (column && column.type === TYPE_ICON && column.icon && item[column.value]) {
      return this.getIcon(column.icon);
    }

    if (column && column.type === TYPE_COLOR && item.color) {
      return <div style={{ backgroundColor: item.color, width: '.4vw', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>&nbsp;</div>;
    }

    if (column && column.type === TYPE_MIXED && item) {
      return this.getMixedContent(item);
    }

    if (column && column.type === TYPE_SUB_MENU && item) {
      return this.getSubMenu(column, item);
    }

    return fieldParser(item[key], column.type);
  }

  getTitleTd = (item, headerByKey, key) => {
    const column = headerByKey[key];

    if (column.value_static) {
      return column.value_static;
    }

    if (column && column.type === TYPE_COLOR) {
      return null;
    }

    return fieldParser(item[key], column.type);
  }

  getTypeColumn = (headerByKey, key) => {
    return headerByKey[key] && headerByKey[key].type ? headerByKey[key].type : null;
  }

  onClickTd = (column, item, e) => {
    const hasClick = (column.type === TYPE_LINK || column.type === TYPE_ICON) ? true : false;

    if (this.props.onClick) {
      this.props.onClick(column, item);
      return;
    }

    if (hasClick && item[column.value]) {
      this.props.history.push(item[column.value]);
    }
  }

  renderItem(items, item, idx, headerByKey) {
    const { expandedRow } = this.props;
    const isExpanded = expandedRow === idx;
    const color = isExpanded ? baseColor : '#000';
    const colSpan = Object.keys(headerByKey).length;
    const isFirst = idx === 0;
    const isLast = idx + 1 === items.length;

    return (
      <Fragment key={`row-data-fragment-${idx}`}>
        <tr onClick={this.handleRowClick.bind(this, idx, item)} key={`row-data-tr-${idx}`}>
          {Object.keys(headerByKey).map((key, idx) => (
            <ItemColumn
              isFirst={isFirst}
              isLast={isLast}
              isIcon={headerByKey[key] && headerByKey[key].type === TYPE_ICON ? true : false}
              onClick={this.onClickTd.bind(this, headerByKey[key], item)}
              type={this.getTypeColumn(headerByKey, key)}
              title={this.getTitleTd(item, headerByKey, key)}
              key={`row-data-td${idx}`}
              align={headerByKey[key] && headerByKey[key].align ? headerByKey[key].align : null}
              color={color}>{this.getValueTd(item, headerByKey, key)}
            </ItemColumn>
          ))}
        </tr>

        {
          isExpanded ?
            <RowDetail key={`row-expanded-${idx}`}>
              <ItemColumn colSpan={colSpan}>{this.renderItemDetail(item)}</ItemColumn>
            </RowDetail>
            : null
        }
      </Fragment >
    )
  }

  getAlignment = (column) => {
    if (!column) return;

    if (column.align === 'right') {
      return 'flex-end';
    }

    if (column.align === 'center') {
      return column.align;
    }

    return 'flex-start';
  }

  renderHeader = (column, idx) => {
    const { sort } = this.props;
    const isSort = sort && sort[column.value] && sort[column.value].value === column.value;

    return (
      <HeaderColumn
        isSort={column.is_sort === false ? false : true}
        isIcon={column.type === TYPE_ICON ? true : false}
        key={idx} align={column.align}
        onClick={this.sortItems.bind(this, column)}>
        <div style={{ display: 'flex', justifyContent: this.getAlignment(column), alignItems: 'center' }}>
          <div>{column.label}</div>
          <div style={{ marginLeft: '.2rem', marginTop: '.5vh' }}>
            {isSort && sort[column.value].order === 'asc' ? <ArrowUpIcon size={22} /> : null}
            {isSort && sort[column.value].order === 'desc' ? <ArrowDownIcon size={22} /> : null}
            {!isSort && column.align !== 'right' ? <ArrowDownIcon color="transparent" size={22} /> : null}
          </div>
        </div>
      </HeaderColumn>
    )
  }

  sortItems = (column) => {
    let newItems;
    let orders = [];
    let columns = [];
    let isContinue = true;
    let { data, sort } = this.props;
    let defaultData = this.props.defaultData || data;
    let newSort = Object.assign({}, sort ? sort.asMutable() : {});

    newSort = Object.assign({
      [column.value]: newSort[column.value]
    }, {});

    if (column && column.is_sort === false) {
      return;
    }

    if (newSort[column.value]) {
      newSort[column.value] = newSort[column.value].asMutable();
    } else {
      newSort[column.value] = { value: column.value, order: 'asc' };
      isContinue = false;
    }

    if (column.value === newSort[column.value].value && isContinue) {
      let order = newSort[column.value].order;

      if (order === 'asc') {
        newSort[column.value].order = 'desc';
      }

      if (order === 'desc') {
        delete newSort[column.value];
      }
    }

    Object.keys(newSort).forEach(key => {
      columns.push(key);
      orders.push(newSort[key].order);
    });

    newItems = defaultData.items;

    if (orders.length) {
      newItems = _.orderBy(data.items, columns, orders);
    }

    if (this.props.sortBy) {
      this.props.sortBy({ header: data.header, items: newItems, indicators: data.indicators, count: data.count }, newSort);
    }
  }

  renderLoading = () => (
    <CenterContainer color={baseColor}>
      <CircularProgress color="primary" />
    </CenterContainer>
  )

  renderError = () => (
    <CenterContainer>Ocorreu um erro. Tente novamente.</CenterContainer>
  )

  renderEmptyState = (emptyMessage) => (
    emptyMessage ?
      <CenterContainer>{emptyMessage}</CenterContainer>
      :
      <CenterContainer>{EMPTY_DATA}</CenterContainer>
  )

  handlePageChange = (event, value) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(value);
    }
  };

  render() {
    let items = [];
    let header = [];
    let headerByKey = {};
    const { data, error, loading, hasDetail, pageCount, page, emptyMessage } = this.props;

    if (error && !loading) return this.renderError();
    if (loading) return this.renderLoading();

    if (!data || !data.header || !data.items || !data.items.length) return this.renderEmptyState(emptyMessage);

    header = data.header;
    items = data.items;

    header.forEach(item => headerByKey[item.value] = item);

    return (
      <>
        <StyledTable hasDetail={hasDetail}>
          <thead>
            <tr>
              {header.map((column, idx) => this.renderHeader(column, idx))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={header.length}>
                <Separator />
              </td>
            </tr>

            {items && items.length ? items.map((item, idx) => this.renderItem(items, item, idx, headerByKey)) : null}
          </tbody>

          {pageCount && items.length ?
            <tfoot align="center">
              <td colSpan={header.length}>
                <tr>
                  <Pagination count={pageCount} page={page} onChange={this.handlePageChange} variant="outlined" color="primary" />
                </tr>
              </td>
            </tfoot>
            : null}
        </StyledTable>
      </>
    )
  }
}

Table.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape),
  items: PropTypes.arrayOf(PropTypes.shape),
  sort: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  sortBy: PropTypes.func.isRequired
};

const mapStateToProps = ({ filter, pie_chart, bar_chart }) => ({ filter, pie_chart, bar_chart });

export default compose(
  connect(mapStateToProps, { 
    ...PieChartCreators, 
    ...BarChartCreators, 
    ...RecCustomerProductCreators, 
    ...DialogCreators,
    ...UserRegisterDialogCreators
  })
)(Table);
import api from "../../services/api";
import { Creators } from "../ducks/chatbot_config";
import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import { captureSentryException } from '../../utils';

export function* getChatbotConfig({ tenant }) {
  try {
    let response;

    yield put(Creators.chatbotConfigRequest());
    response = yield call(api.post, "chatbot-config", {
      tenant,
    });
    yield put(Creators.chatbotConfigSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, tenant);
    yield put(Creators.chatbotConfigError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: "Falha ao buscar configurações.",
      })
    );
  }
}

export function* putChatbotConfig({ data }) {
  try {
    let response;

    yield put(Creators.chatbotConfigRequest());
    response = yield call(api.put, "chatbot-config", {
      ...data,
    });

    yield put(Creators.chatbotConfigSuccess(response.data));
    yield put(
      toastrActions.add({
        type: "success",
        title: "Sucesso",
        message: "Alterações salvas com sucesso.",
      })
    );
  } catch (err) {
    yield captureSentryException(err, data);
    yield put(Creators.chatbotConfigError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: "Falha ao salvar configurações.",
      })
    );
  }
}

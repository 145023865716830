
/** Backend Dashboard Types */

export const FUNNEL = 'FUNNEL';
export const TOP_EVENTS = 'TOP_EVENTS';
export const DAILY_ACTIVE_USERS = 'DAILY_ACTIVE_USERS';
export const UNIQUE_BY_PROPERTY = 'UNIQUE_BY_PROPERTY';
export const MONTHLY_ACTIVE_USERS = 'MONTHLY_ACTIVE_USERS';
export const PRODUCT_ANALYSIS_FUNNEL = 'PRODUCT_ANALYSIS_FUNNEL';
export const CUSTOMER_RECOMMENDATION_FUNNEL = 'CUSTOMER_RECOMMENDATION_FUNNEL';
export const PRODUCT_RECOMMENDATION_FUNNEL = 'PRODUCT_RECOMMENDATION_FUNNEL';

export const CUSTOMERS_MORE_SEARCHED = 'CUSTOMERS_MORE_SEARCHED';
export const CUSTOMERS_CLEANED = 'CUSTOMERS_CLEANED';
export const CUSTOMERS_CLEANED_QTD_BY_DATE = 'CUSTOMERS_CLEANED_QTD_BY_DATE';
export const CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE = 'CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE';
export const CUSTOMERS_MORE_SEARCHED_BY_PORT = 'CUSTOMERS_MORE_SEARCHED_BY_PORT';
export const CUSTOMERS_MORE_SEARCHED_BY_SECTOR = 'CUSTOMERS_MORE_SEARCHED_BY_SECTOR';
export const CUSTOMERS_MORE_SEARCHED_BY_PERSONA = 'CUSTOMERS_MORE_SEARCHED_BY_PERSONA';
export const CUSTOMERS_MORE_SEARCHED_BY_CITY = 'CUSTOMERS_MORE_SEARCHED_BY_CITY';
export const TOP_RECOMMENDATION_PRODUCTS = 'TOP_RECOMMENDATION_PRODUCTS';
export const BOTTOM_RECOMMENDATION_PRODUCTS = 'BOTTOM_RECOMMENDATION_PRODUCTS';
export const TOP_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE = 'TOP_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE';
export const BOTTOM_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE = 'BOTTOM_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE';
export const TOP_RECOMMENDATION_EVENTS = 'TOP_RECOMMENDATION_EVENTS';
export const BOTTOM_RECOMMENDATION_EVENTS = 'BOTTOM_RECOMMENDATION_EVENTS';
export const TOP_RECOMMENDATION_EVENTS_TERRITORIAL_COVERAGE = 'TOP_RECOMMENDATION_EVENTS_TERRITORIAL_COVERAGE';
export const BOTTOM_RECOMMENDATION_EVENTS_TERRITORIAL_COVERAGE = 'BOTTOM_RECOMMENDATION_EVENTS_TERRITORIAL_COVERAGE';
export const MOST_RECOMMENDED_PRODUCTS = 'MOST_RECOMMENDED_PRODUCTS';
export const MOST_SEARCHED_WORDS = 'MOST_SEARCHED_WORDS';
export const MOST_SELECTED_THEMES = 'MOST_SELECTED_THEMES';
export const MOST_SEARCHED_CONTENT_THEMES = 'MOST_SEARCHED_CONTENT_THEMES';
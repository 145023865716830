import api from '../../services/api';
import { Creators } from '../ducks/timeline';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { TIMELINE } from '../../constants';
import { captureSentryException } from '../../utils';

export function* getTimeline({ id }) {
  try {
    let response;

    yield put(Creators.timelineRequest());
    response = yield call(api.post, TIMELINE, { id });

    yield put(Creators.timelineSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, id);
    yield put(Creators.timelineError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar jornada'
    }));
  }
}
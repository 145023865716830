/** General Use */

export const USER = "USER";
export const NIVEL1 = "NIVEL1";
export const NIVEL2 = "NIVEL2";
export const ADMIN = "ADMIN";
export const COMPANY = "COMPANY";
export const PARTNER = "PARTNER";
export const PESSOA_FISICA = "F";
export const PESSOA_JURIDICA = "J";
export const RECSYS = "recsys";
export const TIMELINE = "timeline";
export const HISTORIC = "historic";
export const PRODUCT = "product";
export const MARKET = "market";
export const ITEMS_PER_PAGE = 50;
export const CUSTOMER_CHIP = "CUSTOMER_CHIP";
export const PRODUCT_CHIP = "PRODUCT_CHIP";
export const MARKET_CHIP = "MARKET_CHIP";
export const DASHBOARD_CHIP = "DASHBOARD_CHIP";
export const DASHBOARD_GENERAL_INDICATORS = "DASHBOARD_GENERAL_INDICATORS";
export const DASHBOARD_USE_APP_INDICATORS = "DASHBOARD_USE_APP_INDICATORS";
export const DASHBOARD_SECTION_CLEANED_CUSTOMERS =
  "DASHBOARD_SECTION_CLEANED_CUSTOMERS";
export const DASHBOARD_APP_SEBRAE = "DASHBOARD_APP_SEBRAE";
export const SETTINGS_CHIP = "SETTINGS_CHIP";
export const SETTINGS = "SETTINGS";
export const USER_REGISTER = "USER_REGISTER";
export const TARGET_AUDIENCE = "target_audience";
export const DASHBOARD_PERSONA_INDICATORS = "DASHBOARD_PERSONA_INDICATORS";
export const DASHBOARD_CHATBOT_INDICATORS = "DASHBOARD_CHATBOT_INDICATORS";
export const DASHBOARD_PROSPECT_INDICATORS = "DASHBOARD_PROSPECT_INDICATORS";
export const AGREE = "AGREE";
export const DISAGREE = "DISAGREE";
export const CREATE = "CREATE";
export const SHEET = "SHEET";
export const SHOW = "SHOW";
export const AGUARDANDO_USUARIO_CONFIRMAR_EMAIL =
  "Aguardando usuário confirmar o email";
export const CADASTRADO_COM_SUCESSO = "Cadastrado com sucesso";
export const INATIVO = "Inativo";
export const USUARIO_INVALIDO =
  "Usuário inválido, email fora do domínio Sebrae";
export const DESCONHECIDO = "Desconhecido";
export const USUARIO_RESETAR_SENHA = "Usuário precisa resetar a senha";
export const AGUARDANDO_CADASTRO_SENHA = "Aguardando usuário cadastrar a senha";
export const RESEND_EMAIL_MESSAGE = "Reenviar a senha por email para ";
export const RESEND_EMAIL = "Reenviar Senha";
export const DELETE_ACCOUNT_MESSAGE =
  "Tem certeza que quer deletar a conta do usuário ";
export const DELETE_ACCOUNT = "Deletar Usuário";
export const REC_CUSTOMER_EVENT = "rec_customer_event";
export const EVENT = "evento";
export const SEE_CUSTOMER = "Ver cliente";
export const SEE_EVENT = "Ver eventos abertos";
export const SEE_REPRESENTATIVES = "Ver representantes";
export const EMPTY_DATA = "Sem dados para serem exibidos.";
export const EMPTY_EVENT = "Não há evento disponível para esse produto.";
export const SEE_AVAILABLE_DATES = "Ver datas disponíveis";
export const EDIT_TARGET_AUDIENCE = "Editar público-alvo";
export const CLEAN_TARGET_AUDIENCE = "Limpar público-alvo";
export const EDIT_PERMISSION = "Editar Permissão";
export const EDIT_PERMISSION_MESSAGE = "Edite o nível de permissão do usuário ";
export const RECEIVE_NOTIFICATION_ETL = "Receber Notifição ETL";
export const RECEIVE_NOTIFICATION_ETL_MESSAGE = "Notificar status de execução do ETL para o usuário ";
export const CHOICE_RECEIVE_NOTIFICATION_ETL_MESSAGE = "Desejo ser notificado da extração"
export const GIVE_FEEDBACK_MESSAGE = "Desejo informar que o produto foi recomendado para essa lista de clientes ao exportar com o objetivo de restringir a repetição da mesma recomendação por 10 dias";
export const VIEW_CHATBOT_DETAILS = "Ver detalhes";
export const CSV = "csv";
export const XLS = "xls";
export const XLSX = "xlsx";
export const TOP_10 = "10";
export const TOP_25 = "25";
export const TOP_50 = "50";
export const CONSENTIU = "Consentiu";
export const NAO_CONSENTIU = "Não consentiu";
export const EM_ABERTO = "Em aberto";
export const EMAIL = "EMAIL";
export const TELEFONE = "TELEFONE";
export const TODOS = "TODOS";
export const APP_SEBRAE = "APP_SEBRAE";
export const METABASE = "METABASE";
export const CHATBOT = "chatbot";
export const CHATBOT_CONFIG = "chatbot_config";
export const REC_CUSTOMER_CONTENT = "rec_customer_content";
export const ARI_S3_BUCKET = "https://oncase-ari.s3.amazonaws.com";
export const PROPRIETARIO_OU_SOCIO = "PROPRIETÁRIO OU SÓCIO";
export const DESVINCULADO = "Desvinculado";
export const SIMILARITY = "similarity";

export const FILTER_CITY = "city";
export const FILTER_PRODUCT = "product";
export const FILTER_INSTRUMENT = "instrument";
export const FILTER_SECTOR = "sector";
export const FILTER_PORT = "port";
export const FILTER_REGIONAL_UNIT = "regional_unit";
export const FILTER_APP_CLIENT = "app_product";
export const FILTER_YEAR = "year"
export const FILTER_CONTACT_TYPE = "contact_type"
export const FILTER_THEME = "theme";

/** Mixpanel Events */

export const REC_MARKET_MULTI_CITY = "REC_MARKET_MULTI_CITY";
export const REC_MARKET_CLICK_ONLINE = "REC_MARKET_CLICK_ONLINE";
export const CUSTOMER_DETAIL_DOWNLOAD = "CUSTOMER_DETAIL_DOWNLOAD";
export const PRODUCT_DETAIL_DOWNLOAD = "PRODUCT_DETAIL_DOWNLOAD";
export const REC_CUSTOMER_DOWNLOAD = "REC_CUSTOMER_DOWNLOAD";
export const REC_CITY_DOWNLOAD = "REC_CITY_DOWNLOAD";
export const REC_PRODUCT_DOWNLOAD = "REC_PRODUCT_DOWNLOAD";
export const REC_PRODUCT_EXPORT_CRM = "REC_PRODUCT_EXPORT_CRM";

/** Tenants */

export const SEBRAE_RN = "sebraern";
export const SEBRAE_MA = "sebraema";
export const SEBRAE_PB = "sebraepb";
export const SEBRAE_PE = "sebraepe";
export const SEBRAE_MS = "sebraems";
export const SEBRAE_GO = "sebraego";
export const SEBRAE_ES = "sebraees";

export const STATE_NAMES = {
  "sebraepe": "Pernambuco",
  "sebraema": "Maranhão",
  "sebraego": "Goiás",
  "sebraern": "Rio Grande do Norte",
  "sebraems": "Mato Grosso do Sul",
  "sebraepb": "Paraíba",
  "sebraees": "Espírito Santo",
}

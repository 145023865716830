import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Container,
  HeaderContainer,
  TableContainer,
  Header
} from './styles';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { Creators as CustomerCreators } from '../../store/ducks/customer';
import { Creators as RecCustomerProductCreators } from '../../store/ducks/rec_customer_product';
import Table from '../Table';
import DownloadFile from "../DownloadFile";
import { REC_CUSTOMER_DOWNLOAD, NIVEL1, CSV } from '../../constants';
import { TYPE_ICON } from '../../utils/schema';
import RecEventDialog from '../RecEventDialog';
import { Creators as EventCreators } from '../../store/ducks/event';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";

class RecCustomerProduct extends Component {

  state = {
    isOpenedDialog: false,
    exportType: CSV
  };

  handleOpenDialog = () => {
    this.setState({ isOpenedDialog: !this.state.isOpenedDialog })
  };

  handleExportType = (exportType) => {this.setState({exportType: exportType});}

  onTableClick = (column, item) => {
    if (column.type === TYPE_ICON) {
      this.props.setDialog('event');
      this.props.getOpenedEvents({
        product_id: item.produto,
        is_download: false
      });
    }
  }

  handleDownload = () => this.submit({ is_download: true });

  submit = ({ is_download }) => {
    const { parceiroid } = this.props.customer.data;

    this.props.getRecCustomerProduct({
      parceiroid: parceiroid,
      is_download: is_download || false,
      file_format: this.state.file_format
    });
  }

  render() {
    const { data, defaultData, sort, expandedRow, loading, error } = this.props.rec_customer_product;
    const { role } = this.props;

    return (
      <>
        <Container>
          <TableContainer>
            {!loading ?
              <HeaderContainer style={{ display: 'flex', justifyContent: 'space-between', padding: 0, marginBottom: 0 }}>
                <Header>Detalhe de Recomendação de Produtos</Header>
                {role === NIVEL1 ? null :
                  <div>
                    <DownloadFile
                      label='Baixar lista'
                      mixpanelTrackEvent={REC_CUSTOMER_DOWNLOAD}
                      filename='recomendacao_por_cliente'
                      header={data.header}
                      rows={data.items}
                      isDialogOpened={this.state.isOpenedDialog}
                      handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                      onSelectExportType={this.handleExportType}
                    />
                    <IconButton
                      mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                      onClick={() => this.handleOpenDialog()}
                      label={'Exportar'}
                      icon={<ExportIcon />}
                    />
                  </div>}
              </HeaderContainer>
              : null}

            <Table
              data={data}
              sort={sort}
              error={error}
              loading={loading}
              onClick={(column, item) => this.onTableClick(column, item)}
              defaultData={defaultData}
              expandedRow={expandedRow}
              sortBy={this.props.recCustomerSortBy}
            />

          </TableContainer>
        </Container>
        <RecEventDialog role={role} />
      </>
    )
  }
}

const mapStateToProps = ({ title, customer, rec_customer_product }) =>
  ({ title, customer, rec_customer_product });

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...CustomerCreators,
    ...RecCustomerProductCreators,
    ...DialogCreators,
    ...EventCreators
  })
)(RecCustomerProduct);

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  recCustomerEventInit: [],
  recCustomerEventRequest: [],
  recCustomerEventSuccess: ['data'],
  recCustomerEventError: ['err'],
  getRecCustomerEvent: ['id'],
  recCustomerEventSortBy: ['data', 'sort'],
  recCustomerEventInitTable: [],
  recCustomerEventTopRecommendations: ['selected']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  defaultData: [],
  error: false,
  loading: false,
  selected: []
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, defaultData: data, error: false, loading: false, sort: INITIAL_STATE.sort });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) => state.merge({ data, sort });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort });

export const select = (state, { selected }) => state.merge({ selected });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.REC_CUSTOMER_EVENT_INIT]: init,
  [Types.REC_CUSTOMER_EVENT_REQUEST]: request,
  [Types.REC_CUSTOMER_EVENT_SUCCESS]: success,
  [Types.REC_CUSTOMER_EVENT_ERROR]: error,
  [Types.REC_CUSTOMER_EVENT_SORT_BY]: sortBy,
  [Types.REC_CUSTOMER_EVENT_INIT_TABLE]: initTable,
  [Types.REC_CUSTOMER_EVENT_TOP_RECOMMENDATIONS]: select
});
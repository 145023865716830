import { connect } from "react-redux";
import React, { Component } from "react";
import {
  SelectContainer,
  Text,
  ButtonContainer,
  LoadingContainer,
  StyledButton,
  CheckboxContainer,
  CenterContainer,
  CheckboxEmptyState,
  ActionsContainer,
  ClearText,
  StyledAccordion,
  AccordionText,
  StyledStartInputLabel,
  StyledTooltip,
} from "./styles";
import Header from "../Header";
import { selectStyle, baseColor } from "../App/theme";
import Select from "react-select";
import { Aside } from "../../styles/Aside";
import { compose } from "redux";
import { actions as toastrActions } from "react-redux-toastr";
import { Creators as RfbFilterActions } from "../../store/ducks/rfb_filter";
import { Creators as RfbEnterpriseActions } from "../../store/ducks/rfb_enterprise";
import {
  CircularProgress,
  FormControlLabel,
  AccordionSummary,
} from "@material-ui/core";
import LeadsIcon from "react-feather/dist/icons/user-plus";
import { Checkbox } from "@material-ui/core";
import { ITEMS_PER_PAGE } from "../../constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FILTER_CITY,
  FILTER_CNAE,
  FILTER_PORT,
  FILTER_NEIGHBORHOOD,
  FILTER_SECTION,
  getFilter,
  FILTER_REGIONAL_UNIT,
} from "../../utils/rfb_filter";
import { getDefaultDate, getDateYYMMDDDD, isValidRangeDate } from "../../utils";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "react-feather/dist/icons/help-circle";

class LeadsFilter extends Component {
  state = {
    isExpanded: false,
    today: getDefaultDate({ delta: 0 }),
    date_from_last_attendance: null,
    date_to_last_attendance: null,
  };

  componentDidMount() {
    this.props.getRfbFilters({}, FILTER_CITY);
    this.props.getRfbFilters({}, FILTER_REGIONAL_UNIT);
  }

  clearState = () => {
    this.props.getRfbFilters({}, FILTER_CITY);
    this.props.getRfbFilters({}, FILTER_REGIONAL_UNIT);
    this.props.rfbFilterInit(FILTER_PORT);
    this.setState({
      today: getDefaultDate({ delta: 0 }),
      date_from_last_attendance: null,
      date_to_last_attendance: null,
    });
  };

  showClearFilters = () => {
    let isClear = false;
    const state = { ...this.props.rfb_filter.selecteds };

    Object.keys(state).forEach((key) => {
      const item = state[key];

      if (item && Object.keys(item).length) {
        isClear = true;
        return;
      }
    });

    return isClear;
  };

  renderSelect = ({ name, placeholder, isMulti, duck }) => (
    <SelectContainer>
      <Select
        isMulti={isMulti}
        isClearable
        isDisabled={duck.loading[name]}
        isLoading={duck.loading[name]}
        value={
          this.props.rfb_filter.selecteds[name]
            ? this.props.rfb_filter.selecteds[name].asMutable()
            : null
        }
        noOptionsMessage={() => "Sem dados"}
        onChange={(e) => this.handleChange(e, name)}
        placeholder={duck.loading[name] ? "Carregando..." : placeholder}
        styles={selectStyle}
        options={duck.data[name] ? duck.data[name].asMutable() : []}
      />
    </SelectContainer>
  );

  handleChange = (value, name) => {
    const newState = {
      ...this.props.rfb_filter.selecteds,
      [name]: value,
    };

    this.props.rfbFilterSelect(name, value);

    this.refreshFilters(name, newState);
  };

  getFilterRules = (name) => {

    let filters = [FILTER_NEIGHBORHOOD, FILTER_SECTION, FILTER_CNAE, FILTER_PORT, FILTER_REGIONAL_UNIT,FILTER_CITY];

    const functions = {
      [FILTER_REGIONAL_UNIT]: (filter) => this.props.getRfbFilters(filter, FILTER_REGIONAL_UNIT),
      [FILTER_CITY]: (filter) => this.props.getRfbFilters(filter, FILTER_CITY),
      [FILTER_PORT]: (filter) => this.props.getRfbFilters(filter, FILTER_PORT),
      [FILTER_CNAE]: (filter) => this.props.getRfbFilters(filter, FILTER_CNAE),
      [FILTER_NEIGHBORHOOD]: (filter) => this.props.getRfbFilters(filter, FILTER_NEIGHBORHOOD),
      [FILTER_SECTION]: (filter) => this.props.getRfbFilters(filter, FILTER_SECTION),
    };

    let filterFunctions = [];

    for (let filter of filters) {
      if (filter !== name) {
        filterFunctions.push(functions[filter]);
      }
    }

    return filterFunctions;
  };

  refreshFilters = (name, newState) => {
    const filter = getFilter(newState);
    const filterRules = this.getFilterRules(name);

    if (!filterRules?.length) {
      return;
    }
    

    filterRules.forEach((fun) => {
      fun(filter);
    });
  };

  renderWarningMsg = (msg) => {
    this.props.add({
      type: "warning",
      title: "Atenção",
      message: msg,
    });
  };

  renderEmptyState = (msg) => (
    <CenterContainer>{msg || "Sem Dados"}</CenterContainer>
  );

  renderLoading = () => (
    <LoadingContainer color={baseColor}>
      <CircularProgress color="primary" size={24} />
    </LoadingContainer>
  );

  handleCheckbox = (event, field) => {
    this.props.rfbFilterSelect(field, {
      ...this.props.rfb_filter.selecteds[field],
      [event.target.name]: event.target.checked,
    });
  };

  handleDateChange = (name, value) => {
    this.props.rfbFilterSelect(name, value);
    this.setState({ [name]: value });
  };

  submit = () => {
    const { date_from_last_attendance, date_to_last_attendance } = this.state;
    const date_from = date_from_last_attendance
      ? getDateYYMMDDDD(date_from_last_attendance)
      : null;
    const date_to = date_to_last_attendance
      ? getDateYYMMDDDD(date_to_last_attendance)
      : null;
    let date_last_attendance;

    const filter = getFilter(this.props.rfb_filter.selecteds);

    if ((!filter.city || !filter.city.length) && (!filter.regional_unit || !filter.regional_unit.length)) {
      this.renderWarningMsg("Selecione ao menos uma Cidade ou Unid. regional");
      return;
    }

    if (
      (date_from_last_attendance || date_to_last_attendance) &&
      !isValidRangeDate(date_from_last_attendance, date_to_last_attendance)
    ) {
      this.renderWarningMsg(
        "Período informado de data do último atendimento inválido"
      );
      return;
    }

    if (filter.client && !filter.client.sebrae && filter.client.leads) {
      date_last_attendance = { date_from: null, date_to: null };
    } else {
      date_last_attendance =
        date_from && date_to
          ? { date_from: date_from, date_to: date_to }
          : { date_from: null, date_to: null };
    }

    this.props.getRfbEnterprises({
      ...filter,
      date_last_attendance: date_last_attendance,
      page: 1,
      items_per_page: ITEMS_PER_PAGE,
      is_search: true,
    });
  };

  isLoading = () => {
    let isLoading = false;
    const { loading } = this.props.rfb_filter;

    Object.keys(loading).forEach((item) => {
      if (loading[item]) {
        isLoading = true;
      }
    });

    return isLoading;
  };

  renderPorts = () => {
    const { rfb_filter } = this.props;

    if (rfb_filter.loading[FILTER_PORT]) {
      return this.renderLoading();
    }

    if (!rfb_filter.data[FILTER_PORT]?.length) {
      return (
        <CheckboxEmptyState>
          Escolha um município antes do porte
        </CheckboxEmptyState>
      );
    }

    return (
      <CheckboxContainer>
        {rfb_filter.data[FILTER_PORT].map((port, idx) => (
          <FormControlLabel
            key={idx}
            control={
              <Checkbox
                checked={
                  this.props.rfb_filter.selecteds.port[port.value] === true
                }
              />
            }
            onChange={(e) => this.handleCheckbox(e, "port")}
            name={port.value}
            label={port.label}
          />
        ))}
      </CheckboxContainer>
    );
  };

  handleChangeAccordion = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { rfb_filter, rfb_enterprise } = this.props;
    const isLoading = this.isLoading() || rfb_enterprise.loading;
    const {
      isExpanded,
      today,
      date_from_last_attendance,
      date_to_last_attendance,
    } = this.state;

    return (
      <Aside>
        <Header icon={<LeadsIcon />} title={"Leads"} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>Unidade Regional</Text>
          <StyledTooltip
            placement="right-start"
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Obrigatório o preenchimento de Unidade Regional ou Município
                </Typography>
              </React.Fragment>
            }
          >
            <span style={{ marginRight: "8.5vw", marginTop: "0.5vw" }}>
              <HelpIcon color="#41A2FF" />
            </span>
          </StyledTooltip>
        </div>
        {this.renderSelect({
            duck: rfb_filter,
            name: FILTER_REGIONAL_UNIT,
            placeholder: "Todas as Unid. regionais",
            isMulti: true,
          })}

        <Text>Município</Text>
        {this.renderSelect({
          duck: rfb_filter,
          name: FILTER_CITY,
          placeholder: "Todos os municípios",
          isMulti: true,
        })}

        <Text>Porte</Text>
        {this.renderSelect({
          duck: rfb_filter,
          name: FILTER_PORT,
          placeholder: "Todos os Portes",
          isMulti: true,
        })}

        <Text style={{ paddingTop: "-0.5rem" }}>Cliente</Text>

        <CheckboxContainer>
          <FormControlLabel
            control={
              <Checkbox
                checked={rfb_filter.selecteds.client.sebrae === true}
                onChange={(e) => this.handleCheckbox(e, "client")}
                name="sebrae"
              />
            }
            label="Sebrae"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={rfb_filter.selecteds.client.leads === true}
                  onChange={(e) => this.handleCheckbox(e, "client")}
                  name="leads"
                />
              }
              label="Leads"
            />
            <StyledTooltip
              placement="right-start"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Empresas nunca atendidas pelo SEBRAE
                  </Typography>
                </React.Fragment>
              }
            >
              <span style={{ marginRight: "11vw" }}>
                <HelpIcon color="#41A2FF" />
              </span>
            </StyledTooltip>
          </div>
        </CheckboxContainer>

        <ActionsContainer>
          <StyledAccordion
            elevation={0}
            expanded={isExpanded}
            onChange={() => this.handleChangeAccordion()}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary"></ExpandMoreIcon>}
            >
              <AccordionText>
                {isExpanded
                  ? "Ocultar filtros avançados"
                  : "Mostrar filtros avançados"}
              </AccordionText>
            </AccordionSummary>
            <Text>Bairro</Text>
            {this.renderSelect({
              duck: rfb_filter,
              name: FILTER_NEIGHBORHOOD,
              placeholder: "Todos os bairros",
              isMulti: true,
            })}

            <Text>CNAE</Text>
            {this.renderSelect({
              duck: rfb_filter,
              name: FILTER_CNAE,
              placeholder: "Todos os CNAES",
              isMulti: true,
            })}

            <Text>Setor Econômico</Text>
            {this.renderSelect({
              duck: rfb_filter,
              name: FILTER_SECTION,
              placeholder: "Todos os setores",
              isMulti: true,
            })}

            <Text>Data do último atendimento</Text>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <StyledStartInputLabel>Início</StyledStartInputLabel>
                <KeyboardDatePicker
                  disabled={
                    rfb_filter.selecteds.client.leads &&
                    !rfb_filter.selecteds.client.sebrae
                  }
                  clearable
                  style={{ width: "6.5vw", paddingLeft: "1rem" }}
                  value={
                    rfb_filter.selecteds.client.leads &&
                      !rfb_filter.selecteds.client.sebrae
                      ? null
                      : date_from_last_attendance
                  }
                  name="date_from_last_attendance"
                  allowKeyboardControl={false}
                  placeholder="Selecione..."
                  invalidDateMessage={React.createElement(
                    "span",
                    null,
                    "Data Inválida"
                  )}
                  clearLabel={React.createElement("span", null, "Limpar")}
                  cancelLabel={React.createElement("span", null, "Cancelar")}
                  onChange={(date) =>
                    this.handleDateChange("date_from_last_attendance", date)
                  }
                  maxDate={today}
                  format="dd/MM/yyyy"
                />
              </div>

              <div style={{ paddingRight: "1vw" }}></div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <StyledStartInputLabel>Fim</StyledStartInputLabel>
                <KeyboardDatePicker
                  disabled={
                    rfb_filter.selecteds.client.leads &&
                    !rfb_filter.selecteds.client.sebrae
                  }
                  clearable
                  value={
                    rfb_filter.selecteds.client.leads &&
                      !rfb_filter.selecteds.client.sebrae
                      ? null
                      : date_to_last_attendance
                  }
                  name="date_to_last_attendance"
                  style={{ width: "6.5vw", paddingLeft: "1rem" }}
                  placeholder="Selecione..."
                  allowKeyboardControl={false}
                  minDateMessage={React.createElement(
                    "span",
                    null,
                    "Data Inferior à Data de Início"
                  )}
                  invalidDateMessage={React.createElement(
                    "span",
                    null,
                    "Data Inválida"
                  )}
                  clearLabel={React.createElement("span", null, "Limpar")}
                  cancelLabel={React.createElement("span", null, "Cancelar")}
                  onChange={(date) =>
                    this.handleDateChange("date_to_last_attendance", date)
                  }
                  minDate={date_from_last_attendance}
                  maxDate={today}
                  format="dd/MM/yyyy"
                />
              </div>
            </div>
          </StyledAccordion>
          {this.showClearFilters() && !isLoading ? (
            <ClearText onClick={this.clearState}>Limpar filtros</ClearText>
          ) : null}
          <ButtonContainer>
            <StyledButton
              fullWidth
              onClick={this.submit}
              disabled={isLoading}
              disableRipple={true}
              variant="outlined"
              color="primary"
            >
              Gerar análise
            </StyledButton>
          </ButtonContainer>
        </ActionsContainer>
      </Aside>
    );
  }
}

const mapStateToProps = ({ rfb_filter, rfb_enterprise }) => ({
  rfb_filter,
  rfb_enterprise,
});

export default compose(
  connect(mapStateToProps, {
    ...toastrActions,
    ...RfbFilterActions,
    ...RfbEnterpriseActions,
  })
)(LeadsFilter);

import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    SelectContainer, Text,
    ButtonContainer, LoadingContainer,
    StyledButton, ClearText
} from './styles';
import Header from '../Header';
import { selectStyle, baseColor } from '../App/theme';
import Select from 'react-select';
import { Aside } from '../../styles/Aside';
import { compose } from 'redux';
import { Creators as ProductCreators } from '../../store/ducks/product';
import { Creators as SectorCreators } from '../../store/ducks/sector';
import { actions as toastrActions } from 'react-redux-toastr';
import { Creators as FilterCreators } from '../../store/ducks/filter';
import { Creators as TargetAudienceCreators } from '../../store/ducks/target_audience';
import { Creators as PubAlvoCreators } from '../../store/ducks/pub_alvo';
import { Creators as MacrossegmentCreators } from '../../store/ducks/macrossegment';
import { CircularProgress } from '@material-ui/core';
import { TARGET_AUDIENCE, ITEMS_PER_PAGE } from '../../constants';
import SettingsIcon from 'react-feather/dist/icons/settings';
import TargetAudienceDialog from '../TargetAudienceDialog';
import CleanTargetAudienceDialog from '../CleanTargetAudienceDialog';
import { getFilterValues } from '../../utils/index';

const FILTER_PRODUCT = 'product';   
const FILTER_PUB_ALVO = 'pub_alvo';
const FILTER_SECTOR = 'sector';
const FILTER_MACROSSEGMENT = 'macrossegment'

class TargetAudienceFilter extends Component {

    INITIAL_STATE = {
        [TARGET_AUDIENCE]: {
            product: null,
            pub_alvo: null,
            sector: null,
            macrossegment: null,
        },
        page: 1
    }

    state = this.INITIAL_STATE

    componentDidMount() {
        this.props.getProducts({ chip: TARGET_AUDIENCE });
        this.props.getPubAlvos({ chip: TARGET_AUDIENCE });
        this.props.getMacrossegments({ chip: TARGET_AUDIENCE });
        this.props.getSectors({ chip: TARGET_AUDIENCE });
        this.props.getTargetAudience({ page: 1, items_per_page: ITEMS_PER_PAGE });
    }

    clearState = () => {
        const context = this.props.chip[this.props.context];

        this.setState({
            [context]: { ...this.INITIAL_STATE[context] }
        })
    };

    showClearFilters = () => {
        let isClear = false;
        const context = this.props.chip[this.props.context];

        if (!this.state[context]) {
            return false;
        };

        Object.keys(this.state[context]).forEach(key => {
            const item = this.state[context][key];

            if (item) {
                isClear = true;
                return;
            }
        });

        return isClear;
    }

    renderSelect = ({ name, data, placeholder, isLoading, isMulti, isDisabled }) => (
        <SelectContainer>
            <Select
                isMulti={isMulti}
                isClearable
                isDisabled={isLoading ? true : isDisabled ? true : null}
                value={isDisabled ? null : this.state[this.props.chip[this.props.context]][name]}
                noOptionsMessage={() => 'Sem dados'}
                onChange={(e) => this.handleChange(e, name)}
                placeholder={isLoading ? 'Carregando...' : placeholder}
                styles={selectStyle}
                options={data} />
        </SelectContainer>
    )

    handleChange = (item, name) => {
        const { chip, context } = this.props;
        const newData = { ...this.state[chip[context]], [name]: item };

        this.setState({ [chip[context]]: newData })
    };

    renderWarningMsg = (msg) => {
        this.props.add({
            type: 'warning',
            title: 'Atenção',
            message: msg
        });
    }

    renderLoading = () => (
        <LoadingContainer color={baseColor}>
            <CircularProgress color="primary" size={24} />
        </LoadingContainer>
    )

    getFilter = () => {
        const { chip, context } = this.props;
        const { product, sector, macrossegment, pub_alvo } = this.state[chip[context]];

        const filter = {
            productid: getFilterValues(product),
            sector: getFilterValues(sector),
            macrossegment: getFilterValues(macrossegment),
            pub_alvo: getFilterValues(pub_alvo),
            context: chip[context],
            page: 1,
            items_per_page: ITEMS_PER_PAGE
        };

        return filter;
    }

    submit = () => {
        const filter = this.getFilter();

        this.props.getTargetAudience({ ...filter, page: 1, items_per_page: ITEMS_PER_PAGE });

        this.props.setFilter(filter);
    }

    getTitleIcon = () => {
        let icon = <SettingsIcon />;

        return icon;
    }

    handleCloseToolTip = (e, name) => {
        this.setState({ [name]: false })
    };

    handleOpenToolTip = (e, name) => {
        this.setState({ [name]: true })
    };

    render() {
        const { chip, context } = this.props;
        const { product, sector, pub_alvo, macrossegment } = this.props;
        const isLoading = product.loading || macrossegment.loading || pub_alvo.loading || sector.loading 

        return (
            <>
                <Aside>
                    <Header icon={this.getTitleIcon()} title={this.props.title} />

                    <Text>Produto</Text>
                    {this.renderSelect({
                        name: FILTER_PRODUCT,
                        data: product[chip[context]].asMutable(),
                        placeholder: 'Todos os produtos',
                        isLoading: product.loading,
                        isMulti: true
                    })}

                    <Text>Público Alvo</Text>
                    
                    {this.renderSelect({
                        name: FILTER_PUB_ALVO,
                        data: pub_alvo[chip[context]].asMutable(),
                        placeholder: 'Todos os públicos alvo',
                        isLoading: pub_alvo.loading,
                        isMulti: true,
                    })}

                    <Text>Setor Econômico</Text>
                    
                    {this.renderSelect({
                        name: FILTER_SECTOR,
                        data: sector[chip[context]].asMutable(),
                        placeholder: 'Todos os setores',
                        isLoading: sector.loading,
                        isMulti: true,
                    })}

                    <Text>Macrossegmento</Text>
                    
                    {this.renderSelect({
                        name: FILTER_MACROSSEGMENT,
                        data: macrossegment[chip[context]].asMutable(),
                        placeholder: 'Todos os macrossegmentos',
                        isLoading: macrossegment.loading,
                        isMulti: true,
                        isDisabled: this.state.isDisabled ? false : this.state.typePerson === 'F' ? true : false
                    })}


                    {this.showClearFilters() && !isLoading ?
                        <ClearText onClick={this.clearState}>Limpar filtros</ClearText>
                        : null}
                    <ButtonContainer>
                        <StyledButton
                            fullWidth
                            onClick={this.submit}
                            disabled={isLoading}
                            disableRipple={true}
                            variant="outlined"
                            color="primary">
                            Buscar Produtos
                        </StyledButton>
                    </ButtonContainer>
                </Aside >
                <TargetAudienceDialog />
                <CleanTargetAudienceDialog />
            </>
        );
    }
}

const mapStateToProps = ({ product, filter, chip, sector , target_audience, pub_alvo, macrossegment }) => {
    return ({ product, filter, chip, sector, target_audience, pub_alvo, macrossegment })
};

export default compose(
    connect(mapStateToProps, {
        ...ProductCreators,
        ...toastrActions,
        ...FilterCreators,
        ...SectorCreators,
        ...TargetAudienceCreators,
        ...PubAlvoCreators,
        ...MacrossegmentCreators,
    })
)(TargetAudienceFilter);
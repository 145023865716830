import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  treeMapProductInit: [],
  treeMapProductRequest: [],
  treeMapProductSuccess: ['data'],
  treeMapProductError: ['err'],
  getTreeMapProduct: ['filter'],
  setTreeMapProductConfig: ['field', 'value']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */
const INITIAL_STATE = Immutable({
  data: {},
  parent: { label: 'Município', value: 'cidade_parceiro' },
  child: { label: 'Instrumento', value: 'instrumento' },
  loading: false,
  error: false
});

/* Reducers Treemap */

export const init = state => state.merge({ data: [] });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const config = (state, { field, value }) => state.merge({ [field]: value });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.TREE_MAP_PRODUCT_INIT]: init,
  [Types.TREE_MAP_PRODUCT_REQUEST]: request,
  [Types.TREE_MAP_PRODUCT_SUCCESS]: success,
  [Types.TREE_MAP_PRODUCT_ERROR]: error,
  [Types.SET_TREE_MAP_PRODUCT_CONFIG]: config
});
import api from '../../services/api';
import { Creators } from '../ducks/treemap_market';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getTreeMapMarket({ filter }) {
  try {
    yield put(Creators.treeMapMarketRequest());
    const response = yield call(api.post, 'treemap', filter);

    yield put(Creators.treeMapMarketSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.treeMapMarketError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao exibir treemap'
    }));
  }
}
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-x: auto;
`;

export const ProductContainer = styled.div`
  margin: 2vh;
  width: 70%;
  overflow-y: auto;
  height: 88.4vh;
  max-height: 88.4vh;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin-top: 3vh;
`;

export const HeaderContainer = styled.div`
  padding: .5rem;
`;

export const HeaderTableContainer = styled.div`
  display: flex;
  padding: .5rem 1rem .5rem 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderText = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 23px;
`;

export const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MapContainer = styled.div`
  margin-top: 2vh;
  height: 40.7%;
  max-height: 40.7%;
  background: #FDFDFD;
  border-radius: 5px;
`;

export const TreeMapContainer = styled.div`
  margin-top: 2vh;
  padding: 0;
  height: 44.7%;
  max-height: 44.7%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const HeaderTreeMapContainer = styled.div`
  display: flex;
  padding: .5rem;
  align-items: center;
  justify-content: space-between;
`;

export const TreeMapHeader = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const TreeMapDimensions = styled.div`
  font-size: .8rem;
  padding-right:.5rem;
  
  &:hover {
    cursor: default;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Text = styled.div`
  margin: 1rem;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
`;

export const SliderContainer = styled.div`
  padding: 1rem;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderText = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 6vh;
  text-transform: none;
  font-size: 16px;
`;

export const MapAndTreeMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-right: 1vw;
`;

export const SettingsIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: .5vw;

  &:hover {
    cursor: pointer;
  }
`;
import Table from "../Table";
import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as RecProductCreators } from "../../store/ducks/rec_product";
import { Creators as RecProductIndicatorsCreators } from "../../store/ducks/rec_product_indicators";
import { Creators as CrmCreators } from "../../store/ducks/crm";
import {
  Container,
  ProductContainer,
  DetailContainer,
  Header,
  HeaderContainer,
  ThemeText,
  CardContainer,
} from "./styles";
import CustomCard from "../Card";
import IconButton from "../IconButton";
import CrmIcon from "react-feather/dist/icons/external-link";
import {
  CSV,
  ITEMS_PER_PAGE,
  REC_PRODUCT_EXPORT_CRM,
  NIVEL1,
  XLSX,
  GIVE_FEEDBACK_MESSAGE,
} from "../../constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import RecEventDialog from "../RecEventDialog";
import { primary } from "../App/theme";

class RecProduct extends Component {
  state = {
    page: 1,
    isShowExport: false,
    exportType: CSV,
    isFeedback: false,
  };

  componentDidMount() {
    this.setState({ page: 1 });
  }

  renderCards = () => {
    const { loading, data } = this.props.rec_product_indicators;
    const items = this.props.rec_product.data.items;

    if (!items || !items.length || loading) return null;

    return (
      <CardContainer>
        <CustomCard
          label="Setor de maior impacto"
          value={data.sector_more_commom || "N/A"}
        ></CustomCard>
        <CustomCard
          label="Porte de maior impacto"
          value={data.port_more_commom || "N/A"}
        ></CustomCard>
        <CustomCard
          label="Clientes CPF"
          value={`${data.percentage_cpf}%`}
        ></CustomCard>
        <CustomCard
          label="Clientes CNPJ"
          value={`${data.percentage_cnpj}%`}
        ></CustomCard>
      </CardContainer>
    );
  };

  getLabelProduct = () => {
    const { filter } = this.props.filter;
    let product = {
      label: "",
      value: "",
    };

    if (filter.product && filter.product.length) {
      if (Array.isArray(filter.product)) {
        product.value = filter.product.join(", ");
        product.label = "Produto:";

        if (filter.product.length > 1) {
          product.label = "Produtos:";
        }
      } else {
        product.label = "Produto:";
        product.value = filter.product;
      }
    }

    return product;
  };

  submit = ({ sort, is_download, download_extension, export_context }) => {
    const { filter } = this.props.filter;
    const productLabel = this.getLabelProduct();

    this.props.getRecProduct({
      ...filter,
      ano: undefined,
      page: this.state.page,
      sort: sort || this.props.rec_product.sort,
      items_per_page: ITEMS_PER_PAGE,
      is_download: is_download || false,
      download_extension: download_extension || CSV,
      product_label:
        productLabel && productLabel.value
          ? productLabel.value
          : "Recomendação de Produtos",
      export_context: export_context,
      is_feedback: this.state.isFeedback,
    });
  };

  handlePageChange = (page) => this.setState({ page }, () => this.submit({}));

  handleTableSortBy = (data, sort) => {
    this.submit({ sort });
    this.props.recProductSortBy(sort);
  };

  handleDownload = ({ download_extension, export_context }) => {
    this.submit({ is_download: true, download_extension, export_context });
    this.setState({ isShowExport: !this.state.isShowExport });
  };

  handleExport = () => {
    this.setState({ isShowExport: !this.state.isShowExport });
  };

  handleFeedback = () => {
    this.setState({ isFeedback: !this.state.isFeedback });
  };

  handleSelectChange = (event) => {
    this.setState({ exportType: event.target.value });
  };

  sendToCrm = () => {
    const { filter } = this.props.filter;

    this.props.postCrm({
      ...filter,
      ano: undefined,
      is_download: true,
      crm_context: "rec_product",
    });
  };

  renderProductContainer = () => {
    const tenantConfig = { ...this.props.tenant_config.data };
    const { data, sort, error } = this.props.rec_product;
    const product = this.getLabelProduct();
    const crmLoading = this.props.crm.loading;
    const { role } = this.props;

    const loading =
      this.props.rec_product.loading ||
      this.props.rec_product_indicators.loading;
    return (
      <>
        <DetailContainer>
          <ProductContainer>
            <HeaderContainer>
              <Header>
                <b>{product.label}</b> {product.value}
              </Header>
              {!loading && data && data.items && data.items.length > 0 ? (
                <div>
                  <b>Tema:</b> <ThemeText>{data.items[0].tema}</ThemeText>
                </div>
              ) : null}
            </HeaderContainer>
            {this.renderCards()}
            {!loading && data.items && data.items.length && role !== NIVEL1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1vw",
                }}
              >
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  Recomendação de clientes para este produto:
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <IconButton
                      onClick={loading ? null : () => this.handleExport()}
                      label={loading ? null : "Exportar"}
                      icon={loading ? null : <CrmIcon />}
                    />
                  </div>
                  {tenantConfig && tenantConfig.crm_active ? (
                    <div style={{ paddingLeft: ".5rem" }}>
                      <IconButton
                        onClick={crmLoading ? null : this.sendToCrm}
                        mixpanelTrackEvent={REC_PRODUCT_EXPORT_CRM}
                        label={
                          crmLoading
                            ? "Enviando para o CRM..."
                            : "Enviar para o CRM"
                        }
                        icon={
                          crmLoading ? (
                            <CircularProgress color="primary" size={10} />
                          ) : (
                            <CrmIcon />
                          )
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <Table
              data={data}
              sort={sort}
              error={error}
              loading={loading}
              pageCount={Math.ceil(data.count / ITEMS_PER_PAGE)}
              sortBy={this.handleTableSortBy}
              onPageChange={this.handlePageChange}
              history={this.props.history}
              page={this.state.page}
            />
          </ProductContainer>
        </DetailContainer>
        <RecEventDialog role={role} />
        <Dialog
          maxWidth={"sm"}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.isShowExport}
          onClose={this.handleExport}
        >
          <DialogTitle>
            <b>Exportar</b>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FormControl variant="outlined" style={{ minWidth: "15rem" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de exportação
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.exportType}
                onChange={this.handleSelectChange}
                label="Tipo de exportação"
              >
                <MenuItem value={XLSX}>Excel(XLSX)</MenuItem>
                <MenuItem value={CSV}>CSV</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              style={{ padding: "3.5rem" }}
              control={
                <Checkbox
                  checked={this.state.is_feedback}
                  onChange={this.handleFeedback}
                  color="primary"
                />
              }
              label={<b>{GIVE_FEEDBACK_MESSAGE}</b>}
            />
          </DialogContent>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "1rem",
              }}
            >
              <div>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    minWidth: 130,
                    color: primary,
                    fontSize: "0.8rem",
                  }}
                  onClick={
                    loading
                      ? null
                      : this.handleDownload.bind(this, {
                          download_extension: this.state.exportType,
                          export_context: this.state.exportContext,
                        })
                  }
                >
                  Exportar
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  render() {
    return <Container>{this.renderProductContainer()}</Container>;
  }
}

const mapStateToProps = ({
  filter,
  rec_product,
  tenant_config,
  crm,
  rec_product_indicators,
}) => ({
  filter,
  rec_product,
  tenant_config,
  crm,
  rec_product_indicators,
});

export default compose(
  connect(mapStateToProps, {
    ...RecProductCreators,
    ...FilterCreators,
    ...CrmCreators,
    ...RecProductIndicatorsCreators,
  })
)(RecProduct);

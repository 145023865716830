import { connect } from "react-redux";
import React, { Component } from "react";
import {
  SelectContainer,
  Text,
  ButtonContainer,
  LoadingContainer,
  StyledButton,
  StyledStartInputLabel,
  ClearText,
} from "./styles";
import Header from "../Header";
import { selectStyle, baseColor } from "../App/theme";
import Select from "react-select";
import { Aside } from "../../styles/Aside";
import { compose } from "redux";
import { actions as toastrActions } from "react-redux-toastr";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as ChatbotCreators } from "../../store/ducks/chatbot";
import { Creators as ChatbotCustomerCreators } from "../../store/ducks/chatbot_customer";
import { Creators as CityCreators } from "../../store/ducks/city";
import { CircularProgress } from "@material-ui/core";
import { ITEMS_PER_PAGE, CHATBOT } from "../../constants";
import SettingsIcon from "react-feather/dist/icons/settings";
import { getDateYYMMDDDD, isValidRangeDate, getDefaultDate } from "../../utils";
import ChatbotDialog from "../ChatbotDialog";
//import ChatbotCustomerSelect from "../ChatbotCustomerSelect";

const FILTER_CITY = "city";

class ChatbotFilter extends Component {
  INITIAL_STATE = {
    [CHATBOT]: {
      city: null,
      date_from_last_attendance: null,
      date_to_last_attendance: null,
    },
    page: 1,
    isCross: null,
    isNotCross: null,
    typePerson: null,
    isDisabled: null,
    today: getDefaultDate({ delta: 0 }),
  };

  state = this.INITIAL_STATE;

  componentDidMount() {
    const { chip, context, tenant } = this.props;

    this.props.getCities({ chip: chip[context], tenant: tenant.tenant });
    this.props.getChatbot({
      page: 1,
      items_per_page: ITEMS_PER_PAGE,
      tenant: tenant.tenant,
    });
  }

  clearState = () => {
    const context = this.props.chip[this.props.context];

    this.setState({
      [context]: { ...this.INITIAL_STATE[context] },
    });
  };

  showClearFilters = () => {
    let isClear = false;
    const context = this.props.chip[this.props.context];

    if (!this.state[context]) {
      return false;
    }

    Object.keys(this.state[context]).forEach((key) => {
      const item = this.state[context][key];

      if (item) {
        isClear = true;
        return;
      }
    });

    return isClear;
  };

  renderSelect = ({
    name,
    data,
    placeholder,
    isLoading,
    isMulti,
    isDisabled,
  }) => (
    <SelectContainer>
      <Select
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading ? true : isDisabled ? true : null}
        value={
          isDisabled
            ? null
            : this.state[this.props.chip[this.props.context]][name]
        }
        noOptionsMessage={() => "Sem dados"}
        onChange={(e) => this.handleChange(e, name)}
        placeholder={isLoading ? "Carregando..." : placeholder}
        styles={selectStyle}
        options={data}
      />
    </SelectContainer>
  );

  handleChange = (item, name) => {
    const { chip, context } = this.props;
    const newData = { ...this.state[chip[context]], [name]: item };

    this.setState({ [chip[context]]: newData });
  };

  handleDateChange = (name, value) => {
    const newData = { ...this.state[CHATBOT], [name]: value };

    this.setState({ [CHATBOT]: newData });
  };

  renderLoading = () => (
    <LoadingContainer color={baseColor}>
      <CircularProgress color="primary" size={24} />
    </LoadingContainer>
  );

  renderErrorMsg = (msg) => {
    this.props.add({
      type: "error",
      title: "Erro",
      message: msg,
    });
  };

  getFilter = () => {
    const { chip, context, chatbot_customer } = this.props;
    const { city, date_from_last_attendance, date_to_last_attendance } =
      this.state[chip[context]];
    const date_from = date_from_last_attendance
      ? getDateYYMMDDDD(date_from_last_attendance)
      : null;
    const date_to = date_to_last_attendance
      ? getDateYYMMDDDD(date_to_last_attendance)
      : null;

    const filter = {
      city: city && city.length ? city.map((item) => item.value) : null,
      cpf: chatbot_customer.selected?.cpf,
      date_last_attendance: { date_from: date_from, date_to: date_to },
      chip: chip[context],
      page: 1,
      items_per_page: ITEMS_PER_PAGE,
    };

    return filter;
  };

  submit = () => {
    const { chip, context, tenant } = this.props;
    const filter = this.getFilter();
    const { date_from_last_attendance, date_to_last_attendance } =
      this.state[chip[context]];

    if (
      (date_from_last_attendance || date_to_last_attendance) &&
      !isValidRangeDate(date_from_last_attendance, date_to_last_attendance)
    ) {
      this.renderErrorMsg(
        "Período informado de data do último atendimento inválido"
      );
      return;
    }

    this.props.setFilter(filter);
    this.props.getChatbot({
      ...filter,
      page: 1,
      items_per_page: ITEMS_PER_PAGE,
      tenant: tenant.tenant,
    });
  };

  getTitleIcon = () => {
    let icon = <SettingsIcon />;

    return icon;
  };

  render() {
    const { chip, context } = this.props;
    const { city } = this.props;
    const isLoading = city.loading;
    const { today } = this.state;
    const { date_from_last_attendance, date_to_last_attendance } =
      this.state[chip[context]];
    return (
      <>
        <Aside>
          <Header icon={this.getTitleIcon()} title={this.props.title} />

          {
            //disabled
            /*
            <Text>Ver dados de clientes</Text>
            <SelectContainer>
              <ChatbotCustomerSelect placeholder="Nome" context={context} />
            </SelectContainer>
            */
          }

          <Text>Município</Text>
          {this.renderSelect({
            name: FILTER_CITY,
            data: city[chip[context]].asMutable(),
            placeholder: "Todas as cidades",
            isLoading: city.loading,
            isMulti: true,
          })}

          <Text>Data do atendimento</Text>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <StyledStartInputLabel>Início</StyledStartInputLabel>
              <KeyboardDatePicker
                clearable
                style={{ width: "6.5vw", paddingLeft: "1rem" }}
                value={date_from_last_attendance}
                name="date_from_last_attendance"
                allowKeyboardControl={false}
                placeholder="Selecione..."
                invalidDateMessage={React.createElement(
                  "span",
                  null,
                  "Data Inválida"
                )}
                clearLabel={React.createElement("span", null, "Limpar")}
                cancelLabel={React.createElement("span", null, "Cancelar")}
                onChange={(date) =>
                  this.handleDateChange("date_from_last_attendance", date)
                }
                maxDate={today}
                format="dd/MM/yyyy"
              />
            </div>

            <div style={{ paddingRight: "1vw" }}></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <StyledStartInputLabel>Fim</StyledStartInputLabel>
              <KeyboardDatePicker
                clearable
                value={date_to_last_attendance}
                name="date_to_last_attendance"
                style={{ width: "6.5vw", paddingLeft: "1rem" }}
                placeholder="Selecione..."
                allowKeyboardControl={false}
                minDateMessage={React.createElement(
                  "span",
                  null,
                  "Data Inferior à Data de Início"
                )}
                invalidDateMessage={React.createElement(
                  "span",
                  null,
                  "Data Inválida"
                )}
                clearLabel={React.createElement("span", null, "Limpar")}
                cancelLabel={React.createElement("span", null, "Cancelar")}
                onChange={(date) =>
                  this.handleDateChange("date_to_last_attendance", date)
                }
                minDate={date_from_last_attendance}
                maxDate={today}
                format="dd/MM/yyyy"
              />
            </div>
          </div>

          {this.showClearFilters() && !isLoading ? (
            <ClearText style={{ paddingTop: "16px" }} onClick={this.clearState}>
              Limpar filtros
            </ClearText>
          ) : null}
          <ButtonContainer>
            <StyledButton
              fullWidth
              onClick={this.submit}
              disabled={isLoading}
              disableRipple={true}
              variant="outlined"
              color="primary"
            >
              Buscar Atendimentos
            </StyledButton>
          </ButtonContainer>
        </Aside>
        <ChatbotDialog />
      </>
    );
  }
}

const mapStateToProps = ({
  filter,
  chip,
  page,
  chatbot,
  city,
  chatbot_customer,
  tenant,
}) => {
  return {
    filter,
    chip,
    page,
    chatbot,
    city,
    chatbot_customer,
    tenant,
  };
};

export default compose(
  connect(mapStateToProps, {
    ...toastrActions,
    ...TitleCreators,
    ...FilterCreators,
    ...ChatbotCreators,
    ...CityCreators,
    ...ChatbotCustomerCreators,
  })
)(ChatbotFilter);

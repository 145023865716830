import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_APIURL}/api`
});

api.interceptors.request.use((config) => {
  return new Promise(resolve => {
    const headers = { ...config.headers };

    Auth.currentSession().then(session => {

      if (session) {
        headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
      }

      resolve({ ...config, headers });
    }).catch(err => {
      console.error('[ERROR API]', err);
      resolve({ ...config, headers });
    })
  });
});

export default api;
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 90%;
  width: 100%;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-right: 0;
`;

export const ProductContainer = styled.div`
  margin: 2vh;
  width: 100%;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1vw;
`;

export const Header = styled.div`
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  line-height: 23px;
`;

export const ThemeText = styled.span`
  background-color: #0F86FC;
  color: #FFF;
  padding: .2vw;
`;

export const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CenterContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin: 10% auto;
`;
import styled from 'styled-components';
import { baseColorLight } from '../App/theme';

export const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid ${baseColorLight};
  box-sizing: border-box;
  border-radius: 3px;
  padding: .4rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  color: ${baseColorLight};
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    background-color: ${baseColorLight};
    color: #FFFFFF;
  }
`;
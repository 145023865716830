import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  recProductInit: [],
  recProductRequest: [],
  recProductSuccess: ['data'],
  recProductError: ['err'],
  getRecProduct: ['filter'],
  recProductSortBy: ['sort'],
  recProductExpand: ['expandedRow'],
  recProductInitTable: []
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  count: 0,
  sort: { cliente: { value: 'cliente', order: 'asc' } },
  error: false,
  loading: false,
  expandedRow: null
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { sort }) => state.merge({ sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.REC_PRODUCT_INIT]: init,
  [Types.REC_PRODUCT_REQUEST]: request,
  [Types.REC_PRODUCT_SUCCESS]: success,
  [Types.REC_PRODUCT_ERROR]: error,
  [Types.REC_PRODUCT_SORT_BY]: sortBy,
  [Types.REC_PRODUCT_EXPAND]: expand,
  [Types.REC_PRODUCT_INIT_TABLE]: initTable
});
import styled, { css } from 'styled-components';

export const ContactContainer = styled.div` 
    margin-bottom: 1.2vh;
    font-size: 1.4vh;
    margin-top: 1vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1vw;
  padding-top: 0;
`;

export const Header = styled.div`
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  line-height: 23px;
`;

export const CenterContainer = styled.div`
  color: #B9B9B9;
  display: flex;
  justify-content: center;
  margin-top: 20rem;
`;

export const ContactText = styled.span`
  padding: .2rem;

  ${props => props.action === 'first' && css`
    color: #714288;
    background-color: #F0E3F7;
  `}

  ${props => props.action === 'last' && css`
    color: #005EB8;
    background-color: #E6F3FF;
  `}
`

export const DetailContainer = styled.div`
  font-size: .9rem;
  margin-top: 1rem;
`;

export const DetailText = styled.div`
  margin-top: .5rem;
`;
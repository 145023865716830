import styled from 'styled-components';
import { Button, Accordion, InputLabel, Tooltip } from '@material-ui/core';
import { baseColor } from '../App/theme';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Text = styled.div`
  margin: 1rem;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
`;

export const StyledTooltip = styled(Tooltip)`
  font-size: 20px;
  line-height: 16px;
`;

export const SliderContainer = styled.div`
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderText = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin-top: 3vh;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 1vh;
  text-transform: none;
  font-size: 16px;
`;

export const StyledButton = styled(Button)`
  text-transform: none !important;
  font-size: 16px !important;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
`;

export const ClearText = styled.div`
  color: ${baseColor};
  font-size: 14px;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root:before: {
    backgroundColor: "white";
  }
`;

export const StyledEndInputLabel = styled(InputLabel)`
  margin-right: 15px;
  text-align: end;
  margin-top: 2px;
`;

export const StyledStartInputLabel = styled(InputLabel)`
  margin-left: 15px;
  text-align: left;
  margin-top: 2px;
`;

export const AccordionText = styled.div`
  color: ${baseColor};
  font-size: 14px;
  margin-left: 75px;
  &:hover {
    cursor: pointer;
  }
`;
import api from '../../services/api';
import { Creators } from '../ducks/customer';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getCustomer({ id }) {
  try {
    let response;

    yield put(Creators.customerRequest());
    response = yield call(api.post, 'customer', { id });

    yield put(Creators.customerSuccess(response.data));
    
  } catch (err) {
    yield captureSentryException(err, id);
    yield put(Creators.customerError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar cliente'
    }));
  }
}
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  customerInit: [],
  customerRequest: [],
  getCustomer: ['id'],
  customerSelect: ['selected'],
  customerSuccess: ['data'],
  customerError: ['err']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  selected: null,
  data: {},
  loading: false,
  error: false
});

/* Reducers */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const select = (state, { selected }) => state.merge({ selected });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CUSTOMER_INIT]: init,
  [Types.CUSTOMER_SELECT]: select,
  [Types.CUSTOMER_REQUEST]: request,
  [Types.CUSTOMER_SUCCESS]: success,
  [Types.CUSTOMER_ERROR]: error
});

export default () => ({
  tooltip: {
    position: 'absolute',
    margin: '8px',
    padding: '4px',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    maxWidth: '300px',
    fontSize: '1rem',
    zIindex: 9,
    pointerEvents: 'none'
  },
});

export const lineLayout = {
  visibility: 'visible'
};

export function linePaintBase(color) {
  return {
    'line-opacity': 1,
    'line-color': color || '#74D3F1'
  }
};

export const fillLayout = {
  visibility: 'visible'
}

export function getFillPaintBase(map) {
  let hasMapData = false;
  const data = { ...map };
  let stops =
    [[-1, '#e8e8e8'],
    [0, '#D6EBFF'],
    [1, '#85C3FF'],
    [2, '#52AAFF'],
    [3, '#0078EB'],
    [4, '#005EB8']];

  if (data && data.data && data.data.length) {
    hasMapData = true;
  }

  return {
    'fill-opacity': hasMapData ? .6 : 0.02,
    'fill-color': {
      property: 'density',
      stops: stops
    },
  }
};
import { primary } from '../App/theme';

import { drawerWidth } from '../App/App.styles';

export default (theme) => ({
  drawerPaper: {
    position: 'fixed',
    width: drawerWidth,
    height: '100%',

    color: '#fff',
    fontSize: 12,
    fontWeight: 300,
    padding: '0',

    backgroundColor: primary.dark, /* Old browsers */
  
  },

});
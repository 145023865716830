import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import * as jwt from "jsonwebtoken";
import { Container } from "./styles";
import { DASHBOARD_CHATBOT_INDICATORS, TODOS } from "../../constants";
import { getMetabaseDashIdByDashName } from "../../utils/schema";

class Metabase extends Component {

  isDashboardChatbotIndicators = (dashboardId, tenant) => {
    const dashIdChatbot = getMetabaseDashIdByDashName(
      DASHBOARD_CHATBOT_INDICATORS, tenant
    );

    if (dashboardId === dashIdChatbot) {
      return true;
    }

    return false;
  }

  render = () => {
    let token = null;
    let iframeUrl = null;
    const metabaseSiteUrl = process.env.REACT_APP_METABASE_SITE_URL;
    const metabaseSecretKey = process.env.REACT_APP_METABASE_SECRET_KEY;
    const dashboardId = this.props.dashboardId;
    const isByTenant = this.props.isByTenant === false ? false : true;
    const iframeHeight = this.props.iframeHeight || '100%'
    const isDashboardChatbotIndicators = this.isDashboardChatbotIndicators(dashboardId, this.props.tenant.tenant);

    const payload = {
      resource: { dashboard: dashboardId },
      params: {
        tenant: isByTenant ? this.props.tenant.tenant : undefined,
        date_from: isDashboardChatbotIndicators ? this.props.date_from : undefined,
        date_to: isDashboardChatbotIndicators ? this.props.date_to : undefined,
        user_type:
          isDashboardChatbotIndicators
            ? this.props.user_type !== TODOS
              ? this.props.user_type
              : undefined
            : undefined,
      },
      exp: Math.round(Date.now() / 1000) + 10 * 60,
    };
    token = jwt.sign(payload, metabaseSecretKey);
    iframeUrl =
      metabaseSiteUrl +
      "/embed/dashboard/" +
      token +
      "#bordered=false&titled=true&hide_parameters=user_type";

    return (
      <Container>
        <iframe
          title="metabase"
          src={iframeUrl}
          frameborder="0"
          width="100%"
          height={iframeHeight}
          allowtransparency
        ></iframe>
      </Container>
    );
  };
}

const mapStateToProps = ({ tenant }) => ({ tenant });

export default compose(connect(mapStateToProps, null))(Metabase);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import styles from './styles';
import {
  StyledHeader,
  StyledDialogContent,
  HeaderContainer
} from './styles';
import { withStyles, Dialog } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import Table from '../Table';
import { Creators as EventCreators } from '../../store/ducks/event';
import DownloadFile from "../DownloadFile";
import { EMPTY_EVENT, NIVEL1, CSV } from '../../constants';
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";

class RecEventDialog extends Component {

  state = {
    page: 1,
    isDownload: true,
    isOpenedDialog: false,
    exportType: CSV
  }

  onClose = () => { 
    this.setState({exportType: CSV});
    this.props.setDialog('event');
  }

  handleOpenDialog = () => { this.setState({ isOpenedDialog: !this.state.isOpenedDialog, isDownload: true })};

  handleDownload = () => this.submit({ is_download: true });

  handleExportType = (exportType) => {this.setState({exportType: exportType});}

  submit = ({ is_download }) => {
    const { data } = this.props.event;

    this.props.getOpenedEvents({
      product_id: data.items[0].produto,
      is_download: is_download || false,
      file_format: this.state.exportType
    });
  }

  render = () => {
    const { dialog, role } = this.props;
    const { data, defaultData, loading, error } = this.props.event;

    if (!dialog.event) {
      return null;
    }

    return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.onClose.bind(this)}
        open={dialog.event}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContent>
          {!loading ?
            <HeaderContainer style={{ display: 'flex', justifyContent: 'space-between', padding: 0, marginBottom: 0 }}>
              <StyledHeader>
                Lista de Eventos abertos
              </StyledHeader>
              {role === NIVEL1 || data.items.length === 0 ? null :
                <div style={{ padding: '20px' }}>
                  <DownloadFile
                    label='Baixar lista'
                    filename='recomendacao_de_eventos_por_cliente'
                    loading={loading}
                    header={data.header}
                    rows={data.items}
                    isDialogOpened={this.state.isOpenedDialog}
                    isDownload={this.state.isDownload}
                    handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                    handleDownload={this.handleDownload}
                    onSelectExportType={this.handleExportType}
                  />
                  <IconButton
                    mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                    onClick={() => this.handleOpenDialog()}
                    label={'Exportar'}
                    icon={<ExportIcon />}
                  />
                </div>}
            </HeaderContainer>
            : null}

          <Table
            data={data}
            error={error}
            loading={loading}
            defaultData={defaultData}
            emptyMessage={EMPTY_EVENT}
          />
        </StyledDialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ event, dialog }) => ({ event, dialog });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...EventCreators
  })
)(RecEventDialog);

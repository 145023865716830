import api from '../../services/api';
import { Creators } from '../ducks/rfb_partner';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getRfbPartners({ cnpj }) {
  try {

    yield put(Creators.rfbPartnerRequest());
    const response = yield call(api.get, `rfb-partner/${cnpj}`);

    yield put(Creators.rfbPartnerSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, cnpj);
    yield put(Creators.rfbPartnerError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar representantes da empresa'
    }));
  }
}
import React from 'react';
import { Card, CardTitle, CardValue } from './styles';

const CustomCard = ({ label, value, style }) => (
  <Card style={style}>
    <CardTitle>{label}</CardTitle>
    <CardValue>{value}</CardValue>
  </Card>
)


export default CustomCard;
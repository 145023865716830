import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  rfbPartnerInit: [],
  rfbPartnerRequest: [],
  getRfbPartners: ['cnpj'],
  rfbPartnerSuccess: ['data'],
  rfbPartnerError: ['err'],
  rfbPartnerSortBy: ['data', 'sort'],
  rfbPartnerInitTable: []
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  defaultData: [],
  sort: { nome_socio: { value: 'nome_socio', order: 'asc' } },
  error: false,
  loading: false
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, defaultData: data, error: false, loading: false, sort: INITIAL_STATE.sort });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) => state.merge({ data, sort });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.RFB_PARTNER_INIT]: init,
  [Types.RFB_PARTNER_REQUEST]: request,
  [Types.RFB_PARTNER_SUCCESS]: success,
  [Types.RFB_PARTNER_ERROR]: error,
  [Types.RFB_PARTNER_SORT_BY]: sortBy,
  [Types.RFB_PARTNER_INIT_TABLE]: initTable
});
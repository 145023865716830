import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { baseColor } from '../App/theme';
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as UserRegisterCreators } from "../../store/ducks/user_register";
import { Creators as TargetAudienceCreators } from "../../store/ducks/target_audience";
import { Creators as GithubApiCreators } from "../../store/ducks/github_api";
import { Creators as ChipCreators } from "../../store/ducks/chip";
import { Container, Content, VersionContainer } from "./styles";
import ChipGroup from "../ChipGroup";
import { getFeatures } from "../../utils/features";
import UserRegister from "../UserRegister";
import Chatbot from "../Chatbot";
import ChatbotConfig from "../ChatbotConfig";
import {
  SETTINGS_CHIP,
  USER_REGISTER,
  TARGET_AUDIENCE,
  CHATBOT,
  CHATBOT_CONFIG,
} from "../../constants";
import TargetAudience from "../TargetAudience";
import TargetAudienceFilter from "../TargetAudienceFilter";
import UserRegisterDialog from "../UserRegisterDialog";
import ChatbotFilter from "../ChatbotFilter";
import { StyledTooltip } from './styles';
import InfoIcon from 'react-feather/dist/icons/info';
import { CircularProgress, Typography } from '@material-ui/core';

const CONTEXT = "settings";

class Settings extends Component {
  componentDidMount() {
    const { chip } = this.props;
    this.props.getGithubApi();

    if (chip.settings === TARGET_AUDIENCE) {
      this.props.setChip("settings", TARGET_AUDIENCE);
      return;
    }
    if (chip.settings === CHATBOT) {
      this.props.setChip("settings", CHATBOT);
      return;
    }
    if (chip.settings === CHATBOT_CONFIG) {
      this.props.setChip("settings", CHATBOT_CONFIG);
      return;
    }
    this.props.setChip("settings", USER_REGISTER);
  }

  renderVersions() {
    const { github_api } = this.props
    let versions

    if (github_api.data.length !== 0) {
      versions = github_api.data.map(item =>
        <Typography color="inherit">{item.app_name}: <b>{item.version}</b></Typography>
      )
    }

    return (
      <React.Fragment>
        {versions}
      </React.Fragment>
    )
  }

  renderLoading = () => (
    <VersionContainer color={baseColor}>
      <CircularProgress color="primary" size={18} />
    </VersionContainer>
  )

  render() {
    const { chip, user_register, github_api } = this.props;
    const { tenant } = this.props.tenant;
    const chipOptions = getFeatures(tenant, SETTINGS_CHIP) || [];

    return (
      <>
        <Container>
          {chip.settings === TARGET_AUDIENCE ? (
            <TargetAudienceFilter
              context={CONTEXT}
              title="Gerenciar Público-Alvo"
            />
          ) : null}
          {chip.settings === CHATBOT ? (
            <ChatbotFilter context={CONTEXT} title="Atendimentos Chatbot" />
          ) : null}
          <Content>
            <ChipGroup
              isDisabled={user_register.loading || user_register.loading}
              data={chipOptions}
              title=""
              style={{
                justifyContent: "flex-end",
                paddingTop: "10px",
                paddingRight: "25px",
              }}
              context={CONTEXT}
            />

            {chip.settings === USER_REGISTER ? <UserRegister /> : null}
            {chip.settings === TARGET_AUDIENCE ? (
              <TargetAudience history={this.props.history} />
            ) : null}
            {chip.settings === CHATBOT ? (
              <Chatbot history={this.props.history} />
            ) : null}
            {chip.settings === CHATBOT_CONFIG ? (
              <ChatbotConfig history={this.props.history} />
            ) : null}
            {github_api.data.length > 0 ?
              <VersionContainer>Versões dos apps
                <StyledTooltip
                  placement="top"
                  title={this.renderVersions()}
                >
                  <span style={{ position: 'static', marginLeft: '0.5vh' }}>
                    <InfoIcon
                      color='#41A2FF'
                    />
                  </span>
                </StyledTooltip>
              </VersionContainer>
              : this.renderLoading() }
          </Content>
          <UserRegisterDialog tenant={tenant} />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({
  title,
  chip,
  filter,
  target_audience,
  tenant,
  user_register,
  github_api,
}) => {
  return {
    title,
    chip,
    filter,
    target_audience,
    tenant,
    user_register,
    github_api,
  };
};

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...TargetAudienceCreators,
    ...UserRegisterCreators,
    ...FilterCreators,
    ...ChipCreators,
    ...GithubApiCreators
  })
)(Settings);

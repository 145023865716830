import Drawer from '@material-ui/core/Drawer';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import darkTheme from '../App/darkTheme';
import Links from '../Links/Links';
import RootRef from '@material-ui/core/RootRef';
import styles from './LeftDrawer.styles';

class LeftDrawer extends Component {

  render() {
    const { classes, open, openSide, drawerRef } = this.props;
    return (
      <MuiThemeProvider theme={darkTheme}>
        <RootRef rootRef={drawerRef}>
          <Drawer
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <Links clickOpen={openSide} open={open} />
          </Drawer>
        </RootRef>
      </MuiThemeProvider>
    );
  }
}

export default compose(
  withStyles(styles),
  withTheme
)(LeftDrawer);
import {
  RECSYS,
  TIMELINE,
  HISTORIC,
  REC_CUSTOMER_EVENT,
  REC_CUSTOMER_CONTENT,
  SEBRAE_PB,
  SEBRAE_RN,
  SEBRAE_MA,
  DASHBOARD_GENERAL_INDICATORS,
  DASHBOARD_USE_APP_INDICATORS,
  DASHBOARD_APP_SEBRAE,
  ADMIN,
  SEBRAE_PE,
  SEBRAE_MS,
  SEBRAE_GO,
  USER_REGISTER,
  TARGET_AUDIENCE,
  DASHBOARD_PERSONA_INDICATORS,
  CHATBOT,
  CHATBOT_CONFIG,
  DASHBOARD_CHATBOT_INDICATORS,
  SETTINGS_CHIP,
  SIMILARITY,
  DASHBOARD_CHIP,
  SEBRAE_ES,
} from "../constants";

export const getFeatures = (tenant, feature) => {
  const defaultFeatures = {
    CUSTOMER_CHIP: [
      { value: RECSYS, label: "Recomendação de produtos" },
      { value: REC_CUSTOMER_EVENT, label: "Recomendação de eventos" },
      { value: REC_CUSTOMER_CONTENT, label: "Recomendação de conteúdos", isDisabled: true },
      { value: TIMELINE, label: "Jornada" },
    ],
    PRODUCT_CHIP: [
      { value: HISTORIC, label: "Análise Histórica" },
      { value: RECSYS, label: "Recomendação" },
      { value: SIMILARITY, label: "Similaridade" },
    ],
    MARKET_CHIP: [
      { value: HISTORIC, label: "Análise Histórica" },
      { value: RECSYS, label: "Recomendação" },
    ],
    DASHBOARD_CHIP: [
      { value: DASHBOARD_GENERAL_INDICATORS, label: "Indicadores Gerais" },
      {
        value: DASHBOARD_USE_APP_INDICATORS,
        label: "Indicadores de Uso do Minerador",
        role: ADMIN,
      },
      {
        value: DASHBOARD_PERSONA_INDICATORS,
        label: "Indicadores de Persona",
        role: ADMIN,
        isDisabled: true,
      },
      {
        value: DASHBOARD_APP_SEBRAE,
        label: "Indicadores App Sebrae",
        role: ADMIN,
        isDisabled: true,
      },
    ],
    SETTINGS_CHIP: [
      { value: USER_REGISTER, label: "Gerenciar Usuários" },
      { value: TARGET_AUDIENCE, label: "Gerenciar Público-Alvo" },
    ],
  };

  const features = {
    [SEBRAE_RN]: { ...defaultFeatures },
    [SEBRAE_PB]: { ...defaultFeatures },
    [SEBRAE_MA]: { ...defaultFeatures },
    [SEBRAE_PE]: {
      ...defaultFeatures,
      [SETTINGS_CHIP]: [
        { value: USER_REGISTER, label: "Gerenciar Usuários" },
        { value: TARGET_AUDIENCE, label: "Gerenciar Público-Alvo" },
        { value: CHATBOT, label: "Atendimentos Chatbot" },
        { value: CHATBOT_CONFIG, label: "Customização Chatbot" },
      ],
      [DASHBOARD_CHIP]: [
        ...defaultFeatures[DASHBOARD_CHIP],
        {
          value: DASHBOARD_CHATBOT_INDICATORS,
          label: "Indicadores do Chatbot",
          role: ADMIN,
        },
      ],
    },
    [SEBRAE_MS]: { ...defaultFeatures },
    [SEBRAE_GO]: { ...defaultFeatures },
    [SEBRAE_ES]: {
      ...defaultFeatures,
      [SETTINGS_CHIP]: [
        { value: USER_REGISTER, label: "Gerenciar Usuários" },
        { value: TARGET_AUDIENCE, label: "Gerenciar Público-Alvo" },
        { value: CHATBOT, label: "Atendimentos Chatbot" },
        { value: CHATBOT_CONFIG, label: "Customização Chatbot" },
      ],
      [DASHBOARD_CHIP]: [
        ...defaultFeatures[DASHBOARD_CHIP],
        {
          value: DASHBOARD_CHATBOT_INDICATORS,
          label: "Indicadores do Chatbot",
          role: ADMIN,
        },
      ],
    },
    default: { ...defaultFeatures },
  };

  if (!tenant || !feature) return;

  return features[tenant][feature];
};

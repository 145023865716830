import api from '../../services/api';
import { Creators } from '../ducks/target_audience_per_product';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getTargetAudiencePerProduct({ filter }) {
  try {
    let response; 

    yield put(Creators.targetAudiencePerProductRequest());
    response = yield call(api.post, 'target-audience-per-product', filter);
    
    yield put(Creators.targetAudiencePerProductSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.targetAudiencePerProductError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar setores'
    }));
  }
} 
import { all, takeLatest, takeLeading, takeEvery } from "redux-saga/effects";

import { Types as MapTypes } from "../ducks/map";
import { Types as YearTypes } from "../ducks/year";
import { Types as CityTypes } from "../ducks/city";
import { Types as TenantTypes } from "../ducks/tenant";
import { Types as ProductTypes } from "../ducks/product";
import { Types as CustomerTypes } from "../ducks/customer";
import { Types as TimelineTypes } from "../ducks/timeline";
import { Types as InstrumentTypes } from "../ducks/instrument";
import { Types as PieChartTypes } from "../ducks/pie_chart";
import { Types as BarChartTypes } from "../ducks/bar_chart";
import { Types as TreeMapMarketTypes } from "../ducks/treemap_market";
import { Types as ProductDetailTypes } from "../ducks/product_detail";
import { Types as TreeMapProductTypes } from "../ducks/treemap_product";
import { Types as CustomerDetailTypes } from "../ducks/customer_detail";
import { Types as RecCustomerProductTypes } from "../ducks/rec_customer_product";
import { Types as RecProductTypes } from "../ducks/rec_product";
import { Types as RecMarketTypes } from "../ducks/rec_market";
import { Types as RecPersonaInfoTypes } from "../ducks/persona_info";
import { Types as DashboardTypes } from "../ducks/dashboard";
import { Types as SectorTypes } from "../ducks/sector";
import { Types as PortTypes } from "../ducks/port";
import { Types as RfbFilterTypes } from "../ducks/rfb_filter";
import { Types as RfbEnterpriseTypes } from "../ducks/rfb_enterprise";
import { Types as RfbPartnerTypes } from "../ducks/rfb_partner";
import { Types as CrmTypes } from "../ducks/crm";
import { Types as TenantConfigTypes } from "../ducks/tenant_config";
import { Types as UserRegister } from "../ducks/user_register";
import { Types as RecCustomerEventTypes } from "../ducks/rec_customer_event";
import { Types as eventTypes } from "../ducks/event";
import { Types as DateEventTypes } from "../ducks/date_event";
import { Types as TargetAudienceTypes } from "../ducks/target_audience";
import { Types as UsersGroupsTypes } from "../ducks/users_groups";
import { Types as ChatbotTypes } from "../ducks/chatbot";
import { Types as RecCustomerContentTypes } from "../ducks/rec_customer_content";
import { Types as ChatbotDetailsTypes } from "../ducks/chatbot_details";
import { Types as ChatbotConfigTypes } from "../ducks/chatbot_config";
import { Types as NotifyEtlTypes } from "../ducks/notify_etl";
import { Types as ThemeTypes } from "../ducks/theme";
import { Types as RecProductSimilarityTypes } from "../ducks/rec_product_similarity";
import { Types as RegionalUnitTypes } from "../ducks/regional_unit";
import { Types as GithubApiTypes } from "../ducks/github_api";
import { Types as RecProductIndicatorsTypes } from "../ducks/rec_product_indicators";
import { Types as RecSimilarityIndicatorsTypes } from "../ducks/rec_similarity_indicators";
import { Types as ContactTypeTypes } from "../ducks/contact_type";
import { Types as PubAlvoTypes } from "../ducks/pub_alvo";
import { Types as MacrossegmentTypes } from "../ducks/macrossegment";
import { Types as TargetAudiencePerProductTypes } from "../ducks/target_audience_per_product";

import { getMap } from "./map";
import { getYears } from "./year";
import { getCities } from "./city";
import { setTenant } from "./tenant";
import { getProducts } from "./product";
import { getCustomer } from "./customer";
import { getTimeline } from "./timeline";
import { getInstruments } from "./instrument";
import { getPieChart } from "./pie_chart";
import { getBarChart } from "./bar_chart";
import { getTreeMapMarket } from "./treemap_market";
import { getProductsDetail } from "./product_detail";
import { getCustomersDetail } from "./customer_detail";
import { getTreeMapProduct } from "./treemap_product";
import { getRecCustomerProduct } from "./rec_customer_product";
import { getRecProduct } from "./rec_product";
import { getRecMarket } from "./rec_market";
import { getPersonaInfo } from "./persona_info";
import { getDashboard } from "./dashboard";
import { getSectors } from "./sector";
import { getPorts } from "./port";
import { getRfbFilters } from "./rfb_filter";
import { getRfbEnterprises } from "./rfb_enterprise";
import { getRfbPartners } from "./rfb_partner";
import { postCrm } from "./crm";
import { getTenantConfig } from "./tenant_config";
import { addUsers } from "./user_register";
import { getUsers } from "./user_register";
import { reSendEmail } from "./user_register";
import { deleteUsers } from "./user_register";
import { getRecCustomerEvent } from "./rec_customer_event";
import { getOpenedEvents } from "./event";
import { getDateEvents } from "./date_event";
import { getTargetAudience, updateTargetAudience } from "./target_audience";
import { getUsersGroups } from "./users_groups";
import { editPermission } from "./user_register";
import { getChatbot } from "./chatbot";
import { getRecCustomerContent } from "./rec_customer_content";
import { getChatbotDetails } from "./chatbot_details";
import { getChatbotConfig, putChatbotConfig } from "./chatbot_config";
import { getNotifyEtl, putNotifyEtl } from "./notify_etl";
import { getThemes } from "./theme";
import { getRecProductSimilarity } from "./rec_product_similarity";
import { getRegionalUnits } from "./regional_unit";
import { getGithubApi } from "./github_api";
import { getRecProductIndicators } from "./rec_product_indicators";
import { getRecSimilarityIndicators } from "./rec_similarity_indicators";
import { getContactTypes } from "./contact_type";
import { getPubAlvos } from "./pub_alvo";
import { getMacrossegments } from "./macrossegment";
import { getTargetAudiencePerProduct } from "./target_audience_per_product";

export default function* rootSaga() {
  return yield all([
    takeLatest(MapTypes.GET_MAP, getMap),
    takeLatest(YearTypes.GET_YEARS, getYears),
    takeLatest(CityTypes.GET_CITIES, getCities),
    takeLatest(ProductTypes.GET_PRODUCTS, getProducts),
    takeLatest(CustomerTypes.GET_CUSTOMER, getCustomer),
    takeLatest(TimelineTypes.GET_TIMELINE, getTimeline),
    takeLatest(InstrumentTypes.GET_INSTRUMENTS, getInstruments),
    takeLatest(CustomerDetailTypes.GET_CUSTOMERS_DETAIL, getCustomersDetail),
    takeLatest(ProductDetailTypes.GET_PRODUCTS_DETAIL, getProductsDetail),
    takeLatest(PieChartTypes.GET_PIE_CHART, getPieChart),
    takeLatest(BarChartTypes.GET_BAR_CHART, getBarChart),
    takeLatest(TreeMapMarketTypes.GET_TREE_MAP_MARKET, getTreeMapMarket),
    takeLatest(TreeMapProductTypes.GET_TREE_MAP_PRODUCT, getTreeMapProduct),
    takeLeading(TenantTypes.SET_TENANT, setTenant),
    takeLatest(
      RecCustomerProductTypes.GET_REC_CUSTOMER_PRODUCT,
      getRecCustomerProduct
    ),
    takeLatest(RecProductTypes.GET_REC_PRODUCT, getRecProduct),
    takeLatest(RecMarketTypes.GET_REC_MARKET, getRecMarket),
    takeLatest(RecPersonaInfoTypes.GET_PERSONA_INFO, getPersonaInfo),
    takeLatest(DashboardTypes.GET_DASHBOARD, getDashboard),
    takeLatest(SectorTypes.GET_SECTORS, getSectors),
    takeLatest(PortTypes.GET_PORTS, getPorts),
    takeEvery(RfbFilterTypes.GET_RFB_FILTERS, getRfbFilters),
    takeLatest(RfbEnterpriseTypes.GET_RFB_ENTERPRISES, getRfbEnterprises),
    takeLatest(RfbPartnerTypes.GET_RFB_PARTNERS, getRfbPartners),
    takeLatest(CrmTypes.POST_CRM, postCrm),
    takeLatest(TenantConfigTypes.GET_TENANT_CONFIG, getTenantConfig),
    takeLatest(UserRegister.ADD_USERS, addUsers),
    takeLatest(UserRegister.GET_USERS, getUsers),
    takeLatest(UserRegister.RE_SEND_EMAIL, reSendEmail),
    takeLatest(UserRegister.DELETE_USERS, deleteUsers),
    takeLatest(
      RecCustomerEventTypes.GET_REC_CUSTOMER_EVENT,
      getRecCustomerEvent
    ),
    takeLatest(eventTypes.GET_OPENED_EVENTS, getOpenedEvents),
    takeLatest(DateEventTypes.GET_DATE_EVENTS, getDateEvents),
    takeLatest(TargetAudienceTypes.GET_TARGET_AUDIENCE, getTargetAudience),
    takeLatest(
      TargetAudienceTypes.UPDATE_TARGET_AUDIENCE,
      updateTargetAudience
    ),
    takeLatest(UsersGroupsTypes.GET_USERS_GROUPS, getUsersGroups),
    takeLatest(UserRegister.EDIT_PERMISSION, editPermission),
    takeLatest(ChatbotTypes.GET_CHATBOT, getChatbot),
    takeLatest(
      RecCustomerContentTypes.GET_REC_CUSTOMER_CONTENT,
      getRecCustomerContent
    ),
    takeLatest(ChatbotDetailsTypes.GET_CHATBOT_DETAILS, getChatbotDetails),
    takeLatest(ChatbotConfigTypes.GET_CHATBOT_CONFIG, getChatbotConfig),
    takeLatest(ChatbotConfigTypes.PUT_CHATBOT_CONFIG, putChatbotConfig),
    takeLatest(NotifyEtlTypes.GET_NOTIFY_ETL, getNotifyEtl),
    takeLatest(NotifyEtlTypes.PUT_NOTIFY_ETL, putNotifyEtl),
    takeLatest(ThemeTypes.GET_THEMES, getThemes),
    takeLatest(
      RecProductSimilarityTypes.GET_REC_PRODUCT_SIMILARITY,
      getRecProductSimilarity
    ),
    takeLatest(RegionalUnitTypes.GET_REGIONAL_UNITS, getRegionalUnits),
    takeLatest(GithubApiTypes.GET_GITHUB_API, getGithubApi),
    takeLatest(
      RecProductIndicatorsTypes.GET_REC_PRODUCT_INDICATORS,
      getRecProductIndicators
    ),
    takeLatest(
      RecSimilarityIndicatorsTypes.GET_REC_SIMILARITY_INDICATORS,
      getRecSimilarityIndicators
    ),
    takeLatest(ContactTypeTypes.GET_CONTACT_TYPES, getContactTypes),
    takeLatest(PubAlvoTypes.GET_PUB_ALVOS, getPubAlvos),
    takeLatest(MacrossegmentTypes.GET_MACROSSEGMENTS, getMacrossegments),
    takeLatest(TargetAudiencePerProductTypes.GET_TARGET_AUDIENCE_PER_PRODUCT, getTargetAudiencePerProduct),
  ]);
}

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import styles, { Text } from './styles';
import { withStyles, Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as TreeMapMarketCreators } from '../../store/ducks/treemap_market';
import { Creators as TreeMapProductCreators } from '../../store/ducks/treemap_product';
import { Creators as FilterCreators } from '../../store/ducks/filter';
import { selectStyle } from '../App/theme';
import Select from 'react-select';
import { actions as toastrActions } from 'react-redux-toastr';
import { getTreemapConfigSchema } from '../../utils/schema';
import { HISTORIC } from '../../constants';

class TreeMapDialog extends Component {

  state = {
    parent: null,
    child: null
  }

  componentWillReceiveProps() {
    const { parent, child } = this.props[`treemap_${this.props.context}`];

    this.setState({ parent, child });
  }

  renderWarningMsg = (msg) => {
    this.props.add({
      type: 'warning',
      title: 'Atenção',
      message: msg
    });
  }

  onClose = () => {
    this.props.setDialog('treemap');
  }

  onSubmit = () => {
    const { chip, context } = this.props;
    const { parent, child } = this.state;
    const { filter } = this.props.filter;
    const treemapFilter = { ...filter, parent: parent.value, child: child.value, type: context };

    if (!parent) {
      this.renderWarningMsg('Selecione a hierarquia pai.');
      return;
    }

    if (!child) {
      this.renderWarningMsg('Selecione a hierarquia filha.');
      return;
    }

    if (parent.value === child.value) {
      this.renderWarningMsg('As hierarquias não podem ser iguais.');
      return;
    }

    if (chip.product === HISTORIC) {
      this.props.getTreeMapProduct(treemapFilter);
      this.props.setTreeMapProductConfig('parent', parent);
      this.props.setTreeMapProductConfig('child', child);
    }

    if (chip.market === HISTORIC) {
      this.props.getTreeMapMarket(treemapFilter);
      this.props.setTreeMapMarketConfig('parent', parent);
      this.props.setTreeMapMarketConfig('child', child);
    }

    this.props.setDialog('treemap');
  }

  renderSelect = ({ name, data, placeholder }) => (
    <Select
      isClearable
      value={this.state[name]}
      onChange={(e) => this.handleChange(e, name)}
      placeholder={placeholder}
      styles={selectStyle}
      options={data} />
  )

  handleChange = (item, name) => this.setState({ [name]: item });

  render = () => {
    const fields = getTreemapConfigSchema(this.props.context);
    const { dialog, classes } = this.props;

    if (!dialog.treemap) {
      return null;
    }

    return (
      <Dialog
        onClose={this.onClose.bind(this)}
        open={dialog.treemap}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ height: '55vh' }}>
          <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '1.2rem' }}>
            Configuração do Tree Map
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <div style={{ paddingTop: '1rem', width: '100%' }}>
              <Text>Hierarquia Pai</Text>
              {this.renderSelect({ name: 'parent', data: fields, placeholder: 'Pai' })}
            </div>

            <div style={{ paddingTop: '1rem', width: '100%' }}>
              <Text>Hierarquia Filha</Text>
              {this.renderSelect({ name: 'child', data: fields, placeholder: 'Filha' })}
            </div>
          </div>

        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            style={{ textTransform: 'none', width: '100%' }}
            onClick={this.onSubmit}
            disableRipple={true}
            variant="outlined"
            color="primary">
            Aplicar Configurações
        </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ dialog, treemap_market, filter, chip, treemap_product }) => {
  return ({ dialog, treemap_market, filter, chip, treemap_product });
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators, ...toastrActions,
    ...TreeMapMarketCreators, ...FilterCreators,
    ...TreeMapProductCreators
  })
)(TreeMapDialog);

import styled, { css } from 'styled-components';
import { baseColor } from '../App/theme';
import { TYPE_BUTTON, TYPE_LINK } from '../../utils/schema';

export const StyledTable = styled.table`
  color: black;
  width: 100%;
  border-spacing: 0;
  font-size: 12px;
  padding-top: .5vh;

  tr:nth-child(even) {
    background-color: rgba(249, 249, 249, 0.94);
  }

  ${props => props.hasDetail === true && css`
      tr {
        cursor: pointer;
      }
  `}

  thead, thead th, thead td {
    cursor: pointer;
    position: sticky;
    top: 0;
    background: #FFF;
  }

  tfoot, tfoot th, tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    font-weight: bold;
    padding: .5rem;
    background: rgba(249, 249, 249, 0.94);
  }

  thead {
    cursor: pointer;
  }
`;

export const HeaderColumn = styled.td`
  padding: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: bold;

  ${props => props.isIcon && css`
    padding-left: 0;
  `}
  
  ${props => props.align && css`
    text-align: ${props.align};
  `}

  &:hover {
    ${props => props.isSort && css`
      text-decoration: underline;
    `}

    ${props => !props.isSort && css`
      cursor: default;
    `}
  }
`;

export const ItemColumn = styled.td`
  padding: 1rem;
  padding-bottom: .5rem;
  padding-top: .5rem;
  max-width: 8.5vw;
  overflow: hidden;

  ${props => props.isIcon && css`
    padding-left: 0;
    cursor: pointer;
  `}

  ${props => props.align && css`
    text-align: ${props.align};
  `}

  ${props => props.color && css`
    color: ${props.color};
  `}

  ${props => props.type === TYPE_LINK && css`
    color: ${baseColor};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}

  ${props => props.type === TYPE_BUTTON && css`
    color: ${baseColor};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}

  ${props => props.isFirst === true && css`
    padding-top: .5rem;
  `}

  ${props => props.isLast === true && css`
    padding-bottom: .5rem;
  `}
`;

export const DetailText = styled.div`
  font-size: 14px;
  line-height: 21px;
`;

export const RowDetail = styled.tr`
  background-color: #EEE;
  width: 100%;
  padding-left: 2rem;
  > td {
    vertical-align: top;
    &:hover {
      cursor: default;
    }
  }
`;

export const Separator = styled.div`
  border-top: 1.5px dashed #000;
  opacity: .5;
`;

export const CenterContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin: 10% auto;
`;

export const RowDetailHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2vh;
`;
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as constants from './constants/routes';
import Search from './components/Search';
import Customer from './components/Customer';
import Product from './components/Product';
import Market from './components/Market';
import RouteRedirector from './components/RouteRedirector';
import Dashboard from './components/Dashboard';
import Leads from './components/Leads';
import Prospects from './components/Prospects';
import Settings from './components/Settings';
import Chat from './components/Chat';

class Routes extends Component {

  render() {
    return (
      <Switch>
        <Route exact from={constants.SEARCH} component={Search} />
        <Route exact from={constants.CUSTOMER} component={Customer} />
        <Route exact from={constants.PRODUCT} component={Product} />
        <Route exact from={constants.PRODUCT_TERM} component={Product} />
        <Route exact from={constants.MARKET} component={Market} />
        <Route exact from={constants.DASHBOARD} component={Dashboard} />
        <Route exact from={constants.LEADS} component={Leads} />
        <Route exact from={constants.PROSPECTS} component={Prospects} />
        <Route exact from={constants.SETTINGS} component={Settings} />
        <Route exact from={constants.REDIRECT} component={RouteRedirector} />
        <Route exact from={constants.CHAT} component={Chat} />
        <Redirect exact from={constants.ROOT} to={constants.REDIRECT} />
        <Route component={RouteRedirector} />
      </Switch>
    );
  }

}


export default Routes;
export const FILTER_CITY = 'city';
export const FILTER_NEIGHBORHOOD = 'neighborhood';
export const FILTER_SECTION = 'section';
export const FILTER_CNAE = 'cnae';
export const FILTER_PORT = 'port';
export const FILTER_REGIONAL_UNIT = 'regional_unit'

export const SELECT_FIELDS = [FILTER_CITY, FILTER_NEIGHBORHOOD, FILTER_SECTION, FILTER_CNAE, FILTER_PORT, FILTER_REGIONAL_UNIT];

export const getFilter = (state) => {
  let filter = {};

  SELECT_FIELDS.forEach(field => {
    filter[field] = state[field]?.length ? state[field].map(item => item.value) : null;
  });

  Object.keys(state.client).forEach(key => {
    if (state.client[key]) {

      if (!filter.client) {
        filter.client = {};
      }

      filter.client[key] = true;
    }
  });

  return filter;
}
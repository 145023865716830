import React, { Component } from 'react';
import { Container } from './styles';

export default class Header extends Component {
  render() {
    const { icon, title, size, color, onClick } = this.props;

    return (
      <Container
        onClick={onClick}
        isHover={onClick ? true : false}
        size={size}
        color={color}>
        {icon}
        <span>{title}</span>
      </Container>
    );
  }
}

import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import styles, { MicroText } from "./styles";
import {
  Container,
  CenterContainer,
  HeaderContainer,
  InfoText,
  HeaderText,
  CardGroup,
  Card4,
  Card3,
  NumberIndicator4,
  CardHeader,
  CardDescription,
  CardText,
  InfoContainer,
  InfoTextContainer,
  QuestionsCardText,
} from "./styles";
import { withStyles, Dialog, CircularProgress } from "@material-ui/core";
import { Creators as DialogCreators } from "../../store/ducks/dialog";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import MapIcon from "react-feather/dist/icons/map-pin";
import MoneyIcon from "react-feather/dist/icons/dollar-sign";
import ClockIcon from "react-feather/dist/icons/clock";
import CalendarIcon from "react-feather/dist/icons/calendar";
import { cpfCnpjMask } from "../../utils/index";
import ReactMarkdown from 'react-markdown';

const ProductItem = (props) => {
  return (
    <Card3 style={{ marginRight: "20px" }}>
      <CardHeader>{props.item.type}</CardHeader>
      <CardDescription>{props.item.title}</CardDescription>
      <InfoContainer>
        <InfoTextContainer>
          <MapIcon color="#0AC1A7" />
          <CardText>{props.item.location}</CardText>
        </InfoTextContainer>
      </InfoContainer>
      <InfoContainer>
        <InfoTextContainer>
          <MoneyIcon color="#0AC1A7" />
          <CardText>{props.item.price}</CardText>
        </InfoTextContainer>
      </InfoContainer>
      <InfoContainer>
        <InfoTextContainer>
          <ClockIcon color="#0AC1A7" />
          <CardText>{props.item.time}</CardText>
        </InfoTextContainer>
      </InfoContainer>
      <InfoContainer>
        {props.item.date ? (
          <InfoTextContainer style={{ paddingBottom: "1rem" }}>
            <CalendarIcon color="#0AC1A7" />
            <CardText>{props.item.date}</CardText>
          </InfoTextContainer>
        ) : null}
      </InfoContainer>
    </Card3>
  );
};

class ChatbotDialog extends Component {
  getFirstCardRow(chatbot_details) {
    return (
      <CardGroup>
        <Card4>
          <HeaderText>Data do atendimento</HeaderText>
          <NumberIndicator4>{chatbot_details.date}</NumberIndicator4>
        </Card4>

        <Card4>
          <HeaderText>Hora do atendimento</HeaderText>
          <NumberIndicator4>{chatbot_details.hour}</NumberIndicator4>
        </Card4>

        <Card4>
          <HeaderText>Município</HeaderText>

          <NumberIndicator4>
            {chatbot_details.city ? chatbot_details.city : "N/A"}
          </NumberIndicator4>
        </Card4>

        <Card4>
          <HeaderText>NPS</HeaderText>

          <NumberIndicator4>
            {chatbot_details.service_rating
              ? chatbot_details.service_rating
              : "N/A"}
          </NumberIndicator4>
        </Card4>
      </CardGroup>
    );
  }

  getPromptCardRow(chatbot_details) {
    return (
      <CardGroup style={{ justifyContent: "space-around" }}>
        <Card3>
          <HeaderText>
            Pergunta
            <br />
          </HeaderText>
          <QuestionsCardText>{chatbot_details.promptinput}</QuestionsCardText>
        </Card3>

        <Card3>
          <HeaderText>
            Resposta
            <br />
          </HeaderText>
          <QuestionsCardText>
            {chatbot_details?.promptoutput ? (
              <div>
                <ReactMarkdown
                  components={{
                    a: ({ node, children, ...props }) => (
                      <a target="_blank" rel="noopener noreferrer" {...props}>
                        {children}
                      </a>
                    )
                  }}
                >
                  {chatbot_details?.promptoutput}
                </ReactMarkdown>
              </div>
            )
              : chatbot_details?.products[0]?.resume}
          </QuestionsCardText>
        </Card3>
      </CardGroup>
    );
  }
  getQuestionsCardRow(chatbot_details) {
    let i = 1;
    if (chatbot_details.promptinput) {
      return this.getPromptCardRow(chatbot_details)
    }
    if (!chatbot_details.questions || !chatbot_details.questions.length) return;
    return (
      <CardGroup style={{ justifyContent: "space-around" }}>
        <Card3>
          <HeaderText>
            Pergunta {i++}
            <br />
            {chatbot_details.questions[0]}
          </HeaderText>
          <QuestionsCardText>{chatbot_details.profile}</QuestionsCardText>
          <MicroText>
            {chatbot_details.profile_percentages}% das pessoas atendidas
            escolheram esta resposta
          </MicroText>
        </Card3>

        {chatbot_details.business_areas.length ? (
          <Card3>
            <HeaderText>
              Pergunta {i++}
              <br />
              {chatbot_details.questions[1]}
            </HeaderText>
            <QuestionsCardText>
              {chatbot_details.business_areas[0].name}
            </QuestionsCardText>
            <MicroText>
              {chatbot_details.business_areas[0].percentage}% das pessoas
              atendidas escolheram esta resposta
            </MicroText>
          </Card3>
        ) : null}

        <Card3>
          <HeaderText>
            Pergunta {i++}
            <br />
            {chatbot_details.questions[2]}
          </HeaderText>
          <QuestionsCardText>
            {chatbot_details.themes[0].name}
          </QuestionsCardText>
        </Card3>
      </CardGroup>
    );
  }

  getProductsCardRow(chatbot_details) {
    return (
      <CardGroup>
        {chatbot_details.products.map((product) => (
          <ProductItem key={product.title} item={product}></ProductItem>
        ))}
      </CardGroup>
    );
  }

  onClose = () => {
    this.setState(this.INITIAL_STATE);
    this.props.setDialog("chatbot");
  };

  render() {
    const { dialog, chatbot_details } = this.props;

    if (!dialog.chatbot) {
      return null;
    }

    return (
      <>
        <Dialog
          onClose={this.onClose.bind(this)}
          open={dialog.chatbot}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="false"
        >
          <Container>
            <HeaderContainer>Detalhes do atendimento</HeaderContainer>
            {chatbot_details.loading ? (
              <CenterContainer>
                <CircularProgress color="primary" />
              </CenterContainer>
            ) : chatbot_details.data.cpf ? (
              <>
                <b
                  style={{
                    fontSize: "20px",
                  }}
                >
                  {chatbot_details.data.name}
                </b>
                <div style={{ display: "flex" }}>
                  <InfoText>
                    <b>CPF/CNPJ: </b> {cpfCnpjMask(chatbot_details.data.cpf)}
                  </InfoText>
                  <InfoText>
                    <b>Email: </b> {chatbot_details.data.email}
                  </InfoText>
                  <InfoText>
                    <b>Consentimento LGPD: </b>{" "}
                    {chatbot_details.data.is_agree_use_term ? "Sim" : "Não"}
                  </InfoText>
                </div>

                {this.getFirstCardRow(chatbot_details.data)}

                {this.getQuestionsCardRow(chatbot_details.data)}

                {chatbot_details.data.products.length ? (
                  <HeaderContainer>Recomendações</HeaderContainer>
                ) : null}

                {this.getProductsCardRow(chatbot_details.data)}
              </>
            ) : (
              <CenterContainer>Sem dados para exibir</CenterContainer>
            )}
          </Container>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ dialog, filter, chatbot_details }) => ({
  dialog,
  filter,
  chatbot_details,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...FilterCreators,
  })
)(ChatbotDialog);

import { Title, Container, SearchContainer, FormContainer, LogoContainer } from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { Creators as CustomerCreators } from '../../store/ducks/customer';
import UsersIcon from 'react-feather/dist/icons/users';
import Header from '../Header';
import Button from '../../styles/Button';
import CustomerSelect from '../CustomerSelect';
import logo from '../../assets/logo_blue.png';
import { Mixpanel } from '../../utils/mixpanel';

class Search extends Component {

  componentDidMount() {
    this.props.setTitle('Busca');
    Mixpanel.track('INIT_SEARCH');
  }

  handleSubmit = () => {
    const { customer } = this.props;

    if (!customer.selected) return;

    this.props.history.push(`/customer/${customer.selected.value}`);
  }

  render() {
    const { customer } = this.props;

    return (
      <Fragment>

        <Header icon={<UsersIcon />} title='Cliente' />

        <Container>
          <LogoContainer>
            <img width="8%" src={logo} alt=""></img>
          </LogoContainer>

          <SearchContainer>
            <Title>Ver dados de clientes</Title>

            <FormContainer>
              <CustomerSelect placeholder='Digite CPF / CNPJ, DAP ou Nome Completo' />
              <Button color={customer.selected ? 'default' : 'gray'} onClick={this.handleSubmit.bind(this)}>Buscar</Button>
            </FormContainer>

          </SearchContainer>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ title, customer }) => ({ title, customer });

export default compose(
  connect(mapStateToProps, { ...TitleCreators, ...CustomerCreators, })
)(Search);
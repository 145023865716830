import { primary, defaultBackground } from './theme';

export const drawerWidth = 60;
export const closedMargin = 20;


export default (theme) => ({

  hiddenDrawerBD: {
    backgroundColor: primary.main
  },

  root: {
    flexGrow: 1,
  },

  appFrame: {
    zIndex: 1,
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1300,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: defaultBackground,
  },

  contentBody: {
    flex: '1',
    minHeight: 'calc(100% - 200px)',
    backgroundColor: defaultBackground,
  },


  /**
   * Show/Hide drawer behavior
   */

  // Default main area (dashboard) margin-left 
  contentLeft: {
    marginLeft: closedMargin,
  },

  contentShaddow: {
    'box-shadow': '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'
  },

  // Margin when drawer is shown
  contentShiftLeft: {
    marginLeft: drawerWidth,
    boxShadow: "none",
  },

  // No Margin with fullscreen enabled
  contentShiftLeftHidden: {
    marginLeft: 0,
    boxShadow: "none",
  },

  // Animation behavior
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    opacity: .5,
    zIndex: 9999,
    cursor: 'pointer'
  },
  importLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 99999
  },
});

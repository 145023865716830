import styled, { css } from 'styled-components';
import { baseColor } from '../App/theme';
import { TYPE_LINK } from '../../utils/schema';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const HeaderText = styled.span`
  color: ${baseColor};
  font-size: .7rem;
  text-transform: uppercase;
`;

export const HeaderTextBold = styled.span`
  color: ${baseColor};
  font-weight: bold;
  font-size: .6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Text = styled.span`
  font-size: .9rem;

  ${props => props.type === TYPE_LINK && css`
    color: ${baseColor};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  `}
`;

export const TextBold = styled.span`
  font-weight: bold;
  font-size: .9rem;
`;

export const TabsContainer = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem;
  font-size: 12px;
`;

export const TabItem = styled.div`
  width: 50%;
  text-align: center;
  padding-bottom: 1vh;
  color: #AEAEAE;

  ${props => props.active === true && css`
      border-bottom: 2px solid ${baseColor};
      color: ${baseColor};
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderTextContainer = styled.div`
  margin: 1rem;
  margin-top: 1rem;
  margin-bottom: .2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: .5rem;
  overflow: hidden;
  word-break: break-word;
`;

export const Separator = styled.div`
  margin: 1rem;
  border-top: 1px dashed #464646;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #C9CED3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 1vw;  
  padding: .7vw;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
  align-items: center;
`;

export const CardTitle = styled.div`
  font-size: 14px;
`;

export const CardValue = styled.div`
  font-size: 14px;
  padding-top: .5vh;
`;
export default () => ({
  controlPanel: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 0.85)',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    margin: '.5rem',
    marginRight: '.5rem',
    fontSize: '13px',
    lineHeight: 2,
    color: '#6b6b76',
    outline: 'none'
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
    textTransform: 'none',
    // paddingLeft: '1rem'
  },
  text: {
    fontSize: '18px',
    lineHeight: '23px',
    color: '#707070'
  }
})


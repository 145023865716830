import store from '../../store';
import api from '../../services/api';
import { Creators } from '../ducks/rec_customer_product';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { downloadFromURL } from '../../utils';
import { captureSentryException } from '../../utils';

export function* getRecCustomerProduct({ data }) {
  try {

    yield put(Creators.recCustomerProductRequest());
    const response = yield call(api.post, 'rec-customer-product', { data });

    if (data && data.is_download && response.data.url) {
      downloadFromURL(response.data.url);
      yield put(Creators.recCustomerProductSuccess(store.getState().rec_customer_product.data));
    } else {
      yield put(Creators.recCustomerProductTopRecommendations(response.data.items));
      yield put(Creators.recCustomerProductSuccess(response.data));
    }
  } catch (err) {
    yield captureSentryException(err, data);
    yield put(Creators.recCustomerProductError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar recomendação de produtos'
    }));
  }
}
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  SelectContainer, Text, SliderContainer,
  SliderText, ButtonContainer, LoadingContainer,
  StyledButton, ActionsContainer, ClearText
} from './styles';
import ProductIcon from 'react-feather/dist/icons/flag';
import MarketIcon from 'react-feather/dist/icons/map-pin';
import Header from '../Header';
import { selectStyle, baseColor } from '../App/theme';
import Select from 'react-select';
import { Aside } from '../../styles/Aside';
import Slider from '@material-ui/core/Slider';
import { compose } from 'redux';
import { Creators as InstrumentCreators } from '../../store/ducks/instrument';
import { Creators as RegionalUnitCreators } from '../../store/ducks/regional_unit';
import { Creators as ProductCreators } from '../../store/ducks/product';
import { Creators as YearCreators } from '../../store/ducks/year';
import { Creators as CityCreators } from '../../store/ducks/city';
import { Creators as SectorCreators } from '../../store/ducks/sector';
import { Creators as PortCreators } from '../../store/ducks/port';
import { Creators as ThemeCreators } from '../../store/ducks/theme';
import { actions as toastrActions } from 'react-redux-toastr';
import { Creators as CustomerDetailCreators } from '../../store/ducks/customer_detail';
import { Creators as MapCreators } from '../../store/ducks/map';
import { Creators as TitleCreators } from '../../store/ducks/title';
import { Creators as TreeMapMarketCreators } from '../../store/ducks/treemap_market';
import { Creators as FilterCreators } from '../../store/ducks/filter';
import { CircularProgress } from '@material-ui/core';
import {
  RECSYS,
  HISTORIC,
  ITEMS_PER_PAGE,
  FILTER_CITY,
  FILTER_PRODUCT,
  FILTER_INSTRUMENT,
  FILTER_SECTOR,
  FILTER_PORT,
  FILTER_REGIONAL_UNIT,
  FILTER_THEME,
} from "../../constants";
import { getFilterValues, getFilterRules } from "../../utils";


class AnalysisFilter extends Component {

  INITIAL_STATE = {
    [HISTORIC]: {
      city: null,
      sector: null,
      port: null,
      regional_unit: null,
      instrument: null,
      product: null
    },
    [RECSYS]: {
      city: null,
      sector: null,
      port: null,
      regional_unit: null,
      product: null,
      instrument: null,
      app_client:null,
      theme: null,
    },
    page: 1
  }

  state = this.INITIAL_STATE

  componentDidMount() {
    this.refreshFilters("")
  }

  clearState = () => {
    const context = this.props.chip[this.props.context];

    this.setState({
      [context]: { ...this.INITIAL_STATE[context] }
    })
  };

  showClearFilters = () => {
    let isClear = false;
    const context = this.props.chip[this.props.context];

    if (!this.state[context]) {
      return false;
    };

    Object.keys(this.state[context]).forEach(key => {
      const item = this.state[context][key];

      if (item) {
        isClear = true;
        return;
      }
    });

    return isClear;
  }

  renderSelect = ({ name, data, placeholder, isLoading, isMulti }) => (
    <SelectContainer>
      <Select
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading}
        value={this.state[this.props.chip[this.props.context]][name]}
        noOptionsMessage={() => 'Sem dados'}
        onChange={(e) => this.handleChange(e, name)}
        placeholder={isLoading ? 'Carregando...' : placeholder}
        styles={selectStyle}
        options={data} />
    </SelectContainer>
  )

  handleChangeYear = (e, value) => this.props.yearSelect({ min: value[0], max: value[1] });

  handleChange = (item, name) => {
    const { chip, context } = this.props;
    const newData = { ...this.state[chip[context]], [name]: item };

    this.setState({ [chip[context]]: newData }, () => this.refreshFilters(name))
  };


  refreshFilters = (name) => {
    const { chip, context } = this.props;
    const filter = this.getFilter();
    const filterRules = getFilterRules(this.props, context, chip[context], name);

    if (!filterRules || !filterRules.length) {
      return;
    }

    filterRules.forEach((fun) => {
      fun(filter);
    });
  }

  renderWarningMsg = (msg) => {
    this.props.add({
      type: 'warning',
      title: 'Atenção',
      message: msg
    });
  }

  renderLoading = () => (
    <LoadingContainer color={baseColor}>
      <CircularProgress color="primary" size={24} />
    </LoadingContainer>
  )

  getFilter = () => {
    const { chip, context, year } = this.props;
    const { instrument, product, city, sector, port, regional_unit, app_product, theme } = this.state[chip[context]];

    const filter = {
      instrument: getFilterValues(instrument),
      productid: getFilterValues(product),
      city: getFilterValues(city),
      year: year && chip[context] !== RECSYS ? year.selected : { min: null, max: null },
      sector: getFilterValues(sector),
      port:  getFilterValues(port),
      regional_unit: getFilterValues(regional_unit),
      theme: getFilterValues(theme),
      chip: chip[context],
      context:context,
      app_client: app_product ? app_product.value : null,
      page: 1,
      items_per_page: ITEMS_PER_PAGE
    };

    return filter;
  }

  submit = () => {
    const { chip, context } = this.props;
    const filter = this.getFilter();
    const { parent, child } = this.props[`treemap_${context}`];
    const treemapFilter = { ...filter, parent: parent.value, child: child.value, type: context };

    if (chip.market === HISTORIC) {
      this.props.customerDetailInitTable();
      this.props.getTreeMapMarket(treemapFilter);
      this.props.getCustomersDetail(filter);
    }

    if (chip.market === RECSYS) {
      this.props.getMap(filter, 'rec-market-map');
    }

    this.props.setFilter(filter);
  }

  getTitleIcon = () => {
    let icon = <ProductIcon />;

    if (this.props.context === 'market') {
      icon = <MarketIcon />
    }

    return icon;
  }

  render() {
    const { chip, context } = this.props;
    const baseRangeYear = this.props.year.data;
    const changedYear = this.props.year.selected;
    const { instrument, product, city, year, sector, port, regional_unit, theme } = this.props;
    const isYearLoading = chip[context] !== RECSYS && year.loading ? true : false;
    const isLoading = instrument.loading || product.loading || city.loading || sector.loading || port.loading || regional_unit.loading || isYearLoading || theme.loading;
    const isHistoric = chip[context] === HISTORIC ? true : false;

    return (
      <Aside>
        <Header icon={this.getTitleIcon()} title={this.props.title} />

        {context === 'market' && chip.market === RECSYS ? <Text>Unidade Regional do Cliente</Text> : <Text>Unidade Regional</Text>}
        {this.renderSelect({
          name: FILTER_REGIONAL_UNIT,
          data: regional_unit[chip[context]].asMutable(),
          placeholder: 'Todas as Unid. Regionais',
          isLoading: regional_unit.loading,
          isMulti: true
        })}

        {context === 'market' && chip.market === RECSYS ? <Text>Município do Cliente</Text> : <Text>Município</Text>}
        {this.renderSelect({
          name: FILTER_CITY,
          data: city[chip[context]].asMutable(),
          placeholder: 'Todas as cidades',
          isLoading: city.loading,
          isMulti: true
        })}

        <Text>Setor</Text>
        {this.renderSelect({
          name: FILTER_SECTOR,
          data: sector[chip[context]].asMutable(),
          placeholder: 'Todos os setores',
          isLoading: sector.loading,
          isMulti: true
        })}

        <Text>Porte</Text>
        {this.renderSelect({
          name: FILTER_PORT,
          data: port[chip[context]].asMutable(),
          placeholder: 'Todos os portes',
          isLoading: port.loading,
          isMulti: true
        })}

        <Text>Instrumento</Text>
        {this.renderSelect({
          name: FILTER_INSTRUMENT,
          data: instrument[chip[context]].asMutable(),
          placeholder: 'Todos os instrumentos',
          isLoading: instrument.loading,
          isMulti: true
        })}

        <Text>Produtos</Text>
        {this.renderSelect({
          name: FILTER_PRODUCT,
          data: product[chip[context]].asMutable(),
          placeholder: 'Todos os produtos',
          isLoading: product.loading,
          isMulti: true
        })}

        {context === 'market' && chip.market === RECSYS ?
          <>
            <Text>Tema</Text>
            {this.renderSelect({
              name: FILTER_THEME,
              data: theme[chip[context]].asMutable(),
              placeholder: 'Todos os temas',
              isLoading: theme.loading,
              isMulti: true
            })}
          </> : null}

        {isHistoric ?
          !year.loading ?
            <>
              <Text style={{ marginTop: '2rem' }}>Ano</Text>

              {changedYear && changedYear.min && changedYear.max ?
                <SliderContainer>
                  <SliderText style={{ paddingRight: '.5rem' }}>{changedYear.min}</SliderText>
                  <Slider
                    min={baseRangeYear.min}
                    max={baseRangeYear.max}
                    value={[changedYear.min, changedYear.max]}
                    onChange={this.handleChangeYear}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />
                  <SliderText style={{ paddingLeft: '.5rem' }}>{changedYear.max}</SliderText>
                </SliderContainer>
                : null}
            </>
            : this.renderLoading()
          : null}

        <ActionsContainer>
          {this.showClearFilters() && !isLoading ?
            <ClearText onClick={this.clearState}>Limpar filtros</ClearText>
            : null}
          <ButtonContainer>
            <StyledButton
              fullWidth
              onClick={this.submit}
              disabled={isLoading}
              disableRipple={true}
              variant="outlined"
              color="primary">
              {isHistoric ? 'Gerar análise' : 'Gerar recomendação'}
            </StyledButton>
          </ButtonContainer>
        </ActionsContainer>


      </Aside >
    );
  }
}

const mapStateToProps = ({ instrument, product, year, city, filter, treemap_market, 
  chip, treemap_product, sector, port, regional_unit, page, app_product, theme }) => {
  return ({ instrument, product, year, city, filter, treemap_market, 
    chip, treemap_product, sector, port, regional_unit, page, app_product, theme })
};

export default compose(
  connect(mapStateToProps, {
    ...InstrumentCreators, ...ProductCreators,
    ...YearCreators, ...CityCreators,
    ...toastrActions, ...MapCreators, ...TitleCreators,
    ...TreeMapMarketCreators, ...FilterCreators,
    ...CustomerDetailCreators, ...SectorCreators,
    ...PortCreators, ...RegionalUnitCreators,
    ...ThemeCreators,
  })
)(AnalysisFilter);
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  rfbFilterInit: ['field'],
  rfbFilterSelect: ['field', 'data'],
  rfbFilterRequest: ['field', 'filter'],
  getRfbFilters: ['filter', 'field'],
  rfbFilterSuccess: ['data', 'field'],
  rfbFilterError: ['err', 'field']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  loading: {},
  error: {},
  selecteds: {
    client: {}
  }
});

/* Reducers */

export const init = (state, { field }) => {
  return state.merge({
    data: {
      ...state.data,
      [field]: []
    },
    selecteds: { ...INITIAL_STATE.selecteds }
  })
};

export const select = (state, { field, data }) => {
  return state.merge({
    selecteds: {
      ...state.selecteds,
      [field]: data
    },
  })
};

export const request = (state, { field }) => {
  return state.merge({
    loading: {
      ...state.loading,
      [field]: true
    }
  })
};

export const success = (state, { data, field }) => {
  return state.merge({
    data: {
      ...state.data,
      [field]: data
    },
    loading: {
      ...state.loading,
      [field]: false
    },
    error: {
      ...state.error,
      [field]: false
    }
  })
};

export const error = (state, { field }) => {
  return state.merge({
    loading: {
      ...state.loading,
      [field]: false
    },
    error: {
      ...state.error,
      [field]: true
    }
  })
};

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.RFB_FILTER_INIT]: init,
  [Types.RFB_FILTER_SELECT]: select,
  [Types.RFB_FILTER_REQUEST]: request,
  [Types.RFB_FILTER_SUCCESS]: success,
  [Types.RFB_FILTER_ERROR]: error
});

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  treeMapMarketInit: [],
  treeMapMarketRequest: [],
  treeMapMarketSuccess: ['data'],
  treeMapMarketError: ['err'],
  getTreeMapMarket: ['filter'],
  setTreeMapMarketConfig: ['field', 'value']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */
const INITIAL_STATE = Immutable({
  data: {},
  parent: { label: 'Instrumento', value: 'instrumento' },
  child: { label: 'Porte', value: 'porte' },
  loading: false,
  error: false
});

/* Reducers TreeMap */

export const init = state => state.merge({ data: [] });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const config = (state, { field, value }) => state.merge({ [field]: value });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.TREE_MAP_MARKET_INIT]: init,
  [Types.TREE_MAP_MARKET_REQUEST]: request,
  [Types.TREE_MAP_MARKET_SUCCESS]: success,
  [Types.TREE_MAP_MARKET_ERROR]: error,
  [Types.SET_TREE_MAP_MARKET_CONFIG]: config
});
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  SelectContainer,
  Text,
  SliderContainer,
  SliderText,
  ButtonContainer,
  LoadingContainer,
  StyledButton,
  ActionsContainer,
  ClearText,
  StyledStartInputLabel,
  StyledEndInputLabel,
  StyledAccordion,
  AccordionText,
  CheckboxContainer,
  StyledTooltip,
} from "./styles";
import ProductIcon from "react-feather/dist/icons/flag";
import Header from "../Header";
import { selectStyle, baseColor } from "../App/theme";
import Select from "react-select";
import { Aside } from "../../styles/Aside";
import Slider from "@material-ui/core/Slider";
import { compose } from "redux";
import { Creators as InstrumentCreators } from "../../store/ducks/instrument";
import { Creators as RegionalUnitCreators } from "../../store/ducks/regional_unit";
import { Creators as ProductCreators } from "../../store/ducks/product";
import { Creators as YearCreators } from "../../store/ducks/year";
import { Creators as CityCreators } from "../../store/ducks/city";
import { Creators as SectorCreators } from "../../store/ducks/sector";
import { Creators as PortCreators } from "../../store/ducks/port";
import { actions as toastrActions } from "react-redux-toastr";
import { Creators as ProductDetailCreators } from "../../store/ducks/product_detail";
import { Creators as CustomerDetailCreators } from "../../store/ducks/customer_detail";
import { Creators as RecProductCreators } from "../../store/ducks/rec_product";
import { Creators as RecProductIndicatorsCreators } from "../../store/ducks/rec_product_indicators";
import { Creators as RecProducSimilaritytCreators } from "../../store/ducks/rec_product_similarity";
import { Creators as RecSimilarityIndicatorsCreators } from "../../store/ducks/rec_similarity_indicators";
import { Creators as ContactTypeCreators } from "../../store/ducks/contact_type";
import { Creators as MapCreators } from "../../store/ducks/map";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as TreeMapProductCreators } from "../../store/ducks/treemap_product";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as ThemeCreators } from "../../store/ducks/theme";
import {
  CircularProgress,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  RECSYS,
  HISTORIC,
  ITEMS_PER_PAGE,
  SIMILARITY,
  FILTER_CITY,
  FILTER_PRODUCT,
  FILTER_INSTRUMENT,
  FILTER_SECTOR,
  FILTER_PORT,
  FILTER_REGIONAL_UNIT,
  FILTER_CONTACT_TYPE,
  FILTER_THEME,
} from "../../constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getDateYYMMDDDD, isValidRangeDate, getFilterValues, getFilterRules, getDefaultDate } from "../../utils";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "react-feather/dist/icons/help-circle";

class ProductFilter extends Component {
  INITIAL_STATE = {
    [HISTORIC]: {
      city: null,
      sector: null,
      port: null,
      instrument: null,
      product: null,
      app_product: null,
      regional_unit: null,
    },
    [RECSYS]: {
      city: null,
      sector: null,
      port: null,
      product: null,
      instrument: null,
      date_from_last_attendance: null,
      date_to_last_attendance: null,
      accept_lgpd: true,
      pending_lgpd: true,
      refuse_lgpd: false,
      app_client: null,
      regional_unit: null,
      contact_type: null,
      theme: null,
    },
    [SIMILARITY]: {
      city: null,
      sector: null,
      port: null,
      product: null,
      instrument: null,
      date_from_last_attendance: null,
      date_to_last_attendance: null,
      accept_lgpd: true,
      pending_lgpd: true,
      refuse_lgpd: false,
      app_client: null,
      regional_unit: null,
      contact_type: null,
      theme: null,
    },
    today: getDefaultDate({ delta: 0 }),
    page: 1,
    isExpanded: false,
    ranking: 10,
  };

  state = this.INITIAL_STATE;

  componentDidMount() {
    const { productid, product, city_customer } = this.props.match.params;
    let cities = [];
    //checks url for rec market redirect parameters
    const isRedirectFromMarket = productid && product;

    city_customer && city_customer.split(",").map((item)=> 
      cities.push({ label:item, value:item})
    )

    if (isRedirectFromMarket) {
      const marketFilter = {
        product: [{ label:product, value:productid}],
        city: cities.length === 0 ? null : cities,
      };

      const newFilter = {
        ...this.state[RECSYS],
        ...marketFilter,
      };

      this.setState({ [RECSYS]: newFilter }, () => {
        this.submit()
        this.refreshFilters()
      });
    }

    
    this.refreshFilters()
  }

  clearState = () => {
    const context = this.props.chip[this.props.context];

    this.setState({
      [context]: { ...this.INITIAL_STATE[context] },
      ranking: 10,
    });
  };

  showClearFilters = () => {
    let isClear = false;
    const context = this.props.chip[this.props.context];

    if (!this.state[context]) {
      return false;
    }

    Object.keys(this.state[context]).forEach((key) => {
      const item = this.state[context][key];

      if (item && key !== "accept_lgpd" && item && key !== "pending_lgpd") {
        isClear = true;
        return;
      }
    });

    return isClear;
  };

  renderSelect = ({ name, data, placeholder, isLoading, isMulti }) => (
    <SelectContainer>
      <Select
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading}
        value={this.state[this.props.chip[this.props.context]][name]}
        noOptionsMessage={() => "Sem dados"}
        onChange={(e) => this.handleChange(e, name)}
        placeholder={isLoading ? "Carregando..." : placeholder}
        styles={selectStyle}
        options={data}
      />
    </SelectContainer>
  );

  handleChangeYear = (e, value) =>
    this.props.yearSelect({ min: value[0], max: value[1] });

  handleChangeRanking = (e, value) => {
    this.setState({ ranking: value });
  };

  handleChange = (item, name) => {
    const { chip, context } = this.props;
    const newData = { ...this.state[chip[context]], [name]: item };

    this.setState({ [chip[context]]: newData }, () =>
      this.refreshFilters(name)
    );
  };

  refreshFilters = (name=null) => {
    const { chip, context } = this.props;
    const filter = this.getFilter();
    const filterRules = getFilterRules(this.props, context, chip[context], name);

    if (!filterRules || !filterRules.length) {
      return;
    }

    filterRules.forEach((fun) => {
      fun(filter);
    });
  };

  renderWarningMsg = (msg) => {
    this.props.add({
      type: "warning",
      title: "Atenção",
      message: msg,
    });
  };

  renderErrorMsg = (msg) => {
    this.props.add({
      type: "error",
      title: "Erro",
      message: msg,
    });
  };

  renderLoading = () => (
    <LoadingContainer color={baseColor}>
      <CircularProgress color="primary" size={24} />
    </LoadingContainer>
  );

  getFilter = () => {
    const { chip, context, year } = this.props;
    const {
      instrument,
      product,
      city,
      sector,
      port,
      regional_unit,
      date_from_last_attendance,
      date_to_last_attendance,
      app_product,
      contact_type,
      theme,
    } = this.state[chip[context]];
    const { ranking } = this.state;
    const status_lgpd = this.getStatusLGPD();
    const date_from = date_from_last_attendance ? getDateYYMMDDDD(date_from_last_attendance) : null;
    const date_to = date_to_last_attendance ? getDateYYMMDDDD(date_to_last_attendance) : null;


    //the product field is kept in the filter so it appears in the table title
    //but only productid is used for filtering in the backend
    const filter = {
      instrument: getFilterValues(instrument),
      productid:
        product && chip[context] === SIMILARITY
          ? product.value
          : getFilterValues(product),
      product:
            product && chip[context] === SIMILARITY
              ? product.label
              : product?.length? product.map((item) => item.label): null,
      city: getFilterValues(city),
      year:
        year && chip[context] !== RECSYS && chip[context] !== SIMILARITY
          ? year.selected
          : { min: null, max: null },
      sector: getFilterValues(sector),
      port: getFilterValues(port),
      regional_unit: getFilterValues(regional_unit),
      contact_type: getFilterValues(contact_type),
      theme: getFilterValues(theme),
      status_lgpd:
        status_lgpd && status_lgpd.length && chip[context] !== HISTORIC
          ? status_lgpd
          : null,
      date_last_attendance:
        date_from && date_to && chip[context] !== HISTORIC
          ? { date_from: date_from, date_to: date_to }
          : { date_from: null, date_to: null },
      chip: chip[context],
      context:context,
      app_client: app_product?.value,
      page: 1,
      items_per_page: ITEMS_PER_PAGE,
      ranking: ranking,
    };

    return filter;
  };

  handleChangeAccordion = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  getStatusLGPD = () => {
    const { chip, context } = this.props;
    const { accept_lgpd, refuse_lgpd, pending_lgpd } =
      this.state[chip[context]];
    let status_lgpd = [];

    if (accept_lgpd) {
      status_lgpd = [...status_lgpd, "Consentiu"];
    }

    if (refuse_lgpd) {
      status_lgpd = [...status_lgpd, "Não consentiu"];
    }

    if (pending_lgpd) {
      status_lgpd = [...status_lgpd, "Em aberto"];
    }

    return status_lgpd;
  };

  handleDateChange = (name, value) => {
    const { chip, context } = this.props;
    const newData = { ...this.state[chip[context]], [name]: value };

    this.setState({ [chip[context]]: newData });
  };

  submit = () => {
    const { chip, context } = this.props;
    const filter = this.getFilter();
    const { date_from_last_attendance, date_to_last_attendance } =
      this.state[chip[context]];
    const { parent, child } = this.props[`treemap_${context}`];
    const treemapFilter = {
      ...filter,
      parent: parent.value,
      child: child.value,
      type: context,
    };

    if (chip.product === HISTORIC) {
      this.props.productDetailInitTable();
      this.props.getTreeMapProduct(treemapFilter);
      this.props.getProductsDetail(filter);
      this.props.getMap(filter, "map");
    }

    if (chip.product === RECSYS) {
      if (!filter.productid) {
        this.renderWarningMsg("Selecione ao menos um produto!");
        return;
      }
      if (
        (date_from_last_attendance || date_to_last_attendance) &&
        !isValidRangeDate(date_from_last_attendance, date_to_last_attendance)
      ) {
        this.renderErrorMsg(
          "Período informado de data do último atendimento inválido"
        );
        return;
      }

      this.props.getRecProduct({
        ...filter,
        page: 1,
        items_per_page: ITEMS_PER_PAGE,
      });
      this.props.getRecProductIndicators({
        ...filter,
        page: 1,
        items_per_page: ITEMS_PER_PAGE,
      });
    }

    if (chip.product === SIMILARITY) {
      if (!filter.productid) {
        this.renderWarningMsg("Selecione ao menos um produto!");
        return;
      }

      this.props.getRecProductSimilarity({
        ...filter,
        page: 1,
        items_per_page: ITEMS_PER_PAGE,
      });
      this.props.getRecSimilarityIndicators({
        ...filter,
        page: 1,
        items_per_page: ITEMS_PER_PAGE,
      });
    }

    this.props.setFilter(filter);
  };

  getTitleIcon = () => {
    let icon = <ProductIcon />;

    return icon;
  };

  handleCheckbox = (event) => {
    const { chip, context } = this.props;
    const newData = {
      ...this.state[chip[context]],
      [event.target.name]: event.target.checked,
    };

    this.setState({ [chip[context]]: newData });
  };

  handleDescription = (event) => {
    const newData = {
      ...this.state[SIMILARITY],
      [event.target.id]: event.target.value,
    };

    this.setState({ [SIMILARITY]: newData });
  };

  render() {
    const { chip, context } = this.props;
    const baseRangeYear = this.props.year.data;
    const changedYear = this.props.year.selected;
    const {
      instrument,
      product,
      city,
      year,
      sector,
      port,
      theme,
      regional_unit,
      contact_type,
    } = this.props;
    const isYearLoading =
      chip[context] !== RECSYS && year.loading ? true : false;
    const isLoading =
      instrument.loading ||
      product.loading ||
      city.loading ||
      sector.loading ||
      port.loading ||
      theme.loading ||
      regional_unit.loading ||
      contact_type.loading ||
      isYearLoading;
    const isHistoric = chip[context] === HISTORIC ? true : false;
    const isSimilarity = chip[context] === SIMILARITY ? true : false;
    const isRecsys = chip[context] === RECSYS;
    const { isExpanded, today } = this.state;
    const {
      date_from_last_attendance,
      date_to_last_attendance,
      accept_lgpd,
      refuse_lgpd,
      pending_lgpd,
    } = this.state[chip[context]];

    return (
      <Aside>
        <Header icon={this.getTitleIcon()} title={this.props.title} />
        {isRecsys ? (
          <>
            <Text>Produtos</Text>
            {this.renderSelect({
              name: FILTER_PRODUCT,
              data: product[chip[context]].asMutable(),
              placeholder: "Todos os produtos",
              isLoading: product.loading,
              isMulti: true,
            })}
            <StyledEndInputLabel>obrigatório</StyledEndInputLabel>

            <Text>Unidade Regional</Text>
            {this.renderSelect({
              name: FILTER_REGIONAL_UNIT,
              data: regional_unit[chip[context]].asMutable(),
              placeholder: "Todas as Unid. Regionais",
              isLoading: regional_unit.loading,
              isMulti: true,
            })}

            <Text>Município</Text>
            {this.renderSelect({
              name: FILTER_CITY,
              data: city[chip[context]].asMutable(),
              placeholder: "Todas as cidades",
              isLoading: city.loading,
              isMulti: true,
            })}
          </>
        ) : null}
        {isSimilarity ? (
          <>
            <Text>Produtos</Text>
            {this.renderSelect({
              name: FILTER_PRODUCT,
              data: product[chip[context]].asMutable(),
              placeholder: "Todos os produtos",
              isLoading: product.loading,
              isMulti: false,
            })}
            <StyledEndInputLabel>obrigatório</StyledEndInputLabel>

            <Text>Unidade Regional</Text>
            {this.renderSelect({
              name: FILTER_REGIONAL_UNIT,
              data: regional_unit[chip[context]].asMutable(),
              placeholder: "Todas as Unid. Regionais",
              isLoading: regional_unit.loading,
              isMulti: true,
            })}

            <Text>Município do Cliente</Text>
            {this.renderSelect({
              name: FILTER_CITY,
              data: city[chip[context]].asMutable(),
              placeholder: "Todas as cidades",
              isLoading: city.loading,
              isMulti: true,
            })}
          </>
        ) : null}
        {isHistoric ? (
          <>
            <Text>Produtos</Text>
            {this.renderSelect({
              name: FILTER_PRODUCT,
              data: product[chip[context]].asMutable(),
              placeholder: "Todos os produtos",
              isLoading: product.loading,
              isMulti: true,
            })}
            <Text>Unidade Regional</Text>
            {this.renderSelect({
              name: FILTER_REGIONAL_UNIT,
              data: regional_unit[chip[context]].asMutable(),
              placeholder: "Todas as Unid. Regionais",
              isLoading: regional_unit.loading,
              isMulti: true,
            })}
            <Text>Município</Text>
            {this.renderSelect({
              name: FILTER_CITY,
              data: city[chip[context]].asMutable(),
              placeholder: "Todas as cidades",
              isLoading: city.loading,
              isMulti: true,
            })}
          </>
        ) : null}

        <ActionsContainer>
          <StyledAccordion
            elevation={0}
            expanded={isExpanded}
            onChange={() => this.handleChangeAccordion()}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary"></ExpandMoreIcon>}
            >
              <AccordionText>
                {isExpanded
                  ? "Ocultar filtros avançados"
                  : "Mostrar filtros avançados"}
              </AccordionText>
            </AccordionSummary>
            <Text>Setor</Text>
            {this.renderSelect({
              name: FILTER_SECTOR,
              data: sector[chip[context]].asMutable(),
              placeholder: "Todos os setores",
              isLoading: sector.loading,
              isMulti: true,
            })}

            <Text>Porte</Text>
            {this.renderSelect({
              name: FILTER_PORT,
              data: port[chip[context]].asMutable(),
              placeholder: "Todos os portes",
              isLoading: port.loading,
              isMulti: true,
            })}

            <Text>Instrumento</Text>
            {this.renderSelect({
              name: FILTER_INSTRUMENT,
              data: instrument[chip[context]].asMutable(),
              placeholder: "Todos os instrumentos",
              isLoading: instrument.loading,
              isMulti: true,
            })}

            {isHistoric ? (
              !year.loading ? (
                <>
                  <Text style={{ marginTop: "2rem" }}>Ano</Text>

                  {changedYear && changedYear.min && changedYear.max ? (
                    <SliderContainer>
                      <SliderText style={{ paddingRight: ".5rem" }}>
                        {changedYear.min}
                      </SliderText>
                      <Slider
                        min={baseRangeYear.min}
                        max={baseRangeYear.max}
                        value={[changedYear.min, changedYear.max]}
                        onChange={this.handleChangeYear}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                      />
                      <SliderText style={{ paddingLeft: ".5rem" }}>
                        {changedYear.max}
                      </SliderText>
                    </SliderContainer>
                  ) : null}
                </>
              ) : (
                this.renderLoading()
              )
            ) : (
              <>
                <Text>Tipo de Contato</Text>
                {this.renderSelect({
                  name: FILTER_CONTACT_TYPE,
                  data: contact_type[chip[context]].asMutable(),
                  placeholder: "Todos os tipos",
                  isLoading: contact_type.loading,
                  isMulti: true,
                })}
                <Text>Tema</Text>
                {this.renderSelect({
                  name: FILTER_THEME,
                  data: theme[chip[context]].asMutable(),
                  placeholder: "Todos os temas",
                  isLoading: theme.loading,
                  isMulti: true,
                })}
                <Text>Consentimento LGPD</Text>
                <CheckboxContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accept_lgpd}
                        onChange={(e) => this.handleCheckbox(e)}
                        name="accept_lgpd"
                      />
                    }
                    label="Consentiu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={refuse_lgpd}
                        onChange={(e) => this.handleCheckbox(e)}
                        name="refuse_lgpd"
                      />
                    }
                    label="Não Consentiu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pending_lgpd}
                        onChange={(e) => this.handleCheckbox(e)}
                        name="pending_lgpd"
                      />
                    }
                    label="Em aberto"
                  />
                </CheckboxContainer>
                {!isSimilarity ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Ranking recomendações</Text>
                      <StyledTooltip
                        placement="right-start"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              Ex.: Filtrar pelo nº 5 recomenda somente os
                              clientes que tem o produto entre seu top 5
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <span style={{ marginRight: "5vw" }}>
                          <HelpIcon color="#41A2FF" />
                        </span>
                      </StyledTooltip>
                    </div>
                    <SliderContainer>
                      <SliderText style={{ paddingRight: ".5rem" }}>
                        1
                      </SliderText>
                      <Slider
                        min={1}
                        max={10}
                        step={1}
                        marks
                        value={this.state.ranking}
                        defaultValue={10}
                        valueLabelDisplay="auto"
                        aria-labelledby="discrete-slider-small-steps"
                        onChange={this.handleChangeRanking}
                      />
                      <SliderText style={{ paddingLeft: ".5rem" }}>
                        10
                      </SliderText>
                    </SliderContainer>
                  </>
                ) : null}

                <Text>Data do último atendimento</Text>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledStartInputLabel>Início</StyledStartInputLabel>
                    <KeyboardDatePicker
                      clearable
                      style={{ width: "6.5vw", paddingLeft: "1rem" }}
                      value={date_from_last_attendance}
                      name="date_from_last_attendance"
                      allowKeyboardControl={false}
                      placeholder="Selecione..."
                      invalidDateMessage={React.createElement(
                        "span",
                        null,
                        "Data Inválida"
                      )}
                      clearLabel={React.createElement("span", null, "Limpar")}
                      cancelLabel={React.createElement(
                        "span",
                        null,
                        "Cancelar"
                      )}
                      onChange={(date) =>
                        this.handleDateChange("date_from_last_attendance", date)
                      }
                      maxDate={today}
                      format="dd/MM/yyyy"
                    />
                  </div>

                  <div style={{ paddingRight: "1vw" }}></div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledStartInputLabel>Fim</StyledStartInputLabel>
                    <KeyboardDatePicker
                      clearable
                      value={date_to_last_attendance}
                      name="date_to_last_attendance"
                      style={{ width: "6.5vw", paddingLeft: "1rem" }}
                      placeholder="Selecione..."
                      allowKeyboardControl={false}
                      minDateMessage={React.createElement(
                        "span",
                        null,
                        "Data Inferior à Data de Início"
                      )}
                      invalidDateMessage={React.createElement(
                        "span",
                        null,
                        "Data Inválida"
                      )}
                      clearLabel={React.createElement("span", null, "Limpar")}
                      cancelLabel={React.createElement(
                        "span",
                        null,
                        "Cancelar"
                      )}
                      onChange={(date) =>
                        this.handleDateChange("date_to_last_attendance", date)
                      }
                      minDate={date_from_last_attendance}
                      maxDate={today}
                      format="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </>
            )}
          </StyledAccordion>

          {this.showClearFilters() && !isLoading ? (
            <ClearText onClick={this.clearState}>Limpar filtros</ClearText>
          ) : null}
          <ButtonContainer>
            <StyledButton
              fullWidth
              onClick={this.submit}
              disabled={isLoading}
              disableRipple={true}
              variant="outlined"
              color="primary"
            >
              Gerar análise
            </StyledButton>
          </ButtonContainer>
        </ActionsContainer>
      </Aside>
    );
  }
}

const mapStateToProps = ({
  instrument,
  product,
  year,
  city,
  filter,
  chip,
  treemap_product,
  sector,
  port,
  page,
  app_product,
  theme,
  regional_unit,
  contact_type
}) => {
  return {
    instrument,
    product,
    year,
    city,
    filter,
    chip,
    treemap_product,
    sector,
    port,
    page,
    app_product,
    theme,
    regional_unit,
    contact_type,
  };
};

export default compose(
  connect(mapStateToProps, {
    ...InstrumentCreators,
    ...ProductCreators,
    ...YearCreators,
    ...CityCreators,
    ...toastrActions,
    ...ProductDetailCreators,
    ...MapCreators,
    ...TitleCreators,
    ...FilterCreators,
    ...TreeMapProductCreators,
    ...CustomerDetailCreators,
    ...RecProductCreators,
    ...SectorCreators,
    ...PortCreators,
    ...ThemeCreators,
    ...RecProducSimilaritytCreators,
    ...RegionalUnitCreators,
    ...RecProductIndicatorsCreators,
    ...RecSimilarityIndicatorsCreators,
    ...ContactTypeCreators,
  })
)(ProductFilter);

import api from "../../services/api";
import { Creators } from "../ducks/rec_similarity_indicators";
import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import { captureSentryException } from '../../utils';

export function* getRecSimilarityIndicators({ filter }) {
  let newFilter = { ...filter };
  let errorMsg = "Falha ao listar indicadores da recomendação de produtos";

  try {
    yield put(Creators.recSimilarityIndicatorsRequest());
    const response = yield call(
      api.post,
      "rec-similarity-indicators",
      newFilter
    );

    yield put(Creators.recSimilarityIndicatorsSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, newFilter);
    yield put(Creators.recSimilarityIndicatorsError({ err }));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Erro",
        message: errorMsg,
      })
    );
  }
}

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Creators as RecMarketCreators } from '../../store/ducks/rec_market';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as MapCreators } from '../../store/ducks/map';
import { actions as toastrActions } from 'react-redux-toastr';
import {
  Container, ProductContainer, DetailContainer,
  Header, HeaderContainer, MapContainer
} from './styles';
import Map from '../Map/Map';
import RecMarketDialog from '../RecMarketDialog';
import OnlineIcon from 'react-feather/dist/icons/monitor';
import MapIcon from 'react-feather/dist/icons/map';
import IconButton from '../IconButton';
import { REC_MARKET_CLICK_ONLINE, REC_MARKET_MULTI_CITY } from '../../constants';

class RecMarket extends Component {

  componentDidMount() {
    const { rec_market } = this.props.dialog;

    if (rec_market) {
      this.props.setDialog('rec_market');
    }

    this.props.getMap({}, 'rec-market-map')
  }

  openRecDetails = (filter)=>{
    this.props.setDialog('rec_market');
    this.props.getRecMarket( filter );
  }

  onMapClick = (feature) => {
    const city = [feature.properties.NM_MUNICIP];
    let { filter } = this.props.filter;
    let newFilter = { ...filter,city, status_lgpd: null, product: null, productid: null };

    if (feature.properties.density !== -1) {
      this.openRecDetails(newFilter)
      this.props.recMarketSetCity(city);
    }
  }

  handleMultiCityClick = ()=>{
    let { filter } = this.props.filter;
    let newFilter = { ...filter, is_online:true, status_lgpd: null, product: null, productid: null };

    if (!newFilter.city || newFilter.city.length < 2) {
      this.renderWarningMsg('Selecione ao menos duas cidades e gere uma recomendação');
      return;
    }

    this.openRecDetails(newFilter)

  }

  handleOnlineRecClick = ()=>{
    let { filter } = this.props.filter;
    let newFilter = { ...filter, is_online:true, status_lgpd: null, product: null, productid: null };
    
    this.openRecDetails(newFilter)

  }

  renderWarningMsg = (msg) => {
    this.props.add({
      type: 'warning',
      title: 'Atenção',
      message: msg
    });
  }

  render() {
    const { role, filter } = this.props;

    return (
      <Container>
        <Fragment>
          <DetailContainer>
            <ProductContainer>
              <HeaderContainer>
                <Header>Municípios</Header>
                <div style={{display:'flex'}}>
                  <IconButton
                    onClick={() => this.handleMultiCityClick('MULTI_CITY')}
                    mixpanelTrackEvent={REC_MARKET_MULTI_CITY}
                    label='Recomendações Multi Cidade'
                    icon={<MapIcon />}
                  />
                  <IconButton
                    style={{marginLeft:'1rem'}}
                    onClick={() => this.handleOnlineRecClick('ONLINE')}
                    mixpanelTrackEvent={REC_MARKET_CLICK_ONLINE}
                    label='Recomendações Online'
                    icon={<OnlineIcon />}
                  />
                  </div>
              </HeaderContainer>
              <MapContainer>
                <Map
                  zoom={[7.6]}
                  onClick={this.onMapClick} />
              </MapContainer>
            </ProductContainer>
          </DetailContainer>

          <RecMarketDialog 
            history={this.props.history} 
            role={role} 
            city={filter.filter.city ? filter.filter.city : null}
            regional_unit={filter.filter.regional_unit ? filter.filter.regional_unit : null}/>
        </Fragment>
      </Container>
    )
  }
}

const mapStateToProps = ({ filter, dialog }) => ({ filter, dialog });

export default compose(
  connect(mapStateToProps, {
    ...RecMarketCreators,
    ...DialogCreators,
    ...MapCreators,
    ...toastrActions
  })
)(RecMarket);

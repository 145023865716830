import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Dialog, DialogActions, Button, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import { Creators as TargetAudienceCreators } from '../../store/ducks/target_audience';

class CleanTargetAudienceDialog extends Component {

  onClose = () => {
    this.props.setDialog('clean_target_audience');
  }

  submit = () => {
    const filter = this.getFilter();

    this.props.updateTargetAudience({ filter });
    this.props.setDialog('clean_target_audience');
  }

  getFilter = () => {
    const { dialog } = this.props;

    const filter = {
      productid: dialog.product.produtoid,
      product: dialog.product.produto,
      is_clean: true
    };

    return filter;
  }

  render = () => {
    const { dialog } = this.props;

    if (!dialog.clean_target_audience) {
      return null;
    }

    return (
        <Dialog
          open={dialog.clean_target_audience}
          onClose={() => this.onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{ textAlign: "-webkit-center" }}
            id="alert-dialog-title"><b>{"Tem certeza que deseja limpar as características de público-alvo para este produto? "}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "black" }}
              id="alert-dialog-description">
              As alterações nas características de público-alvo serão processadas sempre no dia seguinte, quando o modelo de recomendação atualizar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onClose()} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.submit()} color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
    )
  }
}

const mapStateToProps = ({ dialog }) => ({ dialog });

export default compose(
  connect(mapStateToProps, {
    ...DialogCreators,
    ...TargetAudienceCreators,
  })
)(CleanTargetAudienceDialog);

import React, { Component } from 'react';
import { ForgotPassword, RequireNewPassword, SignIn, withAuthenticator } from 'aws-amplify-react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Routes from '../../routes';
import LeftDrawer from '../LeftDrawer/LeftDrawer';
import styles from './App.styles';
import theme from './theme';
import { Creators as TenantCreators } from '../../store/ducks/tenant';
import { interceptAuthEvents } from '../../utils/mixpanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import { listenRunningNotifications } from '../../utils/notification';
import { withSnackbar } from 'notistack';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSideMenu: false
    }
  }

  componentDidMount() {
    this.props.setTenant();
    interceptAuthEvents();
    listenRunningNotifications(this.props.enqueueSnackbar, this.props.closeSnackbar);
  }

  handleChangeSideMenu(e) {
    if (e) {
      let open = this.state.openSideMenu;
      this.setState({ openSideMenu: !open });
    } else {
      this.setState({ openSideMenu: false });
    }
  }

  handleClickOut = e => {

    if (this.listSubNode != null) {
      if (this.listSubNode.contains(e.target)) {
        return;
      }

      this.setState({ openSideMenu: false });
    }
  }

  render() {
    const { classes, location } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>

          <div className={classes.root}>
            <div className={classNames(classes.appFrame, classes.hiddenDrawerBD)}>
              <LeftDrawer
                openSide={this.handleChangeSideMenu.bind(this)}
                open={this.state.openSideMenu}
                drawerRef={el => this.leftDrawerNode = el}
              />
              <main
                className={classNames(
                  classes.content,
                  classes.contentLeft,
                  classes.contentShaddow,
                  classes.contentShift,
                  classes.contentShiftLeft
                )}
              >
                <div className={classes.contentBody} >
                  <Routes location={location} />
                </div>

              </main>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );

  }
}

const mapStateToProps = ({ title }) => ({ title });

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles),
  connect(mapStateToProps, { ...TenantCreators })
)(withAuthenticator(App, false, [<SignIn />, <RequireNewPassword />, <ForgotPassword />], null));
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    color: 'white',
    backgroundColor: '#0B4073',
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    backgroundImage: 'white',
    marginLeft: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  collapse: {
    borderRadius: 0,
    backgroundColor: '#ECECEC',
    padding: 16,
  },
  successIcon: {
    fontSize: 20,
    color: '#30E398',
    paddingRight: 4,
  },
  downloadIcon: {
    fontSize: 20,
    color: '#0B4073',
    paddingRight: 4,
  },
  errorIcon: {
    fontSize: 20,
    color: '##FF2D00',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  notificationText: {
    width: '12vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { ContentContainer, Content, Container } from "./styles";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as ChipCreators } from "../../store/ducks/chip";
import { Creators as CustomerCreators } from "../../store/ducks/customer";
import { Creators as TimelineCreators } from "../../store/ducks/timeline";
import { Creators as RecCustomerProductCreators } from "../../store/ducks/rec_customer_product";
import { Creators as PersonaInfoCreators } from "../../store/ducks/persona_info";
import { Creators as RecCustomerEventCreators } from "../../store/ducks/rec_customer_event";
import { Creators as RecCustomerContentCreators } from "../../store/ducks/rec_customer_content";
import CustomerTimeline from "../CustomerTimeline";
import CustomerInfo from "../CustomerInfo";
import UsersIcon from "react-feather/dist/icons/users";
import ChipGroup from "../ChipGroup";
import RecCustomerProduct from "../RecCustomerProduct";
import { getFeatures } from "../../utils/features";
import {
  COMPANY,
  CUSTOMER_CHIP,
  RECSYS,
  REC_CUSTOMER_CONTENT,
  REC_CUSTOMER_EVENT,
  TIMELINE,
} from "../../constants/index";
import { Mixpanel } from "../../utils/mixpanel";
import RecCustomerEvent from "../RecCustomerEvent";
import RecEventDialog from "../RecEventDialog";
import RecCustomerContent from "../RecCustomerContent";

class Customer extends Component {
  state = {
    isTimelineLoaded: false,
    isRecCustomerLoaded: false,
    isRecCustomerEventLoaded: false,
    customerPartner: null,
    customerId: null,
    activeTab: COMPANY,
    selectedPartner: null,
    isRecCustomerContentLoaded: false,
  };

  componentDidMount() {
    const { chip } = this.props;
    const { term } = this.props.match.params;

    this.props.setTitle("Cliente");
    this.props.setChip("customer", RECSYS);

    Mixpanel.track("CUSTOMER_INIT");

    if (term) {
      this.props.getCustomer(term);
      this.getDataByChip(chip.customer, term);
      return;
    }

    this.props.customerInit();
    this.props.recCustomerInit();
    this.props.personaInfoInit();
  }

  componentWillUnmount() {
    this.props.customerInit();
  }

  getDataByChip = (activeChip, customerId) => {
    const {
      isTimelineLoaded,
      isRecCustomerLoaded,
      isRecCustomerEventLoaded,
      isRecCustomerContentLoaded,
    } = this.state;

    if (activeChip === TIMELINE && !isTimelineLoaded) {
      this.props.getTimeline(customerId);
      this.setState({ isTimelineLoaded: true });
    }

    if (activeChip === RECSYS && !isRecCustomerLoaded) {
      this.props.getRecCustomerProduct({
        parceiroid: customerId,
        is_download: false,
      });
      //this.props.getPersonaInfo(customerId);
      this.setState({ isRecCustomerLoaded: true });
    }

    if (activeChip === REC_CUSTOMER_EVENT && !isRecCustomerEventLoaded) {
      this.props.getRecCustomerEvent(customerId);
      this.setState({ isRecCustomerEventLoaded: true });
    }

    if (activeChip === REC_CUSTOMER_CONTENT && !isRecCustomerContentLoaded) {
      this.props.getRecCustomerContent(customerId);
      this.setState({ isRecCustomerContentLoaded: true });
    }
  };

  onChange = ({ customerId, customerPartner }) => {
    const { chip } = this.props;

    this.setState(
      {
        isTimelineLoaded: false,
        isRecCustomerLoaded: false,
        isRecCustomerEventLoaded: false,
        isRecCustomerContentLoaded: false,
        customerPartner,
      },
      () => this.getDataByChip(chip.customer, customerId)
    );
  };

  onChangeChip = ({ activeChip }) => {
    const { parceiroid, cgccpf } = this.props.customer.data;

    this.getDataByChip(activeChip, parceiroid, cgccpf);
  };

  onChangeTab = ({ activeTab, customerId }) => {
    if (
      activeTab !== this.state.activeTab &&
      activeTab === COMPANY &&
      customerId
    ) {
      this.props.getTimeline(customerId);
    }

    this.setState({ activeTab, customerPartner: null });
  };

  render() {
    const { tenant, role } = this.props.tenant;
    const { chip, customer, timeline, rec_customer_product } = this.props;
    const chipOptions = getFeatures(tenant, CUSTOMER_CHIP) || [];

    return (
      <Container>
        <CustomerInfo
          activeTab={this.state.activeTab}
          onChangeTab={this.onChangeTab}
          onChange={this.onChange}
          icon={<UsersIcon />}
          title="Cliente"
        />

        <ContentContainer style={{ fontSize: "2vh" }}>
          <ChipGroup
            isDisabled={
              customer.loading ||
              timeline.loading ||
              rec_customer_product.loading
            }
            onChange={(activeChip) => this.onChangeChip({ activeChip })}
            style={{ paddingBottom: "1vh" }}
            data={chipOptions}
            title=""
            context="customer"
          />
          {chip.customer === TIMELINE && (
            <Content>
              <CustomerTimeline customerPartner={this.state.customerPartner} />
            </Content>
          )}
          {chip.customer === RECSYS && (
            <RecCustomerProduct {...this.props} role={role} />
          )}
          {chip.customer === REC_CUSTOMER_EVENT && (
            <RecCustomerEvent {...this.props} role={role} />
          )}
          {chip.customer === REC_CUSTOMER_CONTENT && (
            <RecCustomerContent {...this.props} role={role} />
          )}
        </ContentContainer>
        <RecEventDialog role={role} />
      </Container>
    );
  }
}

const mapStateToProps = ({
  title,
  customer,
  chip,
  tenant,
  timeline,
  rec_customer_product,
  rec_customer_event,
}) => ({
  title,
  customer,
  chip,
  tenant,
  timeline,
  rec_customer_product,
  rec_customer_event,
});

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...CustomerCreators,
    ...TimelineCreators,
    ...RecCustomerProductCreators,
    ...PersonaInfoCreators,
    ...ChipCreators,
    ...RecCustomerEventCreators,
    ...RecCustomerContentCreators,
  })
)(Customer);

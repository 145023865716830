import Immutable from 'seamless-immutable';
import geoJsonRN from '../../data/sebraern.json';
import geoJsonPB from '../../data/sebraepb.json';
import geoJsonMA from '../../data/sebraema.json';
import geoJsonPE from '../../data/sebraepe.json';
import geoJsonMS from '../../data/sebraems.json';
import geoJsonGO from '../../data/sebraego.json';
import geoJsonES from '../../data/sebraees.json';
import { createReducer, createActions } from 'reduxsauce';

const maps = {
  sebraern: geoJsonRN,
  sebraepb: geoJsonPB,
  sebraema: geoJsonMA,
  sebraepe: geoJsonPE,
  sebraems: geoJsonMS,
  sebraego: geoJsonGO,
  sebraees: geoJsonES,
};

const centers = {
  sebraern: [-36.79522683121116, -5.754945747015],
  sebraepb: [-36.79522683121116, -7.054945747015],
  sebraema: [-45.0898097813, -4.880746973],
  sebraepe: [-37.82599804068508, -8.356314625049194],
  sebraems: [-54.42599804068508, -20.356314625049194],
  sebraego: [-49.1760994260, -16.4647417917],
  sebraees: [-40.50364179653064, -19.700535797502506],
};

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  mapRequest: [],
  mapInit: ['tenant'],
  mapResetData: [],
  getMap: ['filter', 'endpoint'],
  mapSuccess: ['data', 'geoJson'],
  mapError: ['err']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  featuresById: {},
  loading: false,
  error: false,
  center: [0, 0],
  zoom: [6.5],
  geoJson: {
    type: 'FeatureCollection',
    features: []
  }
});

/* Reducers */

export const init = (state, { tenant }) => {
  const geoJson = maps[tenant];
  const center = centers[tenant];

  let featuresById = {};
  const { features } = geoJson;

  features.forEach((feature) => {
    featuresById[feature.properties.NM_MUNICIP] = feature;
  });

  return state.merge({ ...INITIAL_STATE, geoJson, center, featuresById });
}

export const request = state => state.merge({ loading: true });

export const success = (state, { data, geoJson }) => state.merge({ data, geoJson, error: false, loading: false });

export const reset = state => state.merge({ data: INITIAL_STATE.data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.MAP_INIT]: init,
  [Types.MAP_RESET_DATA]: reset,
  [Types.MAP_REQUEST]: request,
  [Types.MAP_SUCCESS]: success,
  [Types.MAP_ERROR]: error
});

import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
  chatbotConfigRequest: ["request"],
  chatbotConfigSuccess: ["data"],
  chatbotConfigError: ["err"],
  getChatbotConfig: ["tenant"],
  putChatbotConfig: ["data"],
  chatbotConfigInit: [],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  error: false,
  loading: false,
  request: {},
});

/* Reducers Table */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CHATBOT_CONFIG_REQUEST]: request,
  [Types.CHATBOT_CONFIG_SUCCESS]: success,
  [Types.CHATBOT_CONFIG_ERROR]: error,
  [Types.CHATBOT_CONFIG_INIT]: init,
});

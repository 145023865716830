import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import styles from './styles';
import { withStyles, Dialog, DialogContent } from '@material-ui/core';
import { Creators as DialogCreators } from '../../store/ducks/dialog';
import Table from '../Table';
import { Creators as RfbPartnerActions } from '../../store/ducks/rfb_partner';

class LeadsDialog extends Component {

  onClose = () => {
    this.props.setDialog('leads');
  }

  render = () => {
    const { dialog } = this.props;
    const { data, sort, error, loading, defaultData } = this.props.rfb_partner;

    if (!dialog.leads) {
      return null;
    }

    return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.onClose.bind(this)}
        open={dialog.leads}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ height: '55vh', paddingRight: 0, paddingLeft: 0 }}>
          <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '1.2rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
            Lista de Representantes
          </div>

          {!loading && defaultData.enterprise ?
            <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem', paddingBottom: 0, fontSize: '12px' }}>
              <div style={{ paddingBottom: '.2rem' }}><b>Razão Social: </b>{defaultData.enterprise.razao_social}</div>
              <div style={{ paddingBottom: '.2rem' }}><b>E-mail da Empresa: </b>{defaultData.enterprise.email}</div>
              <div style={{ paddingBottom: '.2rem' }}><b>Telefone da Empresa: </b>{defaultData.enterprise.telefone}</div>
            </div> : null}

          <Table
            data={data}
            sort={sort}
            error={error}
            loading={loading}
            defaultData={defaultData}
            sortBy={this.props.rfbPartnerSortBy}
          />

        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ rfb_partner, dialog }) => ({ rfb_partner, dialog });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...RfbPartnerActions
  })
)(LeadsDialog);

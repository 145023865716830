import api from '../../services/api';
import { Creators } from '../ducks/rec_market';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getRecMarket({ filter }) {
  try {

    yield put(Creators.recMarketRequest());
    const response = yield call(api.post, 'rec-market', filter);

    yield put(Creators.recMarketSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.recMarketError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar recomendação de mercado'
    }));
  }
}
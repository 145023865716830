import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
  notifyEtlRequest: ["request"],
  notifyEtlSuccess: ["data"],
  notifyEtlError: ["err"],
  getNotifyEtl: ["data"],
  putNotifyEtl: ["data"],
  notifyEtlInit: [],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  error: false,
  loading: false,
  request: {},
});

/* Reducers Table */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.NOTIFY_ETL_REQUEST]: request,
  [Types.NOTIFY_ETL_SUCCESS]: success,
  [Types.NOTIFY_ETL_ERROR]: error,
  [Types.NOTIFY_ETL_INIT]: init,
});

import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  TableContainer,
  Card2,
  Card3,
  Card4,
  HeaderContainer,
  HeaderText,
  LoadingContainer,
  CenterContainer,
  TableHeader,
  TableColumn,
  Separator,
  NumberIndicator,
  CardGroup,
  MicroText,
  TitleContainer,
  StyledButton,
  HeaderActionsContainer,
  NumberIndicator4,
  CardGroupText,
  StyledSelect,
  SelectContainer,
  StyledTooltip,
  Card1,
} from "./styles";
import Header from "../Header";
import MonitorIcon from "react-feather/dist/icons/monitor";
import { Creators as DashboardCreators } from "../../store/ducks/dashboard";
import { actions as toastrActions } from "react-redux-toastr";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { baseColor } from "../App/theme";
import {
  CUSTOMER_RECOMMENDATION_FUNNEL,
  DAILY_ACTIVE_USERS,
  FUNNEL,
  MONTHLY_ACTIVE_USERS,
  TOP_EVENTS,
  UNIQUE_BY_PROPERTY,
  PRODUCT_ANALYSIS_FUNNEL,
  PRODUCT_RECOMMENDATION_FUNNEL,
  TOP_RECOMMENDATION_PRODUCTS,
  BOTTOM_RECOMMENDATION_PRODUCTS,
  TOP_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE,
  BOTTOM_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE,
  TOP_RECOMMENDATION_EVENTS,
  BOTTOM_RECOMMENDATION_EVENTS,
  CUSTOMERS_CLEANED,
  CUSTOMERS_CLEANED_QTD_BY_DATE,
  CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE,
  MOST_RECOMMENDED_PRODUCTS,
  MOST_SEARCHED_CONTENT_THEMES,
  MOST_SELECTED_THEMES,
  MOST_SEARCHED_WORDS,
} from "../../constants/dashboard";
import {
  getMetabaseDashIdByDashName,
  getNameByReduxEvent,
  mixpanelExcludedEvents,
} from "../../utils/schema";
import BarChart from "../BarChart";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ChipGroup from "../ChipGroup";
import { getFeatures } from "../../utils/features";
import {
  DASHBOARD_CHIP,
  DASHBOARD_GENERAL_INDICATORS,
  DASHBOARD_PERSONA_INDICATORS,
  DASHBOARD_SECTION_CLEANED_CUSTOMERS,
  DASHBOARD_USE_APP_INDICATORS,
  DASHBOARD_APP_SEBRAE,
  TOP_10,
  TOP_25,
  TOP_50,
  METABASE,
  APP_SEBRAE,
  DASHBOARD_CHATBOT_INDICATORS,
  PESSOA_FISICA,
  PESSOA_JURIDICA,
  TODOS,
} from "../../constants";
import { formatMoney, getDateYYMMDDDD, isValidDate, getDefaultDate } from "../../utils";
import Metabase from "../Metabase";
import WordCloud from "../WordCloud";
import Plotly from "plotly.js-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import HelpIcon from "react-feather/dist/icons/help-circle";
import InfoIcon from "react-feather/dist/icons/info";
import Typography from "@material-ui/core/Typography";

const Plot = createPlotlyComponent(Plotly);

const productAnalysisFunnel = ["INIT_PRODUCT", "PRODUCT_DETAIL_DOWNLOAD"];

const customerRecommendationFunnel = [
  "REC_CUSTOMER_REQUEST",
  "REC_CUSTOMER_DOWNLOAD",
];

const productRecommendationFunnel = [
  "REC_PRODUCT_REQUEST",
  "REC_PRODUCT_DOWNLOAD",
];

class Dashboard extends Component {
  state = {
    [DASHBOARD_GENERAL_INDICATORS]: {
      date_from: getDefaultDate({ delta: -1 }),
      date_to: getDefaultDate({ delta: 0 }),
    },
    [DASHBOARD_USE_APP_INDICATORS]: {
      date_from: getDefaultDate({ delta: -1 }),
      date_to: getDefaultDate({ delta: 0 }),
    },
    [DASHBOARD_PERSONA_INDICATORS]: {
      date_from: getDefaultDate({ delta: -1 }),
      date_to: getDefaultDate({ delta: 0 }),
      is_download: false,
      personas: [],
    },
    [DASHBOARD_APP_SEBRAE]: {
      date_from: getDefaultDate({ delta: -1 }),
      date_to: getDefaultDate({ delta: 0 }),
      personas: [],
    },
    [DASHBOARD_CHATBOT_INDICATORS]: {
      date_from: getDefaultDate({ delta: -30 }),
      date_to: getDefaultDate({ delta: 0 }),
      user_type: TODOS,
      is_download: false,
      personas: [],
    },
    is_reload_indicator: true,
    topLimitRec: "10",
    persona: null,
    today: getDefaultDate({ delta: 0 }),
  };

  componentDidMount() {
    const { chip } = this.props;

    this.requestIndicators(chip.dashboard, this.state.topLimitRec, true);
    this.setState({ is_reload_indicator: false });
  }

  handleFilterChange = (name, value) => {
    const { chip } = this.props;

    const newData = { ...this.state[chip.dashboard], [name]: value };

    this.setState({ [chip.dashboard]: newData });
  };

  renderErrorMsg = (msg) => {
    this.props.add({
      type: "error",
      title: "Erro",
      message: msg,
    });
  };

  getFilter = (
    type,
    topLimitRec,
    isChangeDate,
    is_download,
    download_extension,
    persona
  ) => {
    let extraFilter = {};
    const { date_from, date_to } = this.state[type];
    let is_reload_indicator = this.state;
    let limit = this.state.topLimitRec;

    is_reload_indicator =
      this.state.topLimitRec === topLimitRec
        ? this.state.is_reload_indicator
        : true;
    limit = topLimitRec ? topLimitRec : limit;

    extraFilter = {
      date_from: getDateYYMMDDDD(date_from),
      date_to: getDateYYMMDDDD(date_to),
    };

    const filter = is_download
      ? {
        DASHBOARD_PERSONA_INDICATORS: [
          {
            type: MOST_RECOMMENDED_PRODUCTS,
            is_download: true,
            download_extension: download_extension,
            persona: this.state.persona,
          },
        ],
      }
      : {
        DASHBOARD_GENERAL_INDICATORS: [
          {
            type: TOP_RECOMMENDATION_PRODUCTS,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: BOTTOM_RECOMMENDATION_PRODUCTS,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: TOP_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: BOTTOM_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: TOP_RECOMMENDATION_EVENTS,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: BOTTOM_RECOMMENDATION_EVENTS,
            is_reload_indicator,
            limit: limit,
          },
          {
            type: CUSTOMERS_CLEANED,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
            feature_flag: DASHBOARD_SECTION_CLEANED_CUSTOMERS,
          },
          {
            type: CUSTOMERS_CLEANED_QTD_BY_DATE,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
            feature_flag: DASHBOARD_SECTION_CLEANED_CUSTOMERS,
          },
          {
            type: CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
            feature_flag: DASHBOARD_SECTION_CLEANED_CUSTOMERS,
          },
          {
            type: FUNNEL,
            redux_type: PRODUCT_ANALYSIS_FUNNEL,
            events: productAnalysisFunnel,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
          },
          {
            type: FUNNEL,
            redux_type: CUSTOMER_RECOMMENDATION_FUNNEL,
            events: customerRecommendationFunnel,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
          },
          {
            type: FUNNEL,
            redux_type: PRODUCT_RECOMMENDATION_FUNNEL,
            events: productRecommendationFunnel,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
          },
        ],
        DASHBOARD_USE_APP_INDICATORS: [
          {
            type: TOP_EVENTS,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
          },
          {
            type: UNIQUE_BY_PROPERTY,
            is_reload_indicator: isChangeDate,
            ...extraFilter,
            events: ["initLoggedApp"],
          },
          { type: DAILY_ACTIVE_USERS },
          { type: MONTHLY_ACTIVE_USERS },
        ],
        DASHBOARD_PERSONA_INDICATORS: [
          { type: MOST_RECOMMENDED_PRODUCTS, limit: 10, persona },
        ],
        DASHBOARD_APP_SEBRAE: [
          { type: MOST_SEARCHED_CONTENT_THEMES, persona },
          { type: MOST_SEARCHED_WORDS, persona },
          { type: MOST_SELECTED_THEMES, persona },
        ],
        DASHBOARD_CHATBOT_INDICATORS: [
          { type: MOST_RECOMMENDED_PRODUCTS, limit: 10, persona },
        ],
      };

    return filter[type];
  };

  requestIndicators = (activeChip, topLimitRec, isChangeDate) => {
    const filter = this.getFilter(activeChip, topLimitRec, isChangeDate);
    const { date_from, date_to } = this.state[activeChip];

    if (!isValidDate(date_from)) {
      this.renderErrorMsg("Data inicial é inválida");
      return;
    }

    if (!isValidDate(date_to)) {
      this.renderErrorMsg("Data final é inválida");
      return;
    }

    this.props.getDashboard(filter);
  };

  renderLoading = (duck) => {
    if (!duck) return null;
    if (!duck.loading) return null;

    return (
      <LoadingContainer color={baseColor}>
        <CircularProgress color="primary" size={24} />
      </LoadingContainer>
    );
  };

  renderError = (duck) => {
    if (!duck) return null;

    return duck.error && !duck.loading ? (
      <CenterContainer>
        Ocorreu um erro. Por favor, tente novamente.
      </CenterContainer>
    ) : null;
  };

  renderEmptyState = (duck) => {
    if (!duck || !duck.data) return null;

    return !duck.loading &&
      !duck.error &&
      (!duck.data || (!duck.data.length && !Object.keys(duck.data).length)) ? (
      <CenterContainer>Sem dados para este indicador</CenterContainer>
    ) : null;
  };

  showData = (type) => {
    const { data, loading, error } = this.props.dashboard;

    return (
      data[type] &&
      (data[type].length || Object.keys(data[type]).length) &&
      !loading[type] &&
      !error[type]
    );
  };

  renderStates = (type) => {
    const { data, loading, error } = this.props.dashboard;
    const customDuck = {
      data: data[type],
      loading: loading[type],
      error: error[type],
    };

    return (
      <>
        {this.renderLoading(customDuck)}
        {this.renderError(customDuck)}
        {this.renderEmptyState(customDuck)}
      </>
    );
  };

  isLoading = (duck) => {
    let isLoading = false;
    const { loading } = duck;

    Object.keys(loading).forEach((key) => {
      if (loading[key]) {
        isLoading = true;
      }
    });

    return isLoading;
  };

  onChangeChip = ({ activeChip }) =>
    this.requestIndicators(activeChip, this.state.topLimitRec, true);

  getTabs = (isLoading) => {
    const { tenant } = this.props;
    const chipOptions = getFeatures(tenant.tenant, DASHBOARD_CHIP) || [];

    return (
      <HeaderActionsContainer>
        <ChipGroup
          isDisabled={isLoading}
          onChange={(activeChip) => this.onChangeChip({ activeChip })}
          style={{ padding: 0 }}
          data={chipOptions}
          title=""
          context="dashboard"
        />
      </HeaderActionsContainer>
    );
  };

  getFilters = (isLoading) => {
    const { chip } = this.props;
    const { date_from, date_to } = this.state[chip.dashboard];
    const { today } = this.state;
    const showFilters =
      chip.dashboard !== DASHBOARD_PERSONA_INDICATORS
        ? true
        : chip.dashboard !== DASHBOARD_APP_SEBRAE
          ? true
          : false;

    const isNotChatbot =
      chip.dashboard !== DASHBOARD_CHATBOT_INDICATORS ? true : false;

    return (
      <HeaderActionsContainer>
        {showFilters ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <KeyboardDatePicker
              clearable
              style={{ width: "9vw" }}
              value={date_from}
              name="date_from"
              allowKeyboardControl={false}
              placeholder="Selecione..."
              invalidDateMessage={React.createElement(
                "span",
                null,
                "Data Inválida"
              )}
              clearLabel={React.createElement("span", null, "Limpar")}
              cancelLabel={React.createElement("span", null, "Cancelar")}
              onChange={(date) => this.handleFilterChange("date_from", date)}
              maxDate={today}
              format="dd/MM/yyyy"
            />

            <div style={{ paddingRight: "1rem" }}></div>

            <KeyboardDatePicker
              clearable
              value={date_to}
              name="date_to"
              style={{ width: "9vw" }}
              placeholder="Selecione..."
              allowKeyboardControl={false}
              minDateMessage={React.createElement(
                "span",
                null,
                "Data Inferior à Data de Início"
              )}
              invalidDateMessage={React.createElement(
                "span",
                null,
                "Data Inválida"
              )}
              clearLabel={React.createElement("span", null, "Limpar")}
              cancelLabel={React.createElement("span", null, "Cancelar")}
              onChange={(date) => this.handleFilterChange("date_to", date)}
              minDate={date_from}
              maxDate={today}
              format="dd/MM/yyyy"
            />

            <div style={{ paddingRight: "1rem" }}></div>

            {isNotChatbot ? (
              <StyledButton
                onClick={this.requestIndicators.bind(
                  this,
                  chip.dashboard,
                  this.state.topLimitRec,
                  true
                )}
                disabled={isLoading}
                disableRipple={true}
                constiant="outlined"
                color="primary"
              >
                {"Atualizar Indicadores"}
              </StyledButton>
            ) : (
              <SelectContainer>
                <FormControl variant="outlined">
                  <InputLabel>Tipo de usuário</InputLabel>
                  <StyledSelect
                    name="persona"
                    onChange={(e) =>
                      this.handleFilterChange("user_type", e.target.value)
                    }
                    label={"Todos"}
                    style={{ textAlign: "left", backgroundColor: "white" }}
                    defaultValue={TODOS}
                  >
                    <MenuItem id={TODOS} value={TODOS}>
                      Todos
                    </MenuItem>
                    <MenuItem id={PESSOA_JURIDICA} value={PESSOA_JURIDICA}>
                      PJ
                    </MenuItem>
                    <MenuItem id={PESSOA_FISICA} value={PESSOA_FISICA}>
                      PF
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </SelectContainer>
            )}
          </div>
        ) : null}
      </HeaderActionsContainer>
    );
  };

  getCleanedCustomersIndicators = () => {
    const { data } = this.props.dashboard;
    const { tenant } = this.props.tenant;
    const feature =
      getFeatures(tenant, DASHBOARD_SECTION_CLEANED_CUSTOMERS) || false;

    if (!feature) return null;

    return (
      <>
        <HeaderContainer>
          <CardGroupText>Indicadores de Higienização</CardGroupText>
        </HeaderContainer>

        <CardGroup>
          <Card4>
            <HeaderContainer>
              <HeaderText>
                Total de Clientes
                <MicroText> (PF)</MicroText>
              </HeaderText>
            </HeaderContainer>

            {this.renderStates(CUSTOMERS_CLEANED)}
            {this.showData(CUSTOMERS_CLEANED) ? (
              <>
                <NumberIndicator4>
                  {formatMoney(data[CUSTOMERS_CLEANED][0]["qtd_clientes"], 0)}
                </NumberIndicator4>
              </>
            ) : null}
          </Card4>

          <Card4>
            <HeaderContainer>
              <HeaderText>
                Total de Clientes Higienizados
                <MicroText> (PF)</MicroText>
              </HeaderText>
            </HeaderContainer>

            {this.renderStates(CUSTOMERS_CLEANED)}
            {this.showData(CUSTOMERS_CLEANED) ? (
              <>
                <NumberIndicator4>
                  {formatMoney(
                    data[CUSTOMERS_CLEANED][0]["qtd_clientes_higienizados"],
                    0
                  )}
                </NumberIndicator4>
              </>
            ) : null}
          </Card4>

          <Card4>
            <HeaderContainer>
              <HeaderText>Percentual de Higienização da Base</HeaderText>
            </HeaderContainer>

            {this.renderStates(CUSTOMERS_CLEANED)}
            {this.showData(CUSTOMERS_CLEANED) ? (
              <>
                <NumberIndicator4>
                  {
                    data[CUSTOMERS_CLEANED][0][
                    "percentual_higienizado_formatado"
                    ]
                  }
                </NumberIndicator4>
              </>
            ) : null}
          </Card4>

          <Card4>
            <HeaderContainer>
              <HeaderText>Data da Última Higienização</HeaderText>
            </HeaderContainer>

            {this.renderStates(CUSTOMERS_CLEANED)}
            {this.showData(CUSTOMERS_CLEANED) ? (
              <>
                <NumberIndicator4>
                  {data[CUSTOMERS_CLEANED][0]["data_ultima_higienizacao"]}
                </NumberIndicator4>
              </>
            ) : null}
          </Card4>
        </CardGroup>
      </>
    );
  };

  handleChangeTopLimit = (event) => {
    const { chip } = this.props;
    const topLimitRec = event.target.value;
    this.setState({ topLimitRec: topLimitRec });

    this.requestIndicators(chip.dashboard, topLimitRec, false);
  };

  getCleanedCustomersByDateIndicators = () => {
    const { data, loading, error } = this.props.dashboard;
    const { tenant } = this.props.tenant;
    const feature =
      getFeatures(tenant, DASHBOARD_SECTION_CLEANED_CUSTOMERS) || false;

    if (!feature) return null;

    return (
      <CardGroup>
        <Card2>
          <HeaderContainer>
            <HeaderText>
              Registros Higienizados
              <MicroText> (por dia)</MicroText>
            </HeaderText>
          </HeaderContainer>

          {this.renderStates(CUSTOMERS_CLEANED_QTD_BY_DATE)}
          {this.showData(CUSTOMERS_CLEANED_QTD_BY_DATE) ? (
            <BarChart
              layout={{
                marker: {
                  color: baseColor,
                },
                margin_left: 50,
                margin_right: 50,
                xaxis_label: "",
                xaxis_showticklabels: true,
                plot_bgcolor: "transparent",
                paper_bgcolor: "transparent",
                yaxis_tickformat: ",d",
                type: "line",
              }}
              duck={{
                data: data[CUSTOMERS_CLEANED_QTD_BY_DATE],
                loading: loading[CUSTOMERS_CLEANED_QTD_BY_DATE],
                error: error[CUSTOMERS_CLEANED_QTD_BY_DATE],
              }}
            />
          ) : null}
        </Card2>

        <Card2>
          <HeaderContainer>
            <HeaderText>
              Percentual Higienizado
              <MicroText> (acumulativo)</MicroText>
            </HeaderText>
          </HeaderContainer>

          {this.renderStates(CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE)}
          {this.showData(CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE) ? (
            <BarChart
              layout={{
                marker: {
                  color: baseColor,
                },
                margin_left: 50,
                margin_right: 50,
                xaxis_label: "",
                xaxis_showticklabels: true,
                plot_bgcolor: "transparent",
                paper_bgcolor: "transparent",
                type: "line",
                yaxis_tickformat: ".3%",
              }}
              duck={{
                data: data[CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE],
                loading: loading[CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE],
                error: error[CUSTOMERS_CLEANED_PERCENTAGE_BY_DATE],
              }}
            />
          ) : null}
        </Card2>
      </CardGroup>
    );
  };

  submit = ({ is_download, download_extension, event }) => {
    const { chip } = this.props;
    const newData = { ...this.state[chip.dashboard], is_download: is_download };
    let filter;

    this.setState({ [chip.dashboard]: newData });
    if (is_download) {
      filter = this.getFilter(
        chip.dashboard,
        this.state.topLimitRec,
        false,
        is_download,
        download_extension,
        this.state.persona
      );
    } else {
      filter = this.getFilter(
        chip.dashboard,
        this.state.topLimitRec,
        false,
        is_download,
        download_extension,
        event.target.value
      );
    }
    this.props.getDashboard(filter);
  };

  handleDownload = ({ download_extension }) =>
    this.submit({ is_download: true, download_extension, event: null });

  handleChangePersona = (event, context) => {
    this.setState({ ...this.state[context], persona: event.target.value });
    this.submit({ is_download: false, download_extension: null, event: event });
  };

  getRecomendationIndicators = (type, show_top_form, isLoading) => {
    const { data, error, loading } = this.props.dashboard;

    let TOP_RECOMMENDATION = "";
    let BOTTOM_RECOMMENDATION = "";
    let TOP_RECOMMENDATION_TERRITORIAL_COVERAGE = "";
    let BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE = "";
    let recommendation_name = "";
    let item_name = "";

    if (type === "EVENTS") {
      recommendation_name = "Eventos";
      item_name = "Evento";
      TOP_RECOMMENDATION = TOP_RECOMMENDATION_EVENTS;
      BOTTOM_RECOMMENDATION = BOTTOM_RECOMMENDATION_EVENTS;
    } else {
      recommendation_name = "Produtos";
      item_name = "Produto";
      TOP_RECOMMENDATION = TOP_RECOMMENDATION_PRODUCTS;
      BOTTOM_RECOMMENDATION = BOTTOM_RECOMMENDATION_PRODUCTS;
      TOP_RECOMMENDATION_TERRITORIAL_COVERAGE =
        TOP_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE;
      BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE =
        BOTTOM_RECOMMENDATION_PRODUCTS_TERRITORIAL_COVERAGE;
    }

    return (
      <>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <HeaderContainer>
            <CardGroupText>Recomendação de {recommendation_name}</CardGroupText>
          </HeaderContainer>

          {show_top_form ? (
            <FormControl
              disabled={isLoading}
              style={{
                display: "flex",
                marginInlineStart: "auto",
                flexDirection: "row",
              }}
            >
              <RadioGroup
                style={{
                  display: "flex",
                  marginInlineStart: "auto",
                  flexDirection: "row",
                }}
                value={this.state.topLimitRec}
                onChange={(event) => this.handleChangeTopLimit(event)}
              >
                <FormControlLabel
                  value={TOP_10}
                  control={
                    <Radio
                      style={
                        this.state.topLimitRec === TOP_10
                          ? { color: baseColor }
                          : { color: "gray" }
                      }
                    />
                  }
                  label="Top 10"
                />
                <FormControlLabel
                  value={TOP_25}
                  control={
                    <Radio
                      style={
                        this.state.topLimitRec === TOP_25
                          ? { color: baseColor }
                          : { color: "gray" }
                      }
                    />
                  }
                  label="Top 25"
                />
                <FormControlLabel
                  value={TOP_50}
                  control={
                    <Radio
                      style={
                        this.state.topLimitRec === TOP_50
                          ? { color: baseColor }
                          : { color: "gray" }
                      }
                    />
                  }
                  label="Top 50"
                />
              </RadioGroup>
            </FormControl>
          ) : null}
        </div>

        <CardGroup>
          <Card2>
            <HeaderContainer>
              <HeaderText>{recommendation_name} mais recomendados</HeaderText>
              <div>
                <StyledTooltip
                  placement="right-start"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Este indicador elenca e relaciona os {recommendation_name} mais recomendados para uma determinada quantidade de clientes.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <InfoIcon color="#41A2FF" />
                  </span>
                </StyledTooltip>
              </div>
            </HeaderContainer>

            {this.renderStates(TOP_RECOMMENDATION)}
            {this.showData(TOP_RECOMMENDATION) ? (
              <BarChart
                layout={{
                  marker: {
                    color: baseColor,
                  },
                  margin_left: 50,
                  margin_right: 50,
                  xaxis_label: `${item_name} x Qtd. Clientes`,
                  xaxis_showticklabels: false,
                  plot_bgcolor: "transparent",
                  paper_bgcolor: "transparent",
                  yaxis_tickformat: ",d",
                }}
                duck={{
                  data: data[TOP_RECOMMENDATION],
                  loading: loading[TOP_RECOMMENDATION],
                  error: error[TOP_RECOMMENDATION],
                }}
              />
            ) : null}
          </Card2>

          <Card2>
            <HeaderContainer>
              <HeaderText>{recommendation_name} menos recomendados</HeaderText>
              <div>
                <StyledTooltip
                  placement="right-start"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Este indicador elenca e relaciona os {recommendation_name} menos recomendados para uma determinada quantidade de clientes.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <InfoIcon color="#41A2FF" />
                  </span>
                </StyledTooltip>
              </div>
            </HeaderContainer>

            {this.renderStates(BOTTOM_RECOMMENDATION)}
            {this.showData(BOTTOM_RECOMMENDATION) ? (
              <BarChart
                layout={{
                  marker: {
                    color: baseColor,
                  },
                  margin_left: 50,
                  margin_right: 50,
                  xaxis_label: `${item_name} x Qtd. Clientes`,
                  xaxis_showticklabels: false,
                  plot_bgcolor: "transparent",
                  paper_bgcolor: "transparent",
                  yaxis_tickformat: ",d",
                }}
                duck={{
                  data: data[BOTTOM_RECOMMENDATION],
                  loading: loading[BOTTOM_RECOMMENDATION],
                  error: error[BOTTOM_RECOMMENDATION],
                }}
              />
            ) : null}
          </Card2>
        </CardGroup>

        {item_name==='Evento'? null:(
          <CardGroup>
          <Card2>
            <HeaderContainer>
              <HeaderText>
                {recommendation_name} com maior abrangência territorial
              </HeaderText>
              <div>
                <StyledTooltip
                  placement="right-start"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Este indicador elenca e relaciona os {recommendation_name} com maior cobertura de cidades no estado.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <InfoIcon color="#41A2FF" />
                  </span>
                </StyledTooltip>
              </div>
            </HeaderContainer>
            
            {this.renderStates(TOP_RECOMMENDATION_TERRITORIAL_COVERAGE)}
            {this.showData(TOP_RECOMMENDATION_TERRITORIAL_COVERAGE) ? (
              <BarChart
                layout={{
                  marker: {
                    color: baseColor,
                  },
                  margin_left: 50,
                  margin_right: 50,
                  xaxis_label: `${item_name} x Percentual de Abrangência`,
                  xaxis_showticklabels: false,
                  plot_bgcolor: "transparent",
                  paper_bgcolor: "transparent",
                }}
                duck={{
                  data: data[TOP_RECOMMENDATION_TERRITORIAL_COVERAGE],
                  loading: loading[TOP_RECOMMENDATION_TERRITORIAL_COVERAGE],
                  error: error[TOP_RECOMMENDATION_TERRITORIAL_COVERAGE],
                }}
              />
            ) : null}
          </Card2>

          <Card2>
            <HeaderContainer>
              <HeaderText>
                {recommendation_name} com menor abrangência territorial
              </HeaderText>
              <div>
                <StyledTooltip
                  placement="right-start"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Este indicador elenca e relaciona os {recommendation_name} com menor cobertura de cidades no estado.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <InfoIcon color="#41A2FF" />
                  </span>
                </StyledTooltip>
              </div>
            </HeaderContainer>

            {this.renderStates(BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE)}
            {this.showData(BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE) ? (
              <BarChart
                layout={{
                  marker: {
                    color: baseColor,
                  },
                  margin_left: 50,
                  margin_right: 50,
                  xaxis_label: `${item_name} x Percentual de Abrangência`,
                  xaxis_showticklabels: false,
                  plot_bgcolor: "transparent",
                  paper_bgcolor: "transparent",
                }}
                duck={{
                  data: data[BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE],
                  loading: loading[BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE],
                  error: error[BOTTOM_RECOMMENDATION_TERRITORIAL_COVERAGE],
                }}
              />
            ) : null}
          </Card2>
        </CardGroup>
        )}
        
      </>
    );
  };

  getIndicators = (isLoading) => {
    const { data } = this.props.dashboard;

    return (
      <>
        {this.getRecomendationIndicators("PRODUCTS", true, isLoading)}

        {this.getRecomendationIndicators("EVENTS", false, isLoading)}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {this.getFilters(isLoading)}
        </div>

        {this.getCleanedCustomersIndicators()}
        {this.getCleanedCustomersByDateIndicators()}

        <HeaderContainer>
          <CardGroupText>Funil de Conversão</CardGroupText>
        </HeaderContainer>

        <CardGroup>
          <Card3>
            <HeaderContainer>
              <HeaderText>Análise histórica de produtos</HeaderText>
            </HeaderContainer>

            {this.renderStates(PRODUCT_ANALYSIS_FUNNEL)}
            {this.showData(PRODUCT_ANALYSIS_FUNNEL) ? (
              <>
                <TableContainer>
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Ação
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Qtd. de Eventos
                    </TableColumn>
                  </TableHeader>
                  <Separator />
                  {productAnalysisFunnel.map((event, idx) => (
                    <TableHeader key={idx} style={{ paddingBottom: ".3rem" }}>
                      <TableColumn>{getNameByReduxEvent(event)}</TableColumn>
                      <TableColumn>
                        {data[PRODUCT_ANALYSIS_FUNNEL][0][event.toLowerCase()]}
                      </TableColumn>
                    </TableHeader>
                  ))}
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Taxa de Conversão
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      {data[PRODUCT_ANALYSIS_FUNNEL][0]["overall_conversion"]}%
                    </TableColumn>
                  </TableHeader>
                </TableContainer>
              </>
            ) : null}
          </Card3>

          <Card3>
            <HeaderContainer>
              <HeaderText>Recomendação de Clientes</HeaderText>
            </HeaderContainer>

            {this.renderStates(CUSTOMER_RECOMMENDATION_FUNNEL)}
            {this.showData(CUSTOMER_RECOMMENDATION_FUNNEL) ? (
              <>
                <TableContainer>
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Ação
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Qtd. de Eventos
                    </TableColumn>
                  </TableHeader>
                  <Separator />
                  {customerRecommendationFunnel.map((event, idx) => (
                    <TableHeader key={idx} style={{ paddingBottom: ".3rem" }}>
                      <TableColumn>{getNameByReduxEvent(event)}</TableColumn>
                      <TableColumn>
                        {
                          data[CUSTOMER_RECOMMENDATION_FUNNEL][0][
                          event.toLowerCase()
                          ]
                        }
                      </TableColumn>
                    </TableHeader>
                  ))}
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Taxa de Conversão
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      {
                        data[CUSTOMER_RECOMMENDATION_FUNNEL][0][
                        "overall_conversion"
                        ]
                      }
                      %
                    </TableColumn>
                  </TableHeader>
                </TableContainer>
              </>
            ) : null}
          </Card3>

          <Card3>
            <HeaderContainer>
              <HeaderText>Recomendação de Produtos</HeaderText>
            </HeaderContainer>

            {this.renderStates(PRODUCT_RECOMMENDATION_FUNNEL)}
            {this.showData(PRODUCT_RECOMMENDATION_FUNNEL) ? (
              <>
                <TableContainer>
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Ação
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Qtd. de Eventos
                    </TableColumn>
                  </TableHeader>
                  <Separator />
                  {productRecommendationFunnel.map((event, idx) => (
                    <TableHeader key={idx} style={{ paddingBottom: ".3rem" }}>
                      <TableColumn>{getNameByReduxEvent(event)}</TableColumn>
                      <TableColumn>
                        {
                          data[PRODUCT_RECOMMENDATION_FUNNEL][0][
                          event.toLowerCase()
                          ]
                        }
                      </TableColumn>
                    </TableHeader>
                  ))}
                  <TableHeader>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      Taxa de Conversão
                    </TableColumn>
                    <TableColumn style={{ fontWeight: "bold" }}>
                      {
                        data[PRODUCT_RECOMMENDATION_FUNNEL][0][
                        "overall_conversion"
                        ]
                      }
                      %
                    </TableColumn>
                  </TableHeader>
                </TableContainer>
              </>
            ) : null}
          </Card3>
        </CardGroup>
      </>
    );
  };

  getUseAppIndicators = (isLoading) => {
    const { dashboard } = this.props;

    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {this.getFilters(isLoading)}
        </div>
        <CardGroup>
          <Card2>
            <HeaderContainer>
              <HeaderText>Funcionalidades mais acessadas</HeaderText>
            </HeaderContainer>

            {this.renderStates(TOP_EVENTS)}
            {this.showData(TOP_EVENTS) ? (
              <>
                <TableHeader>
                  <TableColumn style={{ fontWeight: "bold" }}>Ação</TableColumn>
                  <TableColumn style={{ fontWeight: "bold" }}>
                    Qtd. de Acessos
                  </TableColumn>
                </TableHeader>
                <Separator />
                <TableContainer>
                  {dashboard.data[TOP_EVENTS].filter(
                    (event) => !mixpanelExcludedEvents().includes(event.key)
                  ).map((event, idx) => (
                    <TableHeader key={idx} style={{ paddingBottom: ".3rem" }}>
                      <TableColumn>
                        {getNameByReduxEvent(event.key)}
                      </TableColumn>
                      <TableColumn>{event.value}</TableColumn>
                    </TableHeader>
                  ))}
                </TableContainer>
              </>
            ) : null}
          </Card2>
          <Card2>
            <HeaderContainer>
              <HeaderText>Acesso dos usuários</HeaderText>
            </HeaderContainer>

            {this.renderStates(UNIQUE_BY_PROPERTY)}
            {this.showData(UNIQUE_BY_PROPERTY) ? (
              <>
                <TableHeader>
                  <TableColumn style={{ fontWeight: "bold" }}>
                    E-mail
                  </TableColumn>
                  <TableColumn style={{ fontWeight: "bold" }}>
                    Qtd. Acessos
                  </TableColumn>
                </TableHeader>
                <TableContainer>
                  <Separator />
                  {dashboard.data[UNIQUE_BY_PROPERTY].map((item, idx) => (
                    <TableHeader key={idx} style={{ paddingBottom: ".3rem" }}>
                      <TableColumn>{item.key}</TableColumn>
                      <TableColumn>{item.value}</TableColumn>
                    </TableHeader>
                  ))}
                </TableContainer>
              </>
            ) : null}
          </Card2>
        </CardGroup>

        <CardGroup>
          <Card2>
            <HeaderContainer>
              <HeaderText>Qtd. usuários ativos no dia anterior</HeaderText>
            </HeaderContainer>

            {this.renderStates(DAILY_ACTIVE_USERS)}
            {this.showData(DAILY_ACTIVE_USERS) ? (
              <>
                <NumberIndicator>
                  {dashboard.data[DAILY_ACTIVE_USERS][0]["active_users"]}
                </NumberIndicator>
              </>
            ) : null}
          </Card2>
          <Card2>
            <HeaderContainer>
              <HeaderText>Qtd. usuários ativos no mês atual</HeaderText>
            </HeaderContainer>

            {this.renderStates(MONTHLY_ACTIVE_USERS)}
            {this.showData(MONTHLY_ACTIVE_USERS) ? (
              <NumberIndicator>
                {dashboard.data[MONTHLY_ACTIVE_USERS][0]["active_users"]}
              </NumberIndicator>
            ) : null}
          </Card2>
        </CardGroup>
      </>
    );
  };

  generateSelectPersona = (data, context) => {
    let newData;
    if (context === METABASE) {
      newData = {
        ...this.state[DASHBOARD_PERSONA_INDICATORS],
        personas: data.MOST_RECOMMENDED_PRODUCTS.personas
          ? data.MOST_RECOMMENDED_PRODUCTS.personas
          : data.MOST_RECOMMENDED_PRODUCTS.MOST_RECOMMENDED_PRODUCTS.personas,
      };
      this.setState({ [DASHBOARD_PERSONA_INDICATORS]: newData });
    } else {
      newData = {
        ...this.state[DASHBOARD_APP_SEBRAE],
        personas: data.MOST_SEARCHED_WORDS.personas
          ? data.MOST_SEARCHED_WORDS.personas
          : data.MOST_SEARCHED_WORDS.MOST_SEARCHED_WORDS.personas,
      };
      this.setState({ [DASHBOARD_APP_SEBRAE]: newData });
    }
  };

  getPersonaIndicators = () => {
    const dashboardId = getMetabaseDashIdByDashName(
      DASHBOARD_PERSONA_INDICATORS
    );

    return (
      <>
        <Metabase
          style={{ display: "flex", minHeight: "225vh" }}
          dashboardId={dashboardId}
          persona={
            this.state.persona === null
              ? this.state[DASHBOARD_PERSONA_INDICATORS].personas
              : this.state.persona
          }
        />
      </>
    );
  };

  getChatbotIndicators = (isLoading) => {
    const { chip, tenant } = this.props;
    const dashboardId = getMetabaseDashIdByDashName(
      DASHBOARD_CHATBOT_INDICATORS, tenant.tenant
    );

    const { date_from, date_to, user_type } = this.state[chip.dashboard];
    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {this.getFilters(isLoading)}
        </div>
        <Metabase
          style={{ display: "flex", minHeight: "225vh" }}
          isByTenant={true}
          dashboardId={dashboardId}
          date_to={date_to}
          date_from={date_from}
          user_type={user_type}
          persona={
            this.state.persona === null
              ? this.state[DASHBOARD_PERSONA_INDICATORS].personas
              : this.state.persona
          }
        />
      </>
    );
  };

  getAppSebraeIndicators = (isLoading) => {
    const { data, loading } = this.props.dashboard;

    if (
      !isLoading &&
      data.MOST_SEARCHED_WORDS &&
      this.state.DASHBOARD_APP_SEBRAE.personas.length === 0
    ) {
      this.generateSelectPersona(data, APP_SEBRAE);
    }

    return (
      <>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HeaderContainer>
            <CardGroupText>Indicadores do App Sebrae</CardGroupText>
          </HeaderContainer>
          <div>
            <StyledTooltip
              placement="right-start"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Os dados apresentados são oriundos do App Sebrae, os
                    clientes também podem consumir conteúdos dos Sebraes de
                    outros estados.
                  </Typography>
                </React.Fragment>
              }
            >
              <span>
                <HelpIcon color="#41A2FF" />
              </span>
            </StyledTooltip>
          </div>
        </div>
        <CardGroup>
          <Card1>
            <HeaderContainer>
              <HeaderText>
                Palavras mais digitadas na barra de pesquisa
              </HeaderText>
              <SelectContainer>
                <FormControl variant="outlined">
                  <InputLabel>
                    {loading[MOST_SEARCHED_WORDS] ? "Carregando..." : "Persona"}
                  </InputLabel>
                  <StyledSelect
                    name="persona"
                    onChange={(event) =>
                      this.handleChangePersona(event, DASHBOARD_APP_SEBRAE)
                    }
                    label={
                      loading[MOST_SEARCHED_WORDS]
                        ? "Carregando"
                        : "Sem persona"
                    }
                    disabled={isLoading}
                    style={
                      loading[MOST_SEARCHED_WORDS]
                        ? { textAlign: "left", backgroundColor: "lightgray" }
                        : { textAlign: "left", backgroundColor: "white" }
                    }
                  >
                    {this.state.DASHBOARD_APP_SEBRAE.personas.map((row) => {
                      return row.persona !== 99 ? (
                        <MenuItem id={row.persona} value={row.persona}>
                          Persona {row.persona}
                        </MenuItem>
                      ) : (
                        <MenuItem id={row.persona} value={row.persona}>
                          Sem persona
                        </MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>
              </SelectContainer>
            </HeaderContainer>

            {this.renderStates(MOST_SEARCHED_WORDS)}
            {this.showData(MOST_SEARCHED_WORDS) ? (
              <WordCloud words={data.MOST_SEARCHED_WORDS.items} />
            ) : null}
          </Card1>
        </CardGroup>
        <CardGroup>
          <Card2>
            <HeaderContainer>
              <HeaderText>Temas de interesse mais selecionados</HeaderText>
            </HeaderContainer>

            {this.renderStates(MOST_SELECTED_THEMES)}
            {this.showData(MOST_SELECTED_THEMES) ? (
              <Plot
                useResizeHandler={true}
                style={{ width: "100%", minHeight: "36vh" }}
                data={[
                  {
                    type: "bar",
                    orientation: "h",
                    x: data.MOST_SELECTED_THEMES.x,
                    y: data.MOST_SELECTED_THEMES.y,
                  },
                ]}
                config={{
                  displaylogo: false,
                  displayModeBar: false,
                  responsive: true,
                  editable: false,
                }}
                layout={{
                  margin: {
                    l: 200,
                    t: 20,
                    pad: 10,
                  },
                  yaxis: {
                    autorange: "reversed",
                  },
                }}
              />
            ) : null}
          </Card2>

          <Card2>
            <HeaderContainer>
              <HeaderText>Tipos de conteúdos mais consumidos</HeaderText>
            </HeaderContainer>

            {this.renderStates(MOST_SEARCHED_CONTENT_THEMES)}
            {this.showData(MOST_SEARCHED_CONTENT_THEMES) ? (
              <Plot
                useResizeHandler={true}
                style={{ width: "100%", minHeight: "36vh" }}
                data={[
                  {
                    type: "bar",
                    orientation: "h",
                    x: data.MOST_SEARCHED_CONTENT_THEMES.x,
                    y: data.MOST_SEARCHED_CONTENT_THEMES.y,
                  },
                ]}
                config={{
                  displaylogo: false,
                  displayModeBar: false,
                  responsive: true,
                  editable: false,
                }}
                layout={{
                  margin: {
                    l: 250,
                    t: 20,
                    pad: 10,
                  },
                  yaxis: {
                    autorange: "reversed",
                  },
                }}
              />
            ) : null}
          </Card2>
        </CardGroup>
      </>
    );
  };

  render() {
    const { dashboard, chip } = this.props;
    const isLoading = this.isLoading(dashboard);

    return (
      <>
        <TitleContainer>
          <Header icon={<MonitorIcon />} title={"Dashboard"} />
        </TitleContainer>

        {this.getTabs(isLoading)}

        {chip.dashboard === DASHBOARD_GENERAL_INDICATORS
          ? this.getIndicators(isLoading)
          : null}
        {chip.dashboard === DASHBOARD_USE_APP_INDICATORS
          ? this.getUseAppIndicators(isLoading)
          : null}
        {chip.dashboard === DASHBOARD_PERSONA_INDICATORS
          ? this.getPersonaIndicators()
          : null}
        {chip.dashboard === DASHBOARD_APP_SEBRAE
          ? this.getAppSebraeIndicators(isLoading)
          : null}
        {chip.dashboard === DASHBOARD_CHATBOT_INDICATORS
          ? this.getChatbotIndicators(isLoading)
          : null}
      </>
    );
  }
}

const mapStateToProps = ({ dashboard, chip, tenant }) => ({
  dashboard,
  chip,
  tenant,
});

export default compose(
  connect(mapStateToProps, {
    ...DashboardCreators,
    ...toastrActions,
  })
)(Dashboard);

import { put, select } from 'redux-saga/effects';
import { Creators as TenantCreators } from '../ducks/tenant';
import { Creators as MapCreators } from '../ducks/map';
import { actions as toastrActions } from 'react-redux-toastr';
import { getUserData } from '../../utils';
import { captureSentryException } from '../../utils';

export function* setTenant() {
  try {
    let userData = {};
    const  tenant  = yield select(state => state.tenant.tenant)

    if (!tenant) {
      userData = yield getUserData();

      yield put(TenantCreators.tenantSuccess(userData.tenant, userData.role, userData.name, userData.state_name));
      yield put(MapCreators.mapInit(userData.tenant));
    }

    

  } catch (err) {
    console.error(err);
    yield captureSentryException(err, {},"SetTenant");
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao habilitar tenant'
    }));
  }
}
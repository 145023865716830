import Table from "../Table";
import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as ChatbotCreators } from "../../store/ducks/chatbot";

import {
  Container,
  ProductContainer,
  DetailContainer,
  Header,
  HeaderContainer,
  HeaderText,
  HeaderTableContainer,
} from "./styles";
import IconButton from "../IconButton";
import CrmIcon from "react-feather/dist/icons/external-link";
import { CSV, ITEMS_PER_PAGE, NIVEL1, XLS } from "../../constants";

class Chatbot extends Component {
  state = {
    page: 1,
  };

  componentDidMount() {
    this.setState({ page: 1 });
  }

  submit = ({ sort, is_download, download_extension }) => {
    const { filter, tenant } = this.props;

    this.props.getChatbot({
      ...filter,
      page: this.state.page,
      sort: sort || this.props.chatbot.sort,
      items_per_page: ITEMS_PER_PAGE,
      is_download: is_download || false,
      download_extension: download_extension || CSV,
      tenant: tenant.tenant
    });
  };

  handlePageChange = (page) => this.setState({ page }, () => this.submit({}));

  handleTableSortBy = (data, sort) => {
    this.props.getChatbotSortBy(sort);
    this.submit({ sort });
  };

  handleDownload = ({ download_extension }) =>
    this.submit({ is_download: true, download_extension });

  renderProductContainer = () => {
    const { data, sort, error } = this.props.chatbot;
    const { chatbot } = this.props;
    const { role } = this.props.tenant;
    const isLoading = chatbot.loading;

    return (
      <>
        <DetailContainer>
          <ProductContainer>
            <HeaderContainer>
              <Header>
                {!isLoading &&
                data.items &&
                data.items.length &&
                role !== NIVEL1 ? (
                  <HeaderTableContainer>
                    <HeaderText>Listagem de atendimentos</HeaderText>
                    {role === NIVEL1 ? null : (
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <IconButton
                              onClick={this.handleDownload.bind(this, {
                                download_extension: CSV,
                              })}
                              mixpanelTrackEvent={"CHATBOT_DOWNLOAD_CSV"}
                              label={"Exportar CSV"}
                              icon={<CrmIcon />}
                            />
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <IconButton
                              onClick={this.handleDownload.bind(this, {
                                download_extension: XLS,
                              })}
                              mixpanelTrackEvent={"REC_PRODUCT_DOWNLOAD_XLS"}
                              label={"Exportar XLS"}
                              icon={<CrmIcon />}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </HeaderTableContainer>
                ) : null}
              </Header>
            </HeaderContainer>
            <Table
              data={!isLoading ? data : null}
              sort={sort}
              error={error}
              loading={isLoading}
              pageCount={Math.ceil(data.count / ITEMS_PER_PAGE)}
              sortBy={this.handleTableSortBy}
              onPageChange={this.handlePageChange}
              history={this.props.history}
              page={this.state.page}
            />
          </ProductContainer>
        </DetailContainer>
      </>
    );
  };

  render() {
    return <Container>{this.renderProductContainer()}</Container>;
  }
}

const mapStateToProps = ({ filter, chatbot, tenant }) => ({
  filter,
  chatbot,
  tenant,
});

export default compose(
  connect(mapStateToProps, {
    ...ChatbotCreators,
    ...FilterCreators,
  })
)(Chatbot);

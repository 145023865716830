import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  dashboardRequest: ['dashboardType'],
  getDashboard: ['requests'],
  dashboardSuccess: ['data', 'dashboardType'],
  dashboardError: ['err', 'dashboardType']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  loading: {},
  error: {}
});

/* Reducers */

export const request = (state, { dashboardType }) => {
  return state.merge({
    loading: {
      ...state.loading,
      [dashboardType]: true
    }
  })
};

export const success = (state, { data, dashboardType }) => {
  return state.merge({
    data: {
      ...state.data,
      [dashboardType]: data
    },
    loading: {
      ...state.loading,
      [dashboardType]: false
    },
    error: {
      ...state.error,
      [dashboardType]: false
    }
  })
};

export const error = (state, { dashboardType }) => {
  return state.merge({
    loading: {
      ...state.loading,
      [dashboardType]: false
    },
    error: {
      ...state.error,
      [dashboardType]: true
    }
  })
};

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_REQUEST]: request,
  [Types.DASHBOARD_SUCCESS]: success,
  [Types.DASHBOARD_ERROR]: error
});

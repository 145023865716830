import styled from 'styled-components';
import { Button, Accordion, InputLabel } from '@material-ui/core';
import { baseColor } from '../App/theme';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Text = styled.div`
  margin: 1rem;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 16px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin-top: 3vh;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 1rem;
  text-transform: none;
  font-size: 16px;
`;

export const CheckboxContainer = styled.div`
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  text-transform: none !important;
  font-size: 16px !important;
`;

export const CenterContainer = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  margin: 10% auto;
`;


export const CheckboxEmptyState = styled.div`
  display: flex;
  color: #B9B9B9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.065em;
  justify-content: center;
  padding-top: 0;
  padding-left: 1rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1vh;
`;

export const ClearText = styled.div`
  color: ${baseColor};
  font-size: 14px;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root:before: {
    backgroundColor: "white";
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  margin-right: 15px;
  text-align: end;
  margin-top: 2px;
`;

export const AccordionText = styled.div`
  color: ${baseColor};
  font-size: 14px;
  margin-left: 75px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledStartInputLabel = styled(InputLabel)`
  margin-left: 15px;
  text-align: left;
  margin-top: 2px;
`;

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    marginRight: '30px',
    marginTop: '-30px',
    boxShadow: theme.shadows[5]
  },
}))(Tooltip);
import Immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';

const appBase = process.env.REACT_APP_APPNAME;

const INITIAL_STATE = Immutable({
  title: null
});

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setTitle: ['title']
});

/* Reducers */

export const set = (state, { title }) => {
  window.document.title = `${appBase} - ${title}`
  return state.merge({ title });
}

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_TITLE]: set,
});
import React, { Component } from 'react';
import { Label } from './styles';
import { baseColorLight } from '../App/theme';
import { Mixpanel } from '../../utils/mixpanel';

class IconButton extends Component {

  state = {
    isHovered: false
  }

  onClick = () => {
    if (this.props.mixpanelTrackEvent) Mixpanel.track(this.props.mixpanelTrackEvent);

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  onMouseOver = () => this.setState({ isHovered: true });

  onMouseOut = () => this.setState({ isHovered: false });

  getIcon = ({ color }) => {
    const { isHovered } = this.state;
    const { icon, size } = this.props;
    let newColor = color || baseColorLight;

    if (isHovered) {
      newColor = '#FFF';
    }

    return React.cloneElement(icon, {
      size: size || 16,
      color: newColor
    }, null);
  }

  render() {
    const { label, color, disabled, style } = this.props;
    const icon = this.getIcon({ color });

    return (
      <Label
        style={style}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        onClick={this.onClick.bind(this)}
        disabled={disabled}>
        {icon}&nbsp;{label}
      </Label>
    );
  }
}

export default IconButton;

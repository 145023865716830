import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  targetAudiencePerProductInit: [],
  targetAudiencePerProductRequest: [],
  targetAudiencePerProductSuccess: ['data'],
  targetAudiencePerProductError: ['err'],
  getTargetAudiencePerProduct: ['filter']
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  error: false
});

/* Reducers Product */

export const init = state => state.merge({ data: [] });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.TARGET_AUDIENCE_PER_PRODUCT_INIT]: init,
  [Types.TARGET_AUDIENCE_PER_PRODUCT_REQUEST]: request,
  [Types.TARGET_AUDIENCE_PER_PRODUCT_SUCCESS]: success,
  [Types.TARGET_AUDIENCE_PER_PRODUCT_ERROR]: error
});
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import compose from 'recompose/compose';
import styles from './Links.styles';
import LogoutIcon from 'react-feather/dist/icons/log-out';
import UsersIcon from 'react-feather/dist/icons/users';
import FlagIcon from 'react-feather/dist/icons/flag';
import MarketIcon from 'react-feather/dist/icons/map-pin';
import LeadsIcon from 'react-feather/dist/icons/user-plus';
import ProspectIcon from 'react-feather/dist/icons/bar-chart';
import MonitorIcon from 'react-feather/dist/icons/monitor';
import HelpIcon from 'react-feather/dist/icons/help-circle';
import ChatIcon from 'react-feather/dist/icons/message-circle';
import logo from '../../assets/logo.svg';
import { Auth } from "aws-amplify";
import { SEARCH, PRODUCT, MARKET, DASHBOARD, 
  LEADS, HELPCENTER, SETTINGS, PROSPECTS, CHAT } from '../../constants/routes';
import { Menu, MenuItem } from '@material-ui/core';
import { SEBRAE_RN, SEBRAE_MA, SEBRAE_PE, SEBRAE_GO, ADMIN, SEBRAE_ES} from '../../constants';
import SettingsIcon from 'react-feather/dist/icons/settings';

const links = [
  {
    tenants: [],
    roles: [],
    title: 'Dashboard',
    href: DASHBOARD,
    icon: <MonitorIcon />
  },
  {
    tenants: [],
    roles: [],
    title: 'Cliente',
    href: SEARCH,
    icon: <UsersIcon />
  },
  {
    tenants: [],
    roles: [],
    title: 'Produto',
    href: PRODUCT,
    icon: <FlagIcon />
  },
  {
    tenants: [],
    roles: [],
    title: 'Mercado',
    href: MARKET,
    icon: <MarketIcon />
  },
  {
    tenants: [SEBRAE_RN, SEBRAE_MA, SEBRAE_PE, SEBRAE_GO, SEBRAE_ES],
    roles: [],
    title: 'Leads',
    href: LEADS,
    icon: <LeadsIcon />
  },
  {
    tenants: [SEBRAE_PE],
    roles: [],
    title: 'Prospects',
    href: PROSPECTS,
    icon: <ProspectIcon />
  },
  {
    tenants: [SEBRAE_PE, SEBRAE_ES],
    roles: [],
    title: 'Chat',
    href: CHAT,
    icon: <ChatIcon />
  },
  {
    tenants: [],
    roles: [ADMIN],
    title: 'Configurações',
    href: SETTINGS,
    icon: <SettingsIcon />
  }
];

class Links extends Component {

  state = {
    selectedIndex: 0,
    anchorEl: null,
    open: true,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  async logout() {
    window.history.pushState({}, null, '/');
    window.location.reload();
    await Auth.signOut();
  }

  handleMenuItemClose = () => this.setState({ anchorEl: null });

  handleMenuItemClick = (href, event) => {
    this.handleMenuItemClose();
    this.props.history.push(href);
  };

  handleClickListItem = (index, event) => {
    this.setState({ anchorEl: event.currentTarget, selectedIndex: index });
  };

  isLinkSelected = (idx) => {
    let isLinkSelected = false;
    const path = this.props.location.pathname;
    const listItem = links[idx];

    if (listItem.children && listItem.children && listItem.children.items && listItem.children.items.length) {
      listItem.children.items.forEach(item => {
        if (item.href === path) {
          isLinkSelected = true;
        }
      });
    }

    if (!listItem.chidren && listItem.href && listItem.href === path) {
      isLinkSelected = true;
    }

    return isLinkSelected;
  }

  getLink = (opts, classes, idx) => (
    <ListItem
      button
      title={opts.title}
      component={opts.href ? NavLink : null}
      disableRipple={true}
      key={idx}
      onClick={opts.children ? this.handleClickListItem.bind(this, idx) : null}
      to={opts.href ? opts.href : null}
      selected={this.isLinkSelected(idx)}
      classes={{
        button: classes.listTool,
        gutters: classes.gutters,
        root: classes.bgIcon
      }}
    >
      <ListItemIcon classes={{
        root: classes.listToolIcon
      }}>
        {opts.icon}
      </ListItemIcon>
    </ListItem>
  )

  renderSubMenu = () => {
    const { anchorEl, selectedIndex } = this.state;
    let listItem = links[selectedIndex];

    if (!listItem.children || !listItem.children.header) {
      return;
    }

    if (!listItem.children.items || !listItem.children.items.length) {
      return;
    }

    return (
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleMenuItemClose}
      >
        <MenuItem style={{ fontWeight: 'bold' }}>{listItem.children.header}</MenuItem>
        {listItem.children.items.map((option, index) => (
          <MenuItem
            key={index}
            selected={false}
            onClick={this.handleMenuItemClick.bind(this, option.href)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    )
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  getTenant = () => {
    const { tenant } = this.props.tenant;
    if(tenant !== null)
      return tenant.substr(6,2).toUpperCase();
    else
      return null;
  }

  getAvailableLinks = () => {
    let availableLinks = [];
    const { tenant } = this.props.tenant;
    const { role } = this.props.tenant;

    if (!tenant) return availableLinks;

    links.forEach(link => {
      let isAvailableToTenant = false;
      let isAvailableToRole = false;

      if ((tenant && link.tenants.includes(tenant)) || !link.tenants || !link.tenants.length){
        isAvailableToTenant = true;
      }

      if ((role && link.roles.includes(role)) || !link.roles || !link.roles.length) {
        isAvailableToRole = true;
      }

      if (isAvailableToTenant && isAvailableToRole) {
        availableLinks.push(link);
      }
    });

    return availableLinks;
  };

  render() {
    const { classes } = this.props;
    const { role } = this.props.tenant;
    const userName = this.props.tenant.name;
    const availableLinks = this.getAvailableLinks();
    const estate = this.getTenant();

    return (
      <div className={classes.DrawerContainer} >
        <div className={classes.logo}><img src={logo} alt="Home" /></div>

        <List component="nav" classes={{ root: classes.ToolsContainer }}>
          <div className={classes.infoContainer }>{estate}</div>
          { userName ?
            <div className={classes.infoContainer }>Olá, { String(userName.substr(0,userName.indexOf(' '))) }</div>
            :
              role === ADMIN ?
              <div className={classes.infoContainer }>Olá, Admin.</div>
              :
              <div className={classes.infoContainer }>Olá, Atendente</div>
          }
          <Divider classes={{ root: classes.divider }}/>
        </List >

        <List component="nav" classes={{ root: classes.linksContainer }}>
          {availableLinks.map((l, idx) => l.roles.includes(ADMIN) ? null : this.getLink(l, classes, idx))}
        </List >
      
      { 
        role === 'ADMIN' ?
        <List component="nav" classes={{ root: classes.adminContainer }}>
          <Divider classes={{ root: classes.divider }}/>
          <div className={classes.infoContainer }>Admin.</div>
          {availableLinks.map((l, idx) => l.roles.includes(ADMIN) ? this.getLink(l, classes, idx) : null)}
        </List>
        :
        <List component="nav" classes={{ root: classes.adminContainer }}></List>
      }
        <List component="nav" classes={{ root: classes.ToolsContainer }}>
          <ListItem
            button={true}
            disableRipple={true}
            onClick={() => this.openInNewTab(HELPCENTER)}
            classes={{ button: classes.listTool, gutters: classes.gutters, root: classes.bgIcon }}
          >
            <ListItemIcon classes={{ root: classes.listToolIcon }}>
              <HelpIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem
            button={true}
            disableRipple={true}
            onClick={this.logout}
            classes={{ button: classes.listTool, gutters: classes.gutters, root: classes.bgIcon }}
          >
            <ListItemIcon classes={{ root: classes.listToolIcon }}>
              <LogoutIcon />
            </ListItemIcon>
          </ListItem>
          {/* <Divider classes={{ root: classes.divider }}/>
          <ListItem classes={{ root: classes.listItemExpand}}
            button={true}
            disableRipple={true}
            onClick={this.logout}
            classes={{ button: classes.listTool, gutters: classes.gutters, root: classes.bgIcon }}
          >
            <ListItemIcon classes={{ root: classes.listToolIcon }}>
              <img src={ExpandIcon}/>
              <div className={classes.expandContainer }>Expandir</div>
            </ListItemIcon>
          </ListItem> */}
        </List>
        {this.renderSubMenu()}
      </div>
    );
  }
}


const mapStateToProps = ({ tenant }) => ({ tenant });


export default withRouter(compose(
  withStyles(styles),
  connect(mapStateToProps, {})
)(Links));

import React from 'react';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportFile } from '../utils';
import { CSV } from '../constants/index'

class FileReader extends React.Component {
    constructor() {
      super();
      this.state = {
        csvfile: undefined
      };
    }
  
    downloadCSV = () => {
        exportFile(['name', 'email', 'permission'], [], 'modelo_cadastro_usuarios', CSV)
    }

    handleChange = (event) => {
        if(event.target.files[0] !== undefined) {
            this.props.onSelectFile(event.target.files[0]);
            event.target.value = ''
        }
    };
  
    render() {
      return (
          <div style={{
              display: "flex",
              paddingTop: "30px",
          }}>
              <div>
                  <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.downloadCSV}>
                      <GetAppIcon style={{ fontSize: 20 }} />
                      <span style={{ paddingLeft: '0.5em', textTransform: 'none', fontSize: 15 }}>Baixar Modelo</span>
                  </Button>
              </div>
              <div style={{marginLeft: "50px"}}>
                  <input
                      type="file" 
                      accept=".csv"
                      disabled={this.props.disabled}
                      id="text-button-file"
                      style={{ display: 'none' }}
                      onChange={this.handleChange}
                      placeholder={null} />
                  <label
                      htmlFor="text-button-file"
                      style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Button
                          disabled={this.props.disabled}
                          color="primary"
                          component="span"
                          variant="outlined"
                          startIcon={this.props.startIcon}>
                          <span style={{ paddingLeft: '0.5em', textTransform: 'none', fontSize: 15 }}>Escolher Arquivo</span>
                      </Button>
                  </label>
              </div>
          </div>
      );
    }
  }
  
  export default FileReader
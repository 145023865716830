import styles, {
  lineLayout, fillLayout,
  getFillPaintBase, linePaintBase
} from './Map.style';
import mapboxStyle from '../../data/map-style.json';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import ReactMapboxGl, { GeoJSONLayer, Popup } from 'react-mapbox-gl';
import MapLegend from '../MapLegend/MapLegend';

const ReactMapGL = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN
});

class Map extends Component {

  state = {
    hoveredFeature: null
  };

  handleMapDataLoading = map => {
    map.resize()
  }

  onClick = event => {
    const { features } = event;
    let hoveredFeature = features && features.find(f => f.layer.type === 'fill');

    if (this.props.onClick) {
      return this.props.onClick(hoveredFeature);
    }
  }

  onHover = event => {
    const { map } = this.props;
    const { features } = event;
    let hoveredFeature = features && features.find(f => f.layer.type === 'fill');

    if (!hoveredFeature || !hoveredFeature.properties || !map.data.data_by_key) {
      this.setState({ hoveredFeature: null, x: null, y: null });
      return;
    }

    if (map.data.data_by_key[hoveredFeature.properties.NM_MUNICIP]) {
      hoveredFeature.properties = { ...hoveredFeature.properties, ...map.data.data_by_key[hoveredFeature.properties.NM_MUNICIP] }
      this.setState({ hoveredFeature, x: event.lngLat.lng, y: event.lngLat.lat });
    }
  };

  renderTooltip() {
    const { showTooltipValue, map } = this.props;
    const { hoveredFeature, x, y } = this.state;

    if (!hoveredFeature) return null;

    return (
      <Popup
        coordinates={[x, y]}
        offset={{
          'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
        }
        }>
        <div><b>Município:</b> {hoveredFeature.properties.NM_MUNICIP}</div>
        {showTooltipValue !== false ? <div><b>{map.data.hover_text}:</b> {`${hoveredFeature.properties.value}`}</div> : null}
      </Popup>
    )
  }

  render() {
    const { map, hasLegend, zoom } = this.props;

    return (
      <ReactMapGL
        containerStyle={{
          width: '100%',
          height: '100%',
          background: '#FDFDFD',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
          borderRadius: '5px',
          opacity: map.loading ? .2 : 1
        }}
        style={mapboxStyle}
        center={map.center}
        zoom={zoom ? zoom : map.zoom}
        onDataLoading={this.handleMapDataLoading}
      >

        {this.renderTooltip()}

        <GeoJSONLayer
          before="road-label"
          fillOnClick={this.onClick}
          fillOnMouseMove={this.onHover}
          fillOnMouseLeave={this.onHover}
          data={map.geoJson}
          fillLayout={fillLayout}
          lineLayout={lineLayout}
          linePaint={linePaintBase(map.data.length ? '#000' : '#CAD2D3')}
          fillPaint={getFillPaintBase(map.data)}
        />

        {hasLegend !== false ? <MapLegend /> : null}
      </ReactMapGL>
    )
  }
}

const mapStateToProps = ({ map, tenant }) => ({ map, tenant });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(Map);

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  FormContainer, UserRegisterContainer, HeaderText,
  FormHeader, ButtonContainer, ImportCSVContainer, StyledFormControlLabel,
  AgreemensContainer, StyledTableContainer, StyledButton, DetailContainer,
  HeaderTableContainer, TextContainer, Text, StyledTextField, SelectContainer,
  StyledTableRow, StyledTableCell, DashedDivider, HeaderContainer, StyledSelect,
  StyledTooltip
} from './styles';
import { Creators as UserRegisterCreators } from '../../store/ducks/user_register';
import { Creators as FilterCreators } from '../../store/ducks/filter';
import { Creators as UsersGroups } from '../../store/ducks/users_groups';

import TermsOfUse from '../TermsOfUse'
import { TYPE_LINK } from '../../utils/schema';
import {
  Table, CircularProgress, Dialog, DialogActions, Button, FormControl, InputLabel,
  DialogTitle, DialogContent, DialogContentText, Checkbox, MenuItem
} from '@material-ui/core';
import {
  AGREE, DISAGREE, CREATE, SHEET,  SHOW
} from '../../constants/index';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from 'react-feather/dist/icons/upload-cloud';
import UploadIcon from '../../assets/icone_upload.svg';
import AddIcon from 'react-feather/dist/icons/plus-circle';
import { toastr } from 'react-redux-toastr';
import FileReader from '../../utils/file_reader';
import Papa from 'papaparse';
import DetailTable from '../Table';
import HelpIcon from 'react-feather/dist/icons/help-circle';
import Typography from '@material-ui/core/Typography';

class UserRegister extends Component {

  state = {
    rows: [
      {
        name: '',
        email: '',
        permission: '',
        tenant: this.props.tenant.tenant
      }
    ],
    permissionLevelSelected: '',
    isChecked: false,
    isCheckedImport: false,
    showDialog: false,
    showDialogImport: false,
    showDialogResendEmail: false,
    showDialogDeleteUser: false,
    isAccountManage: false
  };

  handleTableSortBy = (data, sort) => {
    this.props.getUsersSortBy(sort);
  };

  handleFile = (file) => {
    Papa.parse(file, {
      complete: this.handleSubmit,
      header: true
    });
  }

  handleAddRow = () => {
    this.setState({
      rows: [...this.state.rows, {
        name: '',
        email: '',
        permission: '',
        tenant: this.props.tenant.tenant
      }]
    });
  };

  handleSubmit = (result) => {
    let data
    if (result.data) {
      result.data.pop()
      data = result.data.map(item => ({ ...item, "tenant": this.props.tenant.tenant }))
    }
    if (this.isValidRequest(data ? data : null)) {
      this.props.addUsers(data ? data : this.state.rows)
    }
  }

  handleAccountManagement = () => {
    if (!this.state.isAccountManage) {
      this.props.getUsers();
    }
    this.setState({ isAccountManage: !this.state.isAccountManage });
  }

  componentDidMount() {
    this.props.getUsersGroups();
  }

  isValidRequest = (data) => {
    let isValid = true
    let rows = data === null ? [...this.state.rows] : data

    rows.forEach((row) => {
      if (!row.name || !row.email) {
        toastr.warning('Cadastro inválido', 'Informe um nome e um email para cada conta')
        isValid = false
      }
    })
    return isValid
  }

  handleChange = (e) => {
    let rows = [...this.state.rows]
    let row = {
      ...rows[e.target.id === undefined ? e.currentTarget.id : e.target.id],
      [e.target.name]: e.target.value
    }
    rows[e.target.id === undefined ? e.currentTarget.id : e.target.id] = row;

    this.setState({ rows });
  }

  handleCheck(event) {
    if (event.target.name === SHEET) {
      this.setState({ isCheckedImport: event.target.checked, isChecked: false });
    } else {
      this.setState({ isChecked: event.target.checked, isCheckedImport: false });
    }
  }

  handleDialog = (action, origin) => {
    if (origin === CREATE && action === SHOW)
      this.setState({ showDialog: !this.state.showDialog });

    if (origin === SHEET && action === SHOW)
      this.setState({ showDialogImport: !this.state.showDialogImport });

    if (origin === CREATE && action === AGREE) {
      this.setState({ isChecked: true, isCheckedImport: false, showDialog: !this.state.showDialog });
    }
    if (origin === SHEET && action === AGREE) {
      this.setState({ isChecked: false, isCheckedImport: true, showDialogImport: !this.state.showDialogImport });
    }
    if (origin === CREATE && action === DISAGREE) {
      this.setState({ isChecked: false, showDialog: !this.state.showDialog });
    }
    if (origin === SHEET && action === DISAGREE) {
      this.setState({ isCheckedImport: false, showDialogImport: !this.state.showDialogImport });
    }
  }

  renderUserAccountContainer = () => {
    const { loading, sort, error, data } = this.props.user_register;

    return (
      <div
        style={{
          height: '75%',
          width: '95%',
          overflow: 'auto',
          top: '100px',
          left: '40px',
          position: 'absolute',
        }}>
        <DetailTable
          data={data}
          sort={sort}
          error={error}
          loading={loading}
          history={this.props.history}
          sortBy={this.props.getUsersSortBy}
        />
      </div>
    )
  }

  renderUserRegisterTable = () => {
    const { loading, data } = this.props.users_groups;

    return (
      <Table>
        {this.state.rows.map((row, index) => (
          <StyledTableRow>
            <StyledTableCell align="center">
              <StyledTextField
                name="name"
                id={index}
                onChange={this.handleChange}
                label="Nome"
                variant="outlined" />
            </StyledTableCell>
            <StyledTableCell align="center">
              <StyledTextField
                name="email"
                id={index}
                onChange={this.handleChange}
                label="Email Sebrae"
                variant="outlined" />
            </StyledTableCell>
            <StyledTableCell align="center">
              <SelectContainer>
                <FormControl variant="outlined">
                  <InputLabel>{loading ? "Carregando..." : "Nível de permissão"}</InputLabel>
                  <StyledSelect
                    name="permission"
                    onChange={this.handleChange}
                    value={row.permission}
                    label={loading ? "Carregando" : "Nível de permissão"}
                    disabled={loading}
                    style={loading ? { textAlign: 'left', backgroundColor: 'lightgray'} : { textAlign: 'left', backgroundColor: 'white'}}
                  >
                    {data && data.map((group) => {
                      return (
                        <MenuItem id={index} value={group.groupname}>{group.desc}</MenuItem>
                      )
                    })}
                  </StyledSelect>
                </FormControl>
              </SelectContainer>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </Table>
    )
  }

  render() {
    const { loading } = this.props.user_register;

    return (
      <>
        <UserRegisterContainer>
          <HeaderTableContainer>
            <HeaderContainer>
              <AddIcon />
              <HeaderText>
                {this.state.isAccountManage ? 'Gerenciar Contas' : 'Cadastro de Usuários'}
              </HeaderText>
            </HeaderContainer>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <table>
                <td>
                <FormHeader>
                  {this.state.isAccountManage ? null : 'Informe os dados dos usuários para cadastrá-los.'}
                </FormHeader>
                </td>
                <td 
                  style={{paddingInline:'1vh'}}
                >
                {this.state.isAccountManage ? null : <div>
                <StyledTooltip
                  placement="right-start"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Níveis de permissão dos usuários:</Typography>
                      <p />
                      <Typography color="inherit"><b>Nível 1</b> - Acesso as telas: Cliente, Produto, Mercado, Leads e Dashboard(apenas Indicadores Gerais), sem exportação e/ou integração com CRM.</Typography>
                      <p />
                      <Typography color="inherit"><b>Nível 2</b> - Acesso a todas as telas do nível 1, com adição da exportação e/ou integração com CRM.</Typography>
                      <p />
                      <Typography color="inherit"><b>Admin.</b> - Acesso a todas as telas, com adição da tela de Cadastro de Usuário e Público Alvo e adição dos Indicadores de Uso do Minerador e Indicadores de Persona no Dashboard.</Typography>
                    </React.Fragment>
                  }
                >
                  <span style={{position: 'static', marginLeft: '18vw'}}>
                    <HelpIcon
                      color='#41A2FF'
                    />
                  </span>
                </StyledTooltip>
              </div>}
                </td>
              </table>
            </div>
            {this.state.isAccountManage ?
              <DetailContainer>
                {this.renderUserAccountContainer()}
              </DetailContainer> :
              <div>
                <FormContainer>
                  <StyledTableContainer>
                    {this.renderUserRegisterTable()}
                  </StyledTableContainer>
                </FormContainer>
                <TextContainer>
                  <Text
                    onClick={() => this.handleAddRow()}
                    type={TYPE_LINK}>Adicionar campo de cadastro
                  </Text>
                </TextContainer>

                <AgreemensContainer>
                  <StyledFormControlLabel
                    value="end"
                    control={<Checkbox
                      color="primary"
                      name={CREATE}
                      checked={this.state.isChecked}
                      onChange={(event) => this.handleCheck(event)}
                    />}
                    labelPlacement="end"
                  />
                  <Text>Concordo com os
                    {<Text
                      onClick={() => this.handleDialog(SHOW, CREATE)}
                      type={TYPE_LINK}> termos de uso </Text>}
                    e
                    {<Text
                      onClick={() => this.handleDialog(SHOW, CREATE)}
                      type={TYPE_LINK}> política de privacidade</Text>}
                    <br /> do Minerador para os novos usuários</Text>
                </AgreemensContainer>

                <ButtonContainer>
                  <StyledButton
                    onClick={loading ? null : this.handleSubmit}
                    variant="outlined"
                    color="primary"
                    component="span"
                    disabled={!this.state.isChecked}
                    startIcon={loading && this.state.isChecked ? <CircularProgress color="primary" size={15} /> : <SaveIcon />}
                  >
                    {loading && this.state.isChecked ? 'Cadastrando Usuários...' : 'Cadastrar Usuários'}
                  </StyledButton>
                </ButtonContainer>
              </div>
            }
          </HeaderTableContainer>

          <StyledButton
            onClick={this.handleAccountManagement}
            variant="outlined"
            color="primary"
            component="span"
            style={{
              position: 'absolute',
              alignSelf: 'flex-end',
              top: '65px',
              right: '80px'
            }}
          >
            {this.state.isAccountManage ? 'Cadastrar Usuários' : 'Gerenciar Contas'}
          </StyledButton>

          {this.state.isAccountManage ? null : <DashedDivider />}
          {this.state.isAccountManage ? null : <ImportCSVContainer>
            <img src={UploadIcon} alt="Upload Icon" />
            <FormHeader style={{ fontSize: '23px' }}>Ou importe uma lista em <br />formato .csv de até 2 mb</FormHeader>
            <AgreemensContainer style={{
              width: '35vh',
              paddingRight: '25px'
            }}>
              <StyledFormControlLabel
                value="end"
                control={<Checkbox
                  color="primary"
                  name={SHEET}
                  checked={this.state.isCheckedImport}
                  onChange={(event) => this.handleCheck(event)}
                />}
                labelPlacement="end"
              />
              <Text>Concordo com os
                {<Text
                  onClick={() => this.handleDialog(SHOW, SHEET)}
                  type={TYPE_LINK}> termos de uso
                </Text>} e
                {<Text
                  onClick={() => this.handleDialog(SHOW, SHEET)}
                  type={TYPE_LINK}> política de privacidade
                </Text>} do Minerador para os novos usuários
              </Text>
            </AgreemensContainer>

            <FileReader
              onSelectFile={this.handleFile}
              disabled={!this.state.isCheckedImport}
              startIcon={loading && this.state.isCheckedImport ?
                <CircularProgress color="primary" size={18} /> :
                <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />}
            />
          </ImportCSVContainer>}

          <Dialog
            open={this.state.showDialog ? true : this.state.showDialogImport ? true : false}
            onClose={this.handleDialog}
            scroll={'paper'}
          >
            <DialogTitle>Termos de Uso e Política de Privacidade</DialogTitle>
            <DialogContent dividers={'paper'}>
              <DialogContentText>
                <TermsOfUse />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={
                  this.state.showDialog ?
                    () => this.handleDialog(DISAGREE, CREATE)
                    : this.state.showDialogImport ?
                      () => this.handleDialog(DISAGREE, SHEET)
                      : null}
                color="primary">
                Discordo
              </Button>
              <Button
                onClick={
                  this.state.showDialog ?
                    () => this.handleDialog(AGREE, CREATE)
                    : this.state.showDialogImport ?
                      () => this.handleDialog(AGREE, SHEET)
                      : null}
                color="primary">
                Concordo
              </Button>
            </DialogActions>
          </Dialog>

        </UserRegisterContainer>
      </>
    )
  }
}

const mapStateToProps = ({ user_register, tenant, filter, data, users_groups }) =>
  ({ user_register, tenant, filter, data, users_groups });

export default compose(
  connect(mapStateToProps, {
    ...UserRegisterCreators,
    ...FilterCreators,
    ...UsersGroups
  })
)(UserRegister);

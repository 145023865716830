import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Text = styled.span`
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  margin: 1rem;
  margin-top: .5rem;
  min-height: 88.4vh;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const TextContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: .5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
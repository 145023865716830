import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import styles from "./styles";
import { withStyles, Dialog, DialogContent } from "@material-ui/core";
import { Creators as DialogCreators } from "../../store/ducks/dialog";
import { Creators as RecMarketCreators } from "../../store/ducks/rec_market";
import { Creators as TenantCreators } from '../../store/ducks/tenant';
import Table from "../Table";
import DownloadFile from "../DownloadFile";
import { REC_CITY_DOWNLOAD, NIVEL1, CSV } from "../../constants";
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";

class RecMarketDialog extends Component {

  state = {
    isOpenedDialog: false,
    exportType: CSV
  };

  handleOpenDialog = () => {
    this.setState({ isOpenedDialog: !this.state.isOpenedDialog })
  };

  handleExportType = (exportType) => { this.setState({ exportType: exportType }); }

  onClose = () => {
    this.props.setDialog("rec_market");
  };

  render = () => {
    const { dialog, role } = this.props;
    const { state_name } = this.props.tenant;
    const { data, defaultData, sort, expandedRow, loading, error, city } =
      this.props.rec_market;
    let cities = city;
    let regional_unit = "";

    if (!dialog.rec_market) {
      return null;
    }

    if (this.props.city) {
      cities = this.props.city.join(", ");
    }

    if (this.props.regional_unit) {
      regional_unit = this.props.regional_unit;
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={this.onClose.bind(this)}
        open={dialog.rec_market}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ height: "55vh", paddingTop: 0 }}>
          {!loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
                fontSize: "1.2rem",
                paddingTop: "1rem",
              }}
            >
              {(city === null || city === "ONLINE") && cities === null ? (
                <div>Produtos recomendados para {state_name}</div>
              ) : (
                <div>
                  Produtos recomendados para clientes residentes em{" "}
                  {cities ? cities : regional_unit}
                </div>
              )}
              {role === NIVEL1 || data.items ? null : (
                <div>
                  <DownloadFile
                    label='Baixar lista'
                    mixpanelTrackEvent={REC_CITY_DOWNLOAD}
                    filename="recomendacao_por_mercado"
                    header={data.header}
                    rows={data.items}
                    isDialogOpened={this.state.isOpenedDialog}
                    handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                    onSelectExportType={this.handleExportType}
                  />
                  <IconButton
                    mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                    onClick={() => this.handleOpenDialog()}
                    label={'Exportar'}
                    icon={<ExportIcon />}
                  />
                </div>
              )}
            </div>
          ) : null}

          <Table
            data={data}
            sort={sort}
            error={error}
            loading={loading}
            defaultData={defaultData}
            expandedRow={expandedRow}
            history={this.props.history}
            sortBy={this.props.recMarketSortBy}
          />
        </DialogContent>
      </Dialog>
    );
  };
}

const mapStateToProps = ({ dialog, rec_market, tenant }) => {
  return { dialog, rec_market, tenant };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    ...DialogCreators,
    ...RecMarketCreators,
    ...TenantCreators
  })
)(RecMarketDialog);

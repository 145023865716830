import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  initFilter: [],
  setFilter: ['filter']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  filter: {}
});

/* Reducers */

export const init = state => state.merge({ ...INITIAL_STATE });

export const set = (state, { filter }) => state.merge({ filter });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.INIT_FILTER]: init,
  [Types.SET_FILTER]: set
});

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  targetAudienceRequest: ['request'],
  targetAudienceSuccess: ['data'],
  targetAudienceError: ['err'],
  getTargetAudience: ['filter'],
  updateTargetAudience: ['filter'],
  targetAudienceInit: [],
  targetAudienceFilter: ['filter'],
  targetAudienceSortBy: ['data', 'sort'],
  targetAudienceExpand: ['expandedRow'],
  targetAudienceInitTable: []
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  error: false,
  loading: false,
  request: {},
  count: 0,
  sort: { name: { value: 'produtoid', order: 'asc' } },
  expandedRow: null
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { data, sort }) => state.merge({ data, sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.TARGET_AUDIENCE_REQUEST]: request,
  [Types.TARGET_AUDIENCE_SUCCESS]: success,
  [Types.TARGET_AUDIENCE_ERROR]: error,
  [Types.TARGET_AUDIENCE_INIT]: init,
  [Types.TARGET_AUDIENCE_SORT_BY]: sortBy,
  [Types.TARGET_AUDIENCE_EXPAND]: expand,
  [Types.TARGET_AUDIENCE_INIT_TABLE]: initTable,
});
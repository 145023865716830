export const getSnackbarKey = () => {
  return new Date().getTime();
}

export const showRunningNotistack = ({ title, key = getSnackbarKey(), enqueueSnackbar, action, content }) => {
  enqueueSnackbar(title, {
    key,
    persist: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    preventDuplicate: true,
    content
  });
}
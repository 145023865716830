import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  customerDetailInit: ['field'],
  customerDetailSelect: ['field', 'data'],
  customerDetailRequest: [],
  customerDetailSuccess: ['data'],
  customerDetailError: ['err'],
  getCustomersDetail: ['filter'],
  customerDetailSortBy: ['sort'],
  customerDetailExpand: ['expandedRow'],
  customerDetailInitTable: []
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: [],
  sort: { cliente: { value: 'cliente', order: 'asc' } },
  defaultData: [],
  error: false,
  loading: false,
  expandedRow: null,
  selecteds: {
    port: {},
    client: {}
  }
});

/* Reducers Table */

export const init = (state, { field }) => {
  return state.merge({
    data: {
      ...state.data,
      [field]: []
    },
    selecteds: { ...INITIAL_STATE.selecteds }
  })
};

export const select = (state, { field, data }) => {
  return state.merge({
    selecteds: {
      ...state.selecteds,
      [field]: data
    },
  })
};

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, defaultData: data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { sort }) => state.merge({ sort, expandedRow: null });

export const expand = (state, { expandedRow }) => state.merge({ expandedRow });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort, expandedRow: null });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CUSTOMER_DETAIL_INIT]: init,
  [Types.CUSTOMER_DETAIL_SELECT]: select,
  [Types.CUSTOMER_DETAIL_REQUEST]: request,
  [Types.CUSTOMER_DETAIL_SUCCESS]: success,
  [Types.CUSTOMER_DETAIL_ERROR]: error,
  [Types.CUSTOMER_DETAIL_SORT_BY]: sortBy,
  [Types.CUSTOMER_DETAIL_EXPAND]: expand,
  [Types.CUSTOMER_DETAIL_INIT_TABLE]: initTable
});
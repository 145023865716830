import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
  chatbotDetailsRequest: ["request"],
  chatbotDetailsSuccess: ["data"],
  chatbotDetailsError: ["err"],
  getChatbotDetails: ["attendance_id", "tenant"],
  chatbotDetailsInit: [],
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  error: false,
  loading: false,
  request: {},
});

/* Reducers Table */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { data }) =>
  state.merge({ data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CHATBOT_DETAILS_REQUEST]: request,
  [Types.CHATBOT_DETAILS_SUCCESS]: success,
  [Types.CHATBOT_DETAILS_ERROR]: error,
  [Types.CHATBOT_DETAILS_INIT]: init,
});

import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Mixpanel } from "../../utils/mixpanel";
import { LoadingContainer } from './styles';
import { Creators as TitleCreators } from "../../store/ducks/title";
import { baseColor } from '../App/theme';
import { CircularProgress } from '@material-ui/core';

class Chat extends Component {
  state = {
    isLoading: true,
  };

  iframeRef = React.createRef();

  componentDidMount() {
    this.props.setTitle("Chat");
    Mixpanel.track("INIT_CHAT");
  }

  handleIframeLoad = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <>
        {isLoading && (
          <LoadingContainer color={baseColor}>
            <CircularProgress color="primary" size={24} />
          </LoadingContainer>
        )}
        <iframe
          ref={this.iframeRef}
          src={process.env.REACT_APP_SEBRAE_CHATBOT_URL}
          width="100%"
          height="99.25%"
          frameBorder={0}
          title="Chat"
          onLoad={this.handleIframeLoad}
        ></iframe>
      </>
    );
  }
}

const mapStateToProps = ({ title }) => ({ title });

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
  })
)(Chat);
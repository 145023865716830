import styled from 'styled-components';

export const Container = styled.div`
  height: 80%;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30vw;
  
  button {
    margin-left: .5rem;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: .5rem;
  width: 50%;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh;
  padding-top: 15vh;
`;
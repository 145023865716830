import React from 'react';
import { downloadFromURL } from '.';
import { Auth } from 'aws-amplify';
import StackNotification from '../components/StackNotification';
import { toastr } from 'react-redux-toastr';
import ErrorIcon from 'react-feather/dist/icons/alert-circle';

import firebase from 'firebase/app';
import 'firebase/database';
import { showRunningNotistack } from './snackbar';

const RUNNING = 'RUNNING';
const FINISHED = 'FINISHED';
const ERROR = 'ERROR';
const NOTIFICATION = 'NOTIFICATION';

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
  });
}

const getFirebaseKey = () => {
  return new Date().getTime();
}

const getNotificationMessage = (data) => {
  let counts = {};
  let msg = '';
  let runningMsg = '';
  let finishedMsg = '';

  if (!data || !data.length) {
    return 'Notificações';
  }

  data.forEach(item => {
    if (!item.status) return;

    if (!counts[item.status]) {
      counts[item.status] = 0;
    }

    counts[item.status] += 1;
  });

  if (counts[RUNNING]) {
    runningMsg = counts[RUNNING] > 1 ? `${counts[RUNNING]} downloads em andamento` : `${counts[RUNNING]} download em andamento`;
    msg = runningMsg;
  }

  if (counts[FINISHED]) {
    finishedMsg = counts[FINISHED] > 1 ? `${counts[FINISHED]} downloads finalizados` : `${counts[FINISHED]} download finalizado`;
    msg = finishedMsg;
  }

  if (counts[ERROR]) {
    finishedMsg = counts[ERROR] > 1 ? `${counts[ERROR]} downloads com erro` : `${counts[ERROR]} download com erro`;
    msg = finishedMsg;
  }

  if (runningMsg && finishedMsg) {
    finishedMsg = finishedMsg.replace('downloads', '').replace('download', '');
    msg = `${runningMsg} e ${finishedMsg}`;
  }

  return msg;
}

const callNotistack = (enqueueSnackbar, closeSnackbar, data) => {
  setTimeout(() => { closeSnackbar(NOTIFICATION) }, 100);

  if (!data || !Object.keys(data).length) return null;

  setTimeout(() => {
    if (!data) return null;

    showRunningNotistack({
      key: NOTIFICATION,
      enqueueSnackbar,
      content: (
        <StackNotification
          id={NOTIFICATION}
          data={data}
          closeSnackbar={closeSnackbar}
          message={getNotificationMessage(data)} />
      )
    });
  }, 2000);
}

export const createRunningNotification = async (data) => {
  const key = getFirebaseKey();
  const userId = await getUserId();

  firebase.database().ref(`notifications/${userId}/running`).update({
    [key]: {
      ...data,
      status: RUNNING
    }
  });

  console.log('[Firebase] Notification created!');

  return key;
}

const deleteNotifications = async () => {
  const userId = await getUserId();

  return await firebase.database().ref(`notifications/${userId}`).remove();
}

const downloadFile = async (notification) => {
  if (!notification || !notification.url) return;

  downloadFromURL(notification.url);
}

const getNotifications = async (enqueueSnackbar, closeSnackbar, notification) => {
  let data = [];
  let snapshot = null;
  let hasRunning = false;
  const dbRef = firebase.database().ref();
  const userId = await getUserId();

  try {
    await downloadFile(notification);
    snapshot = await dbRef.child("notifications").child(userId).child("running").get();

    if (snapshot.exists()) {
      Object.keys(snapshot.val()).forEach(key => {
        const value = snapshot.val()[key];

        if (value.status === RUNNING) {
          hasRunning = true;
        }

        if (value.status === ERROR) {
          const toastrType = 'error';
          const toastrOptions = {
            icon: (<div style={{ marginTop: '1vh' }}><ErrorIcon size={30} /></div>),
            status: toastrType,
            timeOut: 5000,
          };
          toastr.error("Erro", "Erro ao realizar download.", toastrOptions);
        }

        data.push({ ...value, key });
      });
    } else {
      console.log("No data available");
    }

  } catch (error) {
    console.error(error);
  };

  if (!hasRunning) {
    await deleteNotifications();
    data = [];
  }

  callNotistack(enqueueSnackbar, closeSnackbar, data);

  return data;
}

const getUserId = async () => {
  const session = await Auth.currentSession();
  return session.idToken.payload.sub;
}

export const listenRunningNotifications = async (enqueueSnackbar, closeSnackbar) => {
  const userId = await getUserId();
  const ref = `notifications/${userId}/running`;
  const notificationsRef = firebase.database().ref(ref);

  notificationsRef.on("child_added", () => {
    getNotifications(enqueueSnackbar, closeSnackbar);
  });

  notificationsRef.on("child_changed", (data) => {
    const newValue = data.val();
    let notification = null;

    if (newValue && newValue.status === FINISHED) {
      notification = { ...data.val(), key: data.key };
    }

    getNotifications(enqueueSnackbar, closeSnackbar, notification);
  });


  console.log(`[Firebase] Listen Notifications from ${ref}`);
}
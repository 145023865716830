import api from '../../services/api';
import { Creators } from '../ducks/persona_info';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getPersonaInfo({ id }) {
  try {
    let response;

    yield put(Creators.personaInfoRequest());
    response = yield call(api.post, 'persona-info', { id });

    yield put(Creators.personaInfoSuccess(response.data));
  } catch (err) {
    yield captureSentryException(err, id);
    yield put(Creators.personaInfoError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar informações da persona'
    }));
  }
}
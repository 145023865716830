import './MapLegend.css';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from './MapLegend.style';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { getColorLegendByDensity } from '../../utils';

class MapLegend extends Component {

  renderLegendColors() {
    const { map } = this.props;

    return (
      <div className="my-legend">
        <div className="legend-scale">
          <ul className="legend-labels">
            {map.data && !map.data.data.length ? <li><span style={{ background: '#E8E8E8' }}></span>Sem dados</li> : null}
            {map.data && map.data.data.length ? map.data.legend.map((item, idx) => <li key={idx}><span style={{ background: getColorLegendByDensity(item.key) }}></span>{item.value}</li>) : null}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { map, classes } = this.props;

    if (map.loading || !map.data.data) {
      return null;
    }

    return (
      <div className={classes.controlPanel}>
        <h3 className={classes.title}>{map.data.legend_text}</h3>
        {this.renderLegendColors()}
      </div>
    );
  }
}

const mapStateToProps = ({ map }) => ({ map });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(MapLegend);
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  personaInfoInit: [],
  personaInfoRequest: [],
  getPersonaInfo: ['id'],
  personaInfoSuccess: ['data'],
  personaInfoError: ['err']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  error: false
});

/* Reducers */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.PERSONA_INFO_INIT]: init,
  [Types.PERSONA_INFO_REQUEST]: request,
  [Types.PERSONA_INFO_SUCCESS]: success,
  [Types.PERSONA_INFO_ERROR]: error
});

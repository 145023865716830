import React, { Component } from 'react';
import { Container } from './styles';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import { Creators as ChipCreators } from '../../store/ducks/chip';

class ChipGroup extends Component {

  handleStateClick = item => {
    this.props.setChip(this.props.context, item);

    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  renderChip = (item) => {
    const { chip, context, isDisabled, tenant } = this.props;

    if (item.isDisabled || (item.role && item.role !== tenant.role)) {
      return null;
    }

    return (
      <div key={item.value} style={{ paddingLeft: '.5rem' }}>
        <Chip
          disabled={isDisabled}
          variant={chip[context] === item.value ? "default" : "outlined"}
          color={chip[context] === item.value ? "primary" : "default"}
          label={item.label}
          onClick={() => this.handleStateClick(item.value)}
        />
      </div>
    )
  }


  render() {
    const { title, data, style } = this.props;

    return (
      <Container style={{ ...style }}>
        <div>{title}</div>
        {data.map(item => this.renderChip(item))}
      </Container>
    );
  }
}

const mapStateToProps = ({ chip, tenant }) => ({ chip, tenant });

export default connect(
  mapStateToProps,
  { ...ChipCreators }
)(ChipGroup);
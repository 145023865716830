import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  setDialogType: ['field', 'action'],
  getDataRow: ['item']
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  item: []
});

/* Reducers */

export const set = (state, { field, action }) => state.merge({ [field]: !state[field], 'action': action });

export const get = (state, { item }) => state.merge({ item });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_DIALOG_TYPE]: set,
  [Types.GET_DATA_ROW]: get
});

import Plotly from 'plotly.js-dist';
import React, { Component } from 'react';
import { CenterContainer } from './styles';
import createPlotlyComponent from 'react-plotly.js/factory';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { baseColor } from '../App/theme';

const Plot = createPlotlyComponent(Plotly);

class BarChart extends Component {

  renderLoading = () => (
    <CenterContainer color={baseColor}>
      <CircularProgress color="primary" />
    </CenterContainer>
  )

  renderError = () => (
    <CenterContainer>Ocorreu um erro</CenterContainer>
  )

  renderEmptyState = () => (
    <CenterContainer>Sem dados</CenterContainer>
  )

  render() {
    let traces = [];
    const { error, loading, data } = this.props.duck;
    const customLayout = this.props.layout || {};

    if (error && !loading) return this.renderError();
    if (loading) return this.renderLoading();

    if (!Object.keys(data).length) return this.renderEmptyState();

    const trace1 = {
      x: data.x,
      y: data.y,
      type: customLayout.type ? customLayout.type : 'bar',
      marker: customLayout.marker ? customLayout.marker : {}
    };

    traces = [trace1];

    return (
      <Plot
        useResizeHandler={true}
        style={{ width: '100%', height: customLayout.height || '25vh' }}
        data={traces}
        config={{
          displaylogo: false,
          displayModeBar: false,
          responsive: true,
          editable: false
        }}
        layout={{
          separators: ',.',
          title: customLayout.title ? {
            text: `<b>${customLayout.title}</b>`,
            x: 0.001,
            y: 1.3,
            font: {
              size: 14,
              color: '#000'
            }
          } : null,
          xaxis: {
            title: customLayout.xaxis_label ? {
              text: `<b>${customLayout.xaxis_label}</b>`,
              font: {
                size: 12,
                color: '#616161'
              }
            } : null,
            type: 'category',
            showticklabels: customLayout.xaxis_showticklabels !== undefined ? customLayout.xaxis_showticklabels : true
          },
          yaxis: {
            ticksuffix: customLayout.yaxis_ticksuffix !== undefined ?  customLayout.yaxis_ticksuffix : null,
            tickformat: customLayout.yaxis_tickformat !== undefined ?  customLayout.yaxis_tickformat : null
          },
          showlegend: false,
          margin: {
            l: customLayout.margin_left || 0,
            r: customLayout.margin_right || 0,
            b: customLayout.margin_bottom || 25,
            t: customLayout.margin_top || 25,
            pad: customLayout.pad || 0
          },
          barmode: 'group',
          plot_bgcolor: customLayout.plot_bgcolor || '#EEE',
          paper_bgcolor: customLayout.paper_bgcolor || '#EEE',
          autosize: true,
          font: {
            family: 'Mada'
          },
        }}
      />
    );
  }
}

export default compose(
  connect(null, null)
)(BarChart);
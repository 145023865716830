import api from '../../services/api';
import { Creators } from '../ducks/macrossegment';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getMacrossegments({ filter }) {
  try {
    yield put(Creators.macrossegmentRequest());
    const response = yield call(api.post, 'macrossegment', filter);

    yield put(Creators.macrossegmentSuccess(filter.chip, response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.macrossegmentError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar macrossegmentos'
    }));
  }
}
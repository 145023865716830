import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import IconButton from "@material-ui/core/Button";

export const StyledButton = withStyles((theme) => ({
  root: {
    fontSize: "16px !important",
    width: "fit-content",
    padding: "5px 20px 5px 20px",
    height: "40px",
    textTransform: "none",
  },
}))(IconButton);

export const StyledTextField = withStyles((theme) => ({
  root: {
    fontSize: "12px",
    width: "12vw",
    backgroundColor: "white",
  },
}))(TextField);

export const Text = styled.div`
  margin: 1rem 0 1rem 0;
  font-size: 18px;
  line-height: 16px;
`;

export const BodyStyle = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding-top: 5%;
`;

export const UserRegisterContainer = styled.div`
  width: 98%;
  height: 88.4vh;
  overflow-y: auto;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 10px;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: "row";
  margin: 10px;
`;

export const HeaderText = styled.div`
  margin-top: 3px;
  margin-left: 5px;
`;

export const SmallText = styled.div`
  padding-left: 1rem;
  font-size: 14px;
  width: 37%;
`;

export const FormSection = styled.div`
  display: flex;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  text-transform: none;
  margin-top: 10%;
  color: rgba(0, 0, 0, 0.5);
`;

export const StyledImg = styled.img`
  width: 82px;
  height: 84px;
  border-radius: 50px;
`;

export const ImgContainer = styled.div`
  position: relative;
`;

export const DeleteImgButton = styled.div`
  color: #005eb8;
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 0px 4px 4px 0px #4579c840;
  border: 2px solid #c2c2c2;
  border-radius: 50px;
  padding: 3px 3px 0px 3px;
  background-color: white;
  cursor: pointer;
`;

export const DialogContainer = styled.div`
  padding: 4vh 2vw 2vh 2vw;
  align-items: center;
  width: 20vw;
  height: fit-content;
`;
export const DialogTitle = styled.div`
  margin: 0 auto 0 auto;
  width: fit-content;
  font-size: 25px;
  line-height: 16px;
  padding-bottom: 4rem;
`;

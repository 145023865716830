import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  UserRegisterContainer,
  HeaderText,
  HeaderContainer,
  Text,
  StyledTextField,
  StyledButton,
  ButtonContainer,
  StyledImg,
  BodyStyle,
  FormSection,
  SmallText,
  ImgContainer,
  DeleteImgButton,
  DialogContainer,
  DialogTitle,
} from "./styles";
import { Creators as ChatbotConfigCreators } from "../../store/ducks/chatbot_config";

import { ARI_S3_BUCKET } from "../../constants";

import SaveIcon from "react-feather/dist/icons/upload-cloud";
import { CircularProgress, Dialog } from "@material-ui/core";
import AddIcon from "react-feather/dist/icons/plus-circle";
import Trash2 from "react-feather/dist/icons/trash-2";
import { fileToBase64 } from "../../utils";

class ChatbotConfig extends Component {
  state = {
    unsavedConfig: {},
    showDialog: false,
  };

  handleSubmit = (result) => {
    const config = this.getConfig();

    this.props.putChatbotConfig({
      ...config,
    });

    if (config.img_data) {
      URL.revokeObjectURL(config.imgURL);
    }
    this.setState({
      unsavedConfig: {},
    });
  };

  getConfig() {
    if (!Object.keys(this.state.unsavedConfig).length) {
      return JSON.parse(JSON.stringify(this.props.chatbot_config.data));
    } else {
      return this.state.unsavedConfig;
    }
  }

  handleNameChange(e) {
    const config = this.getConfig();

    this.setState({
      unsavedConfig: {
        ...config,
        name: e.target.value,
      },
    });
  }

  handleChangeFile = async (event) => {
    if (event.target.files[0] !== undefined) {
      const config = this.getConfig();

      this.setState({
        unsavedConfig: {
          ...config,
          imgURL: URL.createObjectURL(event.target.files[0]),
          img_data: await fileToBase64(event.target.files[0]),
        },
      });
    }
  };

  imgDelete() {
    const config = this.getConfig();
    if (config.imgURL) URL.revokeObjectURL(config.imgURL);
    this.setState({
      unsavedConfig: {
        ...config,
        img_id: "default.png",
        imgURL: "",
      },
    });
    this.onDialogClose();
  }

  onDialogClose = () => {
    this.setState({ showDialog: false });
  };
  openImgDeleteDialog() {
    this.setState({ showDialog: true });
  }

  get_s3_url(img_id) {
    return `${ARI_S3_BUCKET}/assets/${img_id}?${Date.now()}`;
  }

  componentDidMount() {
    const { tenant } = this.props;

    this.props.getChatbotConfig(tenant.tenant);
  }

  render() {
    const { chatbot_config } = this.props;
    const { loading } = chatbot_config;
    const config = this.getConfig();
    return (
      <>
        <UserRegisterContainer>
          <HeaderContainer>
            <AddIcon />
            <HeaderText>Customização do Chatbot</HeaderText>
          </HeaderContainer>
          <BodyStyle>
            {loading || !Object.keys(chatbot_config.data).length ? (
              <CircularProgress color="primary" size={50} />
            ) : (
              <>
                <Text>Chatbot</Text>

                <FormSection>
                  <StyledTextField
                    name="name"
                    id={1}
                    onChange={(e) => this.handleNameChange(e)}
                    label="Nome do chatbot"
                    variant="outlined"
                    value={config.name}
                  />
                  <SmallText>
                    Como o Chatbot irá se apresentar aos usuários.
                  </SmallText>
                </FormSection>

                <Text>Alterar imagem do avatar</Text>

                <FormSection>
                  <ImgContainer>
                    <StyledImg
                      src={
                        config.imgURL
                          ? config.imgURL
                          : this.get_s3_url(config.img_id)
                      }
                    ></StyledImg>

                    {config.img_id !== "default.png" || config.imgURL ? (
                      <DeleteImgButton>
                        <Trash2
                          onClick={() => this.openImgDeleteDialog()}
                          style={{
                            width: "25px",
                            height: "20px",
                          }}
                        />
                      </DeleteImgButton>
                    ) : null}
                  </ImgContainer>
                  <div style={{ marginLeft: "1rem", display: "flex" }}>
                    <input
                      value=""
                      type="file"
                      accept=".PNG"
                      id="text-button-file"
                      style={{ display: "none" }}
                      onChange={this.handleChangeFile}
                      placeholder={null}
                    />
                    <label
                      htmlFor="text-button-file"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <StyledButton
                        color="primary"
                        component="span"
                        variant="outlined"
                        startIcon={this.props.startIcon}
                      >
                        <span
                          style={{
                            paddingLeft: "0.5em",
                            textTransform: "none",
                            fontSize: 15,
                          }}
                        >
                          Procurar Imagem
                        </span>
                      </StyledButton>
                    </label>

                    <SmallText>Arquivo .png com fundo transparente.</SmallText>
                  </div>
                </FormSection>

                <ButtonContainer>
                  <StyledButton
                    onClick={loading ? null : this.handleSubmit}
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={
                      loading && this.state.isChecked ? (
                        <CircularProgress color="primary" size={15} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    Salvar Alterações
                  </StyledButton>
                </ButtonContainer>
              </>
            )}
          </BodyStyle>
        </UserRegisterContainer>
        <Dialog
          onClose={this.onDialogClose.bind(this)}
          open={this.state.showDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContainer>
            <DialogTitle>Deseja Remover?</DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <StyledButton
                onClick={this.imgDelete.bind(this)}
                variant="outlined"
                color="primary"
                component="span"
              >
                Sim
              </StyledButton>
              <StyledButton
                onClick={this.onDialogClose.bind(this)}
                variant="contained"
                color="primary"
                component="span"
              >
                Não
              </StyledButton>
            </div>
          </DialogContainer>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ tenant, chatbot_config }) => ({
  tenant,
  chatbot_config,
});

export default compose(
  connect(mapStateToProps, { ...ChatbotConfigCreators, })
)(ChatbotConfig);

import api from '../../services/api';
import { Creators } from '../ducks/pub_alvo';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getPubAlvos({ filter }) {
  try {
    yield put(Creators.pubAlvoRequest());
    const response = yield call(api.post, 'pub-alvo', filter);

    yield put(Creators.pubAlvoSuccess(filter.chip, response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.pubAlvoError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao listar público alvo'
    }));
  }
}
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const { Types, Creators } = createActions({
  rfbEnterpriseInit: [],
  rfbEnterpriseRequest: [],
  getRfbEnterprises: ['filter'],
  rfbEnterpriseSuccess: ['data'],
  rfbEnterpriseError: ['err'],
  rfbEnterpriseSortBy: ['sort'],
  rfbEnterpriseInitTable: []
});

/** --------------------------------
 * Variable declarations
 * --------------------------------- */

const INITIAL_STATE = Immutable({
  data: {},
  count: 0,
  sort: { razao_social: { value: 'razao_social', order: 'asc' } },
  error: false,
  loading: false
});

/* Reducers Table */

export const init = state => state.merge({ ...INITIAL_STATE });

export const request = state => state.merge({ loading: true });

export const success = (state, { data }) => state.merge({ data, error: false, loading: false });

export const error = state => state.merge({ loading: false, error: true });

export const sortBy = (state, { sort }) => state.merge({ sort });

export const initTable = state => state.merge({ sort: INITIAL_STATE.sort });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.RFB_ENTERPRISE_INIT]: init,
  [Types.RFB_ENTERPRISE_REQUEST]: request,
  [Types.RFB_ENTERPRISE_SUCCESS]: success,
  [Types.RFB_ENTERPRISE_ERROR]: error,
  [Types.RFB_ENTERPRISE_SORT_BY]: sortBy,
  [Types.RFB_ENTERPRISE_INIT_TABLE]: initTable
});
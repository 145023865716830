import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

class WordCloud extends Component {

    render = () => {

        const { words } = this.props;

        const options = {
            colors: ["#3f50b5"],
            enableTooltip: true,
            deterministic: true,
            fontFamily: "Roboto, sans-serif",
            fontSizes: [12, 60],
            fontStyle: "normal",
            fontWeight: "bold",
            padding: 2,
            rotations: 3,
            rotationAngles: [0],
            scale: "sqrt",
            spiral: "archimedean",
            transitionDuration: 1000
        };

        const callbacks = {
            getWordTooltip: word => `${word.text} (${word.value})`,
        }

        return (
            JSON.parse(words).length ?
            <div>
                <ReactWordcloud
                    maxWords={150}
                    callbacks={callbacks}
                    options={options}
                    words={JSON.parse(words)} />
            </div> : 
            <div style={{display: 'flex',
                color: '#B9B9B9',
                fontSize: '1.5rem',
                fontWeight: '500',
                letterSpacing: '0.065em',
                justifyContent: 'center',
                margin: '10% auto'}}>
                Sem dados para este indicador
            </div>
        )
    }
}

const mapStateToProps = ({ tenant }) => ({ tenant });

export default compose(
    connect(mapStateToProps, null)
)(WordCloud);
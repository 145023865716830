
export default (theme) => ({

  DrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },

  linksContainer: {
    paddingBottom: 0,
  },

  listItem: {
    display: 'inline-block',
    padding: 0,
    textTransform: 'Uppercase',
    fontSize: '10px',
    cursor: 'pointer'
  },

  gutters: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
  },

  ToolsContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
  },

  listTool: {
    display: 'inline-block',
    padding: 0,
    cursor: 'pointer',
    "&:hover": {
      background: 'rgba(0,0,0,.2)',
    },
    "&.active-route": {
      background: 'rgba(0,0,0,.2)',
    },
  },

  listToolIcon: {
    marginRight: 0,
    color: '#fff',
    fontSize: 35,
    display: 'block',
    textAlign: 'center'
  },

  bgIcon: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  logo: {
    padding: '5px',
    "& img": {
      width: 45
    }
  },

  infoContainer: {
    textAlign: 'center',
    paddingTop: '10px',
    fontSize: 10,
    fontWeight: 'bold'
  },

  divider: {
    marginTop: '15px',
    backgroundColor: 'white',
    marginInline: '15px',
    borderRadius: '1px',
    borderTop: '1px'
  },

  adminContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    flexGrow: 1
  },

  expandContainer: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold'
  },

  listItemExpand: {
    marginBottom: '15px'
  }

});

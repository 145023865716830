import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { HISTORIC, RECSYS, SIMILARITY } from "../../constants";

/* Types & Action Creators */

export const { Types, Creators } = createActions({
  contactTypeInit: [],
  contactTypeRequest: [],
  getContactTypes: ["filter"],
  contactTypeSuccess: ["context", "data"],
  contactTypeError: ["err"],
});

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  [HISTORIC]: [],
  [RECSYS]: [],
  [SIMILARITY]: [],
  loading: false,
  error: false,
});

/* Reducers */

export const init = (state) => state.merge({ ...INITIAL_STATE });

export const request = (state) => state.merge({ loading: true });

export const success = (state, { context, data }) =>
  state.merge({ [context]: data, error: false, loading: false });

export const error = (state) => state.merge({ loading: false, error: true });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.CONTACT_TYPE_INIT]: init,
  [Types.CONTACT_TYPE_REQUEST]: request,
  [Types.CONTACT_TYPE_SUCCESS]: success,
  [Types.CONTACT_TYPE_ERROR]: error,
});

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { HISTORIC, RECSYS, SIMILARITY } from '../../constants';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    portRequest: [],
    getPorts: ['filter'],
    portSuccess: ['context', 'data'],
    portError: ['err']
  });
  
  /* Initial State */
  
  export const INITIAL_STATE = Immutable({
    data: [],
    [HISTORIC]: [],
    [RECSYS]: [],
    [SIMILARITY]: [],
    loading: false,
    error: false
  });
  
  /* Reducers */
  
  export const request = state => state.merge({ loading: true });
  
  export const success = (state, { context, data }) => state.merge({ [context]: data, error: false, loading: false });
  
  export const error = state => state.merge({ loading: false, error: true });
  
  /* Reducers to types */
  
  export default createReducer(INITIAL_STATE, {
    [Types.PORT_REQUEST]: request,
    [Types.PORT_SUCCESS]: success,
    [Types.PORT_ERROR]: error
  });